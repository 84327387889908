import { API, apiActions } from 'store/api'
import { browserHistory } from 'react-router'
import { errorHandler } from 'store/logger'
import { setGenericError } from 'store/_service/error'

// ------------------------------------
// Constants
// ------------------------------------
export const TOGGLE_EMAIL_FORM = 'user.login.show.email.form'
export const SHOW_ERROR = 'user.login.error'
export const SET_EMAIL = 'user.set.email'
export const EMAIL_CHANGE = 'user.email.change'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	isLogged: false,
	enteredEmail: '',
	error: '',
	isValid: false,
	emailFormVisible: false,
	autoLogin: null,
}

// ------------------------------------
// Helpers
// ------------------------------------
const showError = value => {
	return {
		type: SHOW_ERROR,
		value: value,
	}
}

const isEmailValid = email => {
	const isEmailValidRegex = /^\S+@\S+\.\S{2,}$/g
	const isEmailValidFormat = isEmailValidRegex.test(email)
	const hasSingleAtSign = email.split('').filter(char => char === '@').length === 1

	return isEmailValidFormat && hasSingleAtSign
}

// ------------------------------------
// Actions
// ------------------------------------
export const toggleEmailForm = () => (dispatch, getState) => {
	dispatch({
		type: TOGGLE_EMAIL_FORM,
	})
}

export const onEmailChange = (email = null) => (dispatch, getState) => {
	dispatch({
		type: EMAIL_CHANGE,
		email,
		isValid: isEmailValid(email),
	})
}

export const login = type => (dispatch, getState) => {
	let email = getState().login.enteredEmail || null
	if (type === 'email') {
		if (email === null) {
			return dispatch(showError('login_error_empty_email'))
		} else if (isEmailValid(email) === false) {
			return dispatch(showError('login_error_invalid_email'))
		} else {
			dispatch({ type: SET_EMAIL, enteredEmail: email })
		}
	}

	return apiActions
		.login(type, { email: email })(dispatch, getState)
		.then(apiResponse => {
			let state = getState()

			if (apiResponse.type === API.LOGIN.ERROR) {
				setGenericError(
					true,
					apiResponse.status,
					apiResponse.errorTrace,
					apiResponse.errorId,
					apiResponse.errorMessage,
				)(dispatch, getState)
			} else if (state.api.token !== null) {
				browserHistory.push('/solve/' + state.user.idStudy)
			}
		})
}

export const autoLogin = () => (dispatch, getState) => {
	let state = getState()
	return apiActions
		.login(state.login.autoLogin.type, state.login.autoLogin.loginInfo)(dispatch, getState)
		.then(apiResponse => {
			// eslint-disable-line no-shadow
			if (apiResponse.type === API.LOGIN.SUCCESS && apiResponse.response.token) {
				browserHistory.push('/solve/' + state.user.idStudy)
			} else {
				setGenericError(
					true,
					apiResponse.status,
					apiResponse.errorTrace,
					apiResponse.errorId,
					apiResponse.errorMessage,
				)(dispatch, getState)
				errorHandler(new Error(API.LOGIN.ENDPOINT), apiResponse)(dispatch, getState)
			}

			return apiResponse
		})
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.LOGIN.STARTED]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.error = initialState.error

		return newState
	},
	[API.LOGIN.ERROR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.error = 'login_error_unable_to_login'

		return newState
	},
	[API.REQUEST_STUDY_ENTRY.SUCCESS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.enteredEmail = initialState.enteredEmail
		newState.autoLogin = action.response.autoLogin

		return newState
	},
	[API.LOGIN.ERROR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.error = 'login_error_unable_to_login'

		return newState
	},
	[SET_EMAIL]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.enteredEmail = action.enteredEmail

		return newState
	},
	[SHOW_ERROR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.error = action.value

		return newState
	},
	[TOGGLE_EMAIL_FORM]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.enteredEmail = ''
		newState.error = ''
		newState.emailFormVisible = !newState.emailFormVisible

		return newState
	},
	[EMAIL_CHANGE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.enteredEmail = action.email
		newState.isValid = action.isValid
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
