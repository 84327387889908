import _ from 'lodash'
import { API } from 'store/api'
import { reportOption } from 'store/_chat/actions'
import { reportContentFormFields, reasonCodes } from 'constants/reportContentForm'

// ------------------------------------
// Constants
// ------------------------------------
export const REPORT_CONTENT_FORM_INITIALIZE = 'report.content.form.set.data'
export const REPORT_CONTENT_FORM_TOGGLE_VISIBILITY = 'report.content.form.toggle.visibility'
export const REPORT_CONTENT_FORM_CHANGE_FORM_DATA = 'report.content.form.change.form'
export const REPORT_CONTENT_FORM_ADD_REPORT = 'report.content.form.add.report'
export const REPORT_CONTENT_FORM_RESET = 'report.content.form.reset'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	isVisible: false,
	isSubmitted: false,
	idStudyObject: null,
	stepData: [],
	formData: {
		idStatement: '',
		inappropriate: false,
		profane: false,
		gibberish: false,
		irrelevant: false,
		differentLanguage: false,
		other: false,
		note: '',
	},
	flagged: [],
	toFlag: [],
}

// ------------------------------------
// Actions
// ------------------------------------
const initialize = (studyObject, stepData) => (dispatch, getState) => {
	dispatch({
		type: REPORT_CONTENT_FORM_INITIALIZE,
		studyObject,
		stepData,
	})
}

const showReportContentForm = () => (dispatch, getState) => {
	dispatch({ type: REPORT_CONTENT_FORM_TOGGLE_VISIBILITY, isVisible: true })
}

export const open = (studyObject, stepData) => (dispatch, getState) => {
	initialize(studyObject, stepData)(dispatch, getState)
	showReportContentForm()(dispatch, getState)
}

export const hideReportContentForm = () => (dispatch, getState) => {
	dispatch({ type: REPORT_CONTENT_FORM_TOGGLE_VISIBILITY, isVisible: false })
}

export const changeFormData = (id, value) => (dispatch, getState) => {
	dispatch({
		type: REPORT_CONTENT_FORM_CHANGE_FORM_DATA,
		id,
		value,
	})
}

export const addReport = () => (dispatch, getState) => {
	const formData = getState().chatReportContentForm.formData
	reportOption(formData.idStatement)(dispatch, getState)
	dispatch({
		type: REPORT_CONTENT_FORM_ADD_REPORT,
		formData,
	})
}

export const reset = () => (dispatch, getState) => {
	dispatch({ type: REPORT_CONTENT_FORM_RESET })
}

export const actions = {
	addReport,
	changeFormData,
	hideReportContentForm,
	open,
	reset,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		const newState = _.cloneDeep(state)
		newState.flagged = action.response.flagged ? action.response.flagged.slice() : []
		return newState
	},
	[REPORT_CONTENT_FORM_INITIALIZE]: (state, action) => {
		const newState = _.cloneDeep(state)

		newState.idStudyObject = action.studyObject.id

		newState.formData = _.cloneDeep(initialState.formData)

		if (action.studyObject.type === 'A_OEQ_ELABORATE') {
			newState.stepData = [_.cloneDeep(action.stepData)]
		}

		if (action.studyObject.type === 'A_OEQ_ELIMINATE') {
			newState.stepData = action.stepData.suggestions.filter(
				suggestion => suggestion.canFlag === true,
			)
		}

		newState.flagged = state.flagged.slice()

		// filter out already reported statements
		newState.stepData = newState.stepData.filter(
			statement => !newState.flagged.find(report => report.idStatement === statement.idStatement),
		)

		// set forms idStatement if there is only 1 unreported statement
		if (newState.stepData.length === 1) {
			newState.formData.idStatement = newState.stepData[0].idStatement
		}

		return newState
	},
	[REPORT_CONTENT_FORM_TOGGLE_VISIBILITY]: (state, action) => {
		const newState = _.cloneDeep(state)
		newState.isVisible = action.isVisible
		newState.isSubmitted = false
		return newState
	},
	[REPORT_CONTENT_FORM_CHANGE_FORM_DATA]: (state, action) => {
		const newState = _.cloneDeep(state)
		newState.formData[action.id] = action.value

		if (action.id === reportContentFormFields.OTHER && action.value === false) {
			newState.formData.note = ''
		}

		return newState
	},
	[REPORT_CONTENT_FORM_ADD_REPORT]: (state, action) => {
		const newState = _.cloneDeep(state)
		newState.flagged = state.flagged.slice()

		const reasons = []

		Object.keys(action.formData).map(key => {
			if (action.formData[key] === true) {
				reasons.push(reasonCodes[key])
			}
		})

		const newReport = {
			idStatement: action.formData.idStatement,
			reason: {
				reasons,
				note: action.formData.note,
			},
		}

		newState.flagged.push(newReport)
		newState.toFlag.push(newReport)
		newState.isSubmitted = true

		return newState
	},
	[REPORT_CONTENT_FORM_RESET]: (state, action) => {
		const newState = _.cloneDeep(initialState)
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
