/* eslint-disable max-len */
const messages = {
	not_found: "La page que vous cherchez n'existe pas",
	error_unexpected_error:
		'Un problème inattendu est survenu. Veuillez essayer à nouveau plus tard.',

	error_img_alt: "Image d'erreur",
	error_try_again: 'Réessayer',

	error_generic_header: 'Un problème est survenu',
	error_generic_message:
		'Essayez à nouveau, et si cela ne fonctionne toujours pas, envoyez un courriel à support@groupsolver.com avec le code QR que vous voyez ci-dessus.',

	error_offline_header: 'Vous semblez être déconnecté',
	error_offline_message: 'Votre enquête reprendra une fois que votre connexion est rétablie.',

	error_landscape_small_screen_header: 'Veuillez faire pivoter votre appareil.',
	error_landscape_small_screen_text:
		'Votre écran est trop petit pour afficher cette enquête. Veuillez faire pivoter votre appareil.',

	error_small_browser_window_header: 'Veuillez agrandir la fenêtre de votre navigateur.',
	error_small_browser_window_text:
		'Votre fenêtre est trop petite pour afficher cette enquête. Veuillez agrandir la fenêtre de votre navigateur.',

	login_error_empty_email: 'Le courrier électronique ne peut pas être laissé vierge.',
	login_error_invalid_email: 'Veuillez saisir un courrier électronique valide.',
	login_error_unable_to_login:
		'Impossible de se connecter pour le moment. Veuillez essayer à nouveau plus tard.',

	login_email_placeholder: 'Saisissez votre courrier électronique.',
	login_email_button: 'Continuer',
	login_email_back: 'ou revenir aux options de connexion',
	login_email_label: 'Continuer avec le courrier électronique',
	login_anonymous_label: 'Continuer anonymement',

	login_privacy_statement:
		'GroupSolver respecte votre vie privée et les informations que vous fournissez. ',
	login_privacy_statement_email:
		"Votre adresse électronique ne sera jamais partagée, vendue ou utilisée sauf pour vous permettre d'accéder à ce projet.",
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: "L'étude que vous recherchez n'est pas disponible.",
	study_unavailable_description:
		'Merci de votre intérêt pour notre étude! Malheureusement, cette étude est déjà terminée ou est actuellement interrompue. Nous nous excusons pour la gêne occasionnée.',
	study_preview_limit_reached_label: "Vous avez atteint votre limite d'aperçu",
	study_preview_limit_reached_description:
		"Merci pour votre intérêt dans notre étude ! Malheureusement, le nombre d'aperçus disponibles a été atteint.",

	waiting_message:
		"Cette enquête est actuellement ouverte par plusieurs personnes. \n Nous réessayerons automatiquement d'entrer {number, plural, =0 { maintenant } one {dans # seconde} other {dans # secondes}}.",
	waiting_error_recoverable: 'Nous avons rencontré une erreur inattendue.',
	waiting_error_recoverable_retry:
		'Nous réessayerons {number, plural, =0 { maintenant } one {dans # seconde} other {dans # secondes}}.',
	waiting_error_unknown_study: "L'enquête que vous recherchez n'existe pas.",
	waiting_error_dead: 'Un problème inattendu est survenu. Veuillez essayer à nouveau plus tard.',

	action_input_submit_button_text: 'SOUMETTRE',
	action_input_skip_button_text: 'CONTINUER',

	choice_single_hint: "Choisissez l'une des options",
	general_skip_action_message: "Passer à l'étape suivante",

	action_button_primary_default_text: 'Action',
	action_button_hint_default_text: "Choisissez l'une des options",

	message_media_examine_video: "Cliquez sur la vidéo pour l'analyser",
	message_media_examine_image: "Cliquez sur l'image pour l'analyser",
	message_full_screen_close_button_text: 'Fermer',
	message_image_view_label: "Afficher l'image",
	message_video_view_label: 'Afficher la vidéo',
	message_image_examined_label: 'Image analysée',
	message_video_examined_label: 'Vidéo analysée',
	message_zoom_mouse: 'Survoler pour zoomer',
	message_zoom_touch: 'Toucher pour zoomer',

	textarea_too_long_hint: 'Votre texte est trop long',

	ideate_placeholder: 'Répondez par des phrases courtes',
	ideate_skip: ' ',

	eliminate_no: 'Non',
	eliminate_yes: 'Oui',
	eliminate_intro: 'Auparavant, vous avez dit:',
	eliminate_multiple_suggestions: 'Lequel des énoncés suivants pourrait remplacer votre réponse ?',
	eliminate_one_suggestion: 'La réponse suivante pourrait-elle remplacer la vôtre ?',
	eliminate_step: 'Une autre réponse de votre part était :',
	eliminate_none_of_the_above: 'Aucune de ces réponses',

	elaborate_indifferent_label: 'Je ne suis pas sûr(e)',
	elaborate_disagree_label: "Je ne suis pas d'accord",
	elaborate_agree_label: "Je suis d'accord",
	elaborate_unclear_statement_label: 'Réponse floue',
	elaborate_intro:
		"D'autres personnes ont donné les réponses suivantes. Êtes-vous d'accord avec elles ?",

	ideamatching_unsure_label: 'Je ne suis pas sûr(e)',
	ideamatching_unequal_label: 'Différent',
	ideamatching_equal_label: 'Identique',
	ideamatching_intro: "Lisez ces deux réponses. Est-ce qu'elles ont la même signification ?",

	select_input_placeholder: 'Tapez ici pour sélectionner',
	select_input_placeholder_max: 'Maximum atteint',
	select_multiple_choice_hint:
		'Choisissez {minNumber, plural, =0 { 0 } other { # }} à {maxNumber, plural, =0 { 0 } other { # }} options',
	select_no_matches_found: 'Aucun résultat',

	report_content_inappropriate: 'Réponse inappropriée',
	report_content_profane: 'Contenus obscènes ou profanes',
	report_content_gibberish: 'Charabia ou textes insensés',
	report_content_irrelevant: 'N’a rien à voir avec la question posée',
	report_content_different_language: 'Langue différente',
	report_content_other: 'Autre',
	report_content_no_content: 'Pas de contenu à signaler',
	report_content_header: 'Signaler une réponse',
	report_content_form_header:
		'Veuillez sélectionner la raison pour laquelle vous souhaitez signaler cette réponse',
	report_content_flag_answer: 'Signaler une réponse',
	report_content_return_to_study: 'Retour à l’enquête',
	report_content_thank_you: `Merci pour vos commentaires.\n
	Nous examinerons vos remarques et nous prendrons des mesures si nécessaire.'`,
	report_content_statement_flagged: 'Ces propos ont été signalés.',

	platform_update_header: 'GroupSolver est actuellement en cours de maintenance',
	platform_update_message: 'Veuillez nous excuser pour la gêne occasionnée.',

	none_of_these: 'Aucun de ces',

	allocation_instruction_limit: 'Allouer {points} entre les options',
	allocation_instruction_limit_no_minimum: 'Allouer jusqu’à {points} entre les options',
	allocation_instruction_no_limit: 'Allouer points entre les options',
	allocation_instruction_points_left: '{points} restants',
	allocation_options_out_of_range: 'Certaines options sont hors de portée',
	allocation_limit_overflow: '{points} au-dessus de la limite',
	allocation_option_invalid_range: 'La valeur est hors de la {range} plage',
	allocation_option_invalid_min: 'La valeur doit être supérieure ou égale à {min}',

	dropdown_select: 'Sélectionner...',

	ranking_drag_options: 'Faites glisser ou cliquez sur les flèches pour classer les options',
	ranking_assign_option_values: 'Classer toutes les options',
	ranking_require_unique_values: 'Les rangs des options doivent être uniques',

	heatmap_instruction_intro: "Pour commencer la sélection, regardez l'image ci-dessous.",
	heatmap_instruction_unlimited:
		"Sélectionnez autant de points que vous le souhaitez en cliquant sur l'image.",
	heatmap_instruction_count:
		"Sélectionnez {number, plural, =0 {0 points} one {un point} other {{number} points}} en cliquant sur l'image.",
	heatmap_instruction_up_to_count:
		"Sélectionnez {number, plural, =0 {0 points} one {un point} other {jusqu'à {number} points}} en cliquant sur l'image.",
	heatmap_instruction_between_count:
		"Sélectionnez de {from} à {to} points en cliquant sur l'image.",
	heatmap_instruction_at_least_count:
		"Sélectionnez au moins {number, plural, =0 {0 points} one {un point} other {{number} points}} en cliquant sur l'image.",
	heatmap_response:
		'{number, plural, =0 {0 points} one {1 point} other {{number} points}} sélectionnés',

	continue: 'Continuer',
	reset: 'Réinitialiser',

	input_invalid: 'Entrée non valide.',
	input_empty: "L'entrée ne peut pas être vide.",
	input_chars_left:
		'{number} {number, plural, =0 {caractères} one {caractère} other {caractères}} restants.',
	input_too_long:
		'{number} {number, plural, =0 {caractères} one {caractère} other {caractères}} au-delà de la limite.',

	// accessibility
	ranking_select_rank: 'Sélectionnez le rang de {label}.',

	ranking_select_aria_label: 'Sélectionnez le rang de chaque option en saisissant une valeur.',
	ranking_dnd_aria_label:
		"Concentrez-vous sur une option et appuyez sur la barre d'espace pour commencer un glisser-déposer. Lorsque vous faites glisser, vous pouvez utiliser les touches fléchées pour déplacer l'élément et échapper pour annuler. Appuyez de nouveau sur la barre d'espace pour placer l'élément à la nouvelle position. Assurez-vous que votre lecteur d'écran est en mode focus ou en mode formulaire.",

	allocation_option_input: 'Saisie de la valeur {label}.',
	allocation_aria_label: 'Entrez une valeur pour chaque option en utilisant les champs de saisie.',

	play: 'Lecture',
	pause: 'Pause',
	stop: 'Arrêter',
	fwd: 'Avancer',
	rwd: 'Reculer',
	volume_down: 'Diminuer le volume',
	volume_up: 'Augmenter le volume',
	volume_current: 'Volume : {number}%',

	loading: 'Chargement',

	press_tab_to_continue: 'Appuyez sur tab pour continuer',

	option_focus_selected: 'Option {label} focalisée. Appuyez sur entrer pour désélectionner.',
	option_focus_not_selected: 'Option {label} focalisée. Appuyez sur entrer pour sélectionner.',

	image_alt: 'image :',
	video_alt: 'vidéo :',

	submit_enabled: 'Soumission activée.',
	skip_enabled: 'Passer la question activé',
	n_of_min_selected: '{selected} sur {min} sélectionnés.',
	option_selected: '{label} sélectionné.',
	option_deselected: '{label} désélectionné.',

	choice_multiple_hint: 'Sélectionnez {min} à {max} options.',

	ranking_position: 'position : {position}',
	ranking_position_changed: 'Valeur de {label} définie à {value}.',
	ranking_input_aria_label:
		'{label}. Position actuelle : {position}. Tapez une position de 1 à {max}.',
	not_selected: 'non sélectionné',

	study_logo_alt: "Logo de l'étude",
	groupsolver_logo_alt: 'Logo Groupsolver',
}

export default messages
