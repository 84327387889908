import moment from 'moment'
import _ from 'lodash'
import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import { API } from 'store/api'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { COMPONENTS, POSITION } from 'constants/component'
import { DELAY_LENGTH } from 'constants/delay'
import { CHOICE_QUESTION_LOG_ACTIONS } from 'constants/choiceQuestionLog'
import { FREE_TEXT_QUESTION_LOG_ACTIONS } from 'constants/freeTextQuestionActionLog'
import { MODULE_TYPES } from 'constants/moduleTypes'
import { addAction, addTempMessage } from 'store/queue'
import { createComponent, removeTempMessages } from 'store/_chat/history'
import { formatMessage, getIsStudyRtl } from 'store/intl'
import {
	actionsHide,
	actionsShow,
	getActionOptions,
	setOptionsChangeListener,
	setRecordLogItem,
} from 'store/_chat/actions'
import {
	setActionSubmitListener,
	showActionButton,
	showActionHint,
	showActionLoader,
} from 'store/_chat/actionBar'
import {
	getInputValue,
	hideInput,
	invalidInput,
	setInputChangeListener,
	validInput,
} from 'store/_chat/input'
import { showStep as showFreeTextStep } from 'store/_modules/freeText'

import { getStudyObjectDefinition, getStudyObject } from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'researchLabeling.add.response'
export const RESET_RESPONSES = 'researchLabeling.reset.responses'
export const SET_STEP_DATA = 'researchLabeling.set.step.data'
export const RESET_RESEARCH_LABELING_LOG = 'researchLabeling.reset.log'
export const RECORD_RESEARCH_LABELING_LOG_ACTION = 'researchLabeling.log.record.action'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
	stepData: null,
	log: [],
}

// ------------------------------------
// Helpers
// ------------------------------------
export const getResearchLabelingResponses = getState => {
	return getState().researchLabeling.responses
}

export const getResearchLabelingLog = getState => {
	return getState().researchLabeling.log
}

export const getResearchLabelingStepData = getState => {
	return getState().researchLabeling.stepData
}

export const resetResearchLabelingLog = () => ({ type: RESET_RESEARCH_LABELING_LOG })

const recordResearchLabelingLogItem = (
	timestamp,
	idStudyObject,
	studyObjectType,
	action,
	data = null,
) => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const responses = getResearchLabelingResponses(getState)

	const currentStep = definition.steps[responses.length]

	dispatch({
		type: RECORD_RESEARCH_LABELING_LOG_ACTION,
		action: {
			timestamp,
			idStudyObject,
			idResearchLabeling: currentStep.idResearchLabeling,
			studyObjectType,
			action,
			data,
		},
	})
}

export const resetResponses = () => ({ type: RESET_RESPONSES })

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	if (module.priorMessage !== null) {
		const solverMessagePosition =
			getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT
		const priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		addTempMessage(priorMessage, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep(module.steps[0])(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const showNextStep = stepData => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)
	dispatch({ type: SET_STEP_DATA, stepData })

	if (stepData.message !== null) {
		const introMessage = createComponent(COMPONENTS.MESSAGE)

		introMessage.props.text = stepData.message.definition.text
		introMessage.props.media = stepData.message.definition.media

		const solverMessagePosition =
			getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT
		addTempMessage(introMessage, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	if (stepData.question.type === MODULE_TYPES.CHOICE) {
		setOptionsChangeListener(optionsChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
		setActionSubmitListener(choiceSubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define
		setRecordLogItem(recordResearchLabelingLogItem)(dispatch, getState)

		const options = stepData.question.definition.options.map(option => ({
			id: option.id,
			label: option.label,
			selected: false,
		}))

		recordResearchLabelingLogItem(
			moment().toISOString(),
			studyObject.id,
			studyObject.type,
			CHOICE_QUESTION_LOG_ACTIONS.OPTIONS_DISPLAYED,
			{
				options,
				currentMessage: _.get(stepData, 'message.definition.text', null),
			},
		)(dispatch, getState)

		addAction(actionsShow, [CHOICE_SUBTYPE.CHECKBOX, options])(dispatch, getState)
		addAction(showActionButton, [ACTION_BUTTON_STATE.DEFAULT, submitButtonText])(dispatch, getState)
	}

	if (stepData.question.type === MODULE_TYPES.FREE_TEXT) {
		setInputChangeListener(inputChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
		setActionSubmitListener(freeTextSubmitListener, freeTextSkipListener)(dispatch, getState) // eslint-disable-line no-use-before-define

		const { inputHint, placeholder } = stepData.question.definition

		recordResearchLabelingLogItem(
			moment().toISOString(),
			studyObject.id,
			studyObject.type,
			FREE_TEXT_QUESTION_LOG_ACTIONS.INPUT_VISIBLE,
			{
				inputHint,
				placeholder,
				currentMessage: _.get(stepData, 'message.definition.text', null),
			},
		)(dispatch, getState)

		if (inputHint === null) {
			addAction(showActionButton, [ACTION_BUTTON_STATE.DEFAULT, submitButtonText])(
				dispatch,
				getState,
			)
		} else {
			addAction(showActionHint, [inputHint])(dispatch, getState)
		}

		showFreeTextStep({
			...stepData.question.definition,
			maxLength: stepData.question.definition.maxInputLength,
		})(dispatch, getState)
	}
}

export const endStep = () => (dispatch, getState) => {
	dispatch({ type: SET_STEP_DATA, stepData: null })

	const studyObject = getStudyObject()(dispatch, getState)
	const responses = getResearchLabelingResponses(getState)

	const apiResponse = {
		[studyObject.id]: responses,
	}
	const researchLabelingLog = getResearchLabelingLog(getState)

	dispatch(resetResearchLabelingLog())
	dispatch(resetResponses())

	getState().study.getNextStep(apiResponse, researchLabelingLog, true)(dispatch, getState)
}

// ------------------------------------
// Choice Listeners
// ------------------------------------
const handleSingle = selectedOptions => (dispatch, getState) => {
	const buttonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	if (selectedOptions.length === 1) {
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	} else {
		showActionButton(ACTION_BUTTON_STATE.DEFAULT, buttonText)(dispatch, getState)
	}
}

const handleMultiple = (selectedOptions, definition) => (dispatch, getState) => {
	const buttonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	const { minSelection, maxSelection } = definition
	const canSubmit = selectedOptions >= minSelection && selectedOptions <= maxSelection

	if (canSubmit === true) {
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	} else {
		const percent = (selectedOptions.length / minSelection) * 100

		showActionButton(
			ACTION_BUTTON_STATE.SUBMIT_PREP,
			buttonText,
			ACTION_BUTTON_LOADER_TYPE.PERCENT,
			percent,
		)(dispatch, getState)
	}
}

export const optionsChangeListener = options => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const selectedOptions = options.filter(option => option.selected)

	if (definition.multiple === true) {
		handleMultiple(selectedOptions, definition)(dispatch, getState)
	} else {
		handleSingle(selectedOptions)(dispatch, getState)
	}
}

export const choiceSubmitListener = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)

	const options = getActionOptions()(dispatch, getState)
	const studyObject = getStudyObject()(dispatch, getState)
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const responses = getResearchLabelingResponses(getState)

	const currentStep = getResearchLabelingStepData(getState)
	const selectedOptions = options.filter(option => option.selected === true)

	const idResearchLabeling = currentStep.idResearchLabeling
	const newResponse = {
		idResearchLabeling,
		answer: selectedOptions.map(option => option.id),
	}

	recordResearchLabelingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		CHOICE_QUESTION_LOG_ACTIONS.SUBMIT,
		{ selectedOptions },
	)(dispatch, getState)

	dispatch({ type: ADD_RESPONSE, newResponse })
	removeTempMessages()(dispatch, getState)

	if (responses.length + 1 === definition.steps.length) {
		endStep()(dispatch, getState)
	} else {
		const nextStep = definition.steps[responses.length + 1]

		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			showNextStep(nextStep)(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	}
}

// ------------------------------------
// FreeText Listeners
// ------------------------------------
export const handleValidInput = (input, definition) => (dispatch, getState) => {
	const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	if (definition.maxInputLength === null) {
		validInput('')(dispatch, getState)
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText)(dispatch, getState)

		return
	}

	const remainingChars = definition.maxInputLength - input.length

	if (remainingChars >= 0) {
		validInput(remainingChars)(dispatch, getState)
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText)(dispatch, getState)

		return
	}

	invalidInput(remainingChars)(dispatch, getState)
	const hintText = formatMessage('textarea_too_long_hint')(dispatch, getState)
	showActionHint(hintText)(dispatch, getState)
}

export const handleInvalidInput = (input, definition) => (dispatch, getState) => {
	const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

	if (definition.maxInputLength !== null) {
		const remainingChars = definition.maxInputLength - input.length
		invalidInput(remainingChars)(dispatch, getState)
	} else {
		invalidInput('')(dispatch, getState)
	}

	if (input.length > 0) {
		showActionButton(
			ACTION_BUTTON_STATE.DEFAULT,
			definition.inputHint !== null ? definition.inputHint : submitButtonText,
		)(dispatch, getState)

		return
	}

	if (definition.mandatory) {
		const hintText = definition.inputHint
			? definition.inputHint
			: formatMessage('action_input_submit_button_text')(dispatch, getState)

		addAction(showActionHint, [hintText])(dispatch, getState)

		return
	}

	showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
}

export const inputChangeListener = input => (dispatch, getState) => {
	const stepData = getResearchLabelingStepData(getState)
	const definition = stepData.question.definition

	const testPattern = new RegExp(definition.validationPattern || '.*')
	const isValid = testPattern.test(input)

	if (input.length > 0 && isValid) {
		handleValidInput(input, definition)(dispatch, getState)
	} else {
		handleInvalidInput(input, definition)(dispatch, getState)
	}
}

export const freeTextSubmitListener = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)

	const input = getInputValue()(dispatch, getState)
	const studyObject = getStudyObject()(dispatch, getState)
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const responses = getResearchLabelingResponses(getState)

	const currentStep = getResearchLabelingStepData(getState)
	const idResearchLabeling = currentStep.idResearchLabeling
	const newResponse = {
		idResearchLabeling,
		answer: [input],
	}

	recordResearchLabelingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		FREE_TEXT_QUESTION_LOG_ACTIONS.SUBMIT,
		{ answer: input },
	)(dispatch, getState)

	dispatch({ type: ADD_RESPONSE, newResponse })
	removeTempMessages()(dispatch, getState)
	hideInput()(dispatch, getState)

	if (responses.length + 1 === definition.steps.length) {
		endStep()(dispatch, getState)
	} else {
		const nextStep = definition.steps[responses.length + 1]

		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			showNextStep(nextStep)(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	}
}

export const freeTextSkipListener = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const responses = getResearchLabelingResponses(getState)

	const currentStep = getResearchLabelingStepData(getState)
	const idResearchLabeling = currentStep.idResearchLabeling
	const newResponse = {
		idResearchLabeling,
		answer: ['skipped'],
	}

	recordResearchLabelingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		FREE_TEXT_QUESTION_LOG_ACTIONS.SKIP,
		{},
	)(dispatch, getState)

	dispatch({ type: ADD_RESPONSE, newResponse })
	removeTempMessages()(dispatch, getState)
	hideInput()(dispatch, getState)

	if (responses.length + 1 === definition.steps.length) {
		endStep()(dispatch, getState)
	} else {
		const nextStep = definition.steps[responses.length + 1]

		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			showNextStep(nextStep)(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	}
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		return _.cloneDeep(initialState)
	},
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses, action.newResponse]

		return newState
	},
	[RESET_RESPONSES]: state => {
		return { ...state, responses: [] }
	},
	[SET_STEP_DATA]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.stepData = action.stepData

		return newState
	},
	[RECORD_RESEARCH_LABELING_LOG_ACTION]: (state, action) => {
		const newState = { ...state }
		newState.log = state.log.slice()
		newState.log.push(action.action)

		return newState
	},
	[RESET_RESEARCH_LABELING_LOG]: state => {
		return { ...state, log: [] }
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
