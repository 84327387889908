const messages = {
	not_found: 'Den side, du leder efter, findes ikke',
	service_outage:
		'Vi beklager, at vores system er nede på grund af et globalt udfald af vores udbyders skytjenester.',
	error_unexpected_error: 'Der opstod et uventet problem. Prøv igen senere.',

	error_img_alt: 'Fejl billede',
	error_try_again: 'Prøv igen',

	error_generic_header: 'Noget gik galt',
	error_generic_message:
		'Prøv det igen, og hvis det stadig ikke virker, kan du sende en e-mail til support@groupsolver.com med den QR-kode, du ser ovenfor.',

	error_offline_header: 'Det ser ud til, at du er offline',
	error_offline_message: 'Din undersøgelse genoptages, når din forbindelse er genoprettet.',

	error_landscape_small_screen_header: 'Roter din enhed',
	error_landscape_small_screen_text:
		'Din skærm er for kort til at se denne undersøgelse. Roter din enhed.',

	error_small_browser_window_header: 'Forstør dit browservindue',
	error_small_browser_window_text:
		'Dit vindue er for lille til at se denne undersøgelse. Forstør dit browservindue',

	login_error_empty_email: 'E-mail må ikke være tom',
	login_error_invalid_email: 'Indtast en gyldig e-mailadresse',
	login_error_unable_to_login: 'Kan ikke logge ind på nuværende tidspunkt. Prøv igen senere.',

	login_email_placeholder: 'Indtast din e-mailadresse',
	login_email_button: 'Fortsæt',
	login_email_back: 'eller vend tilbage til loginindstillinger',
	login_email_label: 'Fortsæt med e-mail',
	login_anonymous_label: 'Fortsæt anonymt',

	login_privacy_statement: 'GroupSolver respekterer dit privatliv og de oplysninger, du giver.',
	login_privacy_statement_email:
		'Din e-mailadresse vil aldrig blive delt, solgt eller brugt på anden måde udover til at give dig adgang til dette projekt.',
	login_privacy_policy: 'Fortrolighedspolitik',

	study_unavailable_label: 'Undersøgelsen, du leder efter, er i øjeblikket ikke tilgængelig.',
	study_unavailable_description:
		'Tak for din interesse i vores undersøgelse! Desværre er denne undersøgelse allerede fyldt op eller den er sat på pause i øjeblikket. Vi beklager enhver ulejlighed, det måtte have forårsaget.',
	study_preview_limit_reached_label: 'Du har nået din grænse for forhåndsvisning',
	study_preview_limit_reached_description:
		'Tak for din interesse i vores undersøgelse! Desværre har denne undersøgelse nået antallet af tilgængelige forhåndsvisninger.',

	waiting_message:
		'Flere personer er i gang med at gennemføre denne undersøgelse lige nu.\nVi forsøger automatisk at åbne den igen {number, plural, =0 { nu} one {om # sekund} other {om # sekunder}}.',
	waiting_error_recoverable: 'Der opstod en uventet fejl.',
	waiting_error_recoverable_retry:
		'Vi prøver igen {number, plural, =0 { nu} one {om # sekund} other {om # sekunder}}',
	waiting_error_unknown_study: 'Undersøgelsen, du leder efter, findes ikke.',
	waiting_error_dead: 'Der opstod et uventet problem. Prøv igen senere.',

	action_input_submit_button_text: 'INDSEND',
	action_input_skip_button_text: 'Fortsæt',

	choice_single_hint: 'Vælg en af følgende muligheder',
	general_skip_action_message: 'Fortsæt til næste trin',

	action_button_primary_default_text: 'Handling',
	action_button_hint_default_text: 'Vælg en af mulighederne',

	message_media_examine_video: 'Klik på videoen for at anmelde den',
	message_media_examine_image: 'Klik på billedet for at anmelde det',
	message_full_screen_close_button_text: 'Luk',
	message_image_view_label: 'Vis billede',
	message_video_view_label: 'Vis video',
	message_image_examined_label: 'Billede anmeldt',
	message_video_examined_label: 'Video anmeldt',
	message_zoom_mouse: 'Hold musens markør over stedet for at zoome',
	message_zoom_touch: 'Berør for at zoome',

	textarea_too_long_hint: 'Din tekst er for lang',

	ideate_placeholder: 'Svar i korte sætninger',
	ideate_skip: ' eller fortsæt',

	eliminate_no: 'Nej',
	eliminate_yes: 'Ja',
	eliminate_intro: 'Tænk på følgende udsagn:',
	eliminate_multiple_suggestions: 'Hvilken af de følgende kan erstatte den?',
	eliminate_one_suggestion: 'Kan den erstattes med følgende?',
	eliminate_step: 'Tænk på et andet udsagn',
	eliminate_none_of_the_above: 'Ingen af dem',

	elaborate_indifferent_label: 'Neutral',
	elaborate_disagree_label: 'Uenig',
	elaborate_agree_label: 'Enig',
	elaborate_unclear_statement_label: 'Uklart svar',
	elaborate_intro: 'Andre gav følgende svar. Er du enig med dem?',

	ideamatching_unsure_label: 'Ikke sikker',
	ideamatching_unequal_label: 'Forskellig',
	ideamatching_equal_label: 'Samme',
	ideamatching_intro: 'Se på det her svarpar. Har de samme betydning?',

	select_input_placeholder: 'Skriv her for at vælge',
	select_input_placeholder_max: 'Maksimum nået',
	select_multiple_choice_hint:
		'Vælg {minNumber, plural, =0 { 0 } other { # }} til {maxNumber, plural, =0 { 0 } other { # }} muligheder',
	select_no_matches_found: 'Ingen match fundet',

	report_content_inappropriate: 'Upassende svar',
	report_content_profane: 'Uanstændigt eller profant indhold',
	report_content_gibberish: 'Teksten giver ikke mening',
	report_content_irrelevant: 'Irrelevant i forhold til det stillede spørgsmål',
	report_content_different_language: 'Andet sprog',
	report_content_other: 'Andet',
	report_content_no_content: 'Intet indhold at rapportere',
	report_content_header: 'Rapportér svar',
	report_content_form_header: 'Vælg årsagen til, at du vil rapportere dette svar',
	report_content_flag_answer: 'Rapportér svar',
	report_content_return_to_study: 'Vend tilbage til undersøgelsen',
	report_content_thank_you:
		'Tak for din feedback.\nVi vil gennemgå dit input og gøre noget ved det, hvis det er nødvendigt.',
	report_content_statement_flagged: 'Udsagn er rapporteret.',

	platform_update_header: 'GroupSolver er i øjeblikket under planlagt vedligeholdelse',
	platform_update_message: 'Vi beklager ulejligheden.',

	none_of_these: 'Ingen af disse',

	allocation_instruction_limit: 'Tildel {point} på tværs af svarmuligheder',
	allocation_instruction_limit_no_minimum: 'Tildel op til {point} på tværs af svarmuligheder',
	allocation_instruction_no_limit: 'Tildel point på tværs af svarmuligheder',
	allocation_instruction_points_left: '{point} tilbage',
	allocation_options_out_of_range: 'Nogle svarmuligheder er uden for rækkevidde',
	allocation_limit_overflow: '{points} over grænseværdien',
	allocation_option_invalid_range: 'Værdien er uden for {range} område',
	allocation_option_invalid_min: 'Værdien skal være større end eller lig med {min}',

	dropdown_select: 'Vælg …',

	ranking_drag_options: 'Træk eller klik på pile for at rangere svarmuligheder',
	ranking_assign_option_values: 'Ranger alle svarmulighederne',
	ranking_require_unique_values: 'Svarmulighederne skal være unikke',

	heatmap_instruction_intro: 'For at begynde at vælge, se billedet nedenfor.',
	heatmap_instruction_unlimited: 'Vælg så mange punkter, som du ønsker, ved at klikke på billedet.',
	heatmap_instruction_count:
		'Vælg {number, plural, =0 {0 punkter} one {et punkt} other {{number} punkter}} ved at klikke på billedet.',
	heatmap_instruction_up_to_count:
		'Vælg op til {number, plural, =0 {0 punkter} one {et punkt} other {op til {number} punkter}} ved at klikke på billedet.',
	heatmap_instruction_between_count: 'Vælg {from} til {to} punkter ved at klikke på billedet.',
	heatmap_instruction_at_least_count:
		'Vælg mindst {number, plural, =0 {0 punkter} one {et punkt} other {{number} punkter}} ved at klikke på billedet.',
	heatmap_response: '{number, plural, =0 {0 punkter} one {1 punkt} other {{number} punkter}} valgt',

	continue: 'Fortsæt',
	reset: 'Nulstil',

	input_invalid: 'Input er ikke gyldigt.',
	input_empty: 'Input kan ikke være tomt.',
	input_chars_left: '{number} {number, plural, =0 {tegn} one {tegn} other {tegn}} tilbage.',
	input_too_long: '{number} {number, plural, =0 {tegn} one {tegn} other {tegn}} over grænsen.',

	// accessibility
	ranking_select_rank: 'Vælg rang for {label}.',

	ranking_select_aria_label: 'Vælg rang for hver mulighed ved at indtaste en værdi.',
	ranking_dnd_aria_label:
		'Fokuser på en mulighed og tryk på mellemrumstasten for at starte en trækning. Når du trækker, kan du bruge piletasterne til at flytte elementet rundt og escape for at annullere. Tryk på mellemrumstasten igen for at placere elementet på den nye position. Sørg for, at din skærmlæser er i fokus- eller formular-tilstand.',

	allocation_option_input: '{label} værdifelt.',
	allocation_aria_label: 'Indtast en værdi for hver mulighed ved hjælp af inputfelterne.',

	play: 'Afspil',
	pause: 'Pause',
	stop: 'Stop',
	fwd: 'Spring frem',
	rwd: 'Spol tilbage',
	volume_down: 'Skru ned for lyden',
	volume_up: 'Skru op for lyden',
	volume_current: 'Lydstyrke: {number}%',

	loading: 'Indlæser',

	press_tab_to_continue: 'Tryk på tab for at fortsætte',

	option_focus_selected: 'Mulighed {label} fokuseret. Tryk enter for at fravælge.',
	option_focus_not_selected: 'Mulighed {label} fokuseret. Tryk enter for at vælge.',

	image_alt: 'billede:',
	video_alt: 'video:',

	submit_enabled: 'Indsendelse aktiveret.',
	skip_enabled: 'Spring spørgsmål over aktiveret',
	n_of_min_selected: '{selected} af {min} valgt.',
	option_selected: '{label} valgt.',
	option_deselected: '{label} fravalgt.',

	choice_multiple_hint: 'Vælg {min} til {max} muligheder.',

	ranking_position: 'position: {position}',
	ranking_position_changed: '{label} værdi sat til {value}.',
	ranking_input_aria_label:
		'{label}. Nuværende position: {position}. Indtast en position fra 1 til {max}.',
	not_selected: 'ikke valgt',

	study_logo_alt: 'Studie logo',
	groupsolver_logo_alt: 'Groupsolver logo',
}

export default messages
