export const ALLOCATION_SUBTYPE = {
	INPUT: 'INPUT',
}

export const ALLOCATION_QUESTION_LOG_ACTIONS = {
	OPTIONS_DISPLAYED: 'OPTIONS_DISPLAYED',
	OPTION_CHANGED: 'OPTION_CHANGED',
	SUBMIT: 'SUBMIT',
	SKIP: 'SKIP',
}
