import React from 'react'
import PropTypes from 'prop-types'
import './PageLayout.scss'
import StudyLogo from 'components/_scaffolding/StudyLogo'

import config from 'config'

const PageLayout = props => {
	return (
		<div className="PageLayout">
			<div className="PageLayout-container">
				<div className="PageLayout-studyLogo">
					{props.isAutoLoginEnabled === true ? (
						<StudyLogo />
					) : (
						<StudyLogo customLogo={props.customLogo} />
					)}
				</div>
				{props.children}
				{props.customLogo && props.isAutoLoginEnabled === false && (
					<div className="PageLayout-groupsolverLogo">
						<a href={config.clientConfig.web} rel="noopener noreferrer" target="_blank">
							<StudyLogo theme="dark" />
						</a>
					</div>
				)}
			</div>
		</div>
	)
}

PageLayout.propTypes = {
	children: PropTypes.element.isRequired,
	customLogo: PropTypes.string,
	isAutoLoginEnabled: PropTypes.bool.isRequired,
}

export default PageLayout
