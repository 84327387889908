import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addMessage, addAction, queueSetMediaToSeen } from 'store/queue'
import { DELAY_LENGTH } from 'constants/delay'
import { createComponent, setMediaToSeen, setCurrentMessage } from 'store/_chat/history'
import { COMPONENTS, POSITION } from 'constants/component'
import { showActionLoader, showActionHint } from 'store/_chat/actionBar'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { showFullscreen, hideFullscreen } from 'store/_chat/view'
import {
	setMedia,
	showActionButton as fullscreenShowActionButton,
	setActionSubmitListener as fullscreenSetActionSubmitListener,
} from 'store/_chat/fullscreenView'
import { buttonStateUpdateListener } from './_tools/button'
import { setTimerListeners, setTimerInterval, stopInterval, timerReset } from 'store/_chat/timer'
import { MEDIA_TYPE } from 'constants/media'

import { getStudyObjectDefinition, getStudyObject } from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_IS_TIMER_FINISHED = 'message.set.timer.finished'
export const SET_END_STEP_OVERRIDE = 'message.set.end.step.override'

// ------------------------------------
// Actions
// ------------------------------------
export const setIsTimerFinished = value => (dispatch, getState) => {
	dispatch({
		type: SET_IS_TIMER_FINISHED,
		value,
	})
}

export const setEndStepOverride = endStepOverride => (dispatch, getState) => {
	dispatch({
		type: SET_END_STEP_OVERRIDE,
		endStepOverride,
	})
}

// ------------------------------------
// Primary Actions
// ------------------------------------
export const initNestedMessage = (
	message,
	restOfMessages,
	handleEndStep,
	setStudyObject,
	shouldAutoFocus,
) => (dispatch, getState) => {
	if (message === undefined) {
		handleEndStep()

		return
	}

	const nextHandleEndStep = () => {
		const [nextMessage, ...newRestOfMessages] = restOfMessages
		initNestedMessage(
			nextMessage,
			newRestOfMessages,
			handleEndStep,
			setStudyObject,
			false,
		)(dispatch, getState)
	}

	setEndStepOverride(nextHandleEndStep)(dispatch, getState)
	setStudyObject(message)(dispatch, getState)
	init(message.definition, shouldAutoFocus)(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const init = (module, shouldAutoFocus) => (dispatch, getState) => {
	setIsTimerFinished(false)(dispatch, getState)
	showStep(module, shouldAutoFocus)(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const showStep = (module, shouldAutoFocus) => (dispatch, getState) => {
	let studyObject = getStudyObject()(dispatch, getState)
	let message = createComponent(COMPONENTS.MESSAGE)
	message.props.text = module.text
	message.props.media = module.media
	message.props.shouldAutoFocus = shouldAutoFocus
	message.id = studyObject.idHistory
	if (message.props.media !== null) {
		message.props.media.seen = false
	}

	setCurrentMessage(studyObject)(dispatch, getState)

	const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	addMessage(message, messagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	if (module.media !== null && module.media.actionButton === null) {
		setTimeout(() => {
			setIsTimerFinished(true)(dispatch, getState)
			if (getState().chatView.showFullScreen === false) {
				endStep(false)(dispatch, getState) // eslint-disable-line no-use-before-define
			}
		}, module.timeout || 0)
	} else if (module.media !== null) {
		handleMediaHint(module)(dispatch, getState) // eslint-disable-line no-use-before-define

		setTimeout(() => {
			setIsTimerFinished(true)(dispatch, getState)
		}, module.timeout || 0)
		if (module.media.render && module.media.render.forceFullscreen) {
			forceFullscreen(module.media)(dispatch, getState) // eslint-disable-line no-use-before-define
		}
	} else {
		setTimeout(() => {
			setIsTimerFinished(true)(dispatch, getState)
			if (getState().chatView.showFullScreen === false) {
				endStep(false)(dispatch, getState) // eslint-disable-line no-use-before-define
			}
		}, module.timeout || 0)
	}
}

export const endStep = shouldAutoFocusNextMessage => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)

	const state = getState()
	const { endStepOverride } = state.message

	if (endStepOverride !== null) {
		// we're handling messages nested in dataset
		// don't call next-step network call
		setEndStepOverride(null)(dispatch, getState)

		endStepOverride()
	} else {
		// we're handling standalone message, fetch next module
		let response = {}
		response[studyObject.id] = null
		getState().study.getNextStep(response, null, shouldAutoFocusNextMessage)(dispatch, getState)
	}
}

// ------------------------------------
// Listeners
// ------------------------------------
export const updateListener = (currentTime, initialTime) => (dispatch, getState) => {
	let messageDef = getStudyObjectDefinition()(dispatch, getState)
	let definition = messageDef.media.actionButton
	const isFullscreenEnabled = true
	buttonStateUpdateListener(
		definition.minTimer,
		definition.maxTimer,
		currentTime - initialTime,
		definition.label,
		isFullscreenEnabled,
	)(dispatch, getState)
}

export const mediaActionListener = (
	idHistory,
	type,
	url,
	alt,
	mediaSeen,
	minTimer,
	maxTimer,
	buttonLabel,
	videoShowControls,
	videoAutoPlay,
	imageFullscreenZoom,
) => (dispatch, getState) => {
	// eslint-disable-next-line no-use-before-define
	enterFullscreen(
		idHistory,
		type,
		url,
		alt,
		mediaSeen === false ? minTimer : null,
		mediaSeen === false ? maxTimer : null,
		mediaSeen === false
			? buttonLabel
			: formatMessage('message_full_screen_close_button_text')(dispatch, getState),
		videoShowControls,
		videoAutoPlay,
		imageFullscreenZoom,
	)(dispatch, getState)
}

// ------------------------------------
// Helper Actions
// ------------------------------------
export const closeFullScreen = () => (dispatch, getState) => {
	const state = getState()

	stopInterval()(dispatch, getState)
	hideFullscreen()(dispatch, getState)

	let module = state.study.studyObject
	let fullscreenMessageId = state.chatFullscreenView.messageId
	if (fullscreenMessageId === module.idHistory) {
		if (
			module.definition.media &&
			(module.definition.media.actionButton || state.message.isTimerFinished)
		) {
			endStep(true)(dispatch, getState)
		}
		showActionLoader()(dispatch, getState)
	} else {
		/**
		 * Opened fullscreen prevents automatic endStep call of Message study object
		 * Call endStep for messages that do not require respondent's action.
		 */
		if (
			module.type === 'A_MESSAGE' &&
			state.message.isTimerFinished &&
			(module.definition.media === null ||
				(module.definition.media && module.definition.media.actionButton === null))
		) {
			endStep(true)(dispatch, getState)
		}
	}
}

export const isFullscreenDisabled = () => (dispatch, getState) => {
	return !getState().chatView.showFullScreen
}

export const handleMediaHint = module => (dispatch, getState) => {
	const idHintTranslation =
		module.media.type === MEDIA_TYPE.IMAGE
			? 'message_media_examine_image'
			: 'message_media_examine_video'

	const hintText = formatMessage(idHintTranslation)(dispatch, getState)

	addAction(showActionHint, [hintText])(dispatch, getState)
}

export const forceFullscreen = media => (dispatch, getState) => {
	let studyObject = getStudyObject()(dispatch, getState)
	let minTimer = media.actionButton ? media.actionButton.minTimer : null
	let maxTimer = media.actionButton ? media.actionButton.maxTimer : null
	let buttonLabel = media.actionButton ? media.actionButton.label : null
	let videoShowControls = media.render ? media.render.videoShowControls : true
	let videoAutoPlay = media.render ? media.render.videoAutoPlay : false
	let imageFullscreenZoom = media.render ? media.render.imageFullscreenZoom : null

	addAction(
		enterFullscreen, // eslint-disable-line no-use-before-define
		[
			studyObject.idHistory,
			media.type,
			media.url,
			media.alt,
			minTimer,
			maxTimer,
			buttonLabel,
			videoShowControls,
			videoAutoPlay,
			imageFullscreenZoom,
		],
	)(dispatch, getState)
}

export const enterFullscreen = (
	idHistory,
	type,
	url,
	alt,
	minTimer,
	maxTimer,
	buttonLabel,
	videoShowControls,
	videoAutoPlay,
	imageFullscreenZoom,
) => (dispatch, getState) => {
	let label = buttonLabel || formatMessage('action_input_submit_button_text')(dispatch, getState)
	let state = getState()
	let mediaSeen = state.chatHistory.history[idHistory].props.media.seen
	setMediaToSeen(idHistory)(dispatch, getState)
	queueSetMediaToSeen(idHistory)(dispatch, getState)

	if (mediaSeen === false && (minTimer !== null || maxTimer !== null)) {
		let duration = minTimer + maxTimer
		timerReset()(dispatch, getState)
		fullscreenShowActionButton(ACTION_BUTTON_STATE.DEFAULT, label)(dispatch, getState)
		if (maxTimer !== null) {
			setTimerListeners(updateListener, closeFullScreen)(dispatch, getState)
		} else {
			setTimerListeners(updateListener)(dispatch, getState)
		}
		setTimerInterval(duration)(dispatch, getState)
	} else {
		let buttonText = formatMessage('message_full_screen_close_button_text')(dispatch, getState)
		if (mediaSeen === false) {
			buttonText = label
		}
		fullscreenShowActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	}
	setMedia(
		idHistory,
		type,
		url,
		alt,
		videoShowControls,
		videoAutoPlay,
		imageFullscreenZoom,
	)(dispatch, getState)

	fullscreenSetActionSubmitListener(closeFullScreen)(dispatch, getState) // eslint-disable-line no-use-before-define
	showFullscreen()(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	isTimerFinished: false,
	endStepOverride: null,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[SET_IS_TIMER_FINISHED]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.isTimerFinished = action.value
		return newState
	},
	[SET_END_STEP_OVERRIDE]: (state, action) => ({
		...state,
		endStepOverride: action.endStepOverride,
	}),
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
