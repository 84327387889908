/* eslint-disable max-len */

const messages = {
	not_found: 'Shafin da ake nema babu shi',
	service_outage:
		"Muna baku hakuri saboda katsewar ayyukan cloud na kamfanin sadarwarmu na'urar mu ta dauke.",
	error_unexpected_error: 'An sami matsalar da ba a yi tsammani ba. Sake gwadawa wani lokaci.',

	error_img_alt: 'Hoton kuskure',
	error_try_again: 'Sake gwadawa',

	error_generic_header: 'Wata matsala ta faru',
	error_generic_message:
		'Sake gwada hakan, sannan in har yanzu bai yi ba, aika saƙon imel zuwa support@group solver.com tare da lambar  QR da ka gani a sama.',

	error_offline_header: 'Da alama ba ka kan intanet',
	error_offline_message: 'Binciken ka zai cigaba bayan ka sake haɗin sadarwarka.',

	error_landscape_small_screen_header: "Juya na'urar ka",
	error_landscape_small_screen_text:
		"Fuskar na'urar ka ta yi kaɗan matuƙa don kallon wannan nazarin bincike. Juya na'urar ka",

	error_small_browser_window_header: 'Ƙara girman fuskar burauzarka',
	error_small_browser_window_text:
		"Fuskar na'urar ka ta yi kaɗan matuƙa don kallon wannan nazarin bincike. Ƙara girman fuskar burauzarka",

	login_error_empty_email: 'Email ba zai iya zama ɗaya ba',
	login_error_invalid_email: 'Shigar da ingantacciyar imel',
	login_error_unable_to_login:
		'Ba za a iya shiga ba a wannan lokacin. Da alama a sake yin gwaji a baya.',

	login_email_placeholder: 'Shigar da imel ɗin ka',
	login_email_button: 'Cigaba',
	login_email_back: 'ko ka koma zuwa zabubbukan shiga',
	login_email_label: 'Cigaba da imel',
	login_anonymous_label: 'Cigaba ba tare da suna ba',

	login_privacy_statement: 'Groupsolver yana mutunta sirrin ka da kuma bayanan da ka samar.',
	login_privacy_statement_email:
		'Ba za a taɓa yaɗawa, sayarwa, ko amfani da imelɗin ka ta kowace irin hanya ba ta shigar da ku cikin wannan aikin ba.',
	login_privacy_policy: 'Dokar sirri',

	study_unavailable_label: 'Nazarin binciken da ka ke nema babu shi yanzu.',
	study_unavailable_description:
		"Mun gode da sha'awar ka kan nazarin bincike mu! Sai dai kash, wannan binciken ya riga ya cika ko an dakatar da shi a halin yanzu. Muna bada hakuri ga duk yanayi na rashin jindadin da hakan zai haifar.",
	study_preview_limit_reached_label: 'Iyakar ganewa ku ya kai',
	study_preview_limit_reached_description:
		"Godiya da sha'awar ka a cikin bincike mu! Saboda haka, wannan bincike ya kai iyaka na samfurori masu ganewa",

	waiting_message:
		'Mutane da yawa ne suke warware wannan nazarin bincike a halin yanzu. \n Kai tsaye za mu sake gwada shigar da {number, plural, =0 {yanzu} one {a cikin # daƙiƙa} other {a cikin # daƙiƙa}}.',
	waiting_error_recoverable: 'Mun ci karo da matsalar da ba  a zata ba.',
	waiting_error_recoverable_retry:
		'Za mu sake gwadawa {number, plural, =0 { yanzu} one {a cikin # daƙiƙa} other {a cikin # daƙiƙa}}',
	waiting_error_unknown_study: 'Binciken da ka ke nema babu shi.',
	waiting_error_dead: 'An sami matsalar da ba a yi tsammani ba. Sake gwadawa wani lokaci.',

	action_input_submit_button_text: 'AIKA',
	action_input_skip_button_text: 'Cigaba',

	choice_single_hint: 'Zaɓi daya daga cikin zabubbukan',
	general_skip_action_message: 'Cigaba zuwa mataki na gaba',

	action_button_primary_default_text: 'Aikace',
	action_button_hint_default_text: 'Zaɓi daya daga cikin wadannan zabubbukan',

	message_media_examine_video: 'Danna bidiyon don sake duba shi',
	message_media_examine_image: 'Danna hoton don sake duba shi',
	message_full_screen_close_button_text: 'Rufe',
	message_image_view_label: 'Duba hoto',
	message_video_view_label: 'Duba bidiyo',
	message_image_examined_label: 'Hoto ya dub',
	message_video_examined_label: 'Bidiyo ya duba',
	message_zoom_mouse: 'Kewaya don kusantowa',
	message_zoom_touch: 'Taɓa don kusantowa',

	textarea_too_long_hint: 'Rubutunka ka ya yi tsayi sosai',

	ideate_placeholder: 'Ba da amsa a takaitacciyar jimla',
	ideate_skip: ' ko ka cigaba',

	eliminate_no: "A'a",
	eliminate_yes: 'E',
	eliminate_intro: "Yi la'akari da wannan jawabin:",
	eliminate_multiple_suggestions: 'Wannene acikin jerin nan zai iya maye gurbin sa?',
	eliminate_one_suggestion: "Shin Za'a iya maye gurbin sa da wannan?",
	eliminate_step: "Yi la'akari da wani jawabin:",
	eliminate_none_of_the_above: 'Babu ko ɗaya daga cikin su',

	elaborate_indifferent_label: 'Tsaka tsaki',
	elaborate_disagree_label: 'Ban amince ba',
	elaborate_agree_label: 'Na amince',
	elaborate_unclear_statement_label: 'Ban fahimci amsar ba',
	elaborate_intro: 'Wasu sun bayar da amsoshi kamar haka. Shin ka yarda da su?',

	ideamatching_unsure_label: 'Ba ni da tabbas',
	ideamatching_unequal_label: 'Na daban',
	ideamatching_equal_label: 'Iri ɗaya',
	ideamatching_intro: 'Kalli wadannan zubin amsoshin. Shin suna nufin abu daya ne?',

	select_input_placeholder: 'Rubuta a nan don ka zaɓa',
	select_input_placeholder_max: 'An kai maƙura',
	select_multiple_choice_hint:
		'Zaɓi {minNumber, plural, =0 {0} other { # }} zuwa {maxNumber, plural, =0 { 0 } other { # }} zabubbuka',
	select_no_matches_found: 'Ba a sami wanda ya yi daidai ba',

	report_content_inappropriate: 'Amsar da ba ta dace ba',
	report_content_profane: 'Bayanin batsa ko na cin zarafi',
	report_content_gibberish: 'Rubutun shirme ko na rashin hankali',
	report_content_irrelevant: 'Ba shi da alaƙa da tambayar da aka yi',
	report_content_different_language: 'Yare na daban',
	report_content_other: 'Wani',
	report_content_no_content: 'Babu bayanin da za a ba da rahoto',
	report_content_header: 'Rahoton amsa',
	report_content_form_header: 'Zaɓi dalilin da ya sa ka ke son yi wa wannan amsar alama',
	report_content_flag_answer: 'Yi wa amsa alama',
	report_content_return_to_study: 'Koma zuwa nazarin binciken',
	report_content_thank_you:
		'Mun gode da bayananku. \nZa mu sake duba abin da ka shigar kuma ka dauki mataki in hakan ya zama dole.',
	report_content_statement_flagged: 'An yi wa jawabin alama.',

	platform_update_header: 'A halin yanzu GroupSolver yana kan tsarin kulawa',
	platform_update_message: 'Muna ba da hakuri ga duk yanayi na rashin jindadin.',

	none_of_these: 'Babu ko daya daga wadannan',

	allocation_instruction_limit: 'Raba {points} a cikin zabubbukan ka',
	allocation_instruction_limit_no_minimum: 'Raba har zuwa {points} a cikin zabubbukan ka',
	allocation_instruction_no_limit: 'Raba maki a cikin zabubbukan ka',
	allocation_instruction_points_left: '{points} da ya rage',
	allocation_options_out_of_range: 'Wasu zabubbukan su wuce iyaka',
	allocation_limit_overflow: '{points} ya shige iyaka',
	allocation_option_invalid_range: 'Darajar ta wuce {range} iyakae',
	allocation_option_invalid_min: 'Dole daraja ta kasance sama da ko daidai da {min}',

	dropdown_select: 'Zaɓi...',

	ranking_drag_options: 'Jawo ko ka danna kibiyoyi dan yi wa zabubbuka matsayi',
	ranking_assign_option_values: 'Raba duk zabubbukan',
	ranking_require_unique_values: 'Matsayin zabubbukan za su kasance na mu samman',

	next: 'Gaba',
	technical_difficulties: 'Ina da matsalolin fasaha',

	heatmap_instruction_intro: 'Don fara zaɓa, kalli hoton da ke ƙasa.',
	heatmap_instruction_unlimited: 'Zaɓi yawan makin da ka ke so ta hanyar danna hoton.',
	heatmap_instruction_count:
		'Zaɓi {number, plural, =0 {0 maki} one {maki ɗaya} other {{number} maki}} ta hanyar danna hoton.',
	heatmap_instruction_up_to_count:
		'Zaɓi {number, plural, =0 {0 maki} one {maki daya} other {har zuwa {number} maki}} ta hanyar latsa hoton.',
	heatmap_instruction_between_count: 'Zaɓi {from} zuwa {to} maki ta hanyar danna hoton',
	heatmap_instruction_at_least_count:
		'Zaɓi aƙalla {number, plural, =0 {0 maki} one {maki ɗaya} other {{number} maki}} ta hanyar danna hoton.',
	heatmap_response:
		'{number, plural, =0 {0 maki} one {maki ɗaya} other {{number} maki}} da aka zaɓa',

	continue: 'Ci gaba',
	reset: 'Sake saiti',

	input_invalid: 'Shigarwa ba daidai bane.',
	input_empty: 'Shigarwa ba zai iya zama babu komai ba.',
	input_chars_left: 'Ragowar {number} {number, plural, =0 {haruffa} one {harafi} other {haruffa}}.',
	input_too_long: 'Haruffa {number} {number, plural, =0 {0} one {1} other {0}} sun wuce iyaka.',

	// accessibility
	ranking_select_rank: 'Zaɓi matsayi na {label}.',

	ranking_select_aria_label: 'Zaɓi matsayi na kowanne zaɓi ta hanyar rubuta darajar.',
	ranking_dnd_aria_label:
		'Mai da hankali kan zaɓi kuma danna maɓallin sarari don farawa jan sa. Lokacin da aka jan zaka iya amfani da maɓallan kibiyoyi don motsa abu sannan kuma danna sarari don mayar da abu a sabuwar matsayi. Tabbatar cewa mai karatun allo yana cikin yanayin mayar da hankali ko yanayin tsari.',

	allocation_option_input: 'Shigar da ƙima na {label}.',
	allocation_aria_label: 'Shigar da ƙima don kowanne zaɓi ta amfani da shigarwa.',

	play: 'Fara',
	pause: 'Tsaya',
	stop: 'Tsayar',
	fwd: 'Tsallake gaba',
	rwd: 'Komawa baya',
	volume_down: 'Rage ƙara',
	volume_up: 'Ƙara ƙara',
	volume_current: 'Ƙara: {number}%',

	loading: 'Ana loda',

	press_tab_to_continue: 'Danna tab don ci gaba',

	option_focus_selected: 'Zaɓi {label} an mayar da hankali. Danna shiga don cire zaɓi.',
	option_focus_not_selected: 'Zaɓi {label} an mayar da hankali. Danna shiga don zaɓi.',

	image_alt: 'hoto:',
	video_alt: 'bidiyo:',

	submit_enabled: 'Tura an kunna.',
	skip_enabled: 'Tsallake tambaya an kunna',
	n_of_min_selected: '{selected} na {min} an zaɓa.',
	option_selected: '{label} an zaɓa.',
	option_deselected: '{label} an cire zaɓi.',

	choice_multiple_hint: 'Zaɓi {min} zuwa {max} zaɓuɓɓuka.',

	ranking_position: 'matsayi: {position}',
	ranking_position_changed: 'Darajar {label} an saita zuwa {value}.',
	ranking_input_aria_label:
		'{label}. Matsayi na yanzu: {position}. Rubuta matsayi daga 1 zuwa {max}.',
	not_selected: 'ba a zaɓa ba',

	study_logo_alt: 'Tambarin karatu',
	groupsolver_logo_alt: 'Tambarin GroupSolver',
}

export default messages
