export const ACTION_BUTTON_STATE = {
	DEFAULT: 'default',
	SKIP_PREP: 'skip-preparation',
	SKIP_READY: 'skip-ready',
	SUBMIT_PREP: 'submit-preparation',
	SUBMIT_READY: 'submit-ready',
	AUTOSUBMIT_PREP: 'autosubmit-preparation',
	AUTOSUBMISSION: 'autosubmission',
}

export const ACTION_BUTTON_LOADER_TYPE = {
	PERCENT: 'loadPercent',
	TIME: 'loadTime',
}
