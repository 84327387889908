import { connect } from 'react-redux'
import ActionTextArea from './ActionTextArea'
import { injectIntl } from 'react-intl'
import { getIsStudyRtl } from 'store/intl'
import { getIsAccessibilityEnabled } from 'selectors/study'

const mapActionCreators = {}

const mapStateToProps = state => ({
	isStudyRtl: getIsStudyRtl(state),
	isAccessibilityEnabled: getIsAccessibilityEnabled(state),
})

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ActionTextArea))
