import { MEDIA_TYPE } from 'constants/media'
import { ACTION_BAR_TYPE } from 'constants/actionBar'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'

export const SET_MEDIA = 'fullscreen.set.media'
export const VIDEO_UPDATE_TIME = 'fullscreen.video.update.time'
export const VIDEO_SAVE_DURATION = 'fullscreen.video.save.duration'
export const SET_IMAGE_TIMER = 'fullscreen.set.image.timer'
export const STOP_IMAGE_TIMER = 'fullscreen.stop.image.timer'
export const IMAGE_UPDATE_TIME = 'fullscreen.image.update.time'
export const HANDLE_ERROR = 'fullscreen.handle.error'
export const SET_MEDIA_LISTENERS = 'fullscreen.set.media.listeners'
export const FULLSCREEN_ACTION_BAR_SET_BUTTON = 'fullscreen.action.bar.set.button'
export const FULLSCREEN_ACTION_SET_ACTION_SUBMIT_LISTENER =
	'fullscreen.action.bar.set.action.submit.listener'

// ------------------------------------
// initialState
// ------------------------------------
const initialState = {
	messageId: null,
	mediaType: MEDIA_TYPE.IMAGE,
	media: {
		url: null,
		alt: null,
		videoShowControls: true,
		videoAutoPlay: false,
		imageFullscreenZoom: null,
	},
	actionBar: {
		actionType: ACTION_BAR_TYPE.LOADER,
		actionState: ACTION_BUTTON_STATE.DEFAULT,
		isVisible: false,

		hintText: '',

		buttonText: '',
		isPrimaryEnabled: false,

		loaderType: null,
		loadTime: null,
		loadPercent: null,

		secondaryText: '',
		isSecondaryEnabled: false,

		primaryListener: null,
		secondaryListener: null,

		backupState: null,
	},
}

// ------------------------------------
// Actions
// ------------------------------------
export const setMedia = (
	messageId,
	mediaType,
	url,
	alt,
	videoShowControls = true,
	videoAutoPlay = false,
	imageFullscreenZoom = null,
) => (dispatch, getState) => {
	const media = {
		mediaType,
		url,
		alt,
		videoShowControls,
		videoAutoPlay,
		imageFullscreenZoom,
	}
	dispatch({
		type: SET_MEDIA,
		messageId,
		mediaType,
		media,
	})
}

export const showActionButton = (
	actionState = ACTION_BUTTON_STATE.DEFAULT,
	text = '',
	actionButtonLoaderType = null,
	actionButtonLoaderValue = null,
) => (dispatch, getState) => {
	dispatch({
		type: FULLSCREEN_ACTION_BAR_SET_BUTTON,
		actionState,
		text,
		actionButtonLoaderType,
		actionButtonLoaderValue,
	})
}

export const setActionSubmitListener = (primaryListener = null, secondaryListener = null) => (
	dispatch,
	getState,
) => {
	dispatch({
		type: FULLSCREEN_ACTION_SET_ACTION_SUBMIT_LISTENER,
		primaryListener,
		secondaryListener,
	})
}

export const onPrimarySubmit = (input, options) => (dispatch, getState) => {
	let newState = getState()
	let changeListener = newState.chatFullscreenView.actionBar.primaryListener
	if (changeListener) {
		changeListener(input, options)(dispatch, getState)
	}
}

export const onSecondarySubmit = () => (dispatch, getState) => {
	let newState = getState()
	let changeListener = newState.chatFullscreenView.actionBar.secondaryListener
	if (changeListener) {
		changeListener()(dispatch, getState)
	}
}

export const handleError = () => (dispatch, getState) => {
	// TODO: send flag to BE
	setTimeout(() => {
		dispatch({ type: HANDLE_ERROR })
	}, 1000)
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[SET_MEDIA]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.mediaType = action.mediaType
		newState.media = action.media
		newState.messageId = action.messageId
		return newState
	},
	[FULLSCREEN_ACTION_BAR_SET_BUTTON]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.actionBar = Object.assign({}, state.actionBar)
		newState.actionBar.isVisible = true
		newState.actionBar.actionState = action.actionState
		// sets loadPercent or loadTime
		if (action.actionButtonLoaderType) {
			newState.actionBar[action.actionButtonLoaderType] = action.actionButtonLoaderValue
		}
		newState.actionBar.loaderType = action.actionButtonLoaderType

		newState.actionBar.actionType = ACTION_BAR_TYPE.BUTTON
		newState.actionBar.buttonText = action.text

		return newState
	},
	[FULLSCREEN_ACTION_SET_ACTION_SUBMIT_LISTENER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.actionBar.primaryListener = action.primaryListener
		newState.actionBar.secondaryListener = action.secondaryListener

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
