import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'

import optionShape from 'shapes/option'

const createSelectOption = option => ({
	value: option.id,
	label: option.label,
	selected: option.selected,
})

const ActionSelectAccessible = ({
	isStudyRtl,
	options,
	multiple,
	maxSelection,
	placeholder,
	toggleOption,
	theme,
}) => {
	const handleChange = (_newValue, reactSelectAction) => {
		const { action } = reactSelectAction

		if (action === 'select-option') {
			const { option } = reactSelectAction

			toggleOption(option.value, true)
		}

		if (action === 'pop-value' || action === 'remove-value') {
			const { removedValue } = reactSelectAction

			if (removedValue === undefined) {
				return
			}

			toggleOption(removedValue.value, false)
		}
	}

	const styles = {
		control: (base, state) => {
			const { isFocused } = state

			return {
				...base,
				borderColor: isFocused === true ? 'orange' : base.borderColor,
				borderRadius: 20,
				boxShadow: isFocused === true ? '0 0 0 1px orange' : base.boxShadow,
				'&:hover': {
					borderColor: isFocused === true ? 'orange' : base.borderColor,
				},
			}
		},
		dropdownIndicator: () => ({
			display: 'none',
		}),
		indicatorsContainer: () => ({
			display: 'none',
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		menu: base => {
			return {
				...base,
				backgroundColor: 'rgba(255, 255, 255, 0.95)',
				border: 'none',
				borderRadius: 0,
				boxShadow: 'none',
				marginBottom: 2,
			}
		},
		menuList: base => {
			return {
				...base,
				display: 'flex',
				flexDirection: 'column',
				paddingBottom: 6,
				paddingTop: 15,
			}
		},
		multiValue: base => {
			return {
				...base,
				backgroundColor: 'transparent',
				border: `1px solid ${theme.primaryColor}`,
				borderRadius: 20,
				margin: 0,
				marginLeft: 0,
				marginRight: 4,
				overflow: 'hidden',
				padding: '8px 8px 8px 16px',
			}
		},
		multiValueLabel: base => {
			return {
				...base,
				color: theme.primaryColor,
				fontSize: 16,
				fontWeight: 700,
				padding: 0,
				paddingLeft: 0,
				paddingRight: 2,
			}
		},
		multiValueRemove: base => {
			return {
				...base,
				cursor: 'pointer',
			}
		},
		option: (base, state) => {
			return {
				...base,
				backgroundColor: 'transparent',
				border: `1px solid ${theme.primaryColor}`,
				borderColor: state.isFocused === true ? 'orange' : theme.primaryColor,
				borderRadius: 20,
				color: theme.primaryColor,
				cursor: 'pointer',
				marginBottom: 6,
				marginLeft: isStudyRtl === true ? 2 : 'auto',
				marginRight: isStudyRtl === true ? 'auto' : 2,
				outline: state.isFocused === true ? '2px solid orange' : 'none',
				padding: '8px 16px',
				width: 'auto',
				'&:hover': {
					backgroundColor: theme.primaryColor,
					color: 'white',
				},
			}
		},
		placeholder: base => {
			return {
				...base,
				marginLeft: 16,
			}
		},
		valueContainer: base => {
			return {
				...base,
				padding: 1,
			}
		},
	}

	const selectOptions = options.map(createSelectOption)

	const selectValue = options.filter(option => option.selected === true).map(createSelectOption)

	return (
		<Select
			isMulti
			isRtl={isStudyRtl}
			menuPlacement="top"
			onChange={handleChange}
			options={selectOptions}
			placeholder={placeholder}
			styles={styles}
			value={selectValue}
		/>
	)
}

ActionSelectAccessible.propTypes = {
	isStudyRtl: PropTypes.bool.isRequired,
	maxSelection: PropTypes.number.isRequired,
	multiple: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(optionShape).isRequired,
	placeholder: PropTypes.string.isRequired,
	toggleOption: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
}

export default ActionSelectAccessible
