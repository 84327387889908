import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import { hideActionBar } from 'store/_chat/actionBar'
import { reset as resetHeatmap, heatmapShow, addSubmitListener } from 'store/_chat/heatmap'
import { createComponent, removeTempMessages } from 'store/_chat/history'

import { COMPONENTS, POSITION } from 'constants/component'
import { DELAY_LENGTH } from 'constants/delay'

import { getStudyObject } from 'selectors/study'

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// Listeners
// ------------------------------------

// ------------------------------------
// Primary Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showStep(module)(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const endStep = data => (dispatch, getState) => {
	removeTempMessages()(dispatch, getState)
	resetHeatmap()(dispatch, getState)

	let studyObject = getStudyObject()(dispatch, getState)

	// show response message
	let message = createComponent(COMPONENTS.MESSAGE)
	message.props.text = formatMessage('heatmap_response', { number: data.length })(
		dispatch,
		getState,
	)

	const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

	addMessage(message, messagePosition)(dispatch, getState)
	// send & load next module
	let response = {}
	response[studyObject.id] = data
	getState().study.getNextStep(response, null, true)(dispatch, getState)
}

export const showStep = module => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)

	addAction(hideActionBar)(dispatch, getState)

	// show instruction
	const hint = formatMessage('heatmap_instruction_intro')(dispatch, getState)

	const instruction = createComponent(COMPONENTS.INSTRUCTION)
	instruction.props.text = hint

	const solverMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT
	addTempMessage(instruction, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	// show image (message-like)
	const message = createComponent(COMPONENTS.MESSAGE)
	message.props.text = ''
	message.id = studyObject.idHistory
	message.isHeatmap = true
	message.isHeatmapSeen = false
	message.heatmapDefinition = module
	message.openHeatmap = heatmapShow
	message.props.media = {
		id: uuidv4(),
		type: 'A_IMAGE',
		alt: '',
		url: module.imageUrl,
		thumbnailUrl: module.thumbnailUrl || module.imageUrl,
		render: {
			// include all media props
			forceFullscreen: false,
			blurBefore: module.blurBefore,
			hideAfter: module.blurAfter,
			videoShowControls: false,
			videoAutoPlay: false,
			imageFullscreenZoom: null,
		},
		actionButton: null,
		seen: _.get(message, 'props.media.seen', false),
	}

	const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	addMessage(message, messagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	addSubmitListener(endStep)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
