const messages = {
	not_found: 'Hindi umiiral ang page na hinahanap mo',
	service_outage:
		'Humihingi kami ng paumanhin dahil down ang aming sistema dulot ng pandaigdigang pagkatigil sa mga serbisyo ng cloud ng aming tagapagbigay ng serbisyo.',
	error_unexpected_error: 'Nagkaroon ng hindi inaasahang problema. Pakisubukan ulit mamaya.',

	error_img_alt: 'Error na larawan',
	error_try_again: 'Subukan ulit',

	error_generic_header: 'May nangyaring hindi tama',
	error_generic_message:
		'Subukan ulit iyon, at kung hindi pa rin ito gumana, mangyaring magpadala ng email sa support@groupsolver.com kalakip ang code ng QR na nakikita mo sa itaas.',

	error_offline_header: 'Mukhang offline ka',
	error_offline_message:
		'Magpapatuloy ang iyong pag-aaral pagkatapos maibalik muli ang iyong koneksyon.',

	error_landscape_small_screen_header: 'Paki-rotate ang device mo',
	error_landscape_small_screen_text:
		'Masyadong maikli ang iyong screen para tingnan ang pag-aaral na ito. Paki-rotate ang device mo.',

	error_small_browser_window_header: 'Mangyaring palakihin ang window ng browser mo',
	error_small_browser_window_text:
		'Masyadong maliit ang iyong window para tingnan ang pag-aaral na ito. Mangyaring palakihin ang window ng browser mo',

	login_error_empty_email: 'Hindi puwedeng walang laman ang email',
	login_error_invalid_email: 'Mangyaring maglagay ng valid na email',
	login_error_unable_to_login: 'Hindi makapag-login sa oras ito. Pakisubukan ulit mamaya.',

	login_email_placeholder: 'Ilagay ang iyong email',
	login_email_button: 'Magpatuloy',
	login_email_back: 'o bumalik sa mga opsyon sa pag-login',
	login_email_label: 'Magpatuloy gamit ang email',
	login_anonymous_label: 'Magpatuloy nang anonymous',

	login_privacy_statement:
		'Iginagalang ng GroupSolver ang iyong pagkapribado at ang impormasyong ibinibigay mo.',
	login_privacy_statement_email:
		'Ang iyong email ay hindi kailanman ibabahagi, ibebenta, o gagamitin sa anumang paraan maliban sa pagbibigay sa iyo ng access sa proyektong ito. ',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Kasalukuyang hindi available ang pag-aaral na hinahanap mo.',
	study_unavailable_description:
		'Salamat sa iyong interes sa aming pag-aaral! Sa kasamaang palad, ang pag-aaral na ito ay puno na o pansamantalang itinigil sa ngayon. Humihingi kami ng paumanhin para sa anumang abalang maaaring idinulot nito.',
	study_preview_limit_reached_label: 'Naabot na ang iyong limitasyon sa preview',
	study_preview_limit_reached_description:
		'Salamat sa iyong interes sa aming pag-aaral! Sa kasamaang palad, naabot na ng pag-aaral na ito ang bilang ng mga available na preview.',

	waiting_message:
		'Ang pag-aaral na ito ay sinasagutan ng maraming tao sa ngayon.\n  Awtomatiko naming susubukang muli na pumasok {number, plural, =0 { ngayon } one {sa loob ng # segundo} other {sa loob ng # (na) segundo}}.',
	waiting_error_recoverable: 'Nagkaroon kami ng hindi inaasahang error.',
	waiting_error_recoverable_retry:
		'Susubukan naming muli {number, plural, =0 { ngayon } one {sa loob ng # segundo} other {sa loob ng # (na) segundo}}',
	waiting_error_unknown_study: 'Hindi umiiral ang pag-aaral na hinahanap mo.',
	waiting_error_dead: 'Nagkaroon ng hindi inaasahang problema. Pakisubukan ulit mamaya.',

	action_input_submit_button_text: 'ISUMITE',
	action_input_skip_button_text: 'Magpatuloy',

	choice_single_hint: 'Pumili ng isa sa mga opsyon',
	general_skip_action_message: 'Magpatuloy sa susunod na hakbang',

	action_button_primary_default_text: 'Aksyon',
	action_button_hint_default_text: 'Pumili ng isa sa mga opsyon',

	message_media_examine_video: 'I-click ang video para suriin ito',
	message_media_examine_image: 'I-click ang larawan para suriin ito',
	message_full_screen_close_button_text: 'Isara',
	message_image_view_label: 'Tingnan ang Larawan',
	message_video_view_label: 'Tingnan ang Video',
	message_image_examined_label: 'Nasuri ang larawan',
	message_video_examined_label: 'Nasuri ang video',
	message_zoom_mouse: 'Mag-hover para i-zoom',
	message_zoom_touch: 'Pindutin para i-zoom',

	textarea_too_long_hint: 'Masyadong mahaba ang text mo',

	ideate_placeholder: 'Sumagot sa maikling (mga) pangungusap',
	ideate_skip: ' o magpatuloy',

	eliminate_no: 'Hindi',
	eliminate_yes: 'Oo',
	eliminate_intro: 'Isaalang-alang ang sumusunod na pahayag:',
	eliminate_multiple_suggestions: 'Alin sa mga sumusunod ang maaaring pumalit dito?',
	eliminate_one_suggestion: 'Maaari ba itong palitan gamit ang mga sumusunod?',
	eliminate_step: 'Isaalang-alang ang isa pang pahayag:',
	eliminate_none_of_the_above: 'Wala sa mga ito',

	elaborate_indifferent_label: 'Neutral',
	elaborate_disagree_label: 'Hindi sumasang-ayon',
	elaborate_agree_label: 'Sumasang-ayon',
	elaborate_unclear_statement_label: 'Hindi malinaw na sagot',
	elaborate_intro:
		'Ibinigay ng ibang tao ang mga sumusunod na sagot. Sumasang-ayon ka ba sa mga ito?',

	ideamatching_unsure_label: 'Hindi sigurado',
	ideamatching_unequal_label: 'Magkaiba',
	ideamatching_equal_label: 'Magkapareho',
	ideamatching_intro:
		'Tingnan ang pares na ito ng mga sagot. Magkapareho ba ang ibig sabihin ng mga ito?',

	select_input_placeholder: 'Mag-type dito para pumili',
	select_input_placeholder_max: 'Naabot ang maximum',
	select_multiple_choice_hint:
		'Pumili ng {minNumber, plural, =0 { 0 } other { # }} hanggang {maxNumber, plural, =0 { 0 } other { # }} (na) opsyon',
	select_no_matches_found: 'Walang nahanap na katugma',

	report_content_inappropriate: 'Hindi angkop na sagot',
	report_content_profane: 'Mahalay o bastos na nilalaman',
	report_content_gibberish: 'Hindi maintindihan o walang kabuluhang text',
	report_content_irrelevant: 'Walang kaugnayan sa itinatanong',
	report_content_different_language: 'Ibang wika',
	report_content_other: 'Iba pa',
	report_content_no_content: 'Walang nilalaman na iuulat',
	report_content_header: 'Iulat ang sagot',
	report_content_form_header:
		'Mangyaring pumili ng dahilan kung bakit gusto mong i-flag ang sagot na ito',
	report_content_flag_answer: 'I-flag ang sagot',
	report_content_return_to_study: 'Bumalik sa pag-aaral',
	report_content_thank_you: `Salamat sa iyong feedback.\n
		Susuriin namin ang iyong input at gagawa ng aksyon kung kinakailangan.`,
	report_content_statement_flagged: 'Nai-flag ang pahayag.',

	platform_update_header:
		'Kasalukuyang sumasailalim ang GroupSolver sa nakaiskedyul na pagmimintina',
	platform_update_message: 'Humihingi kami ng paumanhin para sa anumang abala.',

	none_of_these: ' Wala sa mga ito',

	allocation_instruction_limit: 'Maglaan ng {points} sa lahat ng opsyon',
	allocation_instruction_limit_no_minimum: 'Maglaan ng hanggang{points} sa lahat ng opsyon',
	allocation_instruction_no_limit: 'Maglaan ng mga puntos sa lahat ng opsyon',
	allocation_instruction_points_left: '{points} na natitira',
	allocation_options_out_of_range: ' Ang ilan sa mga opsyon ay hindi sakop ng saklaw',
	allocation_limit_overflow: '{points} ay lampas sa limit',
	allocation_option_invalid_range: ' Ang halaga ay lampas sa {range} saklaw',
	allocation_option_invalid_min: ' Ang halaga ay dapat mas malaki o katumbas ng {min}',

	dropdown_select: 'Pumili...',

	ranking_drag_options: 'Hilahin o pindutin ang mga arrow upang i-ranggo ang mga pagpipilian',
	ranking_assign_option_values: ' Ranggo ng lahat ng mga opsyon',
	ranking_require_unique_values: ' Dapat naiiba ang mga ranggo ng opsyon',

	heatmap_instruction_intro: 'Upang magsimulang pumili, tingnan ang larawan sa ibaba.',
	heatmap_instruction_unlimited:
		"Pumili ng maraming puntos hangga't gusto mo sa pamamagitan ng pag-click sa larawan.",
	heatmap_instruction_count:
		'Pumili ng {number, plural, =0 {0 puntos} one {isang punto} other {{number} puntos}} sa pamamagitan ng pag-click sa larawan.',
	heatmap_instruction_up_to_count:
		'Pumili ng hanggang {number, plural, =0 {0 puntos} one {isang punto} other {hanggang {number} puntos}} sa pamamagitan ng pag-click sa larawan.',
	heatmap_instruction_between_count:
		'Pumili ng {from} hanggang {to} puntos sa pamamagitan ng pag-click sa larawan',
	heatmap_instruction_at_least_count:
		'Pumili ng hindi bababa sa {number, plural, =0 {0 puntos} one {isang punto} other {{number} puntos}} sa pamamagitan ng pag-click sa larawan.',
	heatmap_response: '{number, plural, =0 {0 puntos} one {1 punto} other {{number} puntos}} napili',
	continue: 'Magpatuloy',
	reset: 'I-reset',
	input_invalid: 'Ang input ay hindi wasto.',
	input_empty: 'Ang input ay hindi maaaring walang laman.',
	input_chars_left:
		'{number} {number, plural, =0 {mga karakter} one {karakter} other {mga karakter}} natitira.',
	input_too_long:
		'{number} {number, plural, =0 {mga karakter} one {karakter} other {mga karakter}} sobra sa limitasyon.',
	ranking_select_rank: 'Pumili ng ranggo ng {label}.',
	ranking_select_aria_label:
		'Pumili ng ranggo ng bawat opsyon sa pamamagitan ng pag-type ng halaga.',
	ranking_dnd_aria_label:
		'Mag-focus sa isang opsyon at pindutin ang space bar upang magsimula ng drag. Kapag nagda-drag, maaari mong gamitin ang mga arrow keys upang ilipat ang item at escape upang kanselahin. Pindutin muli ang spacebar upang ilagay ang item sa bagong posisyon. Tiyakin na ang iyong screen reader ay nasa focus mode o forms mode.',
	allocation_option_input: 'Input ng halaga ng {label}.',
	allocation_aria_label: 'Maglagay ng halaga para sa bawat opsyon gamit ang mga input.',
	play: 'I-play',
	pause: 'I-pause',
	stop: 'Ihinto',
	fwd: 'Laktawan pasulong',
	rwd: 'I-rewind',
	volume_down: 'Bawasan ang volume',
	volume_up: 'Dagdagan ang volume',
	volume_current: 'Volume: {number}%',
	loading: 'Naglo-load',
	press_tab_to_continue: 'Pindutin ang tab upang magpatuloy',
	option_focus_selected: 'Opsyon {label} naka-focus. Pindutin ang enter upang i-deselect.',
	option_focus_not_selected: 'Opsyon {label} naka-focus. Pindutin ang enter upang pumili.',
	image_alt: 'larawan:',
	video_alt: 'video:',
	submit_enabled: 'Submit pinagana.',
	skip_enabled: 'Laktawan ang tanong pinagana',
	n_of_min_selected: '{selected} ng {min} napili.',
	option_selected: '{label} napili.',
	option_deselected: '{label} hindi napili.',
	choice_multiple_hint: 'Pumili ng {min} hanggang {max} opsyon.',
	ranking_position: 'posisyon: {position}',
	ranking_position_changed: 'Halaga ng {label} itinakda sa {value}.',
	ranking_input_aria_label:
		'{label}. Kasalukuyang posisyon: {position}. Mag-type ng posisyon mula 1 hanggang {max}.',
	not_selected: 'hindi napili',
	study_logo_alt: 'Logo ng pag-aaral',
	groupsolver_logo_alt: 'Logo ng Groupsolver',
}

export default messages
