import { formatMessage } from 'store/intl'
import { showActionButton } from 'store/_chat/actionBar'
import { showActionButton as fullscreenShowActionButton } from 'store/_chat/fullscreenView'
import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'

export const buttonStateUpdateListener = (
	minTimeToShow,
	maxTimeToShow,
	currentTime,
	buttonText = null,
	isFullscreenEnabled,
	stateAfterTimerFinish = ACTION_BUTTON_STATE.SUBMIT_READY,
) => (dispatch, getState) => {
	let showActionButtonAction = showActionButton
	if (isFullscreenEnabled) {
		showActionButtonAction = fullscreenShowActionButton
	}

	let submitButtonText =
		buttonText || formatMessage('action_input_submit_button_text')(dispatch, getState)
	if (maxTimeToShow && currentTime >= maxTimeToShow) {
		showActionButtonAction(ACTION_BUTTON_STATE.AUTOSUBMISSION, submitButtonText)(dispatch, getState)
	} else if (maxTimeToShow && currentTime >= minTimeToShow) {
		showActionButtonAction(
			ACTION_BUTTON_STATE.AUTOSUBMIT_PREP,
			submitButtonText,
			ACTION_BUTTON_LOADER_TYPE.TIME,
			maxTimeToShow - minTimeToShow,
		)(dispatch, getState)
	} else if (currentTime >= minTimeToShow) {
		showActionButtonAction(stateAfterTimerFinish, submitButtonText)(dispatch, getState)
	} else if (currentTime < minTimeToShow) {
		showActionButtonAction(
			ACTION_BUTTON_STATE.SUBMIT_PREP,
			submitButtonText,
			ACTION_BUTTON_LOADER_TYPE.TIME,
			minTimeToShow,
		)(dispatch, getState)
	}
}
