export const CHOICE_SUBTYPE = {
	CHECKBOX: 'CHECKBOX',
	IMAGE: 'IMAGE',
	SELECT: 'SELECT',
	BLOCK: 'BLOCK',
}

export const CHOICE_DISPLAY_TYPE = {
	ROW: 'row',
	COLUMN: 'column',
}
