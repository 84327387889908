export const ACTION_BAR_TYPE = {
	LOADER: 'loader',
	BUTTON: 'button',
	HINT: 'hint',
}

export const ACTION_BAR_HEIGHT = {
	BASE: 63,
	EXTENDED: 113,
	EXTENDED_BIGGER: 143,
}
