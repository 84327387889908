import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'

import DotsLoader from 'components/_scaffolding/DotsLoader'
import { getIsE2ETesting } from 'helpers/getIsE2ETesting'

import './PlatformStatus.scss'

class PlatformStatus extends Component {
	static propTypes = {
		getPlatformStatus: PropTypes.func.isRequired,
		intl: intlShape.isRequired,
		isSolverOnline: PropTypes.bool.isRequired,
	}

	constructor(props) {
		super(props)

		this.state = {
			idInterval: null,
		}
	}

	componentDidMount() {
		this.props.getPlatformStatus()
		const idInterval = setInterval(this.props.getPlatformStatus, 60 * 1000)
		this.setState({ idInterval })

		this.idForceUpdateTimeout = setTimeout(() => this.forceUpdate(), 1000)
	}

	componentWillUnmount() {
		clearInterval(this.state.idInterval)
		clearTimeout(this.idForceUpdateTimeout)
	}

	handleClick = event => {
		event.stopPropagation()
	}

	render() {
		const { intl, isSolverOnline } = this.props

		if (isSolverOnline === true) {
			return null
		}

		if (getIsE2ETesting() === true) {
			return null
		}

		return (
			<div className="holder" onClick={this.handleClick}>
				<div className="background" />
				<div className="overlay-content">
					<div className="header">{intl.formatMessage({ id: 'platform_update_header' })}</div>
					<div className="message">{intl.formatMessage({ id: 'platform_update_message' })}</div>
					<div className="dotsloader">
						<DotsLoader />
					</div>
				</div>
			</div>
		)
	}
}

export default injectIntl(PlatformStatus)
