export const getChoiceInstructionArgs = definition => {
	const { multiple, minSelection, maxSelection, options } = definition

	if (multiple === false) {
		return ['choice_single_hint']
	}

	const normalOptionsCount = options.filter(o => o.isNoneOfThese === false).length

	const maxOptionsAllowedToSelect = Math.min(normalOptionsCount, maxSelection)

	if (maxOptionsAllowedToSelect === 1) {
		return ['choice_single_hint']
	}

	return [
		'choice_multiple_hint',
		{
			min: minSelection,
			max: maxOptionsAllowedToSelect,
		},
	]
}
