import _ from 'lodash'

import config from 'config'

import { API, apiActions } from 'store/api'
import { errorHandler } from 'store/logger'
import { setGenericError } from 'store/_service/error'
import { clearSaveQueue } from 'store/queue'
import { v4 as uuidv4 } from 'uuid'

import { setMediaActionListener } from 'store/_chat/history'

import * as suggestionsActions from 'store/_chat/suggestions'
import * as actionsActions from 'store/_chat/actions'
import * as inputActions from 'store/_chat/input'
import * as actionBarActions from 'store/_chat/actionBar'
import * as reportContentFormActions from 'store/_chat/reportContentForm'
import * as accessibilityHintActions from 'store/_chat/accessibilityHint'

import { init as allocationInit } from 'store/_modules/allocation'
import { init as choiceInit } from 'store/_modules/choice'
import { init as elaborateInit } from 'store/_modules/elaborate'
import { init as eliminateInit } from 'store/_modules/eliminate'
import { init as endStudyInit } from 'store/_modules/endStudy'
import { init as freeTextInit } from 'store/_modules/freeText'
import { init as ideamatchingInit } from 'store/_modules/ideamatching'
import { init as ideateInit } from 'store/_modules/ideate'
import { init as messageInit, initNestedMessage, mediaActionListener } from 'store/_modules/message'
import { init as rankingInit } from 'store/_modules/ranking'
import { init as researchLabelingInit } from 'store/_modules/researchLabeling'
import { init as selectInit } from 'store/_modules/select'
import { init as uiCommandInit } from 'store/_modules/uiCommand'
import { init as heatmapInit } from 'store/_modules/heatmap'
import { init as maxDiffInit } from 'store/_modules/maxDiff'

import { getStudyObject } from 'selectors/study'

import { STUDY_LOG_MODULE, STUDY_SET_STUDY_OBJECT, STUDY_SET_GET_NEXT_STEP } from 'constants/study'

export const studyModules = {
	ALLOCATION: allocationInit,
	A_MESSAGE: messageInit,
	A_END_STUDY: endStudyInit,
	A_CHOICE: {
		CHECKBOX: choiceInit,
		IMAGE: choiceInit,
		SELECT: selectInit,
		BLOCK: choiceInit,
	},
	HEATMAP: heatmapInit,
	MAXDIFF: maxDiffInit,
	A_OEQ_IDEATE: ideateInit,
	A_OEQ_ELIMINATE: eliminateInit,
	A_OEQ_ELABORATE: elaborateInit,
	A_OEQ_MATCH: ideamatchingInit,
	// TODO: test this
	A_FREE_TEXT: freeTextInit,
	UI_COMMAND: uiCommandInit,
	RANKING: rankingInit,
	RESEARCH_LABELING: researchLabelingInit,
}

// ------------------------------------
// Constants
// ------------------------------------

const PRIMARY_COLOR_ACCESSIBILITY =
	config.clientConfig.primaryColor.toLowerCase() === '#00aff0'
		? '#007FAD'
		: config.clientConfig.primaryColor
const PRIMARY_COLOR_DEFAULT = config.clientConfig.primaryColor

// ------------------------------------
// Helpers
// ------------------------------------
const shadeColor = (color, percent) => {
	let f = parseInt(color.slice(1), 16)
	let t = percent < 0 ? 0 : 255
	let p = percent < 0 ? percent * -1 : percent
	let r = f >> 16
	let g = (f >> 8) & 0x00ff
	let b = f & 0x0000ff
	let newColor =
		'#' +
		(
			0x1000000 +
			(Math.round((t - r) * p) + r) * 0x10000 +
			(Math.round((t - g) * p) + g) * 0x100 +
			(Math.round((t - b) * p) + b)
		)
			.toString(16)
			.slice(1)
	return newColor
}

// ------------------------------------
// Actions
// ------------------------------------
export const setStudyObject = studyObject => (dispatch, getState) => {
	dispatch({
		type: STUDY_SET_STUDY_OBJECT,
		studyObject,
	})
}

export const setGetNextStep = fn => (dispatch, getState) => {
	dispatch({
		type: STUDY_SET_GET_NEXT_STEP,
		getNextStep: fn,
	})
}

export const getNextStep = (
	studyObjectResponse = null,
	studyObjectActionLog = null,
	shouldAutoFocusNextMessage = false,
) => (dispatch, getState) => {
	// reset view
	suggestionsActions.suggestionsHide()(dispatch, getState)
	suggestionsActions.reset()(dispatch, getState)
	actionsActions.actionsHide()(dispatch, getState)
	actionsActions.reset()(dispatch, getState)
	inputActions.reset()(dispatch, getState)
	actionBarActions.reset()(dispatch, getState)
	actionBarActions.showActionLoader()(dispatch, getState)
	accessibilityHintActions.reset()(dispatch, getState)

	// set media action listener for all messages
	setMediaActionListener(mediaActionListener)(dispatch, getState)

	// save reported content to be sent and reset report content form store
	const flaggedContent = getState().chatReportContentForm.toFlag.slice()
	reportContentFormActions.reset()(dispatch, getState)

	let saveQueue = getState().queue.saveQueue

	return apiActions
		.getNextStep(
			studyObjectResponse,
			studyObjectActionLog,
			saveQueue,
			flaggedContent,
		)(dispatch, getState)
		.then(apiResponse => {
			let appError = null
			// log api response to to apiLog
			dispatch({ type: STUDY_LOG_MODULE, newModule: apiResponse })

			if (apiResponse.type === API.GET_NEXT_STEP.ERROR) {
				setGenericError(
					true,
					apiResponse.status,
					apiResponse.errorTrace,
					apiResponse.errorId,
					apiResponse.errorMessage,
				)(dispatch, getState)
			} else if (apiResponse.type === API.GET_NEXT_STEP.SUCCESS) {
				clearSaveQueue()(dispatch, getState)

				let studyObject = getStudyObject()(dispatch, getState) // eslint-disable-line no-use-before-define

				const nestedMessages = _.get(studyObject, 'definition.messages', []) || []

				if (Object.keys(studyModules).includes(studyObject.type)) {
					const initModuleFn =
						typeof studyModules[studyObject.type][studyObject.definition.subtype] === 'function'
							? studyModules[studyObject.type][studyObject.definition.subtype]
							: studyModules[studyObject.type]

					if (nestedMessages.length === 0) {
						initModuleFn(studyObject.definition, shouldAutoFocusNextMessage)(dispatch, getState)
					} else {
						const [message, ...restOfMessages] = nestedMessages

						const initDatasetModule = () => {
							setStudyObject(studyObject)(dispatch, getState)
							initModuleFn(studyObject.definition)(dispatch, getState)
						}

						initNestedMessage(
							message,
							restOfMessages,
							initDatasetModule,
							setStudyObject,
							shouldAutoFocusNextMessage,
						)(dispatch, getState)
					}
				} else {
					appError = [601, 'Unsupported module', studyObject.type, JSON.stringify(studyObject)]
				}
			} else {
				appError = [602, 'Error loading nextStep', JSON.stringify(apiResponse)]
			}

			if (appError !== null) {
				let state = getState()

				// eslint-disable-next-line no-console
				console.error(...appError)

				// log error to sentry
				errorHandler(new Error(appError), state.study.studyObject)(dispatch, getState)

				setGenericError(true, appError[0], null, null, appError[1])(dispatch, getState)
			}
		})
		.catch(e => {
			setGenericError(true, 603, e.trace, e.id, e.message)(dispatch, getState)
			errorHandler(e)(dispatch, getState)
		})
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	apiEndpoint: null,
	language: 'en',
	publicLabel: '',
	publicDescription: '',
	customLogo: null,
	allowedLoginMethods: {
		anonymous: false,
		email: false,
	},
	autoLogin: false,
	studyObject: null,
	isInPreviewMode: false,

	theme: {
		primaryColor: PRIMARY_COLOR_DEFAULT,
		primaryDark: shadeColor(PRIMARY_COLOR_DEFAULT, -0.17),
		primaryLight: shadeColor(PRIMARY_COLOR_DEFAULT, 0.17),
		primaryPale: shadeColor(PRIMARY_COLOR_DEFAULT, 0.68),
	},

	isAccessibilityEnabled: false,

	getNextStep: null,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.REQUEST_STUDY_ENTRY.SUCCESS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.apiEndpoint = action.response.apiEndpoint
		newState.language = action.response.language
		newState.publicLabel = action.response.loginScreen.publicLabel
		newState.publicDescription = action.response.loginScreen.studyDescription
		newState.customLogo =
			action.response.loginScreen.logo === null ? null : action.response.loginScreen.logo.url
		newState.isInPreviewMode = action.response.isPreview
		newState.allowedLoginMethods = action.response.loginScreen.visibleButtons
		newState.autoLogin = action.response.autoLogin !== null
		newState.tags = action.response.tags || {}
		newState.isAccessibilityEnabled = _.get(newState.tags, 'isAccessibilityEnabled', false)

		if (action.response.custom.theme !== null) {
			let baseColor = action.response.custom.theme.baseColor
			newState.theme.primaryColor = baseColor
			newState.theme.primaryDark = shadeColor(baseColor, -0.17)
			newState.theme.primaryLight = shadeColor(baseColor, 0.17)
			newState.theme.primaryPale = shadeColor(baseColor, 0.68)

			const htmlElement = document.querySelector('html')
			htmlElement.style = `--primaryColor: ${baseColor}`
		}

		const hasDefaultTheme =
			newState.theme.primaryColor.toLowerCase() === PRIMARY_COLOR_DEFAULT.toLowerCase()

		if (hasDefaultTheme === true && newState.isAccessibilityEnabled === true) {
			// use darker blue as primary color if we're using default color
			newState.theme.primaryColor = PRIMARY_COLOR_ACCESSIBILITY
		}

		const htmlElement = document.querySelector('html')
		htmlElement.lang = newState.language

		return newState
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.studyObject = action.response.studyObject
		newState.studyObject.idHistory = uuidv4()

		return newState
	},
	[STUDY_SET_STUDY_OBJECT]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.studyObject = action.studyObject
		newState.studyObject.idHistory = uuidv4()

		return newState
	},
	[STUDY_SET_GET_NEXT_STEP]: (state, action) => {
		return {
			...state,
			getNextStep: action.getNextStep,
		}
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
