import { getIsAccessibilityEnabled } from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const TIMER_RESET = 'timer.reset'
export const TIMER_SET_INTERVAL = 'timer.set.interval'
export const TIMER_STOP = 'timer.stop'
export const TIMER_UPDATE = 'timer.update'
export const TIMER_SET_LISTENERS = 'timer.set.listeners'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	interval: null,
	duration: null,
	initialTime: null,

	updateListener: null,
	endListener: null,
}

// ------------------------------------
// Actions
// ------------------------------------
export const timerReset = () => (dispatch, getState) => {
	dispatch({ type: TIMER_RESET })
}

export const setTimerListeners = (updateListener, endListener = null) => (dispatch, getState) => {
	dispatch({
		type: TIMER_SET_LISTENERS,
		updateListener,
		endListener,
	})
}

export const setTimerInterval = (duration, updateTime = 500) => (dispatch, getState) => {
	let state = getState()

	let updateListener = state.chatTimer.updateListener
	let endListener = state.chatTimer.endListener

	let date = new Date()
	let time = date.getTime()
	let initialTime = state.chatTimer.initialTime || time

	let interval = setInterval(() => {
		let currentDate = new Date()
		let currentTime = currentDate.getTime()
		if (updateListener) {
			updateListener(currentTime, initialTime)(dispatch, getState)
		}
		if (duration <= currentTime - initialTime) {
			dispatch({ type: TIMER_STOP })

			if (getIsAccessibilityEnabled(state) === true) {
				return
			}

			if (endListener) {
				endListener(currentTime, initialTime)(dispatch, getState)
			}
		}
	}, updateTime)
	dispatch({
		type: TIMER_SET_INTERVAL,
		interval,
		duration,
		initialTime,
	})
}

export const stopInterval = () => (dispatch, getState) => {
	dispatch({ type: TIMER_STOP })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[TIMER_RESET]: (state, action) => {
		return initialState
	},
	[TIMER_SET_INTERVAL]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.interval = action.interval
		newState.duration = action.duration
		newState.initialTime = action.initialTime
		return newState
	},
	[TIMER_STOP]: (state, action) => {
		let newState = Object.assign({}, state)
		clearInterval(newState.interval)
		newState.interval = null
		return newState
	},
	[TIMER_SET_LISTENERS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.updateListener = action.updateListener
		newState.endListener = action.endListener
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters
// ------------------------------------
export const getInputValue = () => (dispatch, getState) => {
	let state = getState()
	return state.chatInput.value
}
