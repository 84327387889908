export const POSITION = {
	LEFT: 'left',
	RIGHT: 'right',
}

export const COMPONENTS = {
	MESSAGE: 'MESSAGE',
	QUOTE: 'QUOTE',
	IMAGE: 'IMAGE',
	THUMB: 'THUMB',
	INSTRUCTION: 'INSTRUCTION',
}

export const COMPONENT = {
	MESSAGE: {
		component: 'Message',
		isBlurred: false,
		props: {
			text: null,
			thumbs: [],
			media: null,
		},
	},
	QUOTE: {
		component: 'Quote',
		isBlurred: false,
		props: {
			text: null,
		},
	},
	IMAGE: {
		component: 'Image',
		isBlurred: false,
		props: {
			url: null,
		},
	},
	THUMB: {
		component: 'Thumb',
		isBlurred: false,
		props: {
			url: null,
			label: null,
		},
	},
	MESSAGE_THUMB: {
		url: null,
		label: null,
	},
	INSTRUCTION: {
		component: 'Instruction',
		isBlurred: false,
		props: {
			text: null,
		},
	},
}
