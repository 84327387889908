const messages = {
	not_found: '찾으시는 페이지가 존재하지 않습니다',
	service_outage:
		'당사의 클라우스 서비스 제공사가 전 세계적으로 잠시 서비스 제공을 중단하여 시스템이 다운되었습니다. 불편을 끼쳐 죄송합니다.',
	error_unexpected_error: '예상치 못한 문제가 발생했습니다. 나중에 다시 시도하십시오.',

	error_img_alt: '오류 이미지',
	error_try_again: '다시 시도하십시오',

	error_generic_header: '문제가 발생했습니다',
	error_generic_message:
		'다시 시도해주십시오. 여전히 오류가 발생한다면 위에 보이는 QR 코드를  support@groupsolver.com으로 보내주십시오.',

	error_offline_header: '오프라인이신 것 같습니다',
	error_offline_message: '연결이 재구축된 후 연구가 다시 시작됩니다.',

	error_landscape_small_screen_header: '기기를 회전 시켜 주십시오',
	error_landscape_small_screen_text:
		'이 연구를 보기에는 스크린이 너무 짧습니다. 기기를 회전 시켜 주십시오.',

	error_small_browser_window_header: '브라우저 창을 확대하여 주십시오',
	error_small_browser_window_text:
		'이 연구를 보기에는 창이 너무 작습니다. 브라우저 창을 확대하여 주십시오',

	login_error_empty_email: '이메일이 공란이어서는 안됩니다',
	login_error_invalid_email: '유효한 이메일 주소를 입력하여 주십시오',
	login_error_unable_to_login: '현재 로그인할 수 없습니다. 나중에 다시 시도하여 주십시오.',

	login_email_placeholder: '이메일을 입력해주십시오',
	login_email_button: '계속',
	login_email_back: '또는 다른 방법으로 로그인하기',
	login_email_label: '이메일로 계속하기',
	login_anonymous_label: '익명으로 계속하기',

	login_privacy_statement: 'GroupSolver는 귀하가 제공하신 정보와 귀하의 프라이버시를 존중합니다.',
	login_privacy_statement_email:
		'귀하의 이메일은 귀하에게 본 프로젝트의 접근권을 부여하는 것 이외에는 절대로 공유, 판매 또는 사용되지 않을 것입니다.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: '찾고 계시는 연구는 현재 이용할 수 없습니다.',
	study_unavailable_description:
		'저희 연구에 관심을 가져 주셔서 감사합니다만, 본 연구는 이미 마감되어 현재 멈춰진 상태입니다. 불편을 끼쳐 드려 죄송합니다.',
	study_preview_limit_reached_label: '미리보기 한도를 모두 소진하셨습니다',
	study_preview_limit_reached_description:
		'저희 연구에 관심을 가져 주셔서 감사합니다만, 본 연구의 미리보기 한도가 이미 소진되었습니다.',

	waiting_message:
		'현재 다양한 분들께서 이 연구의 해결에 참여해 주시고 계십니다. \n {number, plural, =0 { 지금 당장 } one {# 초 이내} other {# 초 이내}}에 본 연구에 다시 재입장을 시도하겠습니다.',
	waiting_error_recoverable: '예상치 못한 오류가 발생했습니다.',
	waiting_error_recoverable_retry:
		'{number, plural, =0 { 지금 당장 } one {# 초 이내} other {# 초 이내}}에 다시 시도하겠습니다',
	waiting_error_unknown_study: '찾으시는 연구가 존재하지 않습니다.',
	waiting_error_dead: '예상치 못한 문제가 발생했습니다. 나중에 다시 시도하십시오.',

	action_input_submit_button_text: '제출',
	action_input_skip_button_text: '계속하기',

	choice_single_hint: '옵션 중 하나 선택하기',
	general_skip_action_message: '다음 단계로 진행',

	action_button_primary_default_text: '액션',
	action_button_hint_default_text: '옵션 중에서 하나 선택하기',

	message_media_examine_video: '비디오를 클릭하여 검토하세요',
	message_media_examine_image: '이미지를 클릭하여 검토하세요',
	message_full_screen_close_button_text: '닫기',
	message_image_view_label: '이미지 보기',
	message_video_view_label: '비디오 보기',
	message_image_examined_label: '검토한 이미지',
	message_video_examined_label: '검토한 비디오',
	message_zoom_mouse: '커서를 위에 올려 크기 조절',
	message_zoom_touch: '터치하여 크기 조절',

	textarea_too_long_hint: '텍스트가 너무 깁니다',

	ideate_placeholder: '짧은 문장으로 답하세요',
	ideate_skip: ' 또는, 계속 진행하세요',

	eliminate_no: '아니요',
	eliminate_yes: '예',
	eliminate_intro: '다음 문장을 생각해주십시오:',
	eliminate_multiple_suggestions: '다음 중 이 문장을 대체할 수 있을 보기는 무엇입니까?',
	eliminate_one_suggestion: '이 문장을 다음 보기로 대체할 수 있을까요?',
	eliminate_step: '다른 문장을 생각해주세요:',
	eliminate_none_of_the_above: '이 중에 없음',

	elaborate_indifferent_label: '중립',
	elaborate_disagree_label: '동의하지 않음',
	elaborate_agree_label: '동의',
	elaborate_unclear_statement_label: '명확하지 않은 답',
	elaborate_intro: '다른 이들이 다음과 같이 답했습니다. 귀하께서는 동의하십니까?',

	ideamatching_unsure_label: '잘 모르겠음',
	ideamatching_unequal_label: '다름',
	ideamatching_equal_label: '같음',
	ideamatching_intro: '다음 쌍의 답변을 살펴봐 주십시오. 동일한 의미를 가집니까?',

	select_input_placeholder: '여기에 타이핑하여 선택하십시오',
	select_input_placeholder_max: '최대치 도달',
	select_multiple_choice_hint:
		'{minNumber, plural, =0 { 0 } other { # }} ~ {maxNumber, plural, =0 { 0 } other { # }} 개의 옵션을 선택하십시오',
	select_no_matches_found: '매칭되는 바가 없음',

	report_content_inappropriate: '올바르지 않은 답변',
	report_content_profane: '외설적이거나 불경한 내용',
	report_content_gibberish: '횡설수설하거나 뜻이 통하지 않는 텍스트',
	report_content_irrelevant: '질문과 관련이 없는 답변',
	report_content_different_language: '다른 언어',
	report_content_other: '기타',
	report_content_no_content: '신고할 내용 없음',
	report_content_header: '답변 신고하기',
	report_content_form_header: '이 답변에 대한 문제를 제기하는 이유를 선택하여 주십시오',
	report_content_flag_answer: '답변에 대한 문제 제기',
	report_content_return_to_study: '연구로 돌아가기',
	report_content_thank_you:
		'피드백을 주셔서 감사합니다. 귀하의 의견을 검토한 뒤 필요한 조치를 취하겠습니다. ',
	report_content_statement_flagged: '지문에 대한 문제가 제기됐습니다.',

	platform_update_header: 'GroupSolver는 현재 주기적인 유지보수를 위한 점검 중입니다',
	platform_update_message: '불편을 끼쳐 죄송합니다.',

	none_of_these: '이들 중에 없음',

	allocation_instruction_limit: '모든 옵션에 {points} 할당',
	allocation_instruction_limit_no_minimum: '모든 옵션에 최대 {points} 할당',
	allocation_instruction_no_limit: '모든 옵션에 포인트 할당',
	allocation_instruction_points_left: '{points} 남음',
	allocation_options_out_of_range: '일부 옵션은 범위를 벗어납니다.',
	allocation_limit_overflow: '{points} 초과',
	allocation_option_invalid_range: ' {range} range범위를 넘는 값입니다. ',
	allocation_option_invalid_min: '값은 {min}와 같거나 이상이어야 합니다. ',

	dropdown_select: '선택하세요...',

	ranking_drag_options: '옵션 순위를 매기려면 화살표를 드래그하거나 클릭하세요.',
	ranking_assign_option_values: '모든 옵션의 순위',
	ranking_require_unique_values: '옵션의 순위는 독특해야 합니다.',

	heatmap_instruction_intro: '선택을 시작하려면 아래 이미지를 보세요.',
	heatmap_instruction_unlimited: '이미지를 클릭하여 원하는 만큼의 포인트를 선택하세요.',
	heatmap_instruction_count:
		'이미지를 클릭하여 {number, plural, =0 {0 포인트} one {1 포인트} other {{number} 포인트}}를 선택하세요.',
	heatmap_instruction_up_to_count:
		'이미지를 클릭하여 최대 {number, plural, =0 {0 포인트} one {1 포인트} other {{number} 포인트}}를 선택하세요.',
	heatmap_instruction_between_count: '이미지를 클릭하여 {from}에서 {to} 포인트를 선택하세요.',
	heatmap_instruction_at_least_count:
		'이미지를 클릭하여 최소 {number, plural, =0 {0 포인트} one {1 포인트} other {{number} 포인트}}를 선택하세요.',
	heatmap_response: '{number, plural, =0 {0 포인트} one {1 포인트} other {{number} 포인트}} 선택됨',
	continue: '계속',
	reset: '재설정',
	input_invalid: '입력이 유효하지 않습니다.',
	input_empty: '입력란이 비어 있을 수 없습니다.',
	input_chars_left: '{number} {number, plural, =0 {문자} one {문자} other {문자}} 남음.',
	input_too_long: '{number} {number, plural, =0 {문자} one {문자} other {문자}} 초과.',
	ranking_select_rank: '{label}의 순위를 선택하세요.',
	ranking_select_aria_label: '각 옵션의 순위를 값으로 입력하여 선택하세요.',
	ranking_dnd_aria_label:
		'옵션에 포커스를 맞추고 스페이스 바를 눌러 드래그를 시작하세요. 드래그 중에는 화살표 키를 사용하여 항목을 이동하고, 취소하려면 이스케이프 키를 누르세요. 스페이스 바를 다시 눌러 항목을 새 위치에 놓으세요. 화면 읽기 프로그램이 포커스 모드 또는 폼 모드에 있는지 확인하세요.',
	allocation_option_input: '{label} 값 입력.',
	allocation_aria_label: '입력을 사용하여 각 옵션에 대한 값을 입력하세요.',
	play: '재생',
	pause: '일시 정지',
	stop: '정지',
	fwd: '앞으로 건너뛰기',
	rwd: '되감기',
	volume_down: '볼륨 줄이기',
	volume_up: '볼륨 높이기',
	volume_current: '볼륨: {number}%',
	loading: '로딩 중',
	press_tab_to_continue: '계속하려면 탭을 누르세요',
	option_focus_selected:
		'옵션 {label}에 포커스가 맞춰졌습니다. 선택 해제하려면 엔터 키를 누르세요.',
	option_focus_not_selected: '옵션 {label}에 포커스가 맞춰졌습니다. 선택하려면 엔터 키를 누르세요.',
	image_alt: '이미지:',
	video_alt: '비디오:',
	submit_enabled: '제출 가능.',
	skip_enabled: '질문 건너뛰기 가능',
	n_of_min_selected: '{selected}개 중 {min}개 선택됨.',
	option_selected: '{label} 선택됨.',
	option_deselected: '{label} 선택 해제됨.',
	choice_multiple_hint: '{min}에서 {max}개의 옵션을 선택하세요.',
	ranking_position: '위치: {position}',
	ranking_position_changed: '{label} 값이 {value}로 설정됨.',
	ranking_input_aria_label: '{label}. 현재 위치: {position}. 1에서 {max}까지의 위치를 입력하세요.',
	not_selected: '선택되지 않음',
	study_logo_alt: '연구 로고',
	groupsolver_logo_alt: '그룹솔버 로고',
}

export default messages
