import React from 'react'

export default store => ({
	path: 'error',
	getComponent(nextState, cb) {
		require.ensure(
			[],
			require => {
				cb(null, props => {
					const Component = require('components/_scaffolding/TranslatedText').default
					const ComponentProps = {
						id: 'error_unexpected_error',
					}
					return <Component {...ComponentProps} {...props} />
				})
			},
			'error',
		)
	},
})
