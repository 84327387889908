import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { getIsStudyRtl } from 'store/intl'
import { toggleOption } from 'store/_chat/suggestions'

import ActionSelectAccessible from './ActionSelectAccessible'

const mapActionCreators = {
	toggleOption,
}

const mapStateToProps = (state, ownProps) => {
	return {
		options: state.chatSuggestions.options,
		multiple: state.chatSuggestions.multiple,
		maxSelection:
			state.study.studyObject &&
			state.study.studyObject.definition &&
			state.study.studyObject.definition.maxSelection
				? state.study.studyObject.definition.maxSelection
				: 0,
		theme: state.study.theme,
		isStudyRtl: getIsStudyRtl(state),
	}
}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ActionSelectAccessible))
