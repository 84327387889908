/* eslint-disable max-len */

const messages = {
	not_found: 'La pagina che state cercando non esiste',
	error_unexpected_error: 'Si è verificato un errore imprevisto. Si prega di riprovare più tardi.',

	error_img_alt: 'Immagine con errore',
	error_try_again: 'Prova ancora',

	error_generic_header: 'Qualcosa e` andato in modo sbagliato',
	error_generic_message:
		'Si prega di riprovare, e se il problema rimarra`, si prega di inviare una mail a support@groupsolver.com con il codice QR che vedete sopra.',

	error_offline_header: 'Sembra che il tuo dispositivo non sia connesso a Internet',
	error_offline_message:
		'La tua ricerca continuerà non appena verrà ripristinata la connessione a Internet.',

	error_landscape_small_screen_header: 'Si prega di ruotare il dispositivo',
	error_landscape_small_screen_text:
		'La dimensione del tuo schermo non è sufficiente per vedere questa ricerca. Si prega di ruotare il dispositivo.',

	error_small_browser_window_header:
		'Si prega di aumentare le dimensioni della finestra del browser',
	error_small_browser_window_text:
		'La tua finestra è troppo piccola. Si prega di espandere la finestra del browser.',

	login_error_empty_email: 'Il campo con la tua e-mail non può essere vuoto',
	login_error_invalid_email: 'Immettere un indirizzo e-mail valido',
	login_error_unable_to_login: "Impossibile accedere all'account. Si prega di riprovare più tardi.",

	login_email_placeholder: 'Inserisci il tuo indirizzo e-mail',
	login_email_button: 'Continua',
	login_email_back: 'o passare ai parametri di autorizzazione',
	login_email_label: 'Continua con la posta elettronica',
	login_anonymous_label: 'Continua in modo anonimo',

	login_privacy_statement: 'GroupSolver rispetta la tua privacy e le informazioni che fornisci.',
	login_privacy_statement_email:
		'Il tuo indirizzo e-mail non sarà mai utilizzato, venduto o utilizzato in alcun altro modo, ad eccezione del fatto che ti consente di accedere a questo progetto.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'La ricerca che stai cercando non è disponibile.',
	study_unavailable_description:
		'Grazie per il tuo interesse nella nostra ricerca! Sfortunatamente, questa ricerca è già stata compilata o è in pausa al momento. Ci scusiamo per gli eventuali disagi causati.',
	study_preview_limit_reached_label: 'Il Tuo limite di anteprima è stato raggiunto',
	study_preview_limit_reached_description:
		'Grazie per il Tuo interesse nel nostro studio! Purtroppo, questo studio ha raggiunto il numero di anteprime disponibili.',

	waiting_message:
		'Al momento, un paio delle persone sono sottoposti alla ricerca. \n Effettueremo automaticamente un tentativo {number, plural, =0 { ora } one {tra # seconda} other {tra # secondi}}.',

	waiting_error_recoverable: 'Si è verificato un errore imprevisto.',
	waiting_error_recoverable_retry:
		'Ci riproveremo di nuovo. {number, plural, =0 { ora } one {tra # seconda} other {tra # secondi}}',

	waiting_error_unknown_study: 'La ricerca che stai cercando non esiste.',
	waiting_error_dead: 'Si è verificato un errore sconosciuto. Si prega di riprovare più tardi.',

	action_input_submit_button_text: 'INVIA',
	action_input_skip_button_text: 'Salta',

	choice_single_hint: 'Scegli una delle opzioni',
	general_skip_action_message: 'Vai alla opzione successiva',

	action_button_primary_default_text: 'Continua',
	action_button_hint_default_text: 'Scegli una delle opzioni',

	message_media_examine_video: 'Clicca su video per guardarlo',
	message_media_examine_image: "Clicca un'immagine per vederla",
	message_full_screen_close_button_text: 'Chiudi',
	message_image_view_label: 'Vedere immagine',
	message_video_view_label: 'Vedere video',
	message_image_examined_label: 'Immagine visualizzata',
	message_video_examined_label: 'Video visualizzato',
	message_zoom_mouse: 'Posiziona per ingrandire',
	message_zoom_touch: 'Tocca per ingrandire',

	textarea_too_long_hint: 'il tuo testo e` molto lungo',

	ideate_placeholder: 'Rispondi con frasi brevi',
	ideate_skip: ' o salta',

	eliminate_no: 'No',
	eliminate_yes: 'Si',
	eliminate_intro: 'Prima hai comunicato:',
	eliminate_multiple_suggestions: 'Quale delle seguenti opzioni può sostituire la tua risposta?',
	eliminate_one_suggestion: 'La seguente risposta potrebbe sostituire la tua??',
	eliminate_step: "Un'altra tua risposta è stata:",
	eliminate_none_of_the_above: 'Niente di proposto',

	elaborate_indifferent_label: 'Non sono sicuro',
	elaborate_disagree_label: 'Non sono d`accordo',
	elaborate_agree_label: 'Sono d`accordo',
	elaborate_unclear_statement_label: 'Risposta non chiara',
	elaborate_intro: "Altri partecipanti hanno fornito le seguenti risposte. Sei d'accordo con loro?",

	ideamatching_unsure_label: 'Non sono sicuro',
	ideamatching_unequal_label: 'Altro',
	ideamatching_equal_label: 'Stesso',
	ideamatching_intro: 'Guarda questo paio di risposte. Significano la stessa cosa?',

	select_input_placeholder: 'Clicca qui per scegliere',
	select_input_placeholder_max: 'E` raggiunto il massimo',
	select_multiple_choice_hint:
		'Scegli {minNumber, plural, =0 { 0 } other { # }} e {maxNumber, plural, =0 { 0 } other { # }} opzioni',
	select_no_matches_found: 'Nessuna coincidenza trovata',

	report_content_inappropriate: 'Risposta inappropriata',
	report_content_profane: 'Contenuto osceno o profano',
	report_content_gibberish: 'Testo senza senso',
	report_content_irrelevant: 'Irrilevante per la domanda posta',
	report_content_different_language: 'Altra lingua',
	report_content_other: 'Altro',
	report_content_no_content: "Non c'è contenuto da segnalare",
	report_content_header: 'Segnalare la risposta',
	report_content_form_header:
		'Si prega di selezionare il motivo per cui si desidera contrassegnare questa risposta',
	report_content_flag_answer: 'Contrassegnare la risposta',
	report_content_return_to_study: 'Ritornare allo studio',
	report_content_thank_you: `Grazie per il tuo feedback.\n
		Lo esamineremo e prenderemo provvedimenti necessari.`,
	report_content_statement_flagged: 'Dichiarazione è stata contrassegnata.',

	platform_update_header: 'GroupSolver è attualmente in fase di manutenzione programmata',
	platform_update_message: 'Ci scusiamo per qualsiasi eventuale disagio.',

	none_of_these: 'Nessuna di queste',

	allocation_instruction_limit: 'Assegna {points} a tutte le opzioni',
	allocation_instruction_limit_no_minimum: 'Assegna fino a {points} a tutte le opzioni',
	allocation_instruction_no_limit: 'Assegna punti a tutte le opzioni',
	allocation_instruction_points_left: '{points} rimanenti',
	allocation_options_out_of_range: 'Alcune opzioni sono fuori intervallo',
	allocation_limit_overflow: '{points} oltre il limite',
	allocation_option_invalid_range: 'Il valore ricade fuori dall’intervallo {range}',
	allocation_option_invalid_min: 'Il valore deve essere maggiore o uguale a {min}',

	dropdown_select: 'Seleziona...',

	ranking_drag_options: 'Trascina o clicca le frecce per ordinare le opzioni',
	ranking_assign_option_values: 'Classifica tutte le opzioni',
	ranking_require_unique_values: 'La posizione delle opzioni deve essere univoca',

	heatmap_instruction_intro: "Per iniziare la selezione, visualizza l'immagine qui sotto.",
	heatmap_instruction_unlimited: "Seleziona quanti punti desideri cliccando sull'immagine.",
	heatmap_instruction_count:
		"Seleziona {number, plural, =0 {0 punti} one {un punto} other {{number} punti}} cliccando sull'immagine.",
	heatmap_instruction_up_to_count:
		"Seleziona {number, plural, =0 {0 punti} one {un punto} other {fino a {number} punti}} cliccando sull'immagine.",
	heatmap_instruction_between_count: "Seleziona da {from} a {to} punti cliccando sull'immagine",
	heatmap_instruction_at_least_count:
		"Seleziona almeno {number, plural, =0 {0 punti} one {un punto} other {{number} punti}} cliccando sull'immagine.",
	heatmap_response:
		'{number, plural, =0 {0 punti} one {1 punto} other {{number} punti}} selezionati',
	continue: 'Continua',
	reset: 'Reimposta',
	input_invalid: "L'input non è valido.",
	input_empty: "L'input non può essere vuoto.",
	input_chars_left:
		'{number} {number, plural, =0 {caratteri} one {carattere} other {caratteri}} rimanenti.',
	input_too_long:
		'{number} {number, plural, =0 {caratteri} one {carattere} other {caratteri}} oltre il limite.',
	ranking_select_rank: 'Seleziona il rango di {label}.',
	ranking_select_aria_label: 'Seleziona il rango di ciascuna opzione digitando un valore.',
	ranking_dnd_aria_label:
		"Focalizzati su un'opzione e premi la barra spaziatrice per iniziare a trascinare. Durante il trascinamento puoi usare i tasti freccia per spostare l'elemento e il tasto esc per annullare. Premi di nuovo la barra spaziatrice per posizionare l'elemento nella nuova posizione. Assicurati che il tuo screen reader sia in modalità focus o modalità moduli.",
	allocation_option_input: 'Input valore {label}.',
	allocation_aria_label: 'Inserisci un valore per ciascuna opzione utilizzando gli input.',
	play: 'Riproduci',
	pause: 'Pausa',
	stop: 'Ferma',
	fwd: 'Salta avanti',
	rwd: 'Riavvolgi',
	volume_down: 'Diminuisci volume',
	volume_up: 'Aumenta volume',
	volume_current: 'Volume: {number}%',
	loading: 'Caricamento',
	press_tab_to_continue: 'Premi tab per continuare',
	option_focus_selected: 'Opzione {label} focalizzata. Premi invio per deselezionare.',
	option_focus_not_selected: 'Opzione {label} focalizzata. Premi invio per selezionare.',
	image_alt: 'immagine:',
	video_alt: 'video:',
	submit_enabled: 'Invio abilitato.',
	skip_enabled: 'Salta domanda abilitato',
	n_of_min_selected: '{selected} di {min} selezionati.',
	option_selected: '{label} selezionato.',
	option_deselected: '{label} deselezionato.',
	choice_multiple_hint: 'Seleziona da {min} a {max} opzioni.',
	ranking_position: 'posizione: {position}',
	ranking_position_changed: 'Valore di {label} impostato a {value}.',
	ranking_input_aria_label:
		'{label}. Posizione attuale: {position}. Digita una posizione da 1 a {max}.',
	not_selected: 'non selezionato',
	study_logo_alt: 'Logo dello studio',
	groupsolver_logo_alt: 'Logo di Groupsolver',
}

export default messages
