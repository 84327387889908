/* eslint-disable max-len */

const messages = {
	not_found: 'Страница, которую вы ищите, не существует',
	error_unexpected_error: 'Произошла неожиданная ошибка. Пожалуйста, попробуйте позже. ',

	error_img_alt: 'Изображение с ошибкой',
	error_try_again: 'Попробуйте еще раз',

	error_generic_header: 'Что-то пошло не так',
	error_generic_message:
		'Попробуйте еще раз, и если все еще будет проблема, пожалуйста, отправьте email на support@groupsolver.com с QR кодом, который вы видите выше ',

	error_offline_header: 'Похоже, ваше устройство не подключено к интернету',
	error_offline_message:
		'Ваше исследование продолжится, как только будет восстановлено соединение с интернетом.',

	error_landscape_small_screen_header: 'Пожалуйста, поверните ваше устройство',
	error_landscape_small_screen_text:
		'Размеров вашего экрана не достаточно, чтобы посмотреть это исследование. Пожалуйста, поверните ваше устройство.',

	error_small_browser_window_header: 'Пожалуйста, увеличте размер окна вашего браузера',
	error_small_browser_window_text:
		'Ваше окно слишком маленького размера. Пожалуйста, расширьте окно вашего браузера.',

	login_error_empty_email: 'Поле с вашей электронной почтой не может быть пустым',
	login_error_invalid_email: 'Пожалуйста, введите правильный email',
	login_error_unable_to_login: 'Не удается войти в учетную запись. Пожалуйста, попробуйте позже.',

	login_email_placeholder: 'Введите ваш email',
	login_email_button: 'Продолжить',
	login_email_back: 'или перейти к параметрам авторизации',
	login_email_label: 'Продолжить с помощью электронной почты ',
	login_anonymous_label: 'Продолжить анонимно',

	login_privacy_statement:
		'GroupSolver уважает вашу конфиденциальность и предоставленную вами информацию',
	login_privacy_statement_email:
		'Ваш адрес электронной почты никогда не будет использоваться, продаваться или использоваться каким-либо другим способом, кроме предоставления вам доступа к этому проекту.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Исследование, которое вы ищите, недоступно.',
	study_unavailable_description:
		'Спасибо за интерес к нашему исследованию! К сожалению, ислледование уже завершено или приостановлено. Мы приносим извинения за неудобства.',
	study_preview_limit_reached_label: 'Ваш лимит на предварителтный просмотр исчерпан',
	study_preview_limit_reached_description:
		'Спасибо за Ваш интерес к нашему исследоаванию! К сожалению, лимит на предварительный просмотр данного ислледования был исчерпан.',

	waiting_message:
		'В данный момент исследование проходят несколько людей. \n Мы будем автоматически повторять вход {number, plural, =0 { сейчас } one {через # секунду} other {через # секунд}}.',
	waiting_error_recoverable: 'Произошла неожиданная ошибка.',
	waiting_error_recoverable_retry:
		'Мы попробуем еще раз {number, plural, =0 { сейчас } one {через # секунду} other {через # секунд}}',
	waiting_error_unknown_study: 'Исследование, которое вы ищите, не существует.',
	waiting_error_dead: 'Произошла неизвестная ошибка. Пожалуйста, попробуйте позже.',

	action_input_submit_button_text: 'OТПРАВИТЬ',
	action_input_skip_button_text: 'Пропустить',

	choice_single_hint: 'Выберите один из вариантов',
	general_skip_action_message: 'Пропустить и перейти к следующему шагу',

	action_button_primary_default_text: 'Продолжить',
	action_button_hint_default_text: 'Выберите один из вариантов',

	message_media_examine_video: 'Нажмите на видео, чтобы просмотреть его',
	message_media_examine_image: 'Нажмите на изображение, чтобы просмотреть его',
	message_full_screen_close_button_text: 'Закрыть',
	message_image_view_label: 'Просмотреть изображение',
	message_video_view_label: 'Просмотреть Видео',
	message_image_examined_label: 'Изображение просмотрено',
	message_video_examined_label: 'Видео просмотрено',
	message_zoom_mouse: 'Наведите, чтобы приблизить',
	message_zoom_touch: 'Коснитесь, чтобы приблизить',

	textarea_too_long_hint: 'Ваш текст слишком длинный',

	ideate_placeholder: 'Ответьте короткими фразами',
	ideate_skip: ' или пропустите',

	eliminate_no: 'Нет',
	eliminate_yes: 'Да',
	eliminate_intro: 'Ранее вы сказали:',
	eliminate_multiple_suggestions: 'Что из предложенного может заменить ваш ответ?',
	eliminate_one_suggestion: 'Данный ответ мог быть заменить ваш?',
	eliminate_step: 'Другой ваш ответ был следуюшим:',
	eliminate_none_of_the_above: 'Ничего из предложенного',

	elaborate_indifferent_label: 'Не уверен',
	elaborate_disagree_label: 'Я не согласен',
	elaborate_agree_label: 'Я согласен',
	elaborate_unclear_statement_label: 'Непонятный ответ',
	elaborate_intro: 'Другие участники предоставили следующие ответы. Вы согласны с ними? ',

	ideamatching_unsure_label: 'Не уверен',
	ideamatching_unequal_label: 'Другое',
	ideamatching_equal_label: 'То же самое',
	ideamatching_intro: 'Посмотрите на эту пару ответов. Они означают одно и тоже?',

	select_input_placeholder: 'Нажмите сюда, чтобы выбрать',
	select_input_placeholder_max: 'Достигнут максимум',
	select_multiple_choice_hint:
		'Выберите {minNumber, plural, =0 { 0 } other { # }} до {maxNumber, plural, =0 { 0 } other { # }} вариантов',
	select_no_matches_found: 'Совпадения не найдены ',

	report_content_inappropriate: 'Некорректный ответ',
	report_content_profane: 'Нецензурный ответ',
	report_content_gibberish: 'Бессмысленный набор слов',
	report_content_irrelevant: 'Не имеющий отношения к заданному вопросу',
	report_content_different_language: 'Другой язык',
	report_content_other: 'Другое',
	report_content_no_content: 'Нет контента, о котором стоит предупредить',
	report_content_header: 'Предупредить об ответе',
	report_content_form_header:
		'Пожалуйста, выберите причину, по которой вы хотите пометить этот ответ',
	report_content_flag_answer: 'Пометить ответ',
	report_content_return_to_study: 'Вернуться к исследованию',
	report_content_thank_you: `Спасибо за ваш отчет.\n
		Мы рассмотрим его и в случае необходимости предпримем меры.`,
	report_content_statement_flagged: 'Сообщение было помечено.',

	platform_update_header: 'Сейчас проводится плановое техобслуживание GroupSolver',
	platform_update_message: 'Приносим извинения за доставленные неудобства.',

	none_of_these: 'Ни один из этих',

	allocation_instruction_limit: ' Присвойте {points} вариантам',
	allocation_instruction_limit_no_minimum: 'Присвойте до {points} вариантам',
	allocation_instruction_no_limit: 'Присвойте баллы вариантам',
	allocation_instruction_points_left: 'Остается {points}',
	allocation_options_out_of_range: 'Некоторые варианты вне диапазона',
	allocation_limit_overflow: '{points} сверх лимита',
	allocation_option_invalid_range: 'Значение вне диапазона {range}',
	allocation_option_invalid_min: 'Значение должно быть больше или равно {min}',

	dropdown_select: 'Выберите...',

	ranking_drag_options: 'Перетащите или нажмите на стрелки для упорядочивания по приоритету',
	ranking_assign_option_values: 'Ранжируйте все варианты ',
	ranking_require_unique_values: 'Рейтинги вариантов должны быть уникальными',

	heatmap_instruction_intro: 'Чтобы начать выбор, посмотрите изображение ниже.',
	heatmap_instruction_unlimited: 'Выберите столько точек, сколько хотите, щелкнув по изображению.',
	heatmap_instruction_count:
		'Выберите {number, plural, =0 {0 точек} one {одну точку} other {{number} точек}}, щелкнув по изображению.',
	heatmap_instruction_up_to_count:
		'Выберите {number, plural, =0 {0 точек} one {одну точку} other {до {number} точек}}, щелкнув по изображению.',
	heatmap_instruction_between_count: 'Выберите от {from} до {to} точек, щелкнув по изображению.',
	heatmap_instruction_at_least_count:
		'Выберите как минимум {number, plural, =0 {0 точек} one {одну точку} other {{number} точек}}, щелкнув по изображению.',
	heatmap_response: '{number, plural, =0 {0 точек} one {1 точка} other {{number} точек}} выбрано',
	continue: 'Продолжить',
	reset: 'Сбросить',
	input_invalid: 'Ввод недействителен.',
	input_empty: 'Ввод не может быть пустым.',
	input_chars_left:
		'Осталось {number} {number, plural, =0 {символов} one {символ} other {символов}}.',
	input_too_long:
		'{number} {number, plural, =0 {символов} one {символ} other {символов}} сверх лимита.',
	ranking_select_rank: 'Выберите ранг {label}.',
	ranking_select_aria_label: 'Выберите ранг для каждого варианта, введя значение.',
	ranking_dnd_aria_label:
		'Сфокусируйтесь на варианте и нажмите пробел, чтобы начать перетаскивание. При перетаскивании вы можете использовать клавиши со стрелками для перемещения элемента и Escape для отмены. Нажмите пробел еще раз, чтобы поместить элемент на новое место. Убедитесь, что ваш экранный диктор находится в режиме фокуса или формы.',
	allocation_option_input: 'Ввод значения для {label}.',
	allocation_aria_label: 'Введите значение для каждого варианта, используя поля ввода.',
	play: 'Воспроизвести',
	pause: 'Пауза',
	stop: 'Остановить',
	fwd: 'Перемотать вперед',
	rwd: 'Перемотать назад',
	volume_down: 'Уменьшить громкость',
	volume_up: 'Увеличить громкость',
	volume_current: 'Громкость: {number}%',
	loading: 'Загрузка',
	press_tab_to_continue: 'Нажмите Tab, чтобы продолжить',
	option_focus_selected: 'Вариант {label} в фокусе. Нажмите Enter, чтобы отменить выбор.',
	option_focus_not_selected: 'Вариант {label} в фокусе. Нажмите Enter, чтобы выбрать.',
	image_alt: 'изображение:',
	video_alt: 'видео:',
	submit_enabled: 'Отправка включена.',
	skip_enabled: 'Пропуск вопроса включен',
	n_of_min_selected: 'Выбрано {selected} из {min}.',
	option_selected: '{label} выбрано.',
	option_deselected: '{label} отменено.',
	choice_multiple_hint: 'Выберите от {min} до {max} вариантов.',
	ranking_position: 'позиция: {position}',
	ranking_position_changed: 'Значение {label} установлено на {value}.',
	ranking_input_aria_label: '{label}. Текущая позиция: {position}. Введите позицию от 1 до {max}.',
	not_selected: 'не выбрано',
	study_logo_alt: 'Логотип исследования',
	groupsolver_logo_alt: 'Логотип Gropsolver',
}

export default messages
