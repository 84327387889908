/* eslint-disable max-len */

const messages = {
	not_found: 'Ibe ị na-achọ adịghị',
	service_outage:
		"Anyị na-arịọ mgbaghara na n'ihi nkwụsị zuru ụwa ọnụ nke ọrụ ígwé ojii nke ndị na-eweta anyị sistemu anyị adaala.",
	error_unexpected_error: 'Enwere nsogbu a na-atụghị anya ya. Biko nwaa ọzọ ma emechaa.',

	error_img_alt: 'Foto mperi',
	error_try_again: 'Nwaa ọzọ',

	error_generic_header: 'Ọ nwere ihe adịghị mma',
	error_generic_message:
		"Gbalịa nke ahụ ọzọ, ma ọ bụrụ na ọ ka naghị arụ ọrụ, biko ziga email na support@groupsolver.com na koodu QR ị na-ahụ n'elu.",

	error_offline_header: "Ọ dị gị ka ọ nọghị n'ịntanetị",
	error_offline_message: 'Mụọ ihe gị ga-amaliteghachi mgbe emechara njikọ gị ọzọ.',

	error_landscape_small_screen_header: 'Biko bugharia ngwaọrụ gị',
	error_landscape_small_screen_text:
		'Ihuenyo gị adịchaghị mkpụmkpụ ịhụ ọmụmụ a. Biko bugharia ngwaọrụ gị.',

	error_small_browser_window_header: 'Biko gbasaa windo ihe nchọgharị gị',
	error_small_browser_window_text:
		'Window gị pere mpe ka ilele ọmụmụ ihe a. Biko gbasaa windo ihe nchọgharị gị',

	login_error_empty_email: 'Email enweghị ike ịbụ ihe efu',
	login_error_invalid_email: 'Biko tinye ozi email bara uru',
	login_error_unable_to_login: "Enweghị ike ịbanye n'oge a. Biko nwaa ọzọ ma emechaa.",

	login_email_placeholder: 'Tinye email gị',
	login_email_button: "Gaa n'ihu",
	login_email_back: 'ma ọ bụ laghachi azụ nhọrọ nbanye',
	login_email_label: "Gaa n'ihu na email",
	login_anonymous_label: "Gaa n'ihu na-enweghị aha",

	login_privacy_statement: 'GroupSolver na-asọpụrụ nzuzo gị yana ozi ị na-enye.',
	login_privacy_statement_email:
		"Agaghị ekekọrịta, ree, ma ọ bụ jiri email gị mee ihe n'ụzọ ọ bụla karịa inye gị ohere ịnweta ọrụ a.",
	login_privacy_policy: 'Amụma nzuzo',

	study_unavailable_label: 'Ọmụmụ ihe ị na-achọ adịghị ugbu a.',
	study_unavailable_description:
		'Daalụ maka mmasị gị na ọmụmụ ihe anyị! Ọ dị nwute na ejupụtalarị ọmụmụ a ma ọ bụ kwụsịtụrụ ugbu a. Anyị na-arịọ mgbaghara maka nsogbu ọ bụla kpatara ya.',
	study_preview_limit_reached_label: 'Eruola oke nhụchalụ gị',
	study_preview_limit_reached_description:
		"Daalụ maka mmasị gị na ọmụmụ ihe anyị! N'ụzọ dị mwute, ọmụmụ ihe a eruola ọtụtụ nlegharị anya dị.",

	waiting_message:
		'Ọtụtụ mmadụ na-edozi ihe ọmụmụ a ugbu a. \n Anyị ga-agbalị ọzọ na-akpaghị aka itinye {number, plural, =0 {ugbu a} otu {na # sekọnd} ọzọ {na # sekọnd}}.',
	waiting_error_recoverable: 'Anyị ahụla mperi anyị na-atụghị anya ya.',
	waiting_error_recoverable_retry:
		'Anyị ga-agbalị ọzọ {number, plural, =0 {ugbu a} one {na # sekọnd} other {na # sekọnd}}',
	waiting_error_unknown_study: 'Ọmụmụ ihe ị na-achọ adịghị.',
	waiting_error_dead: 'Enwere nsogbu a na-atụghị anya ya. Biko nwaa ọzọ ma emechaa.',

	action_input_submit_button_text: 'Nyefee',
	action_input_skip_button_text: "Gaa n'ihu",

	choice_single_hint: "Họrọ otu n'ime nhọrọ",
	general_skip_action_message: "Gaa n'ihu na nzọụkwụ ọzọ",

	action_button_primary_default_text: 'Omume',
	action_button_hint_default_text: "Họrọ otu n'ime nhọrọ",

	message_media_examine_video: 'Pịa vidiyo ka ịlele ya',
	message_media_examine_image: 'Pịa onyonyo a ka ịlele ya',
	message_full_screen_close_button_text: 'Mechie',
	message_image_view_label: 'Lelee onyonyo',
	message_video_view_label: 'Lelee vidiyo',
	message_image_examined_label: 'Nyochaa enyochala',
	message_video_examined_label: 'Vidiyo enyochala',
	message_zoom_mouse: 'Hover ka mbugharị',
	message_zoom_touch: 'Metụ ka mbugharị',

	textarea_too_long_hint: 'Ederede gị dị ogologo',

	ideate_placeholder: "Azịza n'ahịrịokwu dị mkpirikpi",
	ideate_skip: " ma ọ bụ gaa n'ihu",

	eliminate_no: 'Mba',
	eliminate_yes: 'Ee',
	eliminate_intro: 'Tụlee okwu a:',
	eliminate_multiple_suggestions: 'Kedu nke nwere ike dochie ya?',
	eliminate_one_suggestion: 'Enwere ike iji ihe ndị a dochie ya?',
	eliminate_step: 'Tụlee okwu ọzọ:',
	eliminate_none_of_the_above: "Ọ dịghị nke ọ bụla n'ime ha",

	elaborate_indifferent_label: 'Anọpụ iche',
	elaborate_disagree_label: 'Ekwetaghị',
	elaborate_agree_label: 'Kwekọrịtara',
	elaborate_unclear_statement_label: 'Azịza edoghị anya',
	elaborate_intro: 'Ndị ọzọ zara azịza ndị a. Ị kwenyere na ha?',

	ideamatching_unsure_label: 'Odochaghị m anya',
	ideamatching_unequal_label: 'Dị iche',
	ideamatching_equal_label: 'Otu ihe',
	ideamatching_intro: 'Lee ụzọ abụọ azịza a. Hà pụtara otu ihe ahụ?',

	select_input_placeholder: 'Pịnye ebe a ka ịhọrọ',
	select_input_placeholder_max: 'Oke ruru',
	select_multiple_choice_hint:
		'Họrọ {minNumber, plural, =0 {0} other { # }} ruo {maxNumber, plural, =0 {0} other { # }} nhọrọ',
	select_no_matches_found: 'Ọnweghị ihe egwuregwu ahụrụ',

	report_content_inappropriate: 'Azịza na-ekwesịghị ekwesị',
	report_content_profane: 'Ihe rụrụ arụ ma ọ bụ ihe rụrụ arụ',
	report_content_gibberish: 'Ederede Gibberish ma ọ bụ enweghị mmetụta',
	report_content_irrelevant: 'Enweghị mkpa na ajụjụ a jụrụ',
	report_content_different_language: 'Asụsụ dị iche iche',
	report_content_other: 'Ndị ọzọ',
	report_content_no_content: 'Enweghị ọdịnaya ị ga-akọ',
	report_content_header: 'Kwuo azịza',
	report_content_form_header: 'Biko họrọ ihe mere ị ga-eji chọọ ịkọ azịza a',
	report_content_flag_answer: 'Kwuo azịza',
	report_content_return_to_study: "Laghachi n'ọmụmụ ihe",
	report_content_thank_you:
		'Daalụ maka nzaghachi gị.\nAnyị ga-enyocha ntinye gị wee mee ihe ma ọ dị mkpa.',
	report_content_statement_flagged: 'Declarația a fost marcată.',

	platform_update_header: 'GroupSolver na-enweta nkwalite ugbu a',
	platform_update_message: 'Anyị na-arịọ mgbaghara maka nsogbu ọ bụla.',

	none_of_these: 'Ọ dịghị nke ndị a',

	allocation_instruction_limit: "Kenye {point} n'ofe nhọrọ",
	allocation_instruction_limit_no_minimum: "Kenye ihe ruru {point} n'ofe nhọrọ",
	allocation_instruction_no_limit: "Kenye isi n'ofe nhọrọ",
	allocation_instruction_points_left: '{point} fọdụrụ',
	allocation_options_out_of_range: 'Ụfọdụ nhọrọ anọghị nso',
	allocation_limit_overflow: '{points} gafere oke',
	allocation_option_invalid_range: 'Uru adịghị na {range} nso',
	allocation_option_invalid_min: 'Uru aghaghi ibu ma ọ bụ haha ​​nha na {min}',

	dropdown_select: 'Họrọ...',

	ranking_drag_options: 'Dọrọ ma ọ bụ pịa akụ iji tụọ nhọrọ',
	ranking_assign_option_values: 'Wepụta nhọrọ niile',
	ranking_require_unique_values: 'Uru nhọrọ ga-abụrịrị ihe pụrụ iche',

	next: 'Na-esote',
	technical_difficulties: 'Enwere m nsogbu teknụzụ',

	heatmap_instruction_intro: "Ka ịmalite ịhọrọ, lelee onyonyo dị n'okpuru.",
	heatmap_instruction_unlimited: 'Họrọ ọtụtụ isi dị ka ịchọrọ site na ịpị onyonyo a.',
	heatmap_instruction_count:
		'Họrọ {number, plural, =0 {0 isi} one {otu isi ihe} other {{number} isi}} site na ịpị onyonyo a.',
	heatmap_instruction_up_to_count:
		'Họrọ {number, plural, =0 {0 isi} one {otu isi ihe} other {ruo {number} isi}} site na ịpị onyonyo a.',
	heatmap_instruction_between_count: 'Họrọ {from} ruo {to} isi site na ịpị onyonyo a',
	heatmap_instruction_at_least_count:
		'Họrọ opekata mpe {number, plural, =0 {0 point} one {otu isi ihe} other {{number} isi}} site na ịpị onyonyo a.',
	heatmap_response: '{number, plural, =0 {0 isi} otu {otu isi ihe} other {{number} isi}} ahọpụtara',

	continue: "Gaa n'ihu",
	reset: 'Tọgharịa',

	input_invalid: 'Ntinye adịghị mma.',
	input_empty: 'Ntinye enweghị ike ịbụ efu.',
	input_chars_left: '{number} {number, plural, =0 {akara} one {akara} other {akara}} fọdụrụ.',
	input_too_long: '{number} {number, plural, =0 {akara} one {akara} other {akara}} gafere oke.',

	ranking_select_rank: 'Họrọ ọkwa nke {label}.',

	ranking_select_aria_label: 'Họrọ ọkwa nke nhọrọ ọ bụla site na ịpị uru.',
	ranking_dnd_aria_label:
		"Lelee nhọrọ ma pịa oghere iji malite ịdọrọ. Mgbe ị na-adọrọ, ị nwere ike iji igodo ntụgharị iji kwaga ihe ahụ ma pịa mgbapụ iji kagbuo. Pịa oghere ọzọ iji tinye ihe ahụ na ọnọdụ ọhụrụ. Jide n'aka na onye na-agụ ihuenyo gị nọ na ọnọdụ ntụle ma ọ bụ ọnọdụ ụdị.",

	allocation_option_input: 'Ntinye uru {label}.',
	allocation_aria_label: 'Tinye uru maka nhọrọ ọ bụla site na iji ntinye.',

	play: 'Gwuo',
	pause: 'Kwụsịtụ',
	stop: 'Kwụsị',
	fwd: "Gaa n'ihu",
	rwd: 'Laghachị azụ',
	volume_down: 'Belata olu',
	volume_up: 'Welie olu',
	volume_current: 'Olu: {number}%',

	loading: 'Na-ebubata',

	press_tab_to_continue: "Pịa tab iji gaa n'ihu",

	option_focus_selected: 'Nhọrọ {label} lekwasịrị anya. Pịa ntinye iji wepu.',
	option_focus_not_selected: 'Nhọrọ {label} lekwasịrị anya. Pịa ntinye iji họrọ.',

	image_alt: 'onyonyo:',
	video_alt: 'vidio:',

	submit_enabled: 'Nyefee kwadoro.',
	skip_enabled: 'Ajụjụ gafere kwadoro',
	n_of_min_selected: '{selected} nke {min} ahọpụtara.',
	option_selected: '{label} ahọpụtara.',
	option_deselected: '{label} wepụrụ.',

	choice_multiple_hint: 'Họrọ {min} ruo {max} nhọrọ.',

	ranking_position: 'ọnọdụ: {position}',
	ranking_position_changed: 'Uru {label} setịpụrụ na {value}.',
	ranking_input_aria_label: '{label}. Ọnọdụ ugbu a: {position}. Tinye ọnọdụ site na 1 ruo {max}.',
	not_selected: 'ahọpụtaraghị',

	study_logo_alt: 'Logo ọmụmụ',
	groupsolver_logo_alt: 'Logo Gropsolver',
}

export default messages
