const messages = {
	not_found: 'Halaman yang Anda cari tak ditemukan',
	service_outage:
		'Mohon maaf, gangguan global pada layanan awan penyedia kami mengakibatkan sistem kami tak dapat beroperasi.',
	error_unexpected_error: 'Terjadi masalah yang tak terduga. Silakan coba lagi nanti.',

	error_img_alt: 'Kesalahan gambar',
	error_try_again: 'Coba lagi',

	error_generic_header: 'Terjadi kesalahan',
	error_generic_message:
		'Silakan coba lagi. Jika masalah berlanjut, silakan kirimkan email ke support@groupsolver.com dengan kode QR yang ditampilkan di atas.',
	error_offline_header: 'Anda tampaknya offline',
	error_offline_message: 'Studi akan dilanjutkan setelah Anda kembali terhubung.',

	error_landscape_small_screen_header: 'Mohon putar perangkat Anda',
	error_landscape_small_screen_text:
		'Layar Anda terlalu pendek untuk menampilkan studi ini. Mohon putar perangkat Anda.',

	error_small_browser_window_header: 'Mohon perbesar jendela peramban Anda',
	error_small_browser_window_text:
		'Jendela Anda terlalu kecil untuk menampilkan studi ini. Mohon perbesar jendela peramban Anda',

	login_error_empty_email: 'Email tidak boleh kosong',
	login_error_invalid_email: 'Mohon masukkan email yang valid',
	login_error_unable_to_login: 'Tak dapat masuk saat ini. Silakan coba lagi nanti.',

	login_email_placeholder: 'Masukkan email Anda',
	login_email_button: 'Lanjutkan',
	login_email_back: 'atau kembali ke pilihan masuk',
	login_email_label: 'Lanjutkan dengan email',
	login_anonymous_label: 'Lanjutkan secara anonim',

	login_privacy_statement: 'GroupSolver menghomati privasi dan informasi yang Anda sediakan.',
	login_privacy_statement_email:
		'Email Anda takkan dibagikan, dijual, atau digunakan dengan cara apa pun selain untuk memberi Anda akses ke proyek ini.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Studi yang Anda cari tak tersedia saat ini.',
	study_unavailable_description:
		'Terima kasih atas minat Anda terhadap studi kami! Sayangnya, studi ini sudah penuh atau sedang dijeda saat ini. Mohon maaf atas ketidaknyamanan ini.',
	study_preview_limit_reached_label: 'Anda telah mencapai batas pratinjau',
	study_preview_limit_reached_description:
		'Terima kasih atas minat Anda terhadap studi kami! Mohon maaf, studi ini telah mencapai jumlah pratinjau yang tersedia.',

	waiting_message:
		'Studi ini sedang dikerjakan oleh beberapa orang saat ini. \n Kami akan mencoba lagi untuk masuk secara otomatis {number, plural, =0 { sekarang } one {dalam # detik} other {dalam # detik}}.',
	waiting_error_recoverable: 'Terjadi kesalahan yang tak terduga.',
	waiting_error_recoverable_retry:
		'Kami akan mencoba lagi {number, plural, =0 { sekarang } one {dalam # detik} other {dalam # detik}}',
	waiting_error_unknown_study: 'Studi yang Anda cari tak ditemukan.',
	waiting_error_dead: 'Terjadi masalah yang tak terduga. Silakan coba lagi nanti.',

	action_input_submit_button_text: 'KIRIM',
	action_input_skip_button_text: 'Lanjutkan',

	choice_single_hint: 'Pilih salah satu opsi',
	general_skip_action_message: 'Lanjutkan ke langkah berikutnya',

	action_button_primary_default_text: 'Tindakan',
	action_button_hint_default_text: 'Pilih salah satu opsi',

	message_media_examine_video: 'Klik video untuk meninjaunya',
	message_media_examine_image: 'Klik gambar untuk meninjaunya',
	message_full_screen_close_button_text: 'Tutup',
	message_image_view_label: 'Lihat Gambar',
	message_video_view_label: 'Lihat Video',
	message_image_examined_label: 'Gambar ditinjau',
	message_video_examined_label: 'Video ditinjau',
	message_zoom_mouse: 'Arahkan untuk perbesar',
	message_zoom_touch: 'Sentuh untuk perbesar',

	textarea_too_long_hint: 'Teks Anda terlalu panjang',

	ideate_placeholder: 'Jawab dalam kalimat singkat',
	ideate_skip: ' atau lanjutkan',

	eliminate_no: 'Tidak',
	eliminate_yes: 'Ya',
	eliminate_intro: 'Cermati pernyataan berikut ini:',
	eliminate_multiple_suggestions: 'Manakah dari yang berikut ini yang dapat menggantikannya?',
	eliminate_one_suggestion: 'Dapatkah yang berikut ini menggantikannya?',
	eliminate_step: 'Cermati pernyataan lainnya:',
	eliminate_none_of_the_above: 'Tidak satu pun di antaranya',

	elaborate_indifferent_label: 'Netral',
	elaborate_disagree_label: 'Tidak setuju',
	elaborate_agree_label: 'Setuju',
	elaborate_unclear_statement_label: 'Jawaban tidak jelas',
	elaborate_intro: 'Peserta lain memberikan jawaban ini. Apakah Anda setuju?',

	ideamatching_unsure_label: 'Tidak yakin',
	ideamatching_unequal_label: 'Berbeda',
	ideamatching_equal_label: 'Sama',
	ideamatching_intro: 'Simak kedua jawaban ini. Apakah artinya sama?',

	select_input_placeholder: 'Ketik di sini untuk memilih',
	select_input_placeholder_max: 'Mencapai maksimum',
	select_multiple_choice_hint:
		'Pilih {minNumber, plural, =0 { 0 } other { # }} sampai {maxNumber, plural, =0 { 0 } other { # }} opsi',
	select_no_matches_found: 'Tidak ada yang cocok',

	report_content_inappropriate: 'Jawaban tidak layak',
	report_content_profane: 'Konten pornografi',
	report_content_gibberish: 'Teks tidak jelas atau tidak masuk akal',
	report_content_irrelevant: 'Tidak relevan dengan pertanyaan',
	report_content_different_language: 'Bahasa yang berbeda',
	report_content_other: 'Lainnya',
	report_content_no_content: 'Tak ada konten untuk dilaporkan',
	report_content_header: 'Laporkan jawaban',
	report_content_form_header: 'Silakan pilih alasan Anda membenderai jawaban ini',
	report_content_flag_answer: 'Benderai jawaban',
	report_content_return_to_study: 'Kembali ke studi',
	report_content_thank_you: `Terima kasih atas umpan balik Anda.\n
		Kami akan meninjau masukan Anda dan mengambil tindakan apabila diperlukan.`,
	report_content_statement_flagged: 'Pernyataan telah dibenderai.',

	platform_update_header: 'GroupSolver sedang dalam pemeliharaan terjadwal',
	platform_update_message: 'Kami mohon maaf atas ketidaknyamanan yang ditimbulkan.',

	none_of_these: 'Tidak satu pun dari jawaban di atas',

	allocation_instruction_limit: 'Alokasikan {poin} di seluruh opsi',
	allocation_instruction_limit_no_minimum: 'Alokasikan hingga {poin} di seluruh opsi',
	allocation_instruction_no_limit: 'Alokasikan poin di seluruh opsi',
	allocation_instruction_points_left: 'Tersisa {points}',
	allocation_options_out_of_range: 'Beberapa opsi berada di luar jangkauan',
	allocation_limit_overflow: '{poin} melebihi batas',
	allocation_option_invalid_range: 'Nilai di luar jangkauan {range}',
	allocation_option_invalid_min: 'Nilai harus lebih besar dari atau sama dengan {min}',

	dropdown_select: 'Pilih...',

	ranking_drag_options: 'Tarik atau klik panah untuk menggolongkan opsi',
	ranking_assign_option_values: 'Beri peringkat semua opsi',
	ranking_require_unique_values: 'Peringkat opsi haruslah unik',

	heatmap_instruction_intro: 'Untuk mulai memilih, lihat gambar di bawah ini.',
	heatmap_instruction_unlimited:
		'Pilih sebanyak mungkin titik yang Anda inginkan dengan mengklik gambar.',
	heatmap_instruction_count:
		'Pilih {number, plural, =0 {0 titik} one {satu titik} other {{number} titik}} dengan mengklik gambar.',
	heatmap_instruction_up_to_count:
		'Pilih {number, plural, =0 {0 titik} one {satu titik} other {hingga {number} titik}} dengan mengklik gambar.',
	heatmap_instruction_between_count: 'Pilih {from} hingga {to} titik dengan mengklik gambar',
	heatmap_instruction_at_least_count:
		'Pilih setidaknya {number, plural, =0 {0 titik} one {satu titik} other {{number} titik}} dengan mengklik gambar.',
	heatmap_response: '{number, plural, =0 {0 titik} one {1 titik} other {{number} titik}} dipilih',
	continue: 'Lanjutkan',
	reset: 'Atur Ulang',
	input_invalid: 'Input tidak valid.',
	input_empty: 'Input tidak boleh kosong.',
	input_chars_left:
		'{number} {number, plural, =0 {karakter} one {karakter} other {karakter}} tersisa.',
	input_too_long:
		'{number} {number, plural, =0 {karakter} one {karakter} other {karakter}} melebihi batas.',
	ranking_select_rank: 'Pilih peringkat dari {label}.',
	ranking_select_aria_label: 'Pilih peringkat dari setiap opsi dengan mengetikkan nilai.',
	ranking_dnd_aria_label:
		'Fokus pada opsi dan tekan bilah spasi untuk memulai seret. Saat menyeret, Anda dapat menggunakan tombol panah untuk memindahkan item dan escape untuk membatalkan. Tekan bilah spasi lagi untuk menempatkan item ke posisi baru. Pastikan pembaca layar Anda dalam mode fokus atau mode formulir.',
	allocation_option_input: 'Input nilai {label}.',
	allocation_aria_label: 'Masukkan nilai untuk setiap opsi menggunakan input.',
	play: 'Putar',
	pause: 'Jeda',
	stop: 'Berhenti',
	fwd: 'Lewati maju',
	rwd: 'Mundur',
	volume_down: 'Kurangi volume',
	volume_up: 'Tingkatkan volume',
	volume_current: 'Volume: {number}%',
	loading: 'Memuat',
	press_tab_to_continue: 'Tekan tab untuk melanjutkan',
	option_focus_selected: 'Opsi {label} difokuskan. Tekan enter untuk membatalkan pilihan.',
	option_focus_not_selected: 'Opsi {label} difokuskan. Tekan enter untuk memilih.',
	image_alt: 'gambar:',
	video_alt: 'video:',
	submit_enabled: 'Kirim diaktifkan.',
	skip_enabled: 'Lewati pertanyaan diaktifkan',
	n_of_min_selected: '{selected} dari {min} dipilih.',
	option_selected: '{label} dipilih.',
	option_deselected: '{label} dibatalkan.',
	choice_multiple_hint: 'Pilih {min} hingga {max} opsi.',
	ranking_position: 'posisi: {position}',
	ranking_position_changed: 'Nilai {label} diatur ke {value}.',
	ranking_input_aria_label:
		'{label}. Posisi saat ini: {position}. Ketikkan posisi dari 1 hingga {max}.',
	not_selected: 'tidak dipilih',
	study_logo_alt: 'Logo studi',
	groupsolver_logo_alt: 'Logo GroupSolver',
}

export default messages
