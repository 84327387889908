import _ from 'lodash'

import { API } from 'store/api'

import { addMessage, addAction, addCommand, addTempMessage } from 'store/queue'
import { createComponent, removeTempMessages } from 'store/_chat/history'
import {
	setOptionsChangeListener,
	actionsShow,
	actionsHide,
	getActionOptions,
	reset as resetChatActions,
} from 'store/_chat/actions'
import {
	setActionSubmitListener,
	showActionLoader,
	showActionButton,
	showActionHint,
} from 'store/_chat/actionBar'
import { historyCommandInit } from 'store/_modules/uiCommand'
import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'

import { handleSingle } from 'store/_modules/choice'
import { initNestedMessage } from 'store/_modules/message'

import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { COMPONENTS, POSITION } from 'constants/component'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { DELAY_LENGTH } from 'constants/delay'
import { MAXDIFF_ROUND_TYPE } from 'constants/maxDiff'
import { HISTORY_COMMANDS } from 'constants/history'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

import { STUDY_SET_STUDY_OBJECT } from 'constants/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'maxDiff.add.response'
export const HANDLE_INIT_ROUND = 'maxDiff.handle.init.round'

// ------------------------------------
// action creators
// ------------------------------------
const setStudyObject = studyObject => (dispatch, getState) => {
	dispatch({
		type: STUDY_SET_STUDY_OBJECT,
		studyObject,
	})
}

const handleInitRound = round => (dispatch, getState) => {
	dispatch({
		type: HANDLE_INIT_ROUND,
		round,
	})
}

const addResponse = response => (dispatch, getState) => {
	dispatch({
		type: ADD_RESPONSE,
		response,
	})
}

// ------------------------------------
// Listeners
// ------------------------------------
export const optionsChangeListener = options => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let selectedOptions = options.filter(option => option.selected)

	handleSingle(selectedOptions, definition)(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const primarySubmitListener = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)
	removeTempMessages()(dispatch, getState)

	const definition = getStudyObjectDefinition()(dispatch, getState)
	const { activeRoundIndex } = getState().maxDiff

	let options = getActionOptions()(dispatch, getState)
	let selectedOptions = options.filter(option => option.selected)

	// build thumbs if image choice
	const messageThumbs =
		definition.subtype === CHOICE_SUBTYPE.IMAGE
			? selectedOptions.map(option => {
					let thumb = createComponent(COMPONENTS.MESSAGE_THUMB)
					thumb.url = option.media.url
					thumb.label = option.label
					return thumb
			  })
			: []

	let messageText = selectedOptions.map(option => option.label).join(', ')
	let props = {
		text: messageText,
		media: null,
		thumbs: messageThumbs,
	}
	const responseMessage = createComponent(COMPONENTS.MESSAGE)
	responseMessage.props = Object.assign({}, responseMessage.props, props)
	const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

	showActionLoader()(dispatch, getState)

	addMessage(responseMessage, messagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	addResponse(selectedOptions.map(option => option.id))(dispatch, getState)

	if (definition.blurBetweenRounds === true && activeRoundIndex % 2 !== 0) {
		addCommand(
			historyCommandInit({
				history: HISTORY_COMMANDS.BLUR,
			})(dispatch, getState),
			DELAY_LENGTH.LONG,
		)(dispatch, getState)
	}

	if (activeRoundIndex + 1 === definition.rounds.length) {
		// eslint-disable-next-line no-use-before-define
		endStep()(dispatch, getState)
	} else {
		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			// eslint-disable-next-line no-use-before-define
			initRound()(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	}
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	// not possible to skip maxdiff but we may want to add "skipped" message in the future
	actionsHide()(dispatch, getState)
	removeTempMessages()(dispatch, getState)

	const definition = getStudyObjectDefinition()(dispatch, getState)
	const { activeRoundIndex } = getState().maxDiff

	if (activeRoundIndex + 1 === definition.rounds.length) {
		// eslint-disable-next-line no-use-before-define
		endStep()(dispatch, getState)
	} else {
		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			// eslint-disable-next-line no-use-before-define
			initRound()(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	}
}

// ------------------------------------
// Module action creators
// ------------------------------------
export const init = module => (dispatch, getState) => {
	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		initRound()(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

const initRound = () => (dispatch, getState) => {
	resetChatActions()(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)

	const { bestQuestion, worstQuestion, rounds } = studyObject.definition
	const { activeRoundIndex } = getState().maxDiff
	const activeRound = rounds[activeRoundIndex]

	handleInitRound(activeRound)(dispatch, getState)

	const messageToDisplay =
		activeRound.roundType === MAXDIFF_ROUND_TYPE.BEST ? bestQuestion : worstQuestion

	const continueAfterMessage = () => {
		setStudyObject(studyObject)(dispatch, getState)
		// eslint-disable-next-line no-use-before-define
		showRound()(dispatch, getState)
	}

	initNestedMessage(
		messageToDisplay,
		[],
		continueAfterMessage,
		setStudyObject,
		true,
	)(dispatch, getState)
}

export const showRound = () => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const maxDiffState = getState().maxDiff
	const { activeRoundIndex } = maxDiffState

	const currentRound = definition.rounds[activeRoundIndex]

	setOptionsChangeListener(optionsChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	const options = currentRound.options.map(option => ({
		...option,
		selected: false,
	}))

	switch (definition.subtype) {
		case CHOICE_SUBTYPE.IMAGE:
			addAction(
				actionsShow,
				[CHOICE_SUBTYPE.IMAGE, options, false, options.some(option => option.showLabel)],
				DELAY_LENGTH.SHORT,
			)(dispatch, getState)
			break
		case CHOICE_SUBTYPE.CHECKBOX:
			addAction(
				actionsShow,
				[CHOICE_SUBTYPE.CHECKBOX, options, false],
				DELAY_LENGTH.SHORT,
			)(dispatch, getState)
			break
		case CHOICE_SUBTYPE.BLOCK:
			addAction(
				actionsShow,
				[CHOICE_SUBTYPE.BLOCK, options, false],
				DELAY_LENGTH.SHORT,
			)(dispatch, getState)
			break
		default:
			break
	}

	if (definition.mandatory === false) {
		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else {
		const hintText = formatMessage('choice_single_hint')(dispatch, getState)

		addAction(showActionHint, [hintText])(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			const solverMessagePosition =
				getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT
			const instruction = createComponent(COMPONENTS.INSTRUCTION)

			instruction.props.text = hintText

			addTempMessage(instruction, solverMessagePosition, 0)(dispatch, getState)
		}
	}
}

export const endStep = () => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)

	// create apiResponse
	const studyObjectResponse = {
		[studyObject.id]: getState().maxDiff.rounds,
	}

	// push answer to server
	showActionLoader()(dispatch, getState)
	getState().study.getNextStep(studyObjectResponse, null, true)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	activeRoundIndex: 0,
	rounds: [],
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'MAXDIFF') {
			return state
		}

		return _.cloneDeep(initialState)
	},
	[ADD_RESPONSE]: (state, action) => {
		const newState = {
			...state,
			activeRoundIndex: state.activeRoundIndex + 1,
			rounds: state.rounds.map((round, index) => {
				if (index !== state.activeRoundIndex) {
					return round
				}

				return { ...state.rounds[state.activeRoundIndex], answer: action.response }
			}),
		}

		return newState
	},
	[HANDLE_INIT_ROUND]: (state, action) => {
		const { round } = action

		const roundData = {
			idRound: round.idRound,
			roundType: round.roundType,
			optionsPresented: round.options.map(option => ({
				id: option.id,
				label: option.label,
				mediaUrl: _.get(option, 'media.url', null),
			})),
			answer: null,
		}

		const newState = {
			...state,
			rounds: [...state.rounds, roundData],
		}

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
