export const MATCHING_OPTIONS = {
	NOT_SURE: {
		label: 'ideamatching_unsure_label',
		idStatement: 'matching-0',
		answer: 'UNSURE',
	},
	DIFFERENT: {
		label: 'ideamatching_unequal_label',
		idStatement: 'matching-1',
		answer: 'UNEQUAL',
	},
	SAME: {
		label: 'ideamatching_equal_label',
		idStatement: 'matching-2',
		answer: 'EQUAL',
	},
}

export const MATCHING_OPTIONS_ORDER = [
	MATCHING_OPTIONS.SAME,
	MATCHING_OPTIONS.DIFFERENT,
	MATCHING_OPTIONS.NOT_SURE,
]
