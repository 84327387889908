import { connect } from 'react-redux'
import DotsLoader from 'components/_scaffolding/DotsLoader/DotsLoader'
import { injectIntl } from 'react-intl'

const mapActionCreators = {}
const mapStateToProps = state => ({
	theme: state.study.theme,
})

export default connect(
	mapStateToProps,
	mapActionCreators,
)(injectIntl(DotsLoader))
