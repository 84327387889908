import React from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'
import classnames from 'classnames'

import { reportContentFormFields } from 'constants/reportContentForm'

import './ReportContentForm.scss'

import Select from 'react-select'

import Checkbox from 'components/_scaffolding/Checkbox'

const reportReasons = formatMessage => [
	{
		id: reportContentFormFields.INAPPROPRIATE,
		label: formatMessage({ id: 'report_content_inappropriate' }),
	},
	{ id: reportContentFormFields.PROFANE, label: formatMessage({ id: 'report_content_profane' }) },
	{
		id: reportContentFormFields.GIBBERISH,
		label: formatMessage({ id: 'report_content_gibberish' }),
	},
	{
		id: reportContentFormFields.IRRELEVANT,
		label: formatMessage({ id: 'report_content_irrelevant' }),
	},
	{
		id: reportContentFormFields.DIFFERENT_LANGUAGE,
		label: formatMessage({ id: 'report_content_different_language' }),
	},
	{ id: reportContentFormFields.OTHER, label: formatMessage({ id: 'report_content_other' }) },
]

const createDropdownOptions = statements =>
	statements.map(statement => {
		return {
			value: statement.idStatement,
			label: statement.label,
		}
	})

const getReportContentForm = props => {
	const formData = props.formData

	const formatMessage = props.intl.formatMessage

	const dropDownOptions = props.stepData ? createDropdownOptions(props.stepData) : []
	const hasContent = dropDownOptions.length > 0

	const quoteLabel = hasContent ? dropDownOptions[0].label : ''

	const canSubmit = formData.idStatement !== '' && Object.values(formData).includes(true)

	const selectStyles = {
		control: base => ({
			...base,
			borderColor: '#E31673',
			boxShadow: 'none',
			cursor: 'pointer',
			fontSize: 12,
			minHeight: 'unset',
			padding: '3px 5px',
			'&:hover': {
				borderColor: '#E31673',
			},
		}),
		dropdownIndicator: base => ({
			...base,
			color: '#E31673',
			padding: 0,
		}),
		indicatorSeparator: () => ({ display: 'none' }),
		input: () => ({ display: 'none' }),
		menu: base => ({
			...base,
			borderRadius: 1,
			margin: 0,
		}),
		menuList: base => ({
			...base,
			padding: 0,
		}),
		option: (base, state, asdf) => ({
			...base,
			// $white
			background: '#fff',
			color: '#000',
			cursor: 'pointer',
			fontStyle: 'italic',
			padding: '5px 10px',
			'&:hover': {
				// $magentaAlert
				background: '#E31673',
				color: '#fff',
			},
		}),
		singleValue: base => ({
			...base,
			padding: '5px 0',
			margin: 0,
		}),
	}

	return (
		<div
			className={classnames('Report-content-content', {
				'Report-content-content--rtl': props.isStudyRtl === true,
			})}
		>
			<div
				className={classnames('Report-content-close-btn', 'icon-cross', {
					'Report-content-close-btn--ltr': props.isStudyRtl === false,
					'Report-content-close-btn--rtl': props.isStudyRtl === true,
				})}
				onClick={props.hideReportContentForm}
			/>
			<div className="Report-content-header">
				{hasContent
					? `${formatMessage({ id: 'report_content_header' })}:`
					: formatMessage({ id: 'report_content_no_content' })}
			</div>
			{dropDownOptions.length > 1 ? (
				<Select
					autoFocus={false}
					isClearable={false}
					isRtl={props.isStudyRtl}
					isSearchable={false}
					onChange={option => {
						props.changeFormData(reportContentFormFields.ID_STATEMENT, option.value)
					}}
					options={dropDownOptions}
					placeholder={formatMessage({ id: 'dropdown_select' })}
					simpleValue
					styles={selectStyles}
					value={dropDownOptions.find(o => o.value === formData.idStatement)}
				/>
			) : (
				hasContent && (
					<div className="Report-content-quote">
						<div className="Report-content-quote-icon icon-quota" />
						{quoteLabel}
					</div>
				)
			)}
			{hasContent && (
				<div className="Report-content-form-wrapper">
					<div className="Report-content-form-header">
						{formatMessage({ id: 'report_content_form_header' })}:
					</div>
					<div className="Report-content-form">
						{reportReasons(formatMessage).map(reason => {
							return (
								<Checkbox
									id={reason.id}
									key={reason.id}
									onChange={props.changeFormData}
									value={formData[reason.id]}
									label={reason.label}
									isStudyRtl={props.isStudyRtl}
								/>
							)
						})}
					</div>
					{formData.other === true && (
						<textarea
							className="Report-content-form-textarea"
							maxLength={100}
							rows={3}
							onChange={event => {
								const value = event.currentTarget.value
								props.changeFormData(reportContentFormFields.NOTE, value)
							}}
						/>
					)}
				</div>
			)}
			<div className="Report-content-btn-holder">
				{hasContent ? (
					<button
						className={classnames('Report-content-btn', {
							'Report-content-btn-disabled': canSubmit === false,
						})}
						disabled={canSubmit === false}
						onClick={props.addReport}
						// needed in safari
						tabIndex={0}
					>
						{formatMessage({ id: 'report_content_flag_answer' })}
					</button>
				) : (
					<button
						className={'Report-content-btn'}
						onClick={props.hideReportContentForm}
						tabIndex={0}
					>
						{formatMessage({ id: 'report_content_return_to_study' })}
					</button>
				)}
			</div>
		</div>
	)
}

const renderSubmitted = (hideReportContentForm, formatMessage, isStudyRtl) => {
	return (
		<div
			className={classnames('Report-content-modal', {
				'Report-content-modal--rtl': isStudyRtl === true,
			})}
		>
			<div className="Report-content-modal-header">
				{formatMessage({ id: 'report_content_statement_flagged' })}
			</div>
			<div className="Report-content-modal-content">
				{formatMessage({ id: 'report_content_thank_you' })}
			</div>
			<div className="Report-content-btn-holder">
				<button className="Report-content-btn" onClick={hideReportContentForm} tabIndex={0}>
					{formatMessage({ id: 'report_content_return_to_study' })}
				</button>
			</div>
		</div>
	)
}

const ReportContentForm = props => {
	const content =
		props.isSubmitted === true
			? renderSubmitted(props.hideReportContentForm, props.intl.formatMessage, props.isStudyRtl)
			: getReportContentForm(props)

	return (
		<div className="Report-content-overlay">
			<div className="Report-content-content-holder">{content}</div>
		</div>
	)
}

getReportContentForm.propTypes = {
	formData: PropTypes.shape({
		idStatement: PropTypes.string.isRequired,
		inappropriate: PropTypes.bool.isRequired,
		profane: PropTypes.bool.isRequired,
		gibberish: PropTypes.bool.isRequired,
		irrelevant: PropTypes.bool.isRequired,
		differentLanguage: PropTypes.bool.isRequired,
		other: PropTypes.bool.isRequired,
		note: PropTypes.string.isRequired,
	}).isRequired,
	isStudyRtl: PropTypes.bool.isRequired,
	stepData: PropTypes.arrayOf(
		PropTypes.shape({
			idStatement: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	addReport: PropTypes.func.isRequired,
	changeFormData: PropTypes.func.isRequired,
	hideReportContentForm: PropTypes.func.isRequired,
	intl: intlShape,
}

ReportContentForm.propTypes = {
	hideReportContentForm: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
	intl: intlShape,
	isStudyRtl: PropTypes.bool.isRequired,
}

export default ReportContentForm
