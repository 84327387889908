import moment from 'moment'

// ------------------------------------
// Constants
// ------------------------------------
export const MONITOR_CALL = 'api.monitor.call'
export const STARTED = 'api.reducer.set.started'
export const SUCCESS = 'api.reducer.set.success'
export const ERROR = 'api.reducer.set.error'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	monitor: {},
	status: {},
}

// ------------------------------------
// Action Creator
// ------------------------------------
export const monitorCall = apiCall => (dispatch, getState) => {
	dispatch({
		type: MONITOR_CALL,
		apiCall,
	})
}

export const monitorCalls = apiCalls => (dispatch, getState) => {
	apiCalls.map(apiCall => {
		dispatch({ type: MONITOR_CALL, apiCall })
	})
}

export const actions = {
	monitorCall,
	monitorCalls,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[STARTED]: (state, action) => {
		const monitor = state.monitor[action.type]
		if (monitor) {
			let newState = Object.assign({}, state)
			newState.status = Object.assign({}, state.status)
			// amend status
			let newStatus = Object.assign({}, state.status[monitor.endPoint])
			newStatus.loading = true
			newStatus.error = false
			newStatus.loaded = false
			newStatus.errorMessage = ''
			newStatus.timestamp = moment.utc().toISOString()
			newStatus.lastStatus = 'STARTED'
			// push new status
			newState.status[monitor.endPoint] = newStatus

			return newState
		}

		return state
	},
	[ERROR]: (state, action) => {
		const monitor = state.monitor[action.type]
		if (monitor) {
			let newState = Object.assign({}, state)
			newState.status = Object.assign({}, state.status)
			// amend status
			let newStatus = Object.assign({}, state.status[monitor.endPoint])
			newStatus.loading = false
			newStatus.error = true
			newStatus.loaded = true
			newStatus.errorMessage = action.response ? action.response.message : ''
			newStatus.timestamp = moment.utc().toISOString()
			newStatus.lastStatus = 'ERROR'
			// push new status
			newState.status[monitor.endPoint] = newStatus

			return newState
		}

		return state
	},
	[SUCCESS]: (state, action) => {
		const monitor = state.monitor[action.type]
		if (monitor) {
			let newState = Object.assign({}, state)
			newState.status = Object.assign({}, state.status)
			// amend status
			let newStatus = Object.assign({}, state.status[monitor.endPoint])
			newStatus.loading = false
			newStatus.loaded = true
			newStatus.timestamp = moment.utc().toISOString()
			newStatus.lastStatus = 'SUCCESS'
			// push new status
			newState.status[monitor.endPoint] = newStatus

			return newState
		}

		return state
	},
	[MONITOR_CALL]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.monitor = Object.assign({}, state.monitor)

		const monitor = {
			endPoint: action.apiCall.ENDPOINT,
		}

		newState.monitor[action.apiCall.STARTED] = Object.assign({ state: STARTED }, monitor)
		newState.monitor[action.apiCall.SUCCESS] = Object.assign({ state: SUCCESS }, monitor)
		newState.monitor[action.apiCall.ERROR] = Object.assign({ state: ERROR }, monitor)

		newState.status[monitor.endPoint] = {
			endPoint: monitor.endPoint,
			loaded: false,
			loading: false,
			error: false,
			definition: action.apiCall,
		}

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function apiReducer(state = initialState, action) {
	let newState = Object.assign(initialState, state)
	let handler
	if (state.monitor[action.type]) {
		const currentAction = state.monitor[action.type]
		if (currentAction.state === STARTED) {
			handler = ACTION_HANDLERS[STARTED]
		} else if (currentAction.state === SUCCESS) {
			handler = ACTION_HANDLERS[SUCCESS]
		} else if (currentAction.state === ERROR) {
			handler = ACTION_HANDLERS[ERROR]
		}
	} else if (action.type === MONITOR_CALL && !Object.keys(state.monitor).includes(action.type)) {
		handler = ACTION_HANDLERS[MONITOR_CALL]
	}

	return handler ? handler(newState, action) : state
}
