const messages = {
	not_found: 'Stránka, ktorú hľadáte, neexistuje',
	error_unexpected_error: 'Vyskytol sa neočakávaný problém. Prosím, vyskúšajte operáciu neskôr.',

	error_img_alt: 'Chybný obrázok',
	error_try_again: 'Obnoviť',

	error_generic_header: 'Stala sa chyba',
	error_generic_message:
		'Prosím, vyskúšajte obnoviť stránku. V prípade, že chyba nebola odstránená, prosím, pošlite email na support@groupsolver.com s QR kódom, ktorý je umiestnený vyššie.',

	error_offline_header: 'Zdá sa, že internetové pripojenie zlyhalo',
	error_offline_message:
		'Vaša štúdia sa načíta znova hneď, ako bude obnovené internetové pripojenie.',

	error_landscape_small_screen_header: 'Prosím, otočte svoje zariadenie',
	error_landscape_small_screen_text:
		'Vaša obrazovka je príliš malá pre zobrazenie obsahu štúdie. Prosím, otočte svoje zariadenie.',

	error_small_browser_window_header: 'Prosím, zväčšite si okno svojho prehliadača',
	error_small_browser_window_text:
		'Vaše okno prehliadača je príliš malé pre zobrazenie obsahu štúdie. Prosím, zväčšite si okno svojho prehliadača.',

	login_error_empty_email: 'E-mail musí byť vyplnený.',
	login_error_invalid_email: 'Prosím, napíšte platný e-mail',
	login_error_unable_to_login: 'Momentálne nie je možné prihlásenie. Prosím, vyskúšajte neskôr.',

	login_email_placeholder: 'Váš e-mail',
	login_email_button: 'Pokračujte',
	login_email_back: 'alebo návrat na prihlásenie',
	login_email_label: 'Pokračujte s prihlásením pomocou e-mailu',
	login_email_description: 'Nezašleme Vám žiadne správy bez Vášho súhlasu',
	login_anonymous_label: 'Pokračovať anonymne',
	login_anonymous_description: 'Pokračovať anonymne',

	login_privacy_statement: 'GroupSolver rešpektuje Vaše súkromie a informácie, ktoré poskytnete.',
	login_privacy_statement_email:
		'Váš e-mail nebude sprístupnený iným osobám, predaný, alebo použitý na iné účely, ako je vstup do tejto štúdie.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Štúdia, ktorú hľadáte, je neprístupná.',
	study_unavailable_description:
		'Ďakujeme za Váš záujem participovať na tejto štúdii! Bohužiaľ, táto štúdia je prerušená alebo už bola dokončená. Ospravedlňujeme sa za prípadné nepríjemnosti.',
	study_preview_limit_reached_label: 'Maximálny počet respondentov pre koncept štúdie sa naplnil',
	study_preview_limit_reached_description:
		'Ďakujeme, za Váš záujem, ale maximálny počet repondentov pre koncept tejto štúdie je naplnený.',

	waiting_message:
		'Na tejto štúdii momentálne pracuje niekoľko respondentov. \n Automaticky Vám umožníme vstup {number, plural, =0 { teraz } one {za # sekundu} other {za # sekúnd}}.',
	waiting_error_recoverable: 'Vyskytla sa neočakávaná chyba.',
	waiting_error_recoverable_retry:
		'Automaticky Vám umožníme vstup {number, plural, =0 { teraz } one {za # sekundu} other {za # sekúnd}}',
	waiting_error_unknown_study: 'Štúdia, ktorú hľadáte, neexistuje',
	waiting_error_dead: 'Vyskytla sa neočakávaná chyba. Prosím, vyskúšajte neskôr.',

	action_input_submit_button_text: 'POTVRDIŤ',
	action_input_skip_button_text: 'PRESKOČIŤ',

	choice_single_hint: 'Vyberte jednu z možností',
	general_skip_action_message: 'Prejsť na ďalší krok',

	action_button_primary_default_text: 'Pokračovať',
	action_button_hint_default_text: 'Vyberte jednu z možností',

	message_media_examine_video: 'Pre prehranie média kliknite na video',
	message_media_examine_image: 'Pre prezretie média kliknite na obrázok',
	message_full_screen_close_button_text: 'Zavrieť',
	message_image_view_label: 'Zobraziť obrázok',
	message_video_view_label: 'Prezrieť video',
	message_image_examined_label: 'Obrázok zobrazený',
	message_video_examined_label: 'Video pozreté',
	message_zoom_mouse: 'Pre priblíženie presuňte myš nad obrázok',
	message_zoom_touch: 'Pre priblíženie sa dotknite obrázka',

	textarea_too_long_hint: 'Váš text je príliš dlhý',

	ideate_placeholder: 'Odpovedajte krátkymi vetami',
	ideate_skip: ' alebo pokračujte ďalej',

	eliminate_no: 'Nie',
	eliminate_yes: 'Áno',
	eliminate_intro: 'V predchádzajúcej odpovedi ste uviedli:',
	eliminate_multiple_suggestions: 'Ktoré z nasledujúcich by mohli nahradiť Vašu odpoveď?',
	eliminate_one_suggestion: 'Mohla by nasledujúca odpoveď nahradiť tú Vašu?',
	eliminate_step: 'Ďalšia Vaša odpoveď bola:',
	eliminate_none_of_the_above: 'Žiadna z nich',

	elaborate_indifferent_label: 'Nie som si istý',
	elaborate_disagree_label: 'Nesúhlasím',
	elaborate_agree_label: 'Súhlasím',
	elaborate_unclear_statement_label: 'Nejasná odpoveď',
	elaborate_intro: 'Iní respondenti poskytli nasledujúce odpovede. Súhlasíte s nimi?',

	ideamatching_unsure_label: 'Nie som si istý',
	ideamatching_unequal_label: 'Sú odlišné',
	ideamatching_equal_label: 'Sú rovanké',
	ideamatching_intro: 'Pozrite sa na túto dvojicu odpovedí. Znamenajú to isté?',

	select_input_placeholder: 'Začnite písať svoju odpoveď',
	select_input_placeholder_max: 'Dosiahli ste maximálny povolený počet znakov',
	select_multiple_choice_hint:
		'Vyberte {minNumber, plural, =0 { 0 } other { # }} až {maxNumber, plural, =0 { 0 } other { # }} možností',
	select_no_matches_found: 'Žiadne zhody neboli nájdené',

	report_content_inappropriate: 'Nevhodná odpoveď',
	report_content_profane: 'Neslušný obsah',
	report_content_gibberish: 'Hlúposť alebo nezmyselný text',
	report_content_irrelevant: 'Irelevantná odpoveď',
	report_content_different_language: 'Iný jazyk',
	report_content_other: 'Iné',
	report_content_no_content: 'Žiadny obsah na nahlásenie.',
	report_content_header: 'Nahlásiť obsah',
	report_content_form_header: 'Prosím, vyberte dôvod, prečo by ste chceli nahlásiť túto odpoveď',
	report_content_flag_answer: 'Nahlásiť odpoveď',
	report_content_return_to_study: 'Návrat do štúdie.',
	report_content_thank_you: `Ďakujeme za Váš názor.\n
		Váš podnet preveríme a v prípade nutnosti vykonáme nápravu.`,
	report_content_statement_flagged: 'Odpoveď bola nahlásená.',

	platform_update_header: 'GroupSolver momentálne prechádza plánovanou údržbou',
	platform_update_message: 'Ospravedlňujeme sa za vzniknuté nepríjemnosti.',

	none_of_these: 'Žiadna z týchto odpovedí',

	allocation_instruction_limit: 'Rozdeľte {points} medzi možnosti',
	allocation_instruction_limit_no_minimum: 'Priraďte najviac {points} možnostiam',
	allocation_instruction_no_limit: 'Priraďte body možnostiam',
	allocation_instruction_points_left: 'Zostáva rozdeliť {points}',
	allocation_options_out_of_range: 'Niektoré možnosti sú mimo povoleného rozsahu',
	allocation_limit_overflow: '{points} nad limit',
	allocation_option_invalid_range: 'Hodnota je mimo rozsahu {range}',
	allocation_option_invalid_min: 'Hodnota musí byť väčšia ako {min}',

	dropdown_select: 'Vybrať...',

	ranking_drag_options: 'Zoraďte možnosti',
	ranking_assign_option_values: 'Ohodnoťte každú možnosť',
	ranking_require_unique_values: 'Hodnotenie jednotlivých možností sa musí líšiť',

	heatmap_instruction_intro: 'Ak chcete začať výber, pozrite si obrázok nižšie.',
	heatmap_instruction_unlimited: 'Vyberte toľko bodov, koľko chcete, kliknutím na obrázok.',
	heatmap_instruction_count:
		'Vyberte {number, plural, =0 {0 bodov} one {jeden bod} other {{number} bodov}} kliknutím na obrázok.',
	heatmap_instruction_up_to_count:
		'Vyberte {number, plural, =0 {0 bodov} one {jeden bod} other {až {number} bodov}} kliknutím na obrázok.',
	heatmap_instruction_between_count: 'Vyberte {from} až {to} bodov kliknutím na obrázok',
	heatmap_instruction_at_least_count:
		'Vyberte aspoň {number, plural, =0 {0 bodov} one {jeden bod} other {{number} bodov}} kliknutím na obrázok.',
	heatmap_response: '{number, plural, =0 {0 bodov} one {1 bod} other {{number} bodov}} vybraných',
	continue: 'Pokračovať',
	reset: 'Resetovať',
	input_invalid: 'Vstup nie je platný.',
	input_empty: 'Vstup nemôže byť prázdny.',
	input_chars_left: '{number} {number, plural, =0 {znakov} one {znak} other {znakov}} zostáva.',
	input_too_long: '{number} {number, plural, =0 {znakov} one {znak} other {znakov}} nad limit.',
	ranking_select_rank: 'Vyberte hodnotenie {label}.',
	ranking_select_aria_label: 'Vyberte hodnotenie každej možnosti zadaním hodnoty.',
	ranking_dnd_aria_label:
		'Zamerajte sa na možnosť a stlačte medzerník, aby ste začali ťahať. Pri ťahaní môžete použiť šípky na presun položky a escape na zrušenie. Stlačte medzerník znova, aby ste položku umiestnili na nové miesto. Uistite sa, že váš čítač obrazovky je v režime zamerania alebo formulárov.',
	allocation_option_input: 'Vstup hodnoty {label}.',
	allocation_aria_label: 'Zadajte hodnotu pre každú možnosť pomocou vstupov.',
	play: 'Prehrať',
	pause: 'Pozastaviť',
	stop: 'Zastaviť',
	fwd: 'Preskočiť dopredu',
	rwd: 'Pretočiť späť',
	volume_down: 'Znížiť hlasitosť',
	volume_up: 'Zvýšiť hlasitosť',
	volume_current: 'Hlasitosť: {number}%',
	loading: 'Načítava sa',
	press_tab_to_continue: 'Stlačte tabulátor pre pokračovanie',
	option_focus_selected: 'Možnosť {label} zameraná. Stlačte enter pre zrušenie výberu.',
	option_focus_not_selected: 'Možnosť {label} zameraná. Stlačte enter pre výber.',
	image_alt: 'obrázok:',
	video_alt: 'video:',
	submit_enabled: 'Odoslanie povolené.',
	skip_enabled: 'Preskočenie otázky povolené',
	n_of_min_selected: '{selected} z {min} vybraných.',
	option_selected: '{label} vybraná.',
	option_deselected: '{label} zrušená.',
	choice_multiple_hint: 'Vyberte {min} až {max} možností.',
	ranking_position: 'pozícia: {position}',
	ranking_position_changed: 'Hodnota {label} nastavená na {value}.',
	ranking_input_aria_label: '{label}. Aktuálna pozícia: {position}. Zadajte pozíciu od 1 do {max}.',
	not_selected: 'nevybrané',
	study_logo_alt: 'Logo štúdie',
	groupsolver_logo_alt: 'Logo GroupSolver',
}

export default messages
