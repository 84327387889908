import { API } from 'store/api'
import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import { DELAY_LENGTH } from 'constants/delay'
import {
	setActionSubmitListener,
	setReportContentListener,
	showActionButton,
	showActionLoader,
} from 'store/_chat/actionBar'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'
import { createComponent, removeTempMessages } from 'store/_chat/history'
import { setOptionsChangeListener, actionsShow, actionsHide } from 'store/_chat/actions'

import { actions as reportContentFormActions } from 'store/_chat/reportContentForm'
import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import { COMPONENTS, POSITION } from 'constants/component'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { ELABORATE_OPTIONS, ELABORATE_OPTIONS_ORDER } from 'constants/elaborate'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'elaborate.add.response'
export const SET_STEP_DATA = 'elaborate.set.step.data'

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	setActionSubmitListener(null, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setOptionsChangeListener(primarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	const solverMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	const hasPriorMessage = module.priorMessage !== null

	if (hasPriorMessage === true) {
		let priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		addTempMessage(priorMessage, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	const introMessage = createComponent(COMPONENTS.MESSAGE)
	introMessage.props.text = formatMessage('elaborate_intro')(dispatch, getState)
	introMessage.props.shouldAutoFocus = hasPriorMessage === false
	addMessage(introMessage, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep(module.steps[0], false)(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const showNextStep = (stepData, shouldAutoFocus) => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getElaborateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	dispatch({ type: SET_STEP_DATA, stepData })
	setReportContentListener(reportContentListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	let options = Object.values(ELABORATE_OPTIONS_ORDER).map(option => {
		return {
			id: option.id,
			label: formatMessage(option.label)(dispatch, getState),
			selected: false,
		}
	})

	const solverMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	let quoteMessage = createComponent(COMPONENTS.QUOTE)
	quoteMessage.props.text = stepData.label
	quoteMessage.props.id = stepData.idStatement
	quoteMessage.props.shouldAutoFocus = shouldAutoFocus
	addTempMessage(quoteMessage, solverMessagePosition, DELAY_LENGTH.SHORT)(dispatch, getState)

	if (responses.length === 0) {
		if (definition.minResponses === 0) {
			let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

			if (getIsAccessibilityEnabled(getState()) === true) {
				addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
				dispatch,
				getState,
			)
		} else {
			let hintText = formatMessage('choice_single_hint')(dispatch, getState)
			addAction(showActionButton, [
				ACTION_BUTTON_STATE.SKIP_PREP,
				hintText,
				ACTION_BUTTON_LOADER_TYPE.PERCENT,
				0,
			])(dispatch, getState)
		}
	} else if (
		responses.length >= definition.minResponses &&
		responses.length < definition.maxResponses
	) {
		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	}
	addAction(actionsShow, [CHOICE_SUBTYPE.CHECKBOX, options])(dispatch, getState)
}

export const reportContentListener = () => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)
	const stepData = getState().elaborate.stepData
	if (stepData) {
		reportContentFormActions.open(studyObject, stepData)(dispatch, getState)
	}
}

export const primarySubmitListener = options => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getElaborateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	let currentStep = definition.steps[responses.length]
	let selectedOption = options.find(option => option.selected === true)

	if (selectedOption === undefined) {
		// this fixes double-click on elaborate option
		return
	}

	let selectedContent = Object.values(ELABORATE_OPTIONS).find(
		apiAnswer => apiAnswer.id === selectedOption.id,
	)

	let newResponse = {
		idStatement: currentStep.idStatement,
		label: currentStep.label,
		answer: selectedContent.apiAnswer,
	}
	dispatch({ type: ADD_RESPONSE, newResponse })

	// update action bar
	if (responses.length + 1 <= definition.minResponses) {
		let phaseProgress = ((responses.length + 1) / definition.minResponses) * 100
		let hintText = formatMessage('choice_single_hint')(dispatch, getState)

		addAction(showActionButton, [
			ACTION_BUTTON_STATE.SKIP_PREP,
			hintText,
			ACTION_BUTTON_LOADER_TYPE.PERCENT,
			phaseProgress,
		])(dispatch, getState)
	}

	const responseMessagePosition =
		getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

	let responseText = createComponent(COMPONENTS.MESSAGE)
	if (selectedOption.id === ELABORATE_OPTIONS.AGREE.id) {
		responseText.props.text = newResponse.label
		addMessage(responseText, responseMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	if (responses.length + 1 === definition.maxResponses) {
		endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		actionsHide()(dispatch, getState)
		removeTempMessages()(dispatch, getState)
		if (responses.length >= definition.minResponses) {
			showActionLoader()(dispatch, getState)
		}

		let nextOption = definition.steps[responses.length + 1]

		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			showNextStep(nextOption, true)(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	}
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const endStep = () => (dispatch, getState) => {
	dispatch({ type: SET_STEP_DATA, stepData: null })
	setReportContentListener()(dispatch, getState)

	actionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)
	removeTempMessages()(dispatch, getState)

	let studyObject = getStudyObject()(dispatch, getState)
	let responses = getElaborateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	let apiResponse = {}
	apiResponse[studyObject.id] = responses
	getState().study.getNextStep(apiResponse, null, true)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
	stepData: null,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses]
		newState.responses.push(action.newResponse)

		return newState
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'elaborate') {
			return state
		}
		let newState = Object.assign({}, state)
		newState.responses = initialState.responses

		return newState
	},
	[SET_STEP_DATA]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.stepData = action.stepData

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Helpers
// ------------------------------------
export const getElaborateResponses = () => (dispatch, getState) => {
	let state = getState()
	return state.elaborate.responses
}
