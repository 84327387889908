const clientConfig = {
	gs: {
		logoPath: '/gs/groupsolver-logo.svg',
		faviconPath: '/gs/groupsolver-glyph.svg',
		primaryColor: '#00aff0',
		title: 'GroupSolver Solver',
		web: 'https://groupsolver.com',
	},
	ra: {
		logoPath: '/ra/ra-logo.png',
		faviconPath: '/ra/ra-logo.png',
		primaryColor: '#0d788c',
		title: 'Research Activate AiQ',
		web: 'https://www.researchactivate.com/',
	},
}

export default clientConfig
