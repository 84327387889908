const messages = {
	not_found: 'De door u gezochte pagina bestaat niet',
	service_outage:
		'Het spijt ons dat ons systeem platligt als gevolg van een wereldwijde storing in de cloud-diensten van onze provider.',
	error_unexpected_error: 'Er is een onverwacht probleem opgetreden. Probeer het later opnieuw.',

	error_img_alt: 'Foutafbeelding',
	error_try_again: 'Probeer het opnieuw',

	error_generic_header: 'Er is iets misgegaan',
	error_generic_message:
		'Probeer dat opnieuw. Als het nog steeds niet werkt, stuur dan een e-mail naar support@groupsolver.com met de QR-code die u hierboven ziet.',

	error_offline_header: 'Het lijkt erop dat u offline bent',
	error_offline_message:
		'Uw onderzoek gaat weer verder nadat uw verbinding opnieuw tot stand is gebracht.',

	error_landscape_small_screen_header: 'Kantel uw apparaat',
	error_landscape_small_screen_text:
		'Uw scherm is te kort om dit onderzoek te zien. Kantel uw apparaat.',

	error_small_browser_window_header: 'Vergroot uw browserscherm',
	error_small_browser_window_text:
		'Uw scherm is te klein om dit onderzoek te zien. Vergroot uw browserscherm',

	login_error_empty_email: 'E-mailadres kan niet leeg zijn',
	login_error_invalid_email: 'Voer een geldig e-mailadres in',
	login_error_unable_to_login: 'Kan op dit moment niet inloggen. Probeer het later opnieuw.',

	login_email_placeholder: 'Voer uw e-mailadres in',
	login_email_button: 'Verder',
	login_email_back: 'of ga terug naar de inlogopties',
	login_email_label: 'Verdergaan met e-mailadres',
	login_anonymous_label: 'Anoniem verdergaan',

	login_privacy_statement: 'GroupSolver respecteert uw privacy en de informatie die u verstrekt.',
	login_privacy_statement_email:
		'Uw e-mailadres zal nooit worden gedeeld, verkocht of op een andere manier worden gebruikt dan om u toegang tot dit project te bieden.',
	login_privacy_policy: 'Privacybeleid',

	study_unavailable_label: 'Het door u gezochte onderzoek is momenteel niet beschikbaar.',
	study_unavailable_description:
		'Bedankt voor uw interesse in ons onderzoek! Dit onderzoek zit helaas al vol of is momenteel onderbroken. Onze verontschuldigingen voor het ongemak.',
	study_preview_limit_reached_label: 'Uw limiet voor voorbezichtiging is bereikt',
	study_preview_limit_reached_description:
		'Bedankt voor uw interesse in ons onderzoek. Helaas heeft dit onderzoek het maximale aantal voorbezichtingen bereikt.',

	waiting_message:
		'Dit onderzoek wordt momenteel door meerdere mensen uitgevoerd.\nWe proberen {number, plural, =0 { nu} one {over # seconde} other {over # seconden}} automatisch opnieuw om te beginnen.',
	waiting_error_recoverable: 'Er is een onverwachte fout opgetreden.',
	waiting_error_recoverable_retry:
		'We proberen het {number, plural, =0 { nu} one {over # seconde} other {over # seconden}} opnieuw',
	waiting_error_unknown_study: 'Het door u gezochte onderzoek bestaat niet.',
	waiting_error_dead: 'Er is een onverwacht probleem opgetreden. Probeer het later opnieuw.',

	action_input_submit_button_text: 'INDIENEN',
	action_input_skip_button_text: 'Verder',

	choice_single_hint: 'Kies een van de opties',
	general_skip_action_message: 'Verdergaan naar de volgende stap',

	action_button_primary_default_text: 'Actie',
	action_button_hint_default_text: 'Kies een van de opties',

	message_media_examine_video: 'Klik op de video om deze te beoordelen',
	message_media_examine_image: 'Klik op de foto om deze te beoordelen',
	message_full_screen_close_button_text: 'Sluiten',
	message_image_view_label: 'Afbeelding bekijken',
	message_video_view_label: 'Video bekijken',
	message_image_examined_label: 'Afbeelding beoordeeld',
	message_video_examined_label: 'Video beoordeeld',
	message_zoom_mouse: 'Beweeg de muis om te zoomen',
	message_zoom_touch: 'Raak aan om te zoomen',

	textarea_too_long_hint: 'Uw tekst is te lang',

	ideate_placeholder: 'Antwoord in (een) korte zin(nen)',
	ideate_skip: ' of ga verder',

	eliminate_no: 'Nee',
	eliminate_yes: 'Ja',
	eliminate_intro: 'Denk na over de volgende uitspraak:',
	eliminate_multiple_suggestions: 'Welke van de volgende zouden deze kunnen vervangen?',
	eliminate_one_suggestion: 'Kan deze worden vervangen door het volgende?',
	eliminate_step: 'Beschouw een andere uitspraak:',
	eliminate_none_of_the_above: 'Geen van deze',

	elaborate_indifferent_label: 'Neutraal',
	elaborate_disagree_label: 'Oneens',
	elaborate_agree_label: 'Eens',
	elaborate_unclear_statement_label: 'Onduidelijk antwoord',
	elaborate_intro: 'Anderen hebben de volgende antwoorden gegeven. Bent u het met ze eens?',

	ideamatching_unsure_label: 'Niet zeker',
	ideamatching_unequal_label: 'Anders',
	ideamatching_equal_label: 'Hetzelfde',
	ideamatching_intro: 'Kijk naar dit paar antwoorden. Betekenen ze hetzelfde?',

	select_input_placeholder: 'Typ hier om te selecteren',
	select_input_placeholder_max: 'Maximum bereikt',
	select_multiple_choice_hint:
		'Kies {minNumber, plural, =0 { 0 } other { # }} tot {maxNumber, plural, =0 { 0 } other { # }} opties',
	select_no_matches_found: 'Geen overeenkomsten gevonden',

	report_content_inappropriate: 'Ongepast antwoord',
	report_content_profane: 'Obscene of godslasterlijke content',
	report_content_gibberish: 'Wartaal of onzintekst',
	report_content_irrelevant: 'Irrelevant voor de gestelde vraag',
	report_content_different_language: 'Andere taal',
	report_content_other: 'Overig',
	report_content_no_content: 'Geen content te rapporteren',
	report_content_header: 'Antwoord rapporteren',
	report_content_form_header: 'Selecteer de reden waarom u dit antwoord wilt markeren',
	report_content_flag_answer: 'Antwoord markeren',
	report_content_return_to_study: 'Teruggaan naar onderzoek',
	report_content_thank_you:
		'Bedankt voor uw feedback.  We zullen uw input beoordelen en indien nodig actie ondernemen.',
	report_content_statement_flagged: 'Melding is gemarkeerd.',

	platform_update_header: 'GroupSolver ondergaat momenteel gepland onderhoud',
	platform_update_message: 'Onze verontschuldigingen voor het ongemak.',

	none_of_these: 'Geen van deze',

	allocation_instruction_limit: 'Verdeel {points} over de opties',
	allocation_instruction_limit_no_minimum: 'Verdeel tot {points} over de verschillende opties',
	allocation_instruction_no_limit: 'Verdeel de punten over de opties',
	allocation_instruction_points_left: '{points} resterend',
	allocation_options_out_of_range: 'Sommige opties zijn buiten bereik',
	allocation_limit_overflow: '{points} over de limiet',
	allocation_option_invalid_range: 'Waarde is buiten bereik van {range}',
	allocation_option_invalid_min: 'Waarde moet groter of gelijk zijn aan {min}',

	dropdown_select: 'Selecteren...',

	ranking_drag_options: 'Sleep of klik op de pijlen om de opties te rangschikken',
	ranking_assign_option_values: 'Rangschik alle opties',
	ranking_require_unique_values: 'De rangschikkingen van de opties moeten uniek zijn',

	heatmap_instruction_intro: 'Om te beginnen met selecteren, bekijk de onderstaande afbeelding.',
	heatmap_instruction_unlimited:
		'Selecteer zoveel punten als u wilt door op de afbeelding te klikken.',
	heatmap_instruction_count:
		'Selecteer {number, plural, =0 {0 punten} one {één punt} other {{number} punten}} door op de afbeelding te klikken.',
	heatmap_instruction_up_to_count:
		'Selecteer {number, plural, =0 {0 punten} one {één punt} other {maximaal {number} punten}} door op de afbeelding te klikken.',
	heatmap_instruction_between_count:
		'Selecteer {from} tot {to} punten door op de afbeelding te klikken',
	heatmap_instruction_at_least_count:
		'Selecteer minimaal {number, plural, =0 {0 punten} one {één punt} other {{number} punten}} door op de afbeelding te klikken.',
	heatmap_response:
		'{number, plural, =0 {0 punten} one {1 punt} other {{number} punten}} geselecteerd',

	continue: 'Doorgaan',
	reset: 'Resetten',

	input_invalid: 'Invoer is niet geldig.',
	input_empty: 'Invoer mag niet leeg zijn.',
	input_chars_left: '{number} {number, plural, =0 {tekens} one {teken} other {tekens}} over.',
	input_too_long:
		'{number} {number, plural, =0 {tekens} one {teken} other {tekens}} over de limiet.',

	// accessibility
	ranking_select_rank: 'Selecteer rang van {label}.',

	ranking_select_aria_label: 'Selecteer de rang van elke optie door een waarde in te typen.',
	ranking_dnd_aria_label:
		'Focus op een optie en druk op de spatiebalk om een ​​sleepactie te starten. Tijdens het slepen kunt u de pijltjestoetsen gebruiken om het item te verplaatsen en op Escape drukken om te annuleren. Druk nogmaals op de spatiebalk om het item op de nieuwe positie te plaatsen. Zorg ervoor dat uw schermlezer in de focusmodus of formulierenmodus staat.',

	allocation_option_input: '{label} waarde-invoer.',
	allocation_aria_label: 'Voer een waarde in voor elke optie met behulp van invoer.',

	play: 'Afspelen',
	pause: 'Pauze',
	stop: 'Stoppen',
	fwd: 'Vooruitspoelen',
	rwd: 'Terugspoelen',
	volume_down: 'Volume verlagen',
	volume_up: 'Volume verhogen',
	volume_current: 'Volume: {number}%',

	loading: 'Laden',

	press_tab_to_continue: 'Druk op tab om door te gaan',

	option_focus_selected: 'Optie {label} gefocust. Druk op Enter om te deselecteren.',
	option_focus_not_selected: 'Optie {label} gefocust. Druk op Enter om te selecteren.',

	image_alt: 'afbeelding:',
	video_alt: 'video:',

	submit_enabled: 'Verzenden ingeschakeld.',
	skip_enabled: 'Vraag overslaan ingeschakeld',
	n_of_min_selected: '{selected} van {min} geselecteerd.',
	option_selected: '{label} geselecteerd.',
	option_deselected: '{label} gedeselecteerd.',

	choice_multiple_hint: 'Selecteer {min} tot {max} opties.',

	ranking_position: 'positie: {position}',
	ranking_position_changed: '{label} waarde ingesteld op {value}.',
	ranking_input_aria_label:
		'{label}. Huidige positie: {position}. Typ een positie in van 1 tot {max}.',
	not_selected: 'niet geselecteerd',

	study_logo_alt: 'Studiologo',
	groupsolver_logo_alt: 'Groupsolver-logo',
}

export default messages
