export const ELABORATE_OPTIONS = {
	INDIFFERENT: {
		label: 'elaborate_indifferent_label',
		id: 'elaborate-0',
		apiAnswer: 'INDIFFERENT',
	},
	DISAGREE: {
		label: 'elaborate_disagree_label',
		id: 'elaborate-1',
		apiAnswer: 'DISAGREE',
	},
	AGREE: {
		label: 'elaborate_agree_label',
		id: 'elaborate-2',
		apiAnswer: 'AGREE',
	},
	UNCLEAR_STATEMENT: {
		label: 'elaborate_unclear_statement_label',
		id: 'elaborate-3',
		apiAnswer: 'UNCLEAR_STATEMENT',
	},
}

export const ELABORATE_OPTIONS_ORDER = [
	ELABORATE_OPTIONS.DISAGREE,
	ELABORATE_OPTIONS.INDIFFERENT,
	ELABORATE_OPTIONS.AGREE,
	ELABORATE_OPTIONS.UNCLEAR_STATEMENT,
]
