import isuuid from 'isuuid'
import _ from 'lodash'

import fetcher from 'store/tools/fetcher'
import config from 'config'
import { MODULE_TYPES } from 'constants/moduleTypes'
import { USER_STATE } from 'constants/userState'

// we cannot import SET_USER_INFO from store/user because of circular dependency between user and api stores
const SET_USER_INFO = 'api.set.user.info'

// ------------------------------------
// Constants
// ------------------------------------
export const API = {
	REQUEST_STUDY_ENTRY: {
		ENDPOINT: '/request-study-entry',
		STARTED: 'api.request.study.entry.started',
		SUCCESS: 'api.request.study.entry.success',
		ERROR: 'api.request.study.entry.error',
	},
	LOGIN: {
		ENDPOINT: '/login',
		STARTED: 'api.login.started',
		SUCCESS: 'api.login.success',
		ERROR: 'api.login.error',
	},
	GET_NEXT_STEP: {
		ENDPOINT: '/next-step',
		STARTED: 'api.load.next.step.started',
		SUCCESS: 'api.load.next.step.success',
		ERROR: 'api.load.next.step.error',
	},
	REPORT_CONTENT: {
		ENDPOINT: '/report-content',
		STARTED: 'api.report.content.started',
		SUCCESS: 'api.report.content.success',
		ERROR: 'api.report.content.error',
	},
	GET_PLATFORM_STATUS: {
		ENDPOINT: '/platform-status',
		STARTED: 'api.get.platform.status.started',
		SUCCESS: 'api.get.platform.status.success',
		ERROR: 'api.get.platform.status.error',
	},
	SET_USER_STATE_ACTIVE: 'api.user.state.active',
	SET_USER_STATE_INACTIVE: 'api.user.state.inactive',
}

// ------------------------------------
// initialState
// ------------------------------------
const initialState = {
	token: null,
	userState: USER_STATE.ACTIVE,
}

// ------------------------------------
// Helpers
// ------------------------------------
const createRequest = (data, DEFINITION, stringify = true) => (dispatch, getState) => {
	// Endpoint overrides and fallbacks
	// 1. use endpoint defined for specific endpoint defined in api config
	// 2. use endpoint defined for specific study defined in studyOptions
	// 3. use endpoint defined for environment
	const requestHost = DEFINITION.ENDPOINT_HOST || getState().study.apiEndpoint || config.apiEndpoint

	let requestUrl = requestHost + DEFINITION.ENDPOINT
	const requestData = {
		method: 'POST',
		mode: 'cors',
		redirect: 'error',
		cache: 'no-cache',
		body: stringify ? JSON.stringify(data) : data,
	}
	return fetcher(requestUrl, requestData, DEFINITION)(dispatch, getState)
}

// ------------------------------------
// Actions
// ------------------------------------
export const apiActions = {
	requestStudyEntry: (idStudy, urlParameters) => (dispatch, getState) => {
		const parameters =
			urlParameters === null ||
			urlParameters === undefined ||
			Object.keys(urlParameters).length === 0
				? null
				: urlParameters
		const requestData = {
			data: {
				idStudy,
				urlParameters: parameters,
				pcid: getState().user.pcid,
			},
		}

		return createRequest(requestData, API.REQUEST_STUDY_ENTRY)(dispatch, getState)
	},
	login: (type, loginData) => (dispatch, getState) => {
		const state = getState()
		const requestData = {
			data: {
				idStudy: state.user.idStudy,
				type,
				urlParameters: state.user.query,
				loginInfo: loginData,
				pcid: state.user.pcid,
			},
		}

		return createRequest(requestData, API.LOGIN)(dispatch, getState)
	},
	getNextStep: (studyObject, actionLog, saveQueue, flaggedContent) => (dispatch, getState) => {
		const state = getState()
		const requestData = {
			data: {
				studyObject,
				actionLog,
				history: saveQueue,
				idStudy: state.user.idStudy,
				flagged: flaggedContent,
				studyObjectType: _.get(getState(), 'study.studyObject.type', null),
			},
			token: getState().api.token,
		}
		return createRequest(requestData, API.GET_NEXT_STEP)(dispatch, getState)
	},
	getPlatformStatus: () => (dispatch, getState) => {
		const state = getState()
		const requestData = {
			data: {
				idActiveStudy: isuuid(state.user.idStudy) === true ? state.user.idStudy : null,
				idRespondent: state.api.token,
			},
		}

		const urlContainsSolve = window.location.href.includes('/solve')
		const isSolverVisible = document.visibilityState === 'visible'
		const isLastModule =
			state.study.studyObject !== null && state.study.studyObject.type === MODULE_TYPES.END_STUDY
		const isInactive = state.api.userState === USER_STATE.INACTIVE

		if (
			urlContainsSolve === false ||
			isSolverVisible === false ||
			isLastModule === true ||
			isInactive === true
		) {
			requestData.data.idRespondent = null
			return createRequest(requestData, API.GET_PLATFORM_STATUS)(dispatch, getState)
		}

		return createRequest(requestData, API.GET_PLATFORM_STATUS)(dispatch, getState)
	},
	setUserStateActive: () => (dispatch, getState) => {
		dispatch({ type: API.SET_USER_STATE_ACTIVE })
	},
	setUserStateInactive: () => (dispatch, getState) => {
		dispatch({ type: API.SET_USER_STATE_INACTIVE })
	},
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.LOGIN.SUCCESS]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.token = action.response.token

		return newState
	},
	[SET_USER_INFO]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.token = action.userInfo.token

		return newState
	},
	[API.SET_USER_STATE_ACTIVE]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.userState = USER_STATE.ACTIVE

		return newState
	},
	[API.SET_USER_STATE_INACTIVE]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.userState = USER_STATE.INACTIVE

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
