import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import { createComponent, removeTempMessages } from 'store/_chat/history'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'
import { actionsHide } from 'store/_chat/actions'
import {
	suggestionsSet,
	suggestionsShow,
	suggestionsHide,
	setSuggestionsChangeListener,
	setRecordSelectLogItem,
} from 'store/_chat/suggestions'
import { setActionSubmitListener, showActionButton, showActionHint } from 'store/_chat/actionBar'
import { setInput, showInput, setInputChangeListener, setPlaceholder } from 'store/_chat/input'

import { COMPONENTS, POSITION } from 'constants/component'
import { CHOICE_QUESTION_LOG_ACTIONS } from 'constants/choiceQuestionLog'
import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import { INPUT_TYPE } from 'constants/input'
import { DELAY_LENGTH } from 'constants/delay'

import { getChoiceInstructionArgs } from 'helpers/getChoiceInstructionArgs'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export const RECORD_SELECT_LOG_ACTION = 'select.log.record.action'
export const RESET_SELECT_LOG = 'select.log.reset'

// ------------------------------------
// Primary Actions
// ------------------------------------
export const init = definition => (dispatch, getState) => {
	// acion bar setup
	setSuggestionsChangeListener(suggestionsChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setInputChangeListener(inputChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setRecordSelectLogItem(recordSelectLogItem)(dispatch, getState) // eslint-disable-line no-use-before-define

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep(definition)(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const recordSelectLogItem = (
	timestamp,
	idStudyObject,
	studyObjectType,
	action,
	data = null,
) => (dispatch, getState) => {
	dispatch({
		type: RECORD_SELECT_LOG_ACTION,
		selectLogAction: { timestamp, idStudyObject, studyObjectType, action, data },
	})
}

export const resetSelectLog = () => (dispatch, getState) => {
	dispatch({
		type: RESET_SELECT_LOG,
	})
}

export const showNextStep = definition => (dispatch, getState) => {
	const { mandatory } = definition

	if (mandatory === false) {
		// enable skip button
		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else {
		let hintText = formatMessage(...getChoiceInstructionArgs(definition))(dispatch, getState)

		addAction(showActionHint, [hintText])(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			const instruction = createComponent(COMPONENTS.INSTRUCTION)

			instruction.props.text = hintText

			const solverMessagePosition =
				getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT
			addTempMessage(instruction, solverMessagePosition, 0)(dispatch, getState)
		}
	}

	let options = definition.options.map(option => {
		option.selected = false
		option.visible = true
		return option
	})

	addAction(suggestionsSet, [
		options,
		definition.multiple,
		definition.minSelection,
		definition.maxSelection,
	])(dispatch, getState)
	addAction(suggestionsHide)(dispatch, getState)

	let inputPlaceholder = formatMessage('select_input_placeholder')(dispatch, getState)
	addAction(setInput, [INPUT_TYPE.CHOICE, inputPlaceholder, null, true, false, true, ''])(
		dispatch,
		getState,
	)
	addAction(showInput)(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)

	recordSelectLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		CHOICE_QUESTION_LOG_ACTIONS.SELECT_VISIBLE,
		null,
	)(dispatch, getState)
}

export const endStep = (selectedOptions, props) => (dispatch, getState) => {
	removeTempMessages()(dispatch, getState)

	const response = createComponent(COMPONENTS.MESSAGE)
	response.props = Object.assign({}, response.props, props)

	const studyObject = getStudyObject()(dispatch, getState)

	// create apiResponse
	const studyObjectResponse = {
		[studyObject.id]: null,
	}

	if (selectedOptions) {
		const responseIds = selectedOptions.map(option => option.id)
		studyObjectResponse[studyObject.id] = responseIds
	}

	recordSelectLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		CHOICE_QUESTION_LOG_ACTIONS.SUBMIT,
		studyObjectResponse[studyObject.id],
	)(dispatch, getState)

	const studyObjectActionLog = getState().select.selectLog

	const responsePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

	addMessage(response, responsePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	resetSelectLog()(dispatch, getState)
	// push answer to server
	getState().study.getNextStep(studyObjectResponse, studyObjectActionLog, true)(dispatch, getState)
}

// ------------------------------------
// Listeners
// ------------------------------------
export const suggestionsChangeListener = options => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let selectedOptions = options.filter(option => option.selected)
	if (definition.multiple) {
		handleMultiplePickSuggestions(definition, selectedOptions)(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		handleSinglePickSuggestions(definition, selectedOptions)(dispatch, getState) // eslint-disable-line no-use-before-define
	}
	if (definition.maxSelection <= selectedOptions.length) {
		let inputPlaceholder = formatMessage('select_input_placeholder_max')(dispatch, getState)
		setPlaceholder(inputPlaceholder)(dispatch, getState)
		suggestionsHide()(dispatch, getState)
	} else {
		let inputPlaceholder = formatMessage('select_input_placeholder')(dispatch, getState)
		setPlaceholder(inputPlaceholder)(dispatch, getState)
		suggestionsShow()(dispatch, getState)
	}
}

export const primarySubmitListener = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)

	let state = getState()
	let selectedOptions = state.chatSuggestions.options.filter(option => option.selected)

	let props = {}
	props.text = selectedOptions.map(option => option.label).join(', ')

	endStep(selectedOptions, props)(dispatch, getState)
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	let props = {}
	props.text = formatMessage('general_skip_action_message')(dispatch, getState)

	endStep(null, props)(dispatch, getState)
}

export const inputChangeListener = (value, selectedOptions) => (dispatch, getState) => {
	let state = getState()
	let allOptions = state.chatSuggestions.options
	let suggestions = allOptions.map(option => {
		option.visible = option.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
		return option
	})
	suggestionsSet(
		suggestions,
		state.chatSuggestions.multiple,
		state.chatSuggestions.min,
		state.chatSuggestions.max,
	)(dispatch, getState)
}

// ------------------------------------
// Helper Actions
// ------------------------------------

export const handleMultiplePickSuggestions = (definition, selectedOptions) => (
	dispatch,
	getState,
) => {
	let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	let isNoneOfTheseSelected = selectedOptions.find(option => option.isNoneOfThese === true)

	if (
		isNoneOfTheseSelected ||
		(definition.minSelection <= selectedOptions.length &&
			definition.maxSelection >= selectedOptions.length)
	) {
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText)(dispatch, getState)
	} else if (selectedOptions.length === 0 && definition.mandatory === false) {
		// enable skip button
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)
		showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
	} else {
		let percent = (selectedOptions.length / definition.minSelection) * 100
		showActionButton(
			ACTION_BUTTON_STATE.SUBMIT_PREP,
			submitButtonText,
			ACTION_BUTTON_LOADER_TYPE.PERCENT,
			percent,
		)(dispatch, getState)
	}
}

export const handleSinglePickSuggestions = (definition, selectedOptions) => (
	dispatch,
	getState,
) => {
	let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	if (selectedOptions.length === 1) {
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		// enable submit button
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText)(dispatch, getState)
	} else if (selectedOptions.length === 0 && definition.mandatory === false) {
		// enable skip button
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)
		showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
	} else {
		showActionHint(formatMessage('choice_single_hint')(dispatch, getState))(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('choice_single_hint')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}
	}
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	selectLog: [],
	idIteration: uuidv4(),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[RECORD_SELECT_LOG_ACTION]: (state, action) => {
		const newState = { ...state }
		newState.selectLog = state.selectLog.slice()
		newState.selectLog.push({ idIteration: state.idIteration, ...action.selectLogAction })
		return newState
	},
	[RESET_SELECT_LOG]: (state, action) => {
		const newState = { ...state }
		newState.selectLog = []
		newState.idIteration = uuidv4()
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
