const messages = {
	not_found: 'Strona, której szukasz nie istnieje',
	service_outage:
		'Przepraszamy, z powodu globalnej awarii usług w chmurze oferowanych przez naszego dostawcę nasz system jest wyłączony.',
	error_unexpected_error: 'Wystąpił nieoczekiwany problem. Spróbuj ponownie później.',

	error_img_alt: 'Obraz błędu',
	error_try_again: 'Spróbuj jeszcze raz',

	error_generic_header: 'Coś poszło nie tak',
	error_generic_message:
		'Spróbuj ponownie, a jeśli nadal nie będzie działać, wyślij wiadomość e-mail na adres support@groupsolver.com z kodem QR widocznym powyżej.',

	error_offline_header: 'Jesteś offline',
	error_offline_message: 'Badanie zostanie wznowione po ponownym nawiązaniu połączenia.',

	error_landscape_small_screen_header: 'Obróć urządzenie',
	error_landscape_small_screen_text:
		'Ekran jest zbyt krótki, aby wyświetlić to badanie. Obróć urządzenie.',

	error_small_browser_window_header: 'Powiększ okno przeglądarki',
	error_small_browser_window_text:
		'Ekran jest za mały, aby wyświetlić to badanie. Powiększ okno przeglądarki',

	login_error_empty_email: 'E-mail nie może być pusty',
	login_error_invalid_email: 'Wpisz prawidłowy e-mail',
	login_error_unable_to_login: 'W tej chwili nie można się zalogować. Spróbuj ponownie później.',

	login_email_placeholder: 'Wpisz swój adres e-mail',
	login_email_button: 'Kontynuuj',
	login_email_back: 'lub wróć do opcji logowania',
	login_email_label: 'Kontynuuj z e-mailem',
	login_anonymous_label: 'Kontynuuj anonimowo',

	login_privacy_statement: 'GroupSolver szanuje Twoją prywatność i przekazywane nam informacje.',
	login_privacy_statement_email:
		'Twój e-mail nigdy nie będzie udostępniany, sprzedawany ani używany w innym celu niż udzielenie Ci dostępu do tego projektu.',
	login_privacy_policy: 'Polityka prywatności',

	study_unavailable_label: 'Badanie, którego szukasz jest obecnie niedostępne.',
	study_unavailable_description:
		'Dziękujemy za zainteresowanie naszym badaniem! Niestety, badanie jest już zamknięte lub zostało w tej chwili wstrzymane. Przepraszamy za wszelkie wynikłe z tego niedogodności.',
	study_preview_limit_reached_label: 'Osiągnięto limit podglądu',
	study_preview_limit_reached_description:
		'Dziękujemy za zainteresowanie naszym badaniem! Niestety, badanie osiągnęło liczbę dostępnych podglądów.',

	waiting_message:
		'Badanie jest obecnie rozwiązywane przez kilka osób.\nAutomatycznie ponowimy próbę wprowadzenia {number, plural, =0 { teraz} one {za # s} other {za # s}}.',
	waiting_error_recoverable: 'Wystąpił nieoczekiwany błąd.',
	waiting_error_recoverable_retry:
		'Spróbujemy ponownie {number, plural, =0 { teraz} one {za # s} other {za # s}}.',
	waiting_error_unknown_study: 'Strona, której szukasz nie istnieje.',
	waiting_error_dead: 'Wystąpił nieoczekiwany problem. Spróbuj ponownie później.',

	action_input_submit_button_text: 'SUBMIT',
	action_input_skip_button_text: 'Kontynuuj',

	choice_single_hint: 'Wybierz jedną z opcji',
	general_skip_action_message: 'Kontynuuj do następnego kroku',

	action_button_primary_default_text: 'Działanie',
	action_button_hint_default_text: 'Wybierz jedną z opcji',

	message_media_examine_video: 'Kliknij wideo, aby je ocenić',
	message_media_examine_image: 'Kliknij obraz, aby go ocenić',
	message_full_screen_close_button_text: 'Zamknij',
	message_image_view_label: 'Wyświetl obraz',
	message_video_view_label: 'Wyświetl wideo',
	message_image_examined_label: 'Oceniony obraz',
	message_video_examined_label: 'Ocenione wideo',
	message_zoom_mouse: 'Wjedź kursorem myszy, aby powiększyć',
	message_zoom_touch: 'Dotknij, aby powiększyć',

	textarea_too_long_hint: 'Twój tekst jest za długi',

	ideate_placeholder: 'Odpowiedz, używając krótkich zdań.',
	ideate_skip: ' lub kontynuuj',

	eliminate_no: 'Nie',
	eliminate_yes: 'Tak',
	eliminate_intro: 'Rozważ następujące stwierdzenie:',
	eliminate_multiple_suggestions: 'Które z poniższych może go zastąpić?',
	eliminate_one_suggestion: 'Czy można go zastąpić następującym?',
	eliminate_step: 'Rozważ kolejne stwierdzenie:',
	eliminate_none_of_the_above: 'Żadne z nich',

	elaborate_indifferent_label: 'Nie mam zdania',
	elaborate_disagree_label: 'Nie zgadzam się',
	elaborate_agree_label: 'Zgadzam się',
	elaborate_unclear_statement_label: 'Niejasna odpowiedź',
	elaborate_intro: 'Inne osoby odpowiedziały w następujący sposób. Zgadzasz się z nimi?',

	ideamatching_unsure_label: 'Nie mam pewności',
	ideamatching_unequal_label: 'Inne',
	ideamatching_equal_label: 'Takie same',
	ideamatching_intro: 'Spójrz na te pary odpowiedzi. Czy oznaczają to samo?',

	select_input_placeholder: 'Wpisz tutaj, aby wybrać',
	select_input_placeholder_max: 'Osiągnięto maksymalną liczbę',
	select_multiple_choice_hint:
		'Wybierz opcje {minNumber, plural, =0 { 0 } other { # }} do {maxNumber, plural, =0 { 0 } other { # }}',
	select_no_matches_found: 'Nie znaleziono dopasowania',

	report_content_inappropriate: 'Niestosowna odpowiedź',
	report_content_profane: 'Treść obsceniczna lub znieważająca',
	report_content_gibberish: 'Bełkot lub tekst bez sensu',
	report_content_irrelevant: 'Nie ma powiązania z zadanym pytaniem',
	report_content_different_language: 'Inny język',
	report_content_other: 'Inne',
	report_content_no_content: 'Brak treści do zgłoszenia',
	report_content_header: 'Zgłoś odpowiedź',
	report_content_form_header: 'Wybierz powód, dla którego chcesz zgłosić tę odpowiedź',
	report_content_flag_answer: 'Zgłoś odpowiedź',
	report_content_return_to_study: 'Wróć do badania',
	report_content_thank_you:
		'Dziękujemy za Twoją opinię.\nPrzeanalizujemy Twoje zgłoszenie i podejmiemy odpowiednie działania w razie potrzeby.',
	report_content_statement_flagged: 'Stwierdzenie zostało oflagowane.',

	platform_update_header: 'GroupSolver jest obecnie w trakcie zaplanowanej konserwacji',
	platform_update_message: 'Przepraszamy za wszelkie niedogodności.',

	none_of_these: 'Żadne z tych',

	allocation_instruction_limit: 'Przydziel {punkt.} na różne opcje',
	allocation_instruction_limit_no_minimum: 'Przydziel do {punkt.} na różne opcje',
	allocation_instruction_no_limit: 'Przydziel punkty na różne opcje',
	allocation_instruction_points_left: '{punk.} do wykorzystania',
	allocation_options_out_of_range: 'Niektóre opcje są poza zakresem',
	allocation_limit_overflow: '{points} poza limitem',
	allocation_option_invalid_range: 'Wartość jest poza zakresem {range}',
	allocation_option_invalid_min: 'Wartość musi być większa lub równa {min}',

	dropdown_select: 'Wybierz...',

	ranking_drag_options: 'Przeciągnij lub klikaj strzałki, aby uporządkować opcje',
	ranking_assign_option_values: 'Uporządkuj wszystkie opcje',
	ranking_require_unique_values: 'Opcje uporządkowania muszą być unikatowe',

	heatmap_instruction_intro: 'Aby rozpocząć wybieranie, zobacz obraz poniżej.',
	heatmap_instruction_unlimited: 'Wybierz dowolną liczbę punktów, klikając na obraz.',
	heatmap_instruction_count:
		'Wybierz {number, plural, =0 {0 punktów} one {jeden punkt} other {{number} punktów}} klikając na obraz.',
	heatmap_instruction_up_to_count:
		'Wybierz do {number, plural, =0 {0 punktów} one {jednego punktu} other {do {number} punktów}} klikając na obraz.',
	heatmap_instruction_between_count: 'Wybierz od {from} do {to} punktów klikając na obraz.',
	heatmap_instruction_at_least_count:
		'Wybierz co najmniej {number, plural, =0 {0 punktów} one {jeden punkt} other {{number} punktów}} klikając na obraz.',
	heatmap_response:
		'{number, plural, =0 {0 punktów} one {1 punkt} other {{number} punktów}} wybrano',
	continue: 'Kontynuuj',
	reset: 'Resetuj',
	input_invalid: 'Wprowadzone dane są nieprawidłowe.',
	input_empty: 'Pole nie może być puste.',
	input_chars_left: '{number} {number, plural, =0 {znaków} one {znak} other {znaków}} pozostało.',
	input_too_long: '{number} {number, plural, =0 {znaków} one {znak} other {znaków}} ponad limit.',
	ranking_select_rank: 'Wybierz rangę {label}.',
	ranking_select_aria_label: 'Wybierz rangę każdej opcji, wpisując wartość.',
	ranking_dnd_aria_label:
		'Skup się na opcji i naciśnij spację, aby rozpocząć przeciąganie. Podczas przeciągania możesz używać klawiszy strzałek, aby przesunąć element i escape, aby anulować. Naciśnij ponownie spację, aby umieścić element w nowej pozycji. Upewnij się, że czytnik ekranu jest w trybie fokus lub trybie formularzy.',
	allocation_option_input: 'Wprowadzenie wartości {label}.',
	allocation_aria_label: 'Wprowadź wartość dla każdej opcji, używając pól wprowadzania.',
	play: 'Odtwórz',
	pause: 'Pauza',
	stop: 'Zatrzymaj',
	fwd: 'Przewiń do przodu',
	rwd: 'Przewiń do tyłu',
	volume_down: 'Zmniejsz głośność',
	volume_up: 'Zwiększ głośność',
	volume_current: 'Głośność: {number}%',
	loading: 'Ładowanie',
	press_tab_to_continue: 'Naciśnij tab, aby kontynuować',
	option_focus_selected: 'Opcja {label} wybrana. Naciśnij enter, aby odznaczyć.',
	option_focus_not_selected: 'Opcja {label} wybrana. Naciśnij enter, aby zaznaczyć.',
	image_alt: 'obraz:',
	video_alt: 'wideo:',
	submit_enabled: 'Przesyłanie włączone.',
	skip_enabled: 'Pomiń pytanie włączone',
	n_of_min_selected: '{selected} z {min} wybrano.',
	option_selected: '{label} wybrano.',
	option_deselected: '{label} odznaczono.',
	choice_multiple_hint: 'Wybierz od {min} do {max} opcji.',
	ranking_position: 'pozycja: {position}',
	ranking_position_changed: 'Wartość {label} ustawiona na {value}.',
	ranking_input_aria_label: '{label}. Aktualna pozycja: {position}. Wpisz pozycję od 1 do {max}.',
	not_selected: 'nie wybrano',
	study_logo_alt: 'Logo badania',
	groupsolver_logo_alt: 'Logo Gropsolver',
}

export default messages
