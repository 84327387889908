import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { API } from 'store/api'
import { push, pushMultiple, blur, unblur, clear } from 'store/_chat/history'

import { DELAY_LENGTH } from 'constants/delay'
import { QUEUE_ITEM_TYPE } from 'constants/queue'

import { getStudyObject } from 'selectors/study'

export const ADD_PLUS_RESTORE = 'queue.add.plus.restore'
export const ADD_NO_RESTORE = 'queue.add.no.restore'
export const SAVE_QUEUE_SET_MEDIA_SEEN = 'queue.set.media.seen'
export const REMOVE = 'queue.remove'
export const START_CYCLE = 'queue.start.cycle'
export const END_CYCLE = 'queue.end.cycle'
export const CLEAR_SAVE_QUEUE = 'queue.clear.save.queue'
export const QUEUE_DID_RESTORE = 'queue.did.restore'
export const SAVE_QUEUE_SET_HEATMAP_SEEN = 'queue.set.heatmap.seen'

const UI_COMMANDS = {
	blur,
	unblur,
	clear,
}

// ------------------------------------
// initialState
// ------------------------------------
const initialState = {
	updateQueue: [],
	saveQueue: [],
	restoreQueue: [],
	isRunning: false,
	isRestoring: false,
}

// ------------------------------------
// Actions
// ------------------------------------
export const initQueue = updaterId => (dispatch, getState) => {
	let state = getState()
	if (state.queue.isRunning) {
		// eslint-disable-next-line no-console
		console.error('Queue store', "Can't start another instance of updater, one is already running")
	} else {
		dispatch({ type: START_CYCLE })
		updater(updaterId)(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const clearSaveQueue = () => (dispatch, getState) => {
	dispatch({ type: CLEAR_SAVE_QUEUE })
}

const restore = () => (dispatch, getState) => {
	let queue = getState().queue.restoreQueue
	let chunk = []
	queue.forEach(item => {
		if (item.type === QUEUE_ITEM_TYPE.COMMAND) {
			if (chunk.length > 0) {
				pushMultiple(chunk)(dispatch, getState)
			}
			let uiCommand = UI_COMMANDS[item.name]
			uiCommand()(dispatch, getState)
			chunk = []
		} else {
			chunk.push(item)
		}
	})
	if (chunk.length > 0) {
		pushMultiple(chunk)(dispatch, getState)
	}
	dispatch({ type: QUEUE_DID_RESTORE })
}

const updater = (delay = DELAY_LENGTH.SHORT) => (dispatch, getState) => {
	let updateQueue = getState().queue.updateQueue

	setTimeout(() => {
		let queueIsRestoring = getState().queue.isRestoring
		let first = null
		if (queueIsRestoring === false && updateQueue.length > 0) {
			first = updateQueue[0]
			if (first.type === QUEUE_ITEM_TYPE.MESSAGE) {
				push(
					first.component,
					first.component.position,
					first.id,
					first.idStudyObject,
				)(dispatch, getState)
			} else if (first.type !== QUEUE_ITEM_TYPE.TIMEOUT) {
				if (first.type === QUEUE_ITEM_TYPE.CONDITIONAL_ACTION) {
					let condition = first.conditionFunc(...first.conditionArgs)(dispatch, getState)
					if (condition === true) {
						first.action(...first.args)(dispatch, getState)
					} else {
						first.substituteAction(...first.substituteArgs)(dispatch, getState)
					}
				} else {
					first.action(...first.args)(dispatch, getState)
				}
				if (first.type === QUEUE_ITEM_TYPE.COMMAND && first.callback) {
					first.callback(...first.callbackArgs)(dispatch, getState)
				}
			}
			dispatch({ type: REMOVE, index: 0 })
		}

		if (queueIsRestoring === true) {
			restore()(dispatch, getState)
		}

		if (getState().queue.isRunning) {
			let nextDelay = first ? first.delay : null
			updater(nextDelay)(dispatch, getState)
		}
	}, delay)
}

export const addMessage = (component, position, delay = 0) => (dispatch, getState) => {
	component.position = position
	dispatch({
		type: ADD_PLUS_RESTORE,
		data: {
			type: QUEUE_ITEM_TYPE.MESSAGE,
			component,
			delay,
			id: component.id || uuidv4(),
			idStudyObject: getStudyObject()(dispatch, getState).id,
		},
	})
}

export const addTempMessage = (component, position, delay = 0) => (dispatch, getState) => {
	component.isTemporary = true
	component.position = position
	dispatch({
		type: ADD_NO_RESTORE,
		data: {
			type: QUEUE_ITEM_TYPE.MESSAGE,
			component,
			delay,
			id: component.id || uuidv4(),
			idStudyObject: getStudyObject()(dispatch, getState).id,
		},
	})
}

export const queueSetMediaToSeen = id => (dispatch, getState) => {
	dispatch({
		type: SAVE_QUEUE_SET_MEDIA_SEEN,
		id,
	})
}

export const queueSetHeatmapToSeen = id => (dispatch, getState) => {
	dispatch({
		type: SAVE_QUEUE_SET_HEATMAP_SEEN,
		id,
	})
}

export const addAction = (action, args = [], delay = 0) => (dispatch, getState) => {
	dispatch({
		type: ADD_NO_RESTORE,
		data: {
			type: QUEUE_ITEM_TYPE.ACTION,
			action,
			args,
			delay,
			idStudyObject: getStudyObject()(dispatch, getState).id,
		},
	})
}

export const addConditionalAction = (
	action,
	substituteAction,
	args = [],
	substituteArgs = [],
	conditionFunc,
	conditionArgs = [],
	delay = 0,
) => (dispatch, getState) => {
	dispatch({
		type: ADD_NO_RESTORE,
		data: {
			type: QUEUE_ITEM_TYPE.CONDITIONAL_ACTION,
			action,
			substituteAction,
			args,
			substituteArgs,
			conditionFunc,
			conditionArgs,
			delay,
			idStudyObject: getStudyObject()(dispatch, getState).id,
		},
	})
}

export const addCommand = (action, delay = 0, callback, callbackArgs = []) => (
	dispatch,
	getState,
) => {
	dispatch({
		type: ADD_PLUS_RESTORE,
		data: {
			type: QUEUE_ITEM_TYPE.COMMAND,
			action: action[action.name],
			args: [],
			delay,
			name: action.name,
			callback,
			callbackArgs,
			idStudyObject: getStudyObject()(dispatch, getState).id,
		},
	})
}

export const addTimeout = delay => (dispatch, getState) => {
	dispatch({
		type: ADD_NO_RESTORE,
		data: {
			type: QUEUE_ITEM_TYPE.TIMEOUT,
			delay,
			idStudyObject: getStudyObject()(dispatch, getState).id,
		},
	})
}

export const stopCycle = () => (dispatch, getState) => {
	dispatch({ type: END_CYCLE })
}

export const continueCycle = updaterId => (dispatch, getState) => {
	dispatch({ type: START_CYCLE })
	updater(updaterId)(dispatch, getState)
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_PLUS_RESTORE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.updateQueue = [...state.updateQueue]
		newState.updateQueue.push(action.data)
		newState.saveQueue = [...state.saveQueue]
		newState.saveQueue.push(action.data)
		return newState
	},
	[ADD_NO_RESTORE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.updateQueue = [...state.updateQueue]
		newState.updateQueue.push(action.data)
		return newState
	},
	// TODO: refactor adding/updating messages to save queue
	[SAVE_QUEUE_SET_MEDIA_SEEN]: (state, action) => {
		let newState = Object.assign({}, state)
		let messageToChange = state.saveQueue.find(e => e.id === action.id)
		if (messageToChange) {
			let itemIndex = state.saveQueue.indexOf(messageToChange)
			if (
				itemIndex > -1 &&
				newState.saveQueue[itemIndex].component &&
				newState.saveQueue[itemIndex].component.props.media
			) {
				newState.saveQueue = [...state.saveQueue]
				newState.saveQueue[itemIndex] = Object.assign({}, state.saveQueue[itemIndex])
				newState.saveQueue[itemIndex].component = Object.assign(
					{},
					state.saveQueue[itemIndex].component,
				)
				newState.saveQueue[itemIndex].component.props = Object.assign(
					{},
					state.saveQueue[itemIndex].component.props,
				)
				newState.saveQueue[itemIndex].component.props.media = Object.assign(
					{},
					state.saveQueue[itemIndex].component.props.media,
				)
				newState.saveQueue[itemIndex].component.props.media.seen = true
			}
		}
		return newState
	},
	[SAVE_QUEUE_SET_HEATMAP_SEEN]: (state, action) => {
		const newState = _.cloneDeep(state)
		const messageToChange = newState.saveQueue.find(e => e.id === action.id)
		if (messageToChange) {
			const itemIndex = newState.saveQueue.indexOf(messageToChange)
			if (
				itemIndex > -1 &&
				newState.saveQueue[itemIndex].component &&
				newState.saveQueue[itemIndex].component.props.media
			) {
				newState.saveQueue[itemIndex].component.isHeatmapSeen = true
				newState.saveQueue[itemIndex].component.props.media.seen = true
			}
		}
		return newState
	},
	[REMOVE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.updateQueue = [...state.updateQueue]
		newState.updateQueue.splice(action.index, 1)
		return newState
	},
	[START_CYCLE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isRunning = true
		return newState
	},
	[END_CYCLE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isRunning = false
		return newState
	},
	[CLEAR_SAVE_QUEUE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.saveQueue = initialState.saveQueue
		return newState
	},
	[QUEUE_DID_RESTORE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.restoreQueue = initialState.restoreQueue
		newState.isRestoring = false

		return newState
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.history !== null) {
			let newState = Object.assign({}, state)
			newState.restoreQueue = action.response.history
			newState.isRestoring = true

			return newState
		}
		return state
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function intlReducer(state = initialState, action) {
	let actionHandler = action.type
	const handler = ACTION_HANDLERS[actionHandler]

	return handler ? handler(state, action) : state
}
