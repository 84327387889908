import { API, apiActions } from 'store/api'
import { errorHandler } from 'store/logger'
import { autoLogin } from 'store/_service/login'
import { browserHistory } from 'react-router'
import { loadTranslations } from 'store/intl'
import { setGenericError } from 'store/_service/error'
import moment from 'moment'

// ------------------------------------
// Constants
// ------------------------------------
export const TIMER_TICK = 'waitingRoom.timer.tick'
export const ERROR_TIMER_COUNTER = 'waitingRoom.error.timer.tick'
export const ALLOW_ENTRY = 'waitingRoom.allow.entry'
export const RESET = 'waitingRoom.reset'
export const SET_ERROR = 'waitingRoom.set.error'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	remainingTime: null,
	endTime: null,
	timerId: 0,
	errorTimerCounter: 0,
	isEntryAllowed: false,
	errorMessage: '',
	errorStatus: null,
}

// ------------------------------------
// Helpers
// ------------------------------------
const getTimeout = errorTimerCounter => {
	let timeout = 200 * errorTimerCounter
	return timeout > 300 ? 300 : timeout
}

// ------------------------------------
// Actions
// ------------------------------------
export const allowEntry = () => ({
	type: ALLOW_ENTRY,
})

export const reset = () => (dispatch, getState) => {
	dispatch({
		type: RESET,
	})
}

export const timerTick = timerId => (dispatch, getState) => {
	let state = getState()
	let remainingTime = state.waitingRoom.remainingTime > 0 ? state.waitingRoom.remainingTime : 0
	let currentTimerId = state.waitingRoom.timerId

	// compare timerId sent through callstack with timerId from state so we dont run 2 countdowns at once
	if (remainingTime >= 0 && timerId === currentTimerId) {
		dispatch({ type: TIMER_TICK, timerId })

		window.setTimeout(() => {
			// resend timer id within timeout so we can distinguis between 2 countdowns
			timerTick(timerId)(dispatch, getState)
		}, 200)
	}
}

export const requestStudyEntry = (idStudy, query) => (dispatch, getState) => {
	return apiActions
		.requestStudyEntry(idStudy, query)(dispatch, getState)
		.then(apiResponse => {
			let responseStatus = apiResponse.status
			let responseData = apiResponse.response

			if (responseStatus === 200) {
				loadTranslations(responseData.language, responseData.custom.translations)(
					dispatch,
					getState,
				)
			}

			if (responseStatus === 200 && responseData && responseData.entryStatus === 'wait') {
				let timerId = getState().waitingRoom.timerId
				timerTick(timerId)(dispatch, getState)
				window.setTimeout(() => {
					requestStudyEntry(idStudy)(dispatch, getState) // eslint-disable-line no-use-before-define
				}, responseData.waitingTime * 1000)
			} else if (responseStatus === 200 && responseData && responseData.entryStatus === 'allowed') {
				// in case of allowed entry allow access and redirect to login
				dispatch(allowEntry())

				// if autoLogin is allowed then let login system handle autologin, otherwise redirect to login page
				let state = getState()
				if (state.study.autoLogin === true) {
					autoLogin()(dispatch, getState)
				} else {
					browserHistory.replace('/login/' + state.user.idStudy + state.user.search)
				}
			} else if (
				responseStatus === 410 || // Undefined solver for a study
				responseStatus === 500 || // Unexpected error
				responseStatus === 404
			) {
				// error within waiting room, start with 20s wait interval and wait *0.5 longer with each try
				let timerId = getState().waitingRoom.timerId
				let errorTimerCounter = getState().waitingRoom.errorTimerCounter

				dispatch({ type: ERROR_TIMER_COUNTER })
				dispatch({
					type: SET_ERROR,
					errorMessage: 'waiting_error_recoverable',
					errorStatus: responseStatus,
				})
				// log error and stay
				errorHandler(new Error('Unexpected response from waiting room'), apiResponse)(
					dispatch,
					getState,
				)

				timerTick(timerId)(dispatch, getState)
				window.setTimeout(
					() => {
						requestStudyEntry(idStudy)(dispatch, getState) // eslint-disable-line no-use-before-define
					},
					getTimeout(errorTimerCounter) * 1000, // increase waiting time every time suqsequent error is received
				)
			} else if (responseStatus === 411 || responseStatus === 412) {
				// unknown study
				dispatch({
					type: SET_ERROR,
					errorMessage: 'waiting_error_unknown_study',
					errorStatus: responseStatus,
				})
			} else {
				dispatch({
					type: SET_ERROR,
					errorMessage: 'waiting_error_dead',
					errorStatus: responseStatus,
				})
				setGenericError(
					true,
					apiResponse.response.status,
					apiResponse.response.errorTrace,
					apiResponse.response.errorId,
					apiResponse.response.errorMessage,
				)(dispatch, getState)
				errorHandler(
					new Error(`Error in requestStudyEntry() method.\n Id study: ${idStudy}\n`),
					apiResponse,
				)(dispatch, getState)
			}

			return apiResponse
		})
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[RESET]: (state, action) => {
		return initialState
	},
	[API.REQUEST_STUDY_ENTRY.SUCCESS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.errorTimerCounter = 0
		newState.timerId++
		newState.endTime = moment.utc().add(action.response.waitingTime, 'seconds')

		return newState
	},
	[API.REQUEST_STUDY_ENTRY.ERROR]: (state, action) => {
		let newState = Object.assign({}, initialState)
		newState.errorTimerCounter = state.errorTimerCounter + 1
		newState.timerId++
		newState.remainingTime = getTimeout(newState.errorTimerCounter)
		newState.endTime = moment.utc().add(newState.remainingTime, 'seconds')

		return newState
	},
	[TIMER_TICK]: (state, action) => {
		if (action.timerId === state.timerId) {
			let newState = Object.assign({}, state)
			newState.remainingTime = state.endTime.diff(moment.utc(), 'seconds')

			return newState
		}
		return state
	},
	[ERROR_TIMER_COUNTER]: (state, action) => {
		if (action.timerId === state.timerId) {
			let newState = Object.assign({}, state)
			newState.errorTimerCounter = state.errorTimerCounter + 1

			return newState
		}
		return state
	},
	[ALLOW_ENTRY]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isEntryAllowed = true

		return newState
	},
	[SET_ERROR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.errorMessage = action.errorMessage
		newState.errorStatus = action.errorStatus

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
