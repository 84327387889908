import React from 'react'
import PropTypes from 'prop-types'
import './ActionBar.scss'
import ActionButton from 'components/ActionButton'
import ActionSelect from 'components/ActionSelect'
import ActionTextArea from 'components/ActionTextArea'
import AnimateHeight from 'react-animate-height'
import classnames from 'classnames'

import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import { ACTION_BAR_TYPE } from 'constants/actionBar'
import { INPUT_TYPE } from 'constants/input'
import { DELAY_LENGTH } from 'constants/delay'

class ActionBar extends React.Component {
	constructor(props) {
		super(props)
		this.handleUpdate = this.handleUpdate.bind(this)
		this.state = {}
		this.state = this.createState(props)
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.input.isVisible !== this.state.lastVisibility) {
			this.setState(this.createState(nextProps))
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.textarea || this.input) {
			this.handleUpdate()
		}
	}

	createState(props) {
		let newState = Object.assign({}, this.state)
		newState.visible = false
		newState.height = 0
		newState.lastVisibility = props.input.isVisible
		return newState
	}

	handleUpdate() {
		if (!this.state.visible) {
			let newState = Object.assign({}, this.state)
			newState.visible = true
			if (this.props.input.isVisible) {
				newState.height = this.props.input.inputType === INPUT_TYPE.TEXTAREA ? 80 : 50
			}
			this.setState(newState)
		}
	}

	render() {
		let isButtonShown = this.props.input.inputType !== INPUT_TYPE.CHOICE || screen.width > 350
		const isReportContentButtonShown = this.props.isReportContentButtonShown

		const hideContentClassName = this.props.showContent ? '' : ' hidden'

		const holderStyle =
			this.props.isStudyRtl === true
				? { paddingLeft: `${this.props.rightOffset}px` }
				: { paddingRight: `${this.props.rightOffset}px` }

		const { isAccessibilityEnabled, isFullscreen, accessibilityHint } = this.props
		const accessibilityHintText =
			accessibilityHint.isPublished === false ? '' : accessibilityHint.message

		const shouldShowHint = isAccessibilityEnabled === true && isFullscreen === false

		return (
			<div className="ActionBar">
				{shouldShowHint === true && (
					<div className="ActionBar-accessibility-hint" role="status">
						{accessibilityHintText}
					</div>
				)}
				<div className="ActionBar-holder" style={holderStyle}>
					{this.props.input.isEnabled && this.props.input.inputType !== INPUT_TYPE.CHOICE && (
						<AnimateHeight
							ref={input => {
								this.input = input
							}}
							duration={DELAY_LENGTH.LONG}
							height={this.state.height}
							contentClassName={'ActionBar-wrapper-textarea' + hideContentClassName}
							className="ActionBar-wrapper-animate"
						>
							<ActionTextArea
								value={this.props.input.value}
								placeholder={this.props.input.placeholder}
								onSubmit={this.props.onPrimarySubmit}
								onChange={this.props.onInputChange}
								isDelayed={this.props.input.isDelayed}
								isEnabled={this.props.input.isEnabled}
								isVisible={this.props.input.isVisible}
								isKeyNavigationDisabled={this.props.input.isKeyNavigationDisabled}
								chatViewScroll={this.props.chatViewScroll}
								maxLength={this.props.input.maxLength}
								isValid={this.props.input.isValid}
								hint={this.props.input.hint}
								isValidatorVisible={this.props.input.isValidatorVisible}
								hasSingleRow={this.props.input.inputType === INPUT_TYPE.INPUT}
							/>
						</AnimateHeight>
					)}
					{this.props.isAccessibilityEnabled === false &&
						this.props.input.isEnabled &&
						this.props.input.inputType === INPUT_TYPE.CHOICE && (
							<AnimateHeight
								ref={input => {
									this.input = input
								}}
								duration={DELAY_LENGTH.LONG}
								height={this.state.height}
								contentClassName={'ActionBar-wrapper-input' + hideContentClassName}
								className="ActionBar-wrapper-animate"
							>
								<ActionSelect
									type={this.props.input.inputType}
									value={this.props.input.value}
									options={this.props.input.options}
									placeholder={this.props.input.placeholder}
									onSubmit={this.props.onPrimarySubmit}
									onChange={this.props.onInputChange}
									toggleOption={this.props.toggleOption}
									suggestionsShow={this.props.suggestionsShow}
									suggestionsHide={this.props.suggestionsHide}
								/>
							</AnimateHeight>
						)}
					{this.props.isAccessibilityEnabled === true &&
						this.props.input.isEnabled &&
						this.props.input.inputType === INPUT_TYPE.CHOICE && (
							<ActionSelect
								type={this.props.input.inputType}
								value={this.props.input.value}
								options={this.props.input.options}
								placeholder={this.props.input.placeholder}
								onSubmit={this.props.onPrimarySubmit}
								onChange={this.props.onInputChange}
								toggleOption={this.props.toggleOption}
								suggestionsShow={this.props.suggestionsShow}
								suggestionsHide={this.props.suggestionsHide}
							/>
						)}
					{isButtonShown && (
						<div className={'ActionBar-wrapper-button' + hideContentClassName}>
							<ActionButton
								type={this.props.actionType}
								actionState={this.props.actionState}
								loaderType={this.props.loaderType}
								percent={this.props.loadPercent}
								transitionTime={this.props.loadTime}
								hintText={this.props.hintText}
								isUppercase={this.props.actionType === ACTION_BAR_TYPE.BUTTON}
								buttonText={this.props.buttonText}
								onPrimarySubmit={
									this.props.isFullscreen
										? this.props.fullscreenOnPrimarySubmit
										: this.props.onPrimarySubmit
								}
								onSecondarySubmit={
									this.props.isFullscreen
										? this.props.fullscreenOnSecondarySubmit
										: this.props.onSecondarySubmit
								}
							/>
						</div>
					)}
					{isReportContentButtonShown && (
						<div
							className={classnames('ActionBar-report-button-wrapper', 'icon-flag', {
								'ActionBar-report-button-wrapper--ltr': this.props.isStudyRtl === false,
								'ActionBar-report-button-wrapper--rtl': this.props.isStudyRtl === true,
							})}
							onClick={this.props.reportContent}
						/>
					)}
				</div>
			</div>
		)
	}
}

ActionBar.propTypes = {
	input: PropTypes.oneOfType([
		PropTypes.shape({
			isEnabled: PropTypes.bool.isRequired,
		}),
		PropTypes.shape({
			inputType: PropTypes.oneOf(Object.values(INPUT_TYPE)).isRequired,
			isVisible: PropTypes.bool.isRequired,
			placeholder: PropTypes.string.isRequired,
			maxLength: PropTypes.number,
			value: PropTypes.string.isRequired,
			hint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			options: PropTypes.array.isRequired,
			isValidatorVisible: PropTypes.bool.isRequired,
			isValid: PropTypes.bool.isRequired,
			isEnabled: PropTypes.bool.isRequired,
			isDelayed: PropTypes.bool.isRequired,
			isKeyNavigationDisabled: PropTypes.bool.isRequired,
		}),
	]).isRequired,
	onInputChange: PropTypes.func.isRequired,
	chatViewScroll: PropTypes.func,
	isStudyRtl: PropTypes.bool.isRequired,

	actionType: PropTypes.oneOf(Object.values(ACTION_BAR_TYPE)).isRequired,
	hintText: PropTypes.string.isRequired,
	actionState: PropTypes.oneOf(Object.values(ACTION_BUTTON_STATE)),

	buttonText: PropTypes.string.isRequired,
	onPrimarySubmit: PropTypes.func.isRequired,
	onSecondarySubmit: PropTypes.func.isRequired,

	accessibilityHint: PropTypes.shape({
		textArray: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
		isPublished: PropTypes.bool.isRequired,
		message: PropTypes.string.isRequired,
	}).isRequired,
	isAccessibilityEnabled: PropTypes.bool.isRequired,
	isReportContentButtonShown: PropTypes.bool.isRequired,
	reportContent: PropTypes.func.isRequired,

	loaderType: PropTypes.oneOf(Object.values(ACTION_BUTTON_LOADER_TYPE)),
	loadPercent: PropTypes.number,
	loadTime: PropTypes.number,

	toggleOption: PropTypes.func,

	showContent: PropTypes.bool.isRequired,

	suggestionsShow: PropTypes.func.isRequired,
	suggestionsHide: PropTypes.func.isRequired,

	isFullscreen: PropTypes.bool.isRequired,
	fullscreenOnPrimarySubmit: PropTypes.func,
	fullscreenOnSecondarySubmit: PropTypes.func,

	rightOffset: PropTypes.number.isRequired,
}

export default ActionBar
