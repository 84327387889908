import React from 'react'
import PropTypes from 'prop-types'

import './SelectInput.scss'

class SelectInput extends React.Component {
	constructor(props) {
		super(props)
		this.handleOnFocus = this.handleOnFocus.bind(this)
		this.handleOnBlur = this.handleOnBlur.bind(this)
	}

	handleOnFocus(event) {
		this.props.suggestionsShow()
	}

	handleOnBlur(event) {
		this.props.suggestionsHide()
	}

	render() {
		return (
			<div
				ref={wrapper => {
					this.wrapper = wrapper
				}}
				className="SelectInput-wrapper"
			>
				<input
					ref={input => {
						this.input = input
					}}
					className={'SelectInput-input'}
					onKeyUp={this.props.handleInputOnKey}
					placeholder={this.props.placeholder || ''}
					onFocus={this.handleOnFocus}
					onBlur={this.handleOnBlur}
				/>
			</div>
		)
	}
}

SelectInput.propTypes = {
	placeholder: PropTypes.string,
	handleInputOnKey: PropTypes.func.isRequired,
	suggestionsShow: PropTypes.func.isRequired,
	suggestionsHide: PropTypes.func.isRequired,
}

export default SelectInput
