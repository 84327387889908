import moment from 'moment'
import _ from 'lodash'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { MEDIA_TYPE } from 'constants/media'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { CHOICE_QUESTION_LOG_ACTIONS } from 'constants/choiceQuestionLog'

import { getStudyObject, getIsAccessibilityEnabled } from 'selectors/study'
import { showFullscreen, hideFullscreen } from 'store/_chat/view'
import {
	setMedia,
	showActionButton as fullscreenShowActionButton,
	setActionSubmitListener as fullscreenSetActionSubmitListener,
} from 'store/_chat/fullscreenView'

import { addAccessibilityHintText } from 'store/_chat/accessibilityHint'
import { formatMessage } from 'store/intl'

// ------------------------------------
// Constants
// ------------------------------------
export const ACTIONS_RESET = 'actions.reset'
export const ACTIONS_SHOW = 'actions.show'
export const ACTIONS_HIDE = 'actions.hide'
export const ACTIONS_TOGGLE_OPTION = 'actions.select.option'
export const ACTIONS_REPORT_OPTION = 'actions.report.option'
export const ACTIONS_SET_OPTIONS_CHANGE_LISTENER = 'actions.set.options.change.listener'
export const ACTIONS_SET_RECORD_LOG_ITEM = 'actions.set.record.log.item'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	options: [],
	optionsChangeListener: null,
	actionType: CHOICE_SUBTYPE.CHECKBOX,
	isVisible: false,
	multiple: false,
	recordLogItem: null,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => {
	dispatch({ type: ACTIONS_RESET })
}

export const actionsShow = (actionType, options, multiple = false) => (dispatch, getState) => {
	const flaggedContent = getState().chatReportContentForm.flagged
	dispatch({ type: ACTIONS_SHOW, actionType, options, multiple, flaggedContent })
}

export const actionsHide = () => (dispatch, getState) => {
	dispatch({ type: ACTIONS_HIDE })
}

export const toggleOption = (idOption, state) => (dispatch, getState) => {
	const clickedOption = getState().chatActions.options.find(option => option.id === idOption)
	const studyObject = getStudyObject()(dispatch, getState)

	const choiceOptionAction =
		clickedOption.selected === true
			? CHOICE_QUESTION_LOG_ACTIONS.OPTION_DESELECTED
			: CHOICE_QUESTION_LOG_ACTIONS.OPTION_SELECTED

	const recordLogItem = getState().chatActions.recordLogItem

	if (recordLogItem !== null) {
		recordLogItem(
			moment().toISOString(),
			studyObject.id,
			studyObject.type,
			choiceOptionAction,
			idOption,
		)(dispatch, getState)
	}

	const updatedOptions = _.cloneDeep(getState().chatActions.options)
	const alreadySelectedOptions = updatedOptions.filter(o => o.selected === true)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	const isMultiple = studyObject.definition.multiple || false

	if (
		clickedOption.selected === false &&
		clickedOption.isNoneOfThese === false &&
		isMultiple === true &&
		alreadySelectedOptions.length >= studyObject.definition.maxSelection
	) {
		return
	}

	if (
		clickedOption.selected === false &&
		(isMultiple === false || clickedOption.isNoneOfThese === true)
	) {
		updatedOptions.forEach(option => {
			if (option.id !== idOption && option.selected === true) {
				if (isAccessibilityEnabled === true) {
					addAccessibilityHintText(
						formatMessage('option_deselected', { label: option.label })(dispatch, getState),
					)(dispatch, getState)
				}

				option.selected = false
			}
		})
	}

	if (clickedOption.selected === false && clickedOption.isNoneOfThese === false) {
		updatedOptions.forEach(option => {
			if (option.isNoneOfThese === true && option.selected === true) {
				if (isAccessibilityEnabled === true) {
					addAccessibilityHintText(
						formatMessage('option_deselected', { label: option.label })(dispatch, getState),
					)(dispatch, getState)
				}

				option.selected = false
			}
		})
	}

	if (isAccessibilityEnabled === true) {
		if (clickedOption.selected === false) {
			addAccessibilityHintText(
				formatMessage('option_selected', { label: clickedOption.label })(dispatch, getState),
			)(dispatch, getState)
		} else {
			addAccessibilityHintText(
				formatMessage('option_deselected', { label: clickedOption.label })(dispatch, getState),
			)(dispatch, getState)
		}
	}

	const optionToChange = updatedOptions.find(o => o.id === idOption)
	optionToChange.selected = state || optionToChange.selected === false

	dispatch({
		type: ACTIONS_TOGGLE_OPTION,
		updatedOptions,
	})

	let newState = getState()
	let changeListener = newState.chatActions.optionsChangeListener
	if (changeListener) {
		changeListener(updatedOptions)(dispatch, getState)
	}
}

export const reportOption = idOption => (dispatch, getState) => {
	dispatch({ type: ACTIONS_REPORT_OPTION, idOption })
}

export const setOptionsChangeListener = optionsChangeListener => (dispatch, getState) => {
	dispatch({
		type: ACTIONS_SET_OPTIONS_CHANGE_LISTENER,
		optionsChangeListener,
	})
}

export const setRecordLogItem = recordLogItem => (dispatch, getState) => {
	dispatch({
		type: ACTIONS_SET_RECORD_LOG_ITEM,
		recordLogItem,
	})
}

export const closeFullScreen = () => (dispatch, getState) => {
	hideFullscreen()(dispatch, getState)
}

export const openFullscreenImageOption = media => (dispatch, getState) => {
	const imageFullscreenZoom = media && media.render ? media.render.imageFullscreenZoom : null
	setMedia(
		'',
		MEDIA_TYPE.IMAGE,
		media.url,
		media.alt,
		false,
		false,
		imageFullscreenZoom,
	)(dispatch, getState)
	fullscreenShowActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, 'Close')(dispatch, getState)
	fullscreenSetActionSubmitListener(closeFullScreen)(dispatch, getState)
	showFullscreen()(dispatch, getState)
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ACTIONS_RESET]: (state, action) => {
		return { ...initialState }
	},
	[ACTIONS_SHOW]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isVisible = true
		newState.actionType = action.actionType
		newState.options = state.options.slice()
		action.options.forEach((option, i) => {
			newState.options[i] = Object.assign({}, option)
			const isReported = Boolean(
				action.flaggedContent.find(flagged => flagged.idStatement === newState.options[i].id),
			)
			newState.options[i].reported = isReported
		})
		newState.multiple = action.multiple

		return newState
	},
	[ACTIONS_HIDE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isVisible = false

		return newState
	},
	[ACTIONS_TOGGLE_OPTION]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.options = action.updatedOptions

		return newState
	},
	[ACTIONS_REPORT_OPTION]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.options = state.options.slice()
		const reportedOption = newState.options.find(option => option.id === action.idOption)
		if (reportedOption) {
			reportedOption.reported = true
		}

		return newState
	},
	[ACTIONS_SET_OPTIONS_CHANGE_LISTENER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.optionsChangeListener = action.optionsChangeListener

		return newState
	},
	[ACTIONS_SET_RECORD_LOG_ITEM]: (state, action) => {
		return {
			...state,
			recordLogItem: action.recordLogItem,
		}
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters
// ------------------------------------
export const getActionOptions = () => (dispatch, getState) => {
	let state = getState()
	return state.chatActions.options
}
