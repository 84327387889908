import React from 'react'
import PropTypes from 'prop-types'
import './SelectedOption.scss'
import optionShape from 'shapes/option'
import { StyleSheet, css } from 'aphrodite/no-important'
import { renderInlineLabel } from 'store/tools/MarkdownText'
import classnames from 'classnames'
class SelectedOption extends React.Component {
	constructor(props) {
		super(props)
		this.handleOptionOnClick = this.handleOptionOnClick.bind(this)
		this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
	}

	handleOptionOnClick(event) {
		let id = event.currentTarget.getAttribute('data-id')
		this.props.removeOption(id)
		this.props.focusInput(event)
	}

	handleOnMouseDown(event) {
		event.preventDefault()
	}

	render() {
		const styles = StyleSheet.create({
			selectedOptionOption: {
				color: this.props.theme.primaryColor,
				borderColor: this.props.theme.primaryColor,
			},
		})

		let selectedOptionOptionCss = classnames(
			css(styles.selectedOptionOption),
			'SelectedOption-option',
		)
		return (
			<div className={selectedOptionOptionCss}>
				<div
					className={classnames('SelectedOption-option-icon', 'icon-cross', {
						'SelectedOption-option-icon--ltr': this.props.isStudyRtl === false,
						'SelectedOption-option-icon__rtl': this.props.isStudyRtl === true,
					})}
					data-id={this.props.option.id}
					onClick={this.handleOptionOnClick}
					onMouseDown={this.handleOnMouseDown}
					onTouchEnd={this.handleOptionOnClick}
				/>
				<div
					className="SelectedOption-option-text"
					dangerouslySetInnerHTML={{ __html: renderInlineLabel(this.props.option.label) }}
				/>
			</div>
		)
	}
}

SelectedOption.propTypes = {
	option: optionShape,
	removeOption: PropTypes.func.isRequired,
	focusInput: PropTypes.func.isRequired,
	theme: PropTypes.object,
	isStudyRtl: PropTypes.bool.isRequired,
}

export default SelectedOption
