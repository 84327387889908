import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ChatLayout from './ChatLayout'
import { setRigthOffset } from 'store/_chat/actionBar'
import { apiActions } from 'store/api'
import { getIsAccessibilityEnabled } from 'selectors/study'

const mapActionCreators = {
	setRigthOffset,
	setUserStateActive: apiActions.setUserStateActive,
	setUserStateInactive: apiActions.setUserStateInactive,
}

const mapStateToProps = state => ({
	showCinemaView: state.chatView.showCinemaView,
	showFullScreen: state.chatView.showFullScreen,
	showSuggestions: state.chatSuggestions.isVisible,
	showHeatmap: state.chatHeatmap.isVisible,
	isAccessibilityEnabled: getIsAccessibilityEnabled(state),
	isInputVisisble: state.chatInput.isVisible,
	inputType: state.chatInput.inputType,
	actionBarIsVisible: state.chatActionBar.isVisible,
	actionBarInputType: state.chatInput.inputType,
	showExtendedActionBar: state.chatInput.isVisible,
	showReportContentForm: state.chatReportContentForm.isVisible,
	userState: state.api.userState,
})

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ChatLayout))
