import { v4 as uuidv4 } from 'uuid'
import { API } from 'store/api'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_USER_INFO = 'api.set.user.info' // SET_USER_INFO is duplicated in 'store/api'
export const SET_PCID = 'api.set.pcid'
export const STORE_PCID = 'user.store.pcid'
export const STORE_LAST_STUDY = 'user.store.last.study'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	idStudy: null,
	query: null,
	search: null,
	pcid: null,
	isLoggedIn: false,
}

// ------------------------------------
// Actions
// ------------------------------------
export const setUserInfo = (idStudy, search, query, token) => (dispatch, getState) => {
	let userInfo = { idStudy, search, query, token }
	dispatch({ type: SET_USER_INFO, userInfo })
}

export const setPcid = pcid => (dispatch, getState) => {
	dispatch({ type: SET_PCID, pcid })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[API.LOGIN.SUCCESS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isLoggedIn = true
		storeStudy(newState.idStudy, newState.search, newState.query, action.response.token) // eslint-disable-line no-use-before-define

		return newState
	},
	[SET_USER_INFO]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.idStudy = action.userInfo.idStudy
		newState.query = action.userInfo.query
		newState.search = action.userInfo.search
		storeStudy(newState.idStudy, newState.search, newState.query, action.userInfo.token) // eslint-disable-line no-use-before-define
		if (action.userInfo.token !== null) {
			newState.isLoggedIn = true
		}

		return newState
	},
	[SET_PCID]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.pcid = action.pcid

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters & Stores
// ------------------------------------
export const storePcid = pcid => {
	try {
		window.localStorage.setItem(STORE_PCID, pcid)
		return pcid
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('UserStore', 'Unable to store pcid', pcid)
		return null
	}
}

export const getStoredPcid = () => {
	let pcid
	try {
		pcid = window.localStorage.getItem(STORE_PCID)

		if (pcid === null) {
			// generate new pcid if its not stored already
			let newPcid = uuidv4()
			pcid = storePcid(newPcid)
		}
		pcid = pcid.toLowerCase()
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('UserStore', 'Unable to retrieve pcid')
	}

	return pcid || null
}

export const storeStudy = (idStudy, search, query, token = null) => {
	let identifier = idStudy + search
	let data = { idStudy, search, query, token }

	try {
		window.localStorage.setItem(identifier, JSON.stringify(data))
		window.localStorage.setItem(STORE_LAST_STUDY, identifier)
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('UserStore', 'Unable to store studyInfo', identifier, data)
	}
}

export const getStoredStudy = (idStudy = null, search = '') => {
	let identifier = idStudy + search
	let studyInfo

	try {
		// retrieve last stored study if no info is provided
		if (idStudy === null && search === '') {
			identifier = window.localStorage.getItem(STORE_LAST_STUDY)
		}

		studyInfo = JSON.parse(window.localStorage.getItem(identifier))
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('UserStore', 'Unable to retrieve study info', identifier)
	}

	return studyInfo || { idStudy: null, query: null, token: null }
}
