import { API, apiActions } from 'store/api'

export const SET_INIT = 'platform.status.init'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	isSolverOnline: true,
}

export const getPlatformStatus = () => (dispatch, getState) => {
	return apiActions.getPlatformStatus()(dispatch, getState)
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[SET_INIT]: (state, action) => {
		return initialState
	},
	[API.GET_PLATFORM_STATUS.SUCCESS]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.isSolverOnline = action.response.isSolverOnline

		if (state.isSolverOnline === false && newState.isSolverOnline === true) {
			window.location.reload(true)
		}

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
