import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { heatmapAddAnswer, heatmapResetAnswers, heatmapSubmit } from 'store/_chat/heatmap'

import Heatmap from './Heatmap'

const mapStateToProps = state => ({
	answers: state.chatHeatmap.answers,
	definition: state.chatHeatmap.definition,
	isVisible: state.chatHeatmap.isVisible,
})

const mapActionCreators = {
	heatmapAddAnswer,
	heatmapResetAnswers,
	heatmapSubmit,
}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(Heatmap))
