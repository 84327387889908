import ReactGA from 'react-ga4'

import { getIsE2ETesting } from 'helpers/getIsE2ETesting'

import appConfig from 'config.js'

const getShouldTrack = () => {
	return getIsE2ETesting() === false
}

if (getShouldTrack() === true) {
	ReactGA.initialize(appConfig.analyticsKey)
}

/**
 * @param {object} data data to set
 */
export const set = data => {
	if (getShouldTrack() === false) {
		return
	}

	ReactGA.set(data)
}

/**
 * @param {object} data event data
 */
export const event = data => {
	if (getShouldTrack() === false) {
		return
	}

	ReactGA.event(data)
}

/**
 * @param {string} page url of the page
 * @param {object} [rest={}] any other info
 */
export const pageview = (page, rest = {}) => {
	if (getShouldTrack() === false) {
		return
	}

	ReactGA.send({ hitType: 'pageview', page, title: page, ...rest })
}
