import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'

import config from 'config'

import './StudyLogo.scss'

const StudyLogo = props => {
	let alt = props.alt || props.intl.formatMessage({ id: 'groupsolver_logo_alt' })

	return props.customLogo ? (
		<div className={classnames('StudyLogo', 'custom-logo')}>
			<img src={props.customLogo} alt={props.intl.formatMessage({ id: 'study_logo_alt' })} />
		</div>
	) : (
		<img
			className={classnames('StudyLogo', 'StudyLogo--default', {
				'dark-theme': props.theme === 'dark',
			})}
			src={config.clientConfig.logoPath}
			aria-label={alt}
		/>
	)
}

StudyLogo.propTypes = {
	customLogo: PropTypes.string,
	theme: PropTypes.oneOf(['dark', 'blue']),
	alt: PropTypes.string,
	intl: PropTypes.object.isRequired,
}

export default injectIntl(StudyLogo)
