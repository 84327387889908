import { Remarkable } from 'remarkable'

let md = new Remarkable({
	html: false,
	breaks: true,
	linkTarget: '_blank',
	typographer: true,
})

let mdBlur = new Remarkable({
	html: false,
	breaks: true,
	linkTarget: '_blank',
	typographer: true,
})

let mdImageChoiceLabel = new Remarkable({
	html: false,
	breaks: false,
	linkTarget: '_blank',
	typographer: true,
})

let mdBlockChoiceLabel = new Remarkable({
	html: false,
	breaks: true,
	linkTarget: '_blank',
	typographer: true,
})

let mdChoiceLabel = new Remarkable({
	html: false,
	breaks: false,
	linkTarget: '_blank',
	typographer: true,
})

// disable all rules except supported
let inlineRulerDisable = [
	'text',
	// 'newline', // supported
	'escape',
	'backticks',
	// 'del', // supported
	// 'ins', // supported
	// 'mark', // supported
	// 'emphasis', // supported
	// 'sub', // supported
	// 'sup', // supported
	// 'links',	// supported
	'footnote_inline',
	'footnote_ref',
	'autolink',
	'htmltag',
	'entity',
]

// enable all supported rules which are disabled in default settings
let inlineRulerEnable = [
	'sub', // supported
	'sup', // supported
	'mark', // supported
	'ins', // supported
	'emphasis', // supported
]

// disable all rules except supported
let blockRulerDisable = [
	'code',
	'fences',
	'blockquote',
	'hr',
	// 'list', // supported
	'footnote',
	// 'heading', // supported
	'lheading',
	'htmlblock',
	'table',
	'deflist',
	// 'paragraph', // breaking everything
]

let coreRulerDisable = [
	'footnote_tail',
	'abbr2',
	'abbr',
	'references',
	// 'block', // breaking everything
	// 'inline', // supported
]

let inlineRulerDisableBlur = [...inlineRulerDisable, 'mark']
let inlineRulerEnableBlur = [
	'sub', // supported
	'sup', // supported
	'ins', // supported
	'emphasis', // supported
]

let inlineRulerEnableChoiceLabel = inlineRulerEnableBlur

let inlineRulerDisableLabel = [...inlineRulerDisable, 'newline', 'links', 'mark']
let blockRulerDisableLabel = [...blockRulerDisable, 'list', 'heading']

// apply rules
md.inline.ruler.disable(inlineRulerDisable)
md.block.ruler.disable(blockRulerDisable)
md.inline.ruler.enable(inlineRulerEnable)
md.core.ruler.disable(coreRulerDisable)

// apply rules on blurBefore
mdBlur.inline.ruler.disable(inlineRulerDisableBlur)
mdBlur.block.ruler.disable(blockRulerDisable)
mdBlur.inline.ruler.enable(inlineRulerEnableBlur)
mdBlur.core.ruler.disable(coreRulerDisable)

// apply rules on Image Choice label
mdImageChoiceLabel.inline.ruler.disable(inlineRulerDisableLabel)
mdImageChoiceLabel.block.ruler.disable(blockRulerDisableLabel)
mdImageChoiceLabel.inline.ruler.enable(inlineRulerEnableChoiceLabel)
mdImageChoiceLabel.core.ruler.disable(coreRulerDisable)

// apply rules on experimental Image Choice label
mdBlockChoiceLabel.inline.ruler.enable(inlineRulerEnable)
mdBlockChoiceLabel.block.ruler.disable([...blockRulerDisable])
mdBlockChoiceLabel.core.ruler.disable(coreRulerDisable)

// apply rules on Choice label
mdChoiceLabel.inline.ruler.disable(inlineRulerDisableLabel)
mdChoiceLabel.block.ruler.disable(blockRulerDisableLabel)
mdChoiceLabel.inline.ruler.enable(inlineRulerEnableChoiceLabel)
mdChoiceLabel.core.ruler.disable(coreRulerDisable)

/* Return plain text on error */
const aHrefRegex = new RegExp('<a href=', 'g')
const tryRender = (text, remarkableInstance, func) => {
	try {
		return remarkableInstance[func](text).replace(aHrefRegex, '<a rel="noopener noreferrer" href=')
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
		return text
	}
}

/* Renders block html content from markdown */
export const render = markdown => tryRender(markdown, md, 'render')
/* Renders inline html from markdown */
export const renderInline = markdown => tryRender(markdown, md, 'renderInline')
/* Renders text from markdown (without html) */
export const renderText = markdown => {
	const div = document.createElement('div')
	div.innerHTML = tryRender(markdown, md, 'renderInline')
	return div.textContent
}
/* Renders imageChoice label */
export const renderLabel = markdown => tryRender(markdown, mdImageChoiceLabel, 'renderInline')
export const renderBlockOptionLabel = markdown => tryRender(markdown, mdBlockChoiceLabel, 'render')

/* Renders choice label */
export const renderInlineLabel = markdown => tryRender(markdown, mdChoiceLabel, 'renderInline')

/* Renders block html content from markdown with rules for blurred text */
export const renderBlur = markdown => tryRender(markdown, mdBlur, 'render')

export default {
	render,
	renderInline,
	renderText,
	renderBlur,
}
