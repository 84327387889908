import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Router } from 'react-router'
import { Provider, connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import { getIsLanguageRtl } from 'store/intl'

import { pageview, set } from 'helpers/googleAnalytics'

class AppContainer extends Component {
	static propTypes = {
		routes: PropTypes.object.isRequired,
		store: PropTypes.object.isRequired,
		language: PropTypes.string.isRequired,
		messages: PropTypes.object.isRequired,
	}

	constructor() {
		super()

		this.logPageView = this.logPageView.bind(this)
	}

	logPageView() {
		const data = { page: window.location.pathname }

		set(data)
		pageview(window.location.pathname)
	}

	render() {
		const { routes, store, language, messages } = this.props

		const textDirection = getIsLanguageRtl(language) === true ? 'rtl' : 'ltr'

		const body = document.querySelector('body')
		body.dir = textDirection

		return (
			<Provider store={store}>
				<IntlProvider locale={language} messages={messages}>
					<Router history={browserHistory} children={routes} onUpdate={this.logPageView} />
				</IntlProvider>
			</Provider>
		)
	}
}

const mapActionCreators = {}

const mapStateToProps = state => ({
	messages: state.intl.messages,
	language: state.intl.language,
})

export default connect(mapStateToProps, mapActionCreators)(AppContainer)
