import { v4 as uuidv4 } from 'uuid'

export default (store, authHandler, loadStudyOptionsSpy = null) => ({
	path: 'preview(/:studyId)',
	onEnter(nextState, replace) {
		let idStudy = nextState.params.studyId
		let query = nextState.location.search
		let gsUuid = uuidv4()

		query += query.indexOf('?') === 0 ? '&' : '?'
		query += 'gs_preview=' + gsUuid

		replace('/login/' + idStudy + query + nextState.location.hash)
	},
})
