/* eslint-disable max-len */

const messages = {
	not_found: 'जो पृष्ठ आप देख रहे हैं वह मौजूद नहीं है',
	error_unexpected_error: 'एक अप्रत्याशित समस्या थी बाद में पुन: प्रयास करें।',

	error_img_alt: 'त्रुटि छवि',
	error_try_again: 'पुनः प्रयास करें',
	error_generic_header: 'कुछ गलत हो गया',
	error_generic_message:
		'फिर से प्रयास करें, और अगर यह अभी भी काम नहीं करता है, तो कृपया support@groupsolver.com को ई-मेल को QR कोड के साथ भेजें।',

	error_offline_header: 'आप ऑफ़लाइन होने लगते हैं',
	error_offline_message: 'आपका कनेक्शन पुन: स्थापित होने के बाद आपका अध्ययन फिर से शुरू होगा।',

	error_landscape_small_screen_header: 'कृपया अपना डिवाइस घुमाएं',
	error_landscape_small_screen_text:
		'इस अध्ययन को देखने के लिए आपकी स्क्रीन बहुत छोटी है कृपया अपना डिवाइस घुमाएं।',

	error_small_browser_window_header: 'कृपया अपने ब्राउज़र विंडो को बड़ा करें',
	error_small_browser_window_text:
		'इस अध्ययन को देखने के लिए आपकी विंडो बहुत छोटी है। कृपया अपने ब्राउज़र विंडो को बड़ा करें',

	login_error_empty_email: 'ईमेल खाली नहीं हो सकता',
	login_error_invalid_email: 'कृपया मान्य ईमेल दर्ज करें',
	login_error_unable_to_login: 'इस समय लॉगिन करने में असमर्थ बाद में पुन: प्रयास करें।',

	login_email_placeholder: 'अपना ईमेल दर्ज करें',
	login_email_button: 'आगे बढ़ें',
	login_email_back: 'या लॉगिन विकल्प पर वापस जाएं',
	login_email_label: 'ईमेल के साथ आगे बढ़ें',
	login_anonymous_label: 'गुमनाम रूप से आगे बढ़ें',

	login_privacy_statement:
		'ग्रूप्सॉवर आपकी गोपनीयता और आपके द्वारा प्रदान की जाने वाली जानकारी का सम्मान करता है।',
	login_privacy_statement_email:
		'आपका ईमेल इस परियोजना के लिए आपको प्रवेश देने के अलावा किसी भी तरह से साझा, बेचे या इस्तेमाल नहीं किया जाएगा।',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'जो अध्ययन आप ढून्ढ रहे हैं वह अनुपलब्ध है',
	study_unavailable_description:
		'हमारे अध्ययन में आपकी रुचि के लिए धन्यवाद! दुर्भाग्य से, इस अध्ययन को पहले ही भर दिया गया है या इस समय रोक दिया गया है। इसके कारण किसी भी असुविधा के लिए हमें खेद है।',
	study_preview_limit_reached_label: 'आपकी पूर्वावलोकन सीमा तक पहुंच गई है।',
	study_preview_limit_reached_description:
		'आपकी पूर्वावलोकन सीमा तक पहुंच गई है। \n हमारे अध्ययन में आपकी रूचि के लिए धन्यवाद! दुर्भाग्यवश, यह अध्ययन उपलब्ध पूर्वावलोकनों की संख्या तक पहुंच गया है',

	waiting_message:
		'इस अध्ययन को अभी कई लोगों से हल किया जा रहा है \n हम स्वचालित रूप से प्रवेश करने का पुनः प्रयास करेंगे {number, plural, =0 { अभी } one {सेकंड में} other {सेकंड में}}.',
	waiting_error_recoverable: 'हमें एक अप्रत्याशित त्रुटि का सामना करना पड़ा है।',
	waiting_error_recoverable_retry:
		'हम फिर से प्रयास करेंगे, {number, plural, =0 {अब} one {सेकंड में} other {सेकंड में}}',
	waiting_error_unknown_study: 'जो अध्ययन आप देख रहे हैं वह मौजूद नहीं है।',
	waiting_error_dead: 'एक अप्रत्याशित समस्या थी बाद में पुन: प्रयास करें।',

	action_input_submit_button_text: 'सबमिट करें',
	action_input_skip_button_text: 'छोड़ें',

	choice_single_hint: 'एक विकल्प चुनें',
	general_skip_action_message: 'अगले चरण पर जाएं',

	action_button_primary_default_text: 'एक्शन',
	action_button_hint_default_text: 'विकल्पों में से एक चुनें',

	message_media_examine_video: 'समीक्षा करने के लिए वीडियो पर क्लिक करें',
	message_media_examine_image: 'समीक्षा करने के लिए छवि पर क्लिक करें',
	message_full_screen_close_button_text: 'बंद',
	message_image_view_label: 'छवि देखें',
	message_video_view_label: 'वीडियो देखें',
	message_image_examined_label: 'चित्र की समीक्षा की गई',
	message_video_examined_label: 'वीडियो की समीक्षा की गई',
	message_zoom_mouse: 'ज़ूम करने के लिए इधर उधर घुमाएं',
	message_zoom_touch: 'ज़ूम करने के लिए स्पर्श करें',

	textarea_too_long_hint: 'आपका पाठ बहुत लंबा है',

	ideate_placeholder: 'छोटे वाक्यों में जवाब दें',
	ideate_skip: 'या छोड़ें',

	eliminate_no: 'नहीं',
	eliminate_yes: 'हाँ',
	eliminate_intro: 'पहले आपने कहा:',
	eliminate_multiple_suggestions: 'निम्न में से कौन आपका जवाब बदल सकता है?',
	eliminate_one_suggestion: 'क्या निम्न उत्तर आपका बदल सकता है?',
	eliminate_step: 'आपके पास एक अन्य जवाब था:',
	eliminate_none_of_the_above: 'इनमें से कोई भी नहीं',

	elaborate_indifferent_label: 'निश्चित नहीं',
	elaborate_disagree_label: 'मैं असहमत हूँ',
	elaborate_agree_label: 'मैं सहमत हूं',
	elaborate_unclear_statement_label: 'अस्पष्ट जवाब',
	elaborate_intro: 'दूसरों को जवाब निम्नलिखित प्रदान की क्या आप उनसे सहमत हो?',

	ideamatching_unsure_label: 'निश्चित नहीं है',
	ideamatching_unequal_label: 'अलग',
	ideamatching_equal_label: 'समान',
	ideamatching_intro: 'उत्तर की इस जोड़ी को देखिये क्या इनका मतलब एक है?',

	select_input_placeholder: 'चयन करने के लिए यहां टाइप करें',
	select_input_placeholder_max: 'अधिकतम क्षमता तक पहुंच',
	select_multiple_choice_hint:
		'चुनें {minNumber, plural, =0 { 0 } other { # }} से {maxNumber, plural, =0 { 0 } other { # }} विकल्प',
	select_no_matches_found: 'कोई मिलान नहीं मिला',

	report_content_inappropriate: 'अनुचित उत्तर',
	report_content_profane: 'अश्लील या अपमानजनक सामग्री',
	report_content_gibberish: 'गिब्बरिश या बकवास पाठ',
	report_content_irrelevant: 'सवाल से अप्रासंगिक',
	report_content_different_language: 'अलग भाषा',
	report_content_other: 'अन्य',
	report_content_no_content: 'रिपोर्ट करने के लिए कोई सामग्री नहीं',
	report_content_header: 'इस उत्तर की रिपोर्ट करें',
	report_content_form_header:
		'कृपया इस कारण का चयन करें कि आप इस जवाब को क्यों ध्वजांकित करना चाहते हैं',
	report_content_flag_answer: 'इस उत्तर को ध्वजांकित करें',
	report_content_return_to_study: 'अध्ययन पर लौटें',
	report_content_thank_you:
		'आपकी प्रतिक्रिया के लिए धन्यवाद। हम आपके इनपुट की समीक्षा करेंगे और यदि आवश्यक हो तो कार्रवाई करेंगे',
	report_content_statement_flagged: 'वक्तव्य ध्वजांकित किया गया है',

	platform_update_header: 'GroupSolver पर इस समय निर्धारित रख-रखाव का काम चल रहा है।',
	platform_update_message: 'हम आपको होने वाली किसी भी असुविधा के लिए क्षमा चाहते हैं। ',

	none_of_these: 'इनमे से कोई नहीं',

	allocation_instruction_limit: 'सभी विकल्पों में {points} आवंटित करें',
	allocation_instruction_limit_no_minimum: 'सभी विकल्पों में {points} तक आवंटित करें',
	allocation_instruction_no_limit: 'सभी विकल्पों में पॉइंट आवंटित करें',
	allocation_instruction_points_left: '{points} शेष',
	allocation_options_out_of_range: 'कुछ विकल्प सीमा के बाहर हैं',
	allocation_limit_overflow: '{points} सीमा से अधिक',
	allocation_option_invalid_range: 'मान {range} सीमा के बाहर है',
	allocation_option_invalid_min: 'मान {min} से अधिक या के बराबर होना ज़रूरी है ',

	dropdown_select: 'चुने',

	ranking_drag_options: 'विकल्पों को रैंक करने के लिए तीरों को खींचें या क्लिक करें',
	ranking_assign_option_values: 'सभी विकल्पों को रैंक करें',
	ranking_require_unique_values: 'विकल्पों की रैंक भिन्न होनी चाहिएं',

	heatmap_instruction_intro: 'चयन शुरू करने के लिए, नीचे दी गई छवि देखें।',
	heatmap_instruction_unlimited: 'छवि पर क्लिक करके जितने चाहें उतने बिंदु चुनें।',
	heatmap_instruction_count:
		'छवि पर क्लिक करके {number, plural, =0 {0 बिंदु} one {एक बिंदु} other {{number} बिंदु}} चुनें।',
	heatmap_instruction_up_to_count:
		'छवि पर क्लिक करके {number, plural, =0 {0 बिंदु} one {एक बिंदु} other {अधिकतम {number} बिंदु}} चुनें।',
	heatmap_instruction_between_count: 'छवि पर क्लिक करके {from} से {to} बिंदु चुनें।',
	heatmap_instruction_at_least_count:
		'छवि पर क्लिक करके कम से कम {number, plural, =0 {0 बिंदु} one {एक बिंदु} other {{number} बिंदु}} चुनें।',
	heatmap_response: '{number, plural, =0 {0 बिंदु} one {1 बिंदु} other {{number} बिंदु}} चुने गए',

	continue: 'जारी रखें',
	reset: 'रीसेट करें',

	input_invalid: 'इनपुट मान्य नहीं है।',
	input_empty: 'इनपुट खाली नहीं हो सकता।',
	input_chars_left: '{number} {number, plural, =0 {वर्ण} one {वर्ण} other {वर्ण}} शेष।',
	input_too_long: '{number} {number, plural, =0 {वर्ण} one {वर्ण} other {वर्ण}} सीमा से अधिक।',

	// accessibility
	ranking_select_rank: '{label} का रैंक चुनें।',

	ranking_select_aria_label: 'प्रत्येक विकल्प का रैंक चुनने के लिए एक मान दर्ज करें।',
	ranking_dnd_aria_label:
		'किसी विकल्प पर ध्यान केंद्रित करें और ड्रैग शुरू करने के लिए स्पेस बार दबाएं। ड्रैग करते समय आप आइटम को इधर-उधर ले जाने के लिए एरो कुंजियों का उपयोग कर सकते हैं और रद्द करने के लिए एस्केप दबा सकते हैं। आइटम को नई स्थिति में रखने के लिए स्पेस बार फिर से दबाएं। सुनिश्चित करें कि आपका स्क्रीन रीडर फोकस मोड या फॉर्म्स मोड में है।',

	allocation_option_input: '{label} मान इनपुट।',
	allocation_aria_label: 'इनपुट का उपयोग करके प्रत्येक विकल्प के लिए एक मान दर्ज करें।',

	play: 'चलाएं',
	pause: 'रोकें',
	stop: 'बंद करें',
	fwd: 'आगे बढ़ें',
	rwd: 'पीछे जाएं',
	volume_down: 'ध्वनि कम करें',
	volume_up: 'ध्वनि बढ़ाएं',
	volume_current: 'ध्वनि: {number}%',

	loading: 'लोड हो रहा है',

	press_tab_to_continue: 'जारी रखने के लिए टैब दबाएं',

	option_focus_selected: 'विकल्प {label} पर ध्यान केंद्रित है। चयन रद्द करने के लिए एंटर दबाएं।',
	option_focus_not_selected: 'विकल्प {label} पर ध्यान केंद्रित है। चयन करने के लिए एंटर दबाएं।',

	image_alt: 'छवि:',
	video_alt: 'वीडियो:',

	submit_enabled: 'सबमिट सक्षम।',
	skip_enabled: 'प्रश्न छोड़ें सक्षम',
	n_of_min_selected: '{selected} में से {min} चुने गए।',
	option_selected: '{label} चुना गया।',
	option_deselected: '{label} चयन रद्द किया गया।',

	choice_multiple_hint: '{min} से {max} विकल्प चुनें।',

	ranking_position: 'स्थिति: {position}',
	ranking_position_changed: '{label} का मान {value} पर सेट किया गया।',
	ranking_input_aria_label:
		'{label}। वर्तमान स्थिति: {position}। 1 से {max} तक की स्थिति दर्ज करें।',
	not_selected: 'चयनित नहीं',

	study_logo_alt: 'अध्ययन लोगो',
	groupsolver_logo_alt: 'ग्रूपसॉल्वर लोगो',
}

export default messages
