import clientConfig from 'clientConfig'
const envConfig = JSON.parse(`${__CONFIG__}`)

const getClientConfig = () => {
	if (window.location.origin.includes('aiq.researchactivate')) {
		return clientConfig.ra
	}

	return clientConfig.gs
}

const appConfig = {
	idCommit: envConfig.idCommit,
	apiEndpoint: envConfig.apiEndpoint,
	logger: envConfig.logger || false,
	analyticsKey: envConfig.analyticsKey,
	sentryKey: 'https://a204cafba5d14fed92ca81c513802760@sentry.io/151683',
	clientConfig: getClientConfig(),
}

export default appConfig
