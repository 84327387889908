import React from 'react'
import PropTypes from 'prop-types'
import './Option.scss'
import optionShape from 'shapes/option'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { renderInlineLabel } from 'store/tools/MarkdownText'
import { StyleSheet, css } from 'aphrodite/no-important'
import { createClassName } from 'components/helpers'

const Option = props => {
	const styles = StyleSheet.create({
		optionChoice: {
			color: props.theme.primaryColor,
			backgroundColor: 'white',
			borderColor: props.theme.primaryColor,
		},
		hoverEnabled: {
			':hover': {
				'@media screen and (min-width: 1024px)': {
					backgroundColor: props.theme.primaryDark,
					color: 'white',
				},
			},
		},
		selected: {
			backgroundColor: props.theme.primaryColor,
			color: 'white',
		},
	})

	let optionCss = createClassName(
		css(
			styles.optionChoice,
			props.option.selected && props.actionType === CHOICE_SUBTYPE.CHECKBOX && styles.selected,
			props.isHoverEnabled && styles.hoverEnabled,
		),
		'Option-choice',
		props.option.selected && props.actionType === CHOICE_SUBTYPE.CHECKBOX
			? 'test-option-choice-selected'
			: '',
		props.isSingleRow === false ? ' column' : '',
	)

	const idAriaLabelTranslation =
		props.option.selected === true ? 'option_focus_selected' : 'option_focus_not_selected'

	return (
		<div
			onClick={props.selectOption}
			onMouseDown={props.handleOnMouseDown}
			data-id={props.option.id}
			className={optionCss}
		>
			{props.option.reported === true ? <div className="Option-choice-reported icon-flag" /> : null}
			<div
				tabIndex={0}
				data-id={props.option.id}
				onKeyUp={event => {
					if (event.keyCode === 13) {
						event.persist()
						props.selectOption(event)
					}
				}}
				className="Option-choice-label"
				dangerouslySetInnerHTML={{ __html: renderInlineLabel(props.option.label) }}
				aria-label={props.intl.formatMessage(
					{ id: idAriaLabelTranslation },
					{ label: props.option.label },
				)}
			/>
		</div>
	)
}

Option.defaultProps = {
	isSingleRow: false,
}

Option.propTypes = {
	actionType: PropTypes.oneOf(Object.values(CHOICE_SUBTYPE)).isRequired,
	option: optionShape,
	intl: PropTypes.object.isRequired,
	isSingleRow: PropTypes.bool,
	isHoverEnabled: PropTypes.bool.isRequired,
	selectOption: PropTypes.func.isRequired,
	handleOnMouseDown: PropTypes.func.isRequired,
	theme: PropTypes.object,
}

export default Option
