import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { intlShape } from 'react-intl'
import { StyleSheet, css } from 'aphrodite/no-important'

import './ActionButton.scss'

import DotsLoader from 'components/_scaffolding/DotsLoader'
import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import { ACTION_BAR_TYPE } from 'constants/actionBar'

class ActionButton extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.state.isTimeSet = false
		this.state.lastActionState = null
		this.handleOnClick = this.handleOnClick.bind(this)
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.actionState !== this.props.actionState &&
			nextProps.loaderType === ACTION_BUTTON_LOADER_TYPE.TIME
		) {
			let newState = Object.assign({}, this.state)
			newState.isTimeSet = false
			this.setState(newState)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.actionState !== this.state.lastActionState) {
			let timeout =
				this.state.lastActionState === ACTION_BUTTON_STATE.SKIP_PREP ||
				this.state.lastActionState === ACTION_BUTTON_STATE.SUBMIT_PREP
					? 1000
					: 50
			let newState = Object.assign({}, this.state)
			newState.lastActionState = this.props.actionState
			setTimeout(() => {
				this.setState(newState) //eslint-disable-line
			}, timeout)
		}
		if (this.state.isTimeSet === false && prevProps.loaderType === ACTION_BUTTON_LOADER_TYPE.TIME) {
			let newState = Object.assign({}, this.state)
			newState.isTimeSet = true
			setTimeout(() => {
				this.setState(newState) //eslint-disable-line
			}, 50)
		}
	}

	handleOnClick() {
		if (
			this.props.actionState === ACTION_BUTTON_STATE.SUBMIT_READY ||
			this.props.actionState === ACTION_BUTTON_STATE.AUTOSUBMIT_PREP
		) {
			this.props.onPrimarySubmit()
		} else if (this.props.actionState === ACTION_BUTTON_STATE.SKIP_READY) {
			this.props.onSecondarySubmit()
		}
	}

	render() {
		const { formatMessage } = this.props.intl
		const defaultButtonText = formatMessage({ id: 'action_button_primary_default_text' })
		const defaultHintText = formatMessage({ id: 'action_button_hint_default_text' })
		const actionState = this.props.actionState || ACTION_BUTTON_STATE.DEFAULT
		const enabled = [
			ACTION_BUTTON_STATE.SUBMIT_READY,
			ACTION_BUTTON_STATE.SKIP_READY,
			ACTION_BUTTON_STATE.AUTOSUBMIT_PREP,
		].includes(actionState)
		const isLoaderTime = this.props.loaderType === ACTION_BUTTON_LOADER_TYPE.TIME

		const submitReadyStyle = {
			borderColor: this.props.theme.primaryColor,
			backgroundColor: this.props.theme.primaryColor,
			color: 'white',
		}

		const hoverStyle = {
			backgroundColor: this.props.theme.primaryColor,
			borderColor: this.props.theme.primaryColor,
		}

		const styles = StyleSheet.create({
			'submit-ready-wrapper': {
				':hover': hoverStyle,
			},
			'autosubmit-preparation-wrapper': {
				':hover': hoverStyle,
			},
			'skip-ready-wrapper': {
				':hover': {
					borderColor: this.props.theme.primaryColor,
				},
			},
			'autosubmission-wrapper': {
				borderColor: this.props.theme.primaryColor,
				backgroundColor: this.props.theme.primaryColor,
			},
			'submit-ready': submitReadyStyle,
			'autosubmit-preparation': submitReadyStyle,
			autosubmission: submitReadyStyle,
			'submit-preparation': {
				borderColor: this.props.theme.primaryColor,
			},
			'skip-ready': {
				color: this.props.theme.primaryColor,
				borderColor: this.props.theme.primaryColor,
				backgroundColor: 'transparent',
				':hover': {
					borderColor: 'transparent',
				},
			},
			progressOuter: {
				backgroundColor: this.props.theme.primaryColor,
			},
			progressInnerSubmitPreparation: {
				backgroundColor: this.props.theme.primaryColor,
			},
		})

		let percent = this.props.percent
		let virtualActionState = actionState

		if (this.state.lastActionState !== this.props.actionState) {
			percent = this.state.lastActionState === ACTION_BUTTON_STATE.SUBMIT_READY ? 100 : 0
		}

		if (
			(this.state.lastActionState === ACTION_BUTTON_STATE.SUBMIT_PREP &&
				this.props.actionState === ACTION_BUTTON_STATE.SUBMIT_READY) ||
			(this.state.lastActionState === ACTION_BUTTON_STATE.SKIP_PREP &&
				this.props.actionState === ACTION_BUTTON_STATE.SKIP_READY)
		) {
			virtualActionState = this.state.lastActionState
			percent = 100
		} else if (
			(this.state.lastActionState === ACTION_BUTTON_STATE.SUBMIT_PREP &&
				this.props.actionState === ACTION_BUTTON_STATE.DEFAULT) ||
			(this.state.lastActionState === ACTION_BUTTON_STATE.SKIP_PREP &&
				this.props.actionState === ACTION_BUTTON_STATE.DEFAULT)
		) {
			virtualActionState = this.state.lastActionState
			percent = 0
		}

		// Progress button outer
		const hasOuterProgressWrapper =
			virtualActionState === ACTION_BUTTON_STATE.AUTOSUBMIT_PREP && isLoaderTime

		// Progress button inner
		const actionProgressInnerCss = classnames(
			'ActionButton-progress',
			`inner-${virtualActionState}`,
			this.props.loaderType,
			{
				[css(styles.progressInnerSubmitPreparation)]:
					virtualActionState === ACTION_BUTTON_STATE.SUBMIT_PREP,
				fullWidth: this.state.isTimeSet,
				'ActionButton-progress--rtl': this.props.isStudyRtl === true,
				'ActionButton-progress--ltr': this.props.isStudyRtl === false,
			},
		)

		const showProgressBar =
			virtualActionState === ACTION_BUTTON_STATE.SKIP_PREP ||
			virtualActionState === ACTION_BUTTON_STATE.SUBMIT_PREP

		return (
			<div className="ActionButton-wrapper-main">
				<div
					className={classnames('ActionButton-wrapper', {
						[css(styles[`${virtualActionState}-wrapper`])]:
							this.props.type === ACTION_BAR_TYPE.BUTTON,
						[virtualActionState]: this.props.type === ACTION_BAR_TYPE.BUTTON,
					})}
				>
					{hasOuterProgressWrapper === true && (
						<div
							className={classnames('ActionButton-progress', 'outer', {
								[css(styles.progressOuter)]: hasOuterProgressWrapper === true,
								[this.props.loaderType]: hasOuterProgressWrapper === true,
								fullWidth: hasOuterProgressWrapper === true && this.state.isTimeSet,
								'ActionButton-progress--rtl': this.props.isStudyRtl === true,
								'ActionButton-progress--ltr': this.props.isStudyRtl === false,
							})}
							style={
								isLoaderTime
									? { transitionDuration: this.props.transitionTime + 'ms' }
									: { width: percent + '%' }
							}
						/>
					)}
					{this.props.type === ACTION_BAR_TYPE.BUTTON && (
						<div
							className={classnames(
								css(styles[virtualActionState]),
								'ActionButton-button',
								`ActionButton-button-${virtualActionState}`,
								{
									[`ActionButton-button-${virtualActionState}--accessibility`]:
										this.props.isAccessibilityEnabled === true,
									disabled: enabled === false,
								},
							)}
							onClick={enabled ? this.handleOnClick : () => {}}
						>
							{showProgressBar === true && (
								<div
									className={actionProgressInnerCss}
									style={
										isLoaderTime
											? { transitionDuration: this.props.transitionTime + 'ms' }
											: { width: percent + '%' }
									}
								/>
							)}
							<button
								disabled={enabled === false}
								className="ActionButton-button-element"
								type="button"
								// needed in safari
								tabIndex={0}
							>
								{this.props.buttonText || defaultButtonText}
							</button>
						</div>
					)}
					{this.props.type === ACTION_BAR_TYPE.HINT && (
						<div
							className={classnames('ActionButton-hint', {
								uppercase: this.props.isUppercase,
							})}
						>
							{this.props.hintText || defaultHintText}
						</div>
					)}
					{this.props.type === ACTION_BAR_TYPE.LOADER && (
						<div aria-label={formatMessage({ id: 'loading' })} className="ActionButton-loader">
							<DotsLoader />
						</div>
					)}
				</div>
			</div>
		)
	}
}

ActionButton.propTypes = {
	type: PropTypes.oneOf(Object.values(ACTION_BAR_TYPE)).isRequired,
	actionState: PropTypes.oneOf(Object.values(ACTION_BUTTON_STATE)),

	isAccessibilityEnabled: PropTypes.bool.isRequired,
	isStudyRtl: PropTypes.bool.isRequired,
	loaderType: PropTypes.oneOf(Object.values(ACTION_BUTTON_LOADER_TYPE)),
	transitionTime: PropTypes.number,
	percent: PropTypes.number,

	hintText: PropTypes.string,
	isUppercase: PropTypes.bool,

	buttonText: PropTypes.string,
	onPrimarySubmit: PropTypes.func,
	onSecondarySubmit: PropTypes.func,

	theme: PropTypes.object,

	intl: intlShape.isRequired,
}

export default ActionButton
