import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { API } from 'store/api'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import {
	interactiveOptionsShow,
	interactiveOptionsHide,
	setOptionsChangeListener,
	selectors as allocationSelectors,
} from 'store/_chat/interactiveOptions'
import { createComponent, getCurrentMessage, removeTempMessages } from 'store/_chat/history'
import { formatMessage, getIsStudyRtl } from 'store/intl'
import {
	setActionSubmitListener,
	showActionButton,
	showActionLoader,
	showActionHint,
} from 'store/_chat/actionBar'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'

import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { ALLOCATION_SUBTYPE, ALLOCATION_QUESTION_LOG_ACTIONS } from 'constants/allocation'
import { COMPONENTS, POSITION } from 'constants/component'
import { DELAY_LENGTH } from 'constants/delay'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'allocation.add.response'
export const RECORD_ALLOCATION_LOG_ACTION = 'allocation.records.action.log'
export const RESET_ALLOCATION_LOG = 'allocation.log.reset'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
	allocationLog: [],
	idIteration: uuidv4(),
}

// ------------------------------------
// Helpers
// ------------------------------------
const getSumOfValues = options => {
	const optionsWithvalue = options
		.filter(option => option.isNoneOfThese === false)
		.filter(option => option.value !== '')

	return _.sum(optionsWithvalue.map(o => Number(o.value)))
}

export const getAllocationLog = getState => {
	return getState().allocation.allocationLog
}

export const getIsOptionValueOutOfRange = (option, range) => {
	if (option.value === '') {
		return true
	}

	if (option.isNoneOfThese === true) {
		return false
	}

	if (range.max === null) {
		return option.value < range.min
	}

	return option.value < range.min || option.value > range.max
}

export const getIsLimitBreached = (options, limit) => {
	if (limit.isEnabled === false) {
		return false
	}

	const sumOfValues = getSumOfValues(options)

	return sumOfValues > limit.value
}

export const recordAllocationLogItem = (
	timestamp,
	idStudyObject,
	studyObjectType,
	action,
	data = null,
) => (dispatch, getState) => {
	dispatch({
		type: RECORD_ALLOCATION_LOG_ACTION,
		timestamp,
		idStudyObject,
		studyObjectType,
		action,
		data,
	})
}

const formatHintValue = (hintValueSettings, originalValue) => {
	const { addSpace, isLeft, unitLabel } = hintValueSettings

	const value = _.round(originalValue, 4)

	if (unitLabel === '') {
		return value
	}

	const stringsToJoin = isLeft === true ? [unitLabel, value] : [value, unitLabel]

	const joinString = addSpace === true ? ' ' : ''

	return stringsToJoin.join(joinString)
}

const getInstructionTranslation = limit => {
	if (limit.isEnabled === false) {
		return 'allocation_instruction_no_limit'
	}

	if (limit.useLimitAsMin === false) {
		return 'allocation_instruction_limit_no_minimum'
	}

	return 'allocation_instruction_limit'
}

// ------------------------------------
// Respondent interactions listeners
// ------------------------------------
const handleOptionsChange = (options, changedOption) => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const { limit, range, hintValueSettings } = definition

	const studyObject = getStudyObject()(dispatch, getState)

	recordAllocationLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		ALLOCATION_QUESTION_LOG_ACTIONS.OPTION_CHANGED,
		{ changedOption },
	)(dispatch, getState)

	const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	const noneOfTheseOption = options.find(option => option.isNoneOfThese === true)
	const isNoneOfTheseChecked = _.get(noneOfTheseOption, 'isChecked', false)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (isNoneOfTheseChecked === true) {
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText])(
			dispatch,
			getState,
		)

		return
	}

	if (options.some(option => getIsOptionValueOutOfRange(option, range))) {
		const hint = formatMessage('allocation_options_out_of_range')(dispatch, getState)

		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(hint)(dispatch, getState)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionHint, [hint])(dispatch, getState)

		return
	}

	const sumOfValues = getSumOfValues(options)

	if (getIsLimitBreached(options, limit)) {
		const pointsOverflow = sumOfValues - limit.value
		const formattedPointsOverflow = formatHintValue(hintValueSettings, pointsOverflow)

		// translations that have {points} at the end of the translation string
		// arent rendered correctly in rtl (e.g. arabic) studies
		const hint =
			getIsStudyRtl(getState()) === true
				? formatMessage('allocation_limit_overflow', { points: '' })(dispatch, getState) +
				  ` ${formattedPointsOverflow}`
				: formatMessage('allocation_limit_overflow', { points: formattedPointsOverflow })(
						dispatch,
						getState,
				  )

		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(hint)(dispatch, getState)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionHint, [hint])(dispatch, getState)

		return
	}

	const pointsLeft = limit.value - sumOfValues

	// there are no points left OR limit is not enabled OR respondent does not have to assign all points
	// enable submit and end execution
	if (pointsLeft === 0 || limit.isEnabled === false || limit.useLimitAsMin === false) {
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText])(
			dispatch,
			getState,
		)

		return
	}

	// display hint that user has to assign all remaining points
	const formattedPointsLeft = formatHintValue(hintValueSettings, pointsLeft)

	// translations that have {points} at the end of the translation string
	// arent rendered correctly in rtl (e.g. arabic) studies
	const hint =
		getIsStudyRtl(getState()) === true
			? formatMessage('allocation_instruction_points_left', { points: '' })(dispatch, getState) +
			  ` ${formattedPointsLeft}`
			: formatMessage('allocation_instruction_points_left', { points: formattedPointsLeft })(
					dispatch,
					getState,
			  )

	if (isAccessibilityEnabled === true) {
		addAccessibilityHintText(hint)(dispatch, getState)
		publishAccessibilityHint()(dispatch, getState)
	}

	addAction(showActionHint, [hint])(dispatch, getState)
}

const primarySubmitListener = () => (dispatch, getState) => {
	const state = getState()
	const options = allocationSelectors.getChatInteractiveOptionsOptions(state)
	const definition = getStudyObjectDefinition()(dispatch, getState)

	const answwerMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT
	const answerMessageText = options
		.map(
			option => `${option.label}: ${formatHintValue(definition.hintValueSettings, option.value)}`,
		)
		.join('\n')
	const answerMessage = createComponent(COMPONENTS.MESSAGE)
	answerMessage.props.text = answerMessageText

	addMessage(answerMessage, answwerMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)

	recordAllocationLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		ALLOCATION_QUESTION_LOG_ACTIONS.SUBMIT,
		{ options },
	)(dispatch, getState)

	endStep(options)(dispatch, getState) // eslint-disable-line no-use-before-define
}

const secondarySubmitListener = () => (dispatch, getState) => {
	const skipMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT
	const skippedMessageText = formatMessage('general_skip_action_message')(dispatch, getState)
	const skippedMessage = createComponent(COMPONENTS.MESSAGE)
	skippedMessage.props.text = skippedMessageText

	addMessage(skippedMessage, skipMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)

	recordAllocationLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		ALLOCATION_QUESTION_LOG_ACTIONS.SKIP,
	)(dispatch, getState)

	endStep(null)(dispatch, getState) // eslint-disable-line no-use-before-define
}

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState)
	setOptionsChangeListener(handleOptionsChange)(dispatch, getState)

	if (_.isNil(module.priorMessage) === false) {
		const priorMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

		let priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		addMessage(priorMessage, priorMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

const showNextStep = () => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())
	const shouldShowInstruction =
		isAccessibilityEnabled === true || definition.instructionSettings.isVisible === true

	if (shouldShowInstruction === true) {
		const instruction = createComponent(COMPONENTS.INSTRUCTION)

		const instructionTranslation = getInstructionTranslation(definition.limit)

		instruction.props.text =
			definition.instructionSettings.isCustom === true
				? definition.instructionSettings.text
				: formatMessage(instructionTranslation, {
						points: formatHintValue(definition.hintValueSettings, definition.limit.value),
				  })(dispatch, getState)

		instruction.props.ariaLabel = formatMessage('allocation_aria_label')(dispatch, getState)

		const solverMessagePosition =
			getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

		addTempMessage(instruction, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

	if (definition.mandatory === false) {
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else {
		const sumOfOptionsDefaults = getSumOfValues(definition.options)
		const pointsLeft = definition.limit.value - sumOfOptionsDefaults

		const hintTranslation =
			definition.limit.isEnabled === true
				? 'allocation_instruction_points_left'
				: 'action_input_submit_button_text'

		const formattedPointsLeft = formatHintValue(definition.hintValueSettings, pointsLeft)
		const hint = formatMessage(hintTranslation, { points: formattedPointsLeft })(dispatch, getState)

		addAction(showActionHint, [hint])(dispatch, getState)
	}

	const studyObject = getStudyObject()(dispatch, getState)
	const currentMessage = getCurrentMessage()(dispatch, getState)

	recordAllocationLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		ALLOCATION_QUESTION_LOG_ACTIONS.OPTIONS_DISPLAYED,
		{
			options: definition.options,
			range: definition.range,
			limit: definition.limit,
			currentMessage,
		},
	)(dispatch, getState)

	addAction(
		interactiveOptionsShow,
		[
			ALLOCATION_SUBTYPE.INPUT,
			definition.options,
			{ limit: definition.limit, range: definition.range },
			null,
		],
		DELAY_LENGTH.SHORT,
	)(dispatch, getState)
}

const resetAllocationLog = () => (dispatch, getState) => {
	dispatch({ type: RESET_ALLOCATION_LOG })
}

const endStep = options => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)
	const { limit } = studyObject.definition

	const noneOfTheseOption = options.find(option => option.isNoneOfThese === true)
	const isNoneOfTheseChecked = _.get(noneOfTheseOption, 'isChecked', false)

	const studyObjectResponse =
		options === null
			? null
			: {
					[studyObject.id]: {
						limit: limit.isEnabled === true ? limit.value : null,
						isNoneOfTheseChecked,
						options: options.map(option => ({
							idOption: option.id,
							value: Number(option.value),
						})),
					},
			  }

	const studyObjectActionLog = getAllocationLog(getState)

	resetAllocationLog()(dispatch, getState)
	removeTempMessages()(dispatch, getState)
	interactiveOptionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)

	getState().study.getNextStep(studyObjectResponse, studyObjectActionLog, true)(dispatch, getState)
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses]
		newState.responses.push(action.idea)

		return newState
	},
	[RECORD_ALLOCATION_LOG_ACTION]: (state, action) => ({
		...state,
		allocationLog: [
			...state.allocationLog,
			{
				idIteration: state.idIteration,
				...action,
			},
		],
	}),
	[RESET_ALLOCATION_LOG]: (state, action) => ({
		...state,
		allocationLog: [],
		idIteration: uuidv4(),
	}),
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'allocation') {
			return state
		}

		return _.cloneDeep(initialState)
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
