import GSReactImageMagnify from 'gs-rim'
import PropTypes from 'prop-types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import _ from 'lodash'
import isuuid from 'isuuid'

import ActionBar from 'components/ActionBar'

import { MEDIA_TYPE } from 'constants/media'

import './FullscreenView.scss'

const getThumbnailByScreenSize = screenWidth => {
	if (screenWidth <= 720 && screenWidth > 480) {
		return '720.thumbnail.jpeg'
	}

	if (screenWidth <= 480) {
		return '480.thumbnail.jpeg'
	}

	return '1080.thumbnail.jpeg'
}

const secondsToMinutes = number => {
	let minutes = Math.floor(number / 60)
	let seconds = number - minutes * 60

	if (minutes < 10) {
		minutes = '0' + minutes
	}
	if (seconds < 10) {
		seconds = '0' + seconds
	}
	return minutes + ':' + seconds
}

export const FullscreenView = props => {
	const [isRepaintNeeded, setIsRepaintNeeded] = useState(true)
	const [videoDuration, setVideoDuration] = useState(0)

	const { intl } = props

	const scrollHeight = document.body.scrollHeight
	const mediaUrl = props.media.url
	const thumbnailUrl = mediaUrl.replace(
		/(png|jpg|jpeg)$/,
		getThumbnailByScreenSize(window.innerWidth),
	)

	const imageRef = useRef(null)
	const videoRef = useRef(null)
	const videoPlayButtonRef = useRef(null)
	const videoTimeRef = useRef(null)
	const videoVolumeRef = useRef(null)

	useLayoutEffect(() => {
		if (props.isAccessibilityEnabled === false) {
			return
		}

		if (imageRef.current === null) {
			return
		}

		imageRef.current.focus()
	})

	useLayoutEffect(() => {
		if (props.isAccessibilityEnabled === false) {
			return () => {}
		}

		if (videoRef.current === null) {
			return () => {}
		}

		if (isRepaintNeeded === true) {
			setIsRepaintNeeded(false)

			return () => {}
		}

		videoRef.current.focus()
		videoRef.current.removeAttribute('controls')

		const updateTime = () => {
			const duration = Math.floor(videoRef.current.duration)
			const seconds = Math.floor(videoRef.current.currentTime)
			videoTimeRef.current.textContent = `${secondsToMinutes(seconds)} / ${secondsToMinutes(
				duration,
			)}`
		}

		videoRef.current.addEventListener('timeupdate', updateTime)

		return () => {
			videoRef.current.removeEventListener('timeupdate', updateTime)
		}
	}, [isRepaintNeeded])

	const imageName = mediaUrl.substring(mediaUrl.lastIndexOf('/') + 1, mediaUrl.lastIndexOf('.'))
	const imageUrl = isuuid(imageName) === true ? thumbnailUrl : mediaUrl

	const player = videoRef.current

	const handlePlayClick = () => {
		if (player === null) {
			return
		}

		if (player.paused) {
			player.play()
			videoPlayButtonRef.current.textContent = '⏸'
			videoPlayButtonRef.current.ariaLabel = intl.formatMessage({ id: 'pause' })
		} else {
			player.pause()
			videoPlayButtonRef.current.textContent = '▶'
			videoPlayButtonRef.current.ariaLabel = intl.formatMessage({ id: 'play' })
		}
	}

	const handleStopClick = () => {
		if (player === null) {
			return
		}

		player.pause()
		player.currentTime = 0
		videoPlayButtonRef.current.textContent = '▶'
		videoPlayButtonRef.current.ariaLabel = intl.formatMessage({ id: 'play' })
	}

	const handleFwdClick = () => {
		if (player === null) {
			return
		}

		player.currentTime += 3

		if (player.currentTime >= player.duration || player.paused) {
			player.pause()
			player.currentTime = 0
		}
	}

	const handleRwdClick = () => {
		if (player === null) {
			return
		}

		player.currentTime -= 3
	}

	const handleVolumeClick = isUp => {
		if (player === null) {
			return
		}

		const roundedVolume = _.round(player.volume, 1)

		if ((isUp === true && roundedVolume === 1) || (isUp === false && roundedVolume === 0)) {
			return
		}

		const newVolume = isUp === true ? roundedVolume + 0.1 : roundedVolume - 0.1
		player.volume = newVolume

		videoVolumeRef.current.textContent = intl.formatMessage(
			{ id: 'volume_current' },
			{ number: Math.floor(newVolume * 100) },
		)
	}

	const handleVolumeUpClick = () => {
		handleVolumeClick(true)
	}

	const handleVolumeDownClick = () => {
		handleVolumeClick(false)
	}

	const handleLoadedMetadata = () => {
		if (props.isAccessibilityEnabled === false) {
			return
		}

		if (player === null) {
			return
		}

		setVideoDuration(Math.floor(player.duration))
	}

	const handleVideoKeyUp = event => {
		if (props.isAccessibilityEnabled === false) {
			return
		}

		if (event.keyCode === 32) {
			handlePlayClick()
		}

		if (event.keyCode === 37) {
			handleRwdClick()
		}

		if (event.keyCode === 39) {
			handleFwdClick()
		}
	}

	return (
		<div className="FullscreenView-wrapper">
			<div className="FullscreenView-wrapper-content">
				<div className="FullscreenView-wrapper-content-inner">
					{props.type === MEDIA_TYPE.IMAGE &&
						(props.media.imageFullscreenZoom === null ? (
							<div className="plain-image-holder">
								<img
									className="FullscreenView-image FullscreenView-image-plain"
									src={imageUrl}
									alt={props.media.alt}
									tabIndex={0}
									ref={imageRef}
								/>
							</div>
						) : (
							<div className="image-magnifier-holder">
								<GSReactImageMagnify
									{...{
										largeImage: {
											alt: props.media.alt,
											src: imageUrl,
											width: props.media.imageFullscreenZoom.width,
											height: props.media.imageFullscreenZoom.height,
										},
										smallImage: {
											isFluidWidth: true,
											alt: props.media.alt,
											src: imageUrl,
										},
									}}
									imageClassName="FullscreenView-image"
									imageStyle={{
										width: 'auto',
										maxHeight: `${scrollHeight - 80}px`,
										display: 'inline',
									}}
									style={{
										display: 'flex',
										alignItems: 'center',
										maxHeight: '100%',
										maxWidth: '100%',
									}}
									enlargedImageContainerStyle={{
										bottom: 0,
										right: 0,
										margin: 'auto',
										maxWidth: '100%',
										maxHeight: '100%',
									}}
									isHintEnabled
									shouldHideHintAfterFirstActivation={false}
									enlargedImagePosition="over"
								/>
							</div>
						))}
					{props.type === MEDIA_TYPE.VIDEO && (
						<div className="FullscreenView-video__wrapper">
							<video
								aria-label={props.media.alt}
								className="FullscreenView-video"
								controls={props.media.videoShowControls}
								controlsList="nodownload"
								autoPlay={props.isAccessibilityEnabled === true ? false : props.media.videoAutoPlay}
								onContextMenu={event => {
									event.preventDefault()
								}}
								onKeyUp={handleVideoKeyUp}
								onLoadedMetadata={handleLoadedMetadata}
								tabIndex={0}
								ref={videoRef}
							>
								<source src={props.media.url} />
							</video>
							{props.isAccessibilityEnabled === true && (
								<div className="FullscreenView-video__control-bar">
									<div className="FullscreenView-video__controls">
										<button
											aria-label={intl.formatMessage({ id: 'play' })}
											className="FullscreenView-video__button"
											onClick={handlePlayClick}
											ref={videoPlayButtonRef}
											tabIndex={0}
										>
											⏵
										</button>
										<button
											aria-label={intl.formatMessage({ id: 'stop' })}
											className="FullscreenView-video__button"
											onClick={handleStopClick}
											tabIndex={0}
										>
											⏹
										</button>
										<button
											aria-label={intl.formatMessage({ id: 'rwd' })}
											className="FullscreenView-video__button"
											onClick={handleRwdClick}
											tabIndex={0}
										>
											{'<'}
										</button>
										<button
											aria-label={intl.formatMessage({ id: 'fwd' })}
											className="FullscreenView-video__button"
											onClick={handleFwdClick}
											tabIndex={0}
										>
											{'>'}
										</button>
									</div>
									<div className="FullscreenView-video__controls">
										<button
											aria-label={intl.formatMessage({ id: 'volume_down' })}
											className="FullscreenView-video__button"
											onClick={handleVolumeDownClick}
											tabIndex={0}
										>
											-
										</button>
										<div
											className="FullscreenView-video__volume"
											ref={videoVolumeRef}
											role="status"
										>
											{intl.formatMessage({ id: 'volume_current' }, { number: 100 })}
										</div>
										<button
											aria-label={intl.formatMessage({ id: 'volume_up' })}
											className="FullscreenView-video__button"
											onClick={handleVolumeUpClick}
											tabIndex={0}
										>
											+
										</button>
										<div
											className="FullscreenView-video__time"
											ref={videoTimeRef}
											role="timer"
											tabIndex={0}
										>
											00:00 / {secondsToMinutes(videoDuration)}
										</div>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<div className={'FullscreenView-actionBar'}>
				<ActionBar isFullscreen showContent />
			</div>
		</div>
	)
}

FullscreenView.propTypes = {
	intl: PropTypes.object.isRequired,
	isAccessibilityEnabled: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(Object.values(MEDIA_TYPE)).isRequired,
	media: PropTypes.shape({
		url: PropTypes.string.isRequired,
		alt: PropTypes.string.isRequired,
		videoShowControls: PropTypes.bool,
		videoAutoPlay: PropTypes.bool,
		imageFullscreenZoom: PropTypes.shape({
			height: PropTypes.number.isRequired,
			width: PropTypes.number.isRequired,
		}),
	}),
}

export default FullscreenView
