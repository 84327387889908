// ------------------------------------
// Constants
// ------------------------------------
export const CHAT_RESET = 'chat.reset'
export const CINEMA_SHOW = 'cinema.show'
export const CINEMA_HIDE = 'cinema.hide'
export const FULLSCREEN_SHOW = 'fullscreen.show'
export const FULLSCREEN_HIDE = 'fullscreen.hide'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	showCinemaView: false,
	showFullScreen: false,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => {
	dispatch({ type: CHAT_RESET })
}

export const showCinema = () => (dispatch, getState) => {
	dispatch({ type: CINEMA_SHOW })
}

export const hideCinema = () => (dispatch, getState) => {
	dispatch({ type: CINEMA_HIDE })
}

export const showFullscreen = () => (dispatch, getState) => {
	dispatch({ type: FULLSCREEN_SHOW })
}

export const hideFullscreen = () => (dispatch, getState) => {
	dispatch({ type: FULLSCREEN_HIDE })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[CHAT_RESET]: (state, action) => {
		return initialState
	},
	[CINEMA_SHOW]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.showFullScreen = true

		return newState
	},
	[CINEMA_HIDE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.showFullScreen = false

		return newState
	},
	[FULLSCREEN_SHOW]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.showFullScreen = true

		return newState
	},
	[FULLSCREEN_HIDE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.showFullScreen = false

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
