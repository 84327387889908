import _ from 'lodash'

import { COMPONENT } from 'constants/component'
import { DELAY_LENGTH } from 'constants/delay'

export const PUSH = 'history.push'
export const PUSH_MULTIPLE = 'history.push.multiple'
export const BLUR = 'history.blur'
export const UNBLUR = 'history.unblur'
export const HIDE = 'history.hide'
export const UNHIDE = 'history.unhide'
export const CLEAR = 'history.clear'
export const UPDATE = 'history.update'
export const HISTORY_SET_MEDIA_ACTION_LISTENER = 'history.set.media.action.listener'
export const STOP_CYCLE = 'history.stop.cycle'
export const HISTORY_DID_UPDATE = 'history.did.update'
export const HISTORY_SET_MEDIA_LOADED = 'history.set.media.loaded'
export const HISTORY_RESET_MEDIA_LOADED = 'history.reset.media.loaded'
export const HISTORY_SET_MEDIA_SEEN = 'history.set.media.seen'
export const REMOVE_TEMP = 'history.remove.temporary.messages'
export const SET_CURRENT_MESSAGE = 'history.set.current.message'
export const HISTORY_SET_HEATMAP_SEEN = 'history.set.heatmap.seen'

// ------------------------------------
// Helpers
// ------------------------------------
export const createComponent = definition => {
	let newComponent = Object.assign({}, COMPONENT[definition])
	if (!newComponent) {
		throw new Error('Invalid component requested from history', definition)
	}

	newComponent.props = Object.assign({}, newComponent.props)
	return newComponent
}

// ------------------------------------
// initialState
// ------------------------------------
const initialState = {
	history: {},
	historyList: [],

	mediaActionListener: null,
	mediaCommandListener: null,

	currentMessage: {},
}

// ------------------------------------
// Actions
// ------------------------------------

export const push = (component, position, idHistory, idStudyObject) => (dispatch, getState) => {
	component.position = position
	component.idStudyObject = idStudyObject
	dispatch({
		type: PUSH,
		component: component,
		idHistory,
	})
}

export const pushMultiple = components => (dispatch, getState) => {
	const reversedComponents = components.slice().reverse()
	// find last dispalyed Message module
	// position condition filters out respondents answers
	const lastMessage = reversedComponents.find(
		component => component.type === 'message' && component.component.position === 'left',
	)

	if (lastMessage !== undefined) {
		// eslint-disable-next-line no-use-before-define
		setCurrentMessage({
			idHistory: lastMessage.id,
			id: lastMessage.idStudyObject,
			definition: {
				text: lastMessage.component.props.text,
			},
		})(dispatch, getState)
	}

	dispatch({ type: PUSH_MULTIPLE, components })
}

export const clear = () => (dispatch, getState) => {
	dispatch({ type: CLEAR })
}

export const blur = () => (dispatch, getState) => {
	dispatch({ type: BLUR })
}

export const unblur = () => (dispatch, getState) => {
	dispatch({ type: UNBLUR })
}

export const setHeatmapToSeen = id => (dispatch, getState) => {
	dispatch({
		type: HISTORY_SET_HEATMAP_SEEN,
		id,
	})
}

export const onMediaAction = idHistory => (dispatch, getState) => {
	let state = getState()
	let mediaActionListener = state.chatHistory.mediaActionListener
	const historyModule = state.chatHistory.history[idHistory]
	let media = historyModule.props.media
	let minTimer = media.actionButton ? media.actionButton.minTimer : null
	let maxTimer = media.actionButton ? media.actionButton.maxTimer : null
	let buttonLabel = media.actionButton ? media.actionButton.label : null
	let videoShowControls = true
	let videoAutoPlay = false
	let imageFullscreenZoom = null
	if (media.render !== null) {
		videoShowControls = media.render.videoShowControls
		videoAutoPlay = media.render.videoAutoPlay
		imageFullscreenZoom = media.render.imageFullscreenZoom
	}

	if (historyModule.isHeatmap === true && historyModule.isHeatmapSeen === false) {
		historyModule.openHeatmap(historyModule.heatmapDefinition)(dispatch, getState)
		setHeatmapToSeen(idHistory)(dispatch, getState)
		return
	}

	if (historyModule.isBlurred === false && mediaActionListener) {
		mediaActionListener(
			idHistory,
			media.type,
			media.url,
			media.alt,
			media.seen,
			minTimer,
			maxTimer,
			buttonLabel,
			videoShowControls,
			videoAutoPlay,
			imageFullscreenZoom,
		)(dispatch, getState)
	}
}

export const onMediaLoad = () => (dispatch, getState) => {
	dispatch({ type: HISTORY_SET_MEDIA_LOADED })
}

export const resetMediaLoaded = () => (dispatch, getState) => {
	dispatch({ type: HISTORY_RESET_MEDIA_LOADED })
}

export const setMediaActionListener = actionListener => (dispatch, getState) => {
	dispatch({
		type: HISTORY_SET_MEDIA_ACTION_LISTENER,
		actionListener,
	})
}

export const setMediaToSeen = id => (dispatch, getState) => {
	dispatch({
		type: HISTORY_SET_MEDIA_SEEN,
		id,
	})
}

export const removeTempMessages = () => (dispatch, getState) => {
	// let animation finish
	setTimeout(() => {
		dispatch({ type: REMOVE_TEMP })
	}, DELAY_LENGTH.LONG)
}

export const setCurrentMessage = studyObject => (dispatch, getState) => {
	dispatch({
		type: SET_CURRENT_MESSAGE,
		studyObject,
	})
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	// @TODO: update message to show hidden parts (image choice)
	[PUSH]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.historyList = [...state.historyList]
		newState.historyList.push(action.idHistory)
		newState.history = Object.assign({}, state.history)
		newState.history[action.idHistory] = action.component
		return newState
	},
	[PUSH_MULTIPLE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.historyList = [...state.historyList]
		newState.history = Object.assign({}, state.history)
		action.components.forEach(item => {
			newState.historyList.push(item.id)
			newState.history[item.id] = item.component
		})
		return newState
	},
	[BLUR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.history = Object.assign({}, state.history)
		newState.historyList.forEach(module => {
			newState.history[module] = Object.assign({}, state.history[module])
			newState.history[module].isBlurred = true
		})
		return newState
	},
	[UNBLUR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.history = Object.assign({}, state.history)
		newState.historyList.forEach(module => {
			newState.history[module] = Object.assign({}, state.history[module])
			newState.history[module].isBlurred = false
		})
		return newState
	},
	[CLEAR]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.history = initialState.history
		newState.historyList = initialState.historyList
		return newState
	},
	[HISTORY_SET_MEDIA_ACTION_LISTENER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.mediaActionListener = action.actionListener
		return newState
	},
	[HISTORY_SET_MEDIA_SEEN]: (state, action) => {
		let newState = Object.assign({}, state)
		if (newState.history[action.id].props.media) {
			newState.history = Object.assign({}, state.history)
			newState.history[action.id] = Object.assign({}, state.history[action.id])
			newState.history[action.id].props = Object.assign({}, state.history[action.id].props)
			newState.history[action.id].props.media = Object.assign(
				{},
				state.history[action.id].props.media,
			)
			newState.history[action.id].props.media.seen = true
		}
		return newState
	},
	[HISTORY_SET_HEATMAP_SEEN]: (state, action) => {
		const newState = _.cloneDeep(state)

		newState.history[action.id].isHeatmapSeen = true
		newState.history[action.id].props.media.seen = true

		return newState
	},
	[REMOVE_TEMP]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.historyList = []
		newState.history = {}

		state.historyList.forEach(idHistory => {
			const item = state.history[idHistory]

			if (item.isTemporary === true) {
				return
			}

			newState.history[idHistory] = item
			newState.historyList.push(idHistory)
		})

		return newState
	},
	[SET_CURRENT_MESSAGE]: (state, action) => {
		const newState = Object.assign({}, state)
		const { studyObject } = action
		newState.currentMessage = {
			id: studyObject.id,
			idHistory: studyObject.idHistory,
			text: studyObject.definition.text,
		}
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function intlReducer(state = initialState, action) {
	let actionHandler = action.type
	const handler = ACTION_HANDLERS[actionHandler]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters
// ------------------------------------
export const getCurrentMessage = () => (dispatch, getState) => {
	return getState().chatHistory.currentMessage
}
