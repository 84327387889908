import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const HEATMAP_RESET = 'heatmap.reset'
export const HEATMAP_RESET_ANSWERS = 'heatmap.reset.answers'
export const HEATMAP_ADD_ANSWER = 'heatmap.add.answer'
export const HEATMAP_SHOW = 'heatmap.show'
export const HEATMAP_ADD_SUBMIT_LISTENER = 'heatmap.add.submit.listener'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	answers: [],
	definition: {},
	isVisible: false,
	submitListener: null,
}

// ------------------------------------
// getters
// ------------------------------------
const getChatHeatmapState = state => {
	return state.chatHeatmap
}

export const selectors = {
	getChatHeatmapState,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => {
	dispatch({ type: HEATMAP_RESET })
}

export const heatmapShow = definition => (dispatch, getState) => {
	dispatch({ type: HEATMAP_SHOW, definition })
}

export const heatmapResetAnswers = () => (dispatch, getState) => {
	dispatch({ type: HEATMAP_RESET_ANSWERS })
}

export const heatmapAddAnswer = answer => (dispatch, getState) => {
	dispatch({ type: HEATMAP_ADD_ANSWER, answer })
}

export const heatmapSubmit = () => (dispatch, getState) => {
	const state = getState()

	const { submitListener, answers } = state.chatHeatmap

	submitListener(answers)(dispatch, getState)
}

export const addSubmitListener = submitListener => (dispatch, getState) => {
	dispatch({ type: HEATMAP_ADD_SUBMIT_LISTENER, submitListener })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[HEATMAP_RESET]: (state, action) => {
		return _.cloneDeep(initialState)
	},
	[HEATMAP_RESET_ANSWERS]: (state, action) => {
		const newState = _.cloneDeep(state)

		newState.answers = []

		return newState
	},
	[HEATMAP_ADD_ANSWER]: (state, action) => {
		const newState = _.cloneDeep(state)

		if (newState.answers.length >= newState.definition.maxAnswers) {
			return newState
		}

		newState.answers.push(action.answer)

		return newState
	},
	[HEATMAP_SHOW]: (state, action) => {
		const newState = _.cloneDeep(state)

		newState.isVisible = true
		newState.definition = action.definition

		return newState
	},
	[HEATMAP_ADD_SUBMIT_LISTENER]: (state, action) => {
		const newState = _.cloneDeep(state)

		newState.submitListener = action.submitListener

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = _.cloneDeep(initialState), action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
