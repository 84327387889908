import React from 'react'

export default store => ({
	path: '*',
	getComponent(nextState, cb) {
		require.ensure(
			[],
			require => {
				cb(null, props => {
					const Component = require('components/_scaffolding/TranslatedText').default
					const ComponentProps = {
						id: 'not_found',
					}
					return <Component {...ComponentProps} {...props} />
				})
			},
			'notfound',
		)
	},
})
