import PropTypes from 'prop-types'
import mediaShape from './media'

const optionShape = PropTypes.shape({
	id: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	reported: PropTypes.bool,
	label: PropTypes.string.isRequired,
	media: mediaShape,
	visible: PropTypes.bool,
})

export default optionShape
