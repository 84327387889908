import { connect } from 'react-redux'
import PlatformStatus from './PlatformStatus'
import { getPlatformStatus } from 'store/platformStatus'

const mapActionCreators = { getPlatformStatus }

const mapStateToProps = state => ({
	isSolverOnline: state.platformStatus.isSolverOnline,
})

export default connect(
	mapStateToProps,
	mapActionCreators,
)(PlatformStatus)
