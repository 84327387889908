export const MODULE_TYPES = {
	CHOICE: 'A_CHOICE',
	END_STUDY: 'A_END_STUDY',
	FREE_TEXT: 'A_FREE_TEXT',
	MESSAGE: 'A_MESSAGE',
	OEQ_ELABORATE: 'A_OEQ_ELABORATE',
	OEQ_ELIMIANTE: 'A_OEQ_ELIMINATE',
	OEQ_IDEATE: 'A_OEQ_IDEATE',
	UI_COMMAND: 'UI_COMMAND',
	RESEARCH_LABELING: 'RESEARCH_LABELING',
}
