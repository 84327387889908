import { API } from 'store/api'
import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addAction, addTempMessage } from 'store/queue'
import {
	setActionSubmitListener,
	setReportContentListener,
	showActionButton,
	showActionLoader,
	showActionHint,
	hideActionBar,
} from 'store/_chat/actionBar'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'
import { actions as reportContentFormActions } from 'store/_chat/reportContentForm'
import {
	setOptionsChangeListener,
	actionsShow,
	actionsHide,
	getActionOptions,
} from 'store/_chat/actions'
import { createComponent, removeTempMessages } from 'store/_chat/history'

import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { COMPONENTS, POSITION } from 'constants/component'

import { CHOICE_SUBTYPE } from 'constants/choice'
import { SUGGESTION_IDS, SUGGESTION_ANSWERS } from 'constants/eliminate'
import { DELAY_LENGTH } from 'constants/delay'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'eliminate.add.response'
export const SET_STEP_DATA = 'eliminate.set.step.data'

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	setOptionsChangeListener(optionsChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setActionSubmitListener(primaryActionListener, secondaryActionListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	const hasPriorMessage = module.priorMessage !== null

	if (hasPriorMessage === true) {
		const priorMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

		let priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		priorMessage.props.shouldAutoFocus = true
		addTempMessage(priorMessage, priorMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep(module.steps[0], hasPriorMessage === false)(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const showNextStep = (step, shouldAutoFocus) => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let currentStep = definition.steps[responses.length]
	let numOfReplacements = currentStep.suggestions.length

	dispatch({ type: SET_STEP_DATA, stepData: step })
	setReportContentListener(reportContentListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	const solverMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	if (responses.length === 0) {
		let intro = createComponent(COMPONENTS.MESSAGE)
		intro.props.text = formatMessage('eliminate_intro')(dispatch, getState)
		intro.props.shouldAutoFocus = shouldAutoFocus
		addTempMessage(intro, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	} else {
		let intro = createComponent(COMPONENTS.MESSAGE)
		intro.props.shouldAutoFocus = shouldAutoFocus
		intro.props.text = formatMessage('eliminate_step')(dispatch, getState)
		addTempMessage(intro, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	let quote = createComponent(COMPONENTS.QUOTE)
	quote.props.text = definition.steps[responses.length].label
	quote.props.id = definition.steps[responses.length].idStatement
	addTempMessage(quote, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	if (numOfReplacements > 1) {
		let intro1 = createComponent(COMPONENTS.MESSAGE)
		intro1.props.text = formatMessage('eliminate_multiple_suggestions')(dispatch, getState)
		addTempMessage(intro1, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState) // eslint-disable-line no-use-before-define

		let choices = definition.steps[responses.length].suggestions.map(option => {
			return {
				id: option.idStatement,
				label: option.label,
				selected: false,
			}
		})

		addAction(actionsShow, [CHOICE_SUBTYPE.CHECKBOX, choices])(dispatch, getState)
		let buttonText = formatMessage('eliminate_none_of_the_above')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, buttonText])(dispatch, getState)
	} else if (numOfReplacements === 1) {
		let intro1 = createComponent(COMPONENTS.MESSAGE)
		intro1.props.text = formatMessage('eliminate_one_suggestion')(dispatch, getState)
		addTempMessage(intro1, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

		let suggestion = createComponent(COMPONENTS.QUOTE)
		suggestion.props.text = currentStep.suggestions[0].label
		suggestion.props.id = currentStep.suggestions[0].idStatement
		addTempMessage(suggestion, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

		let answers = SUGGESTION_ANSWERS.map(option => {
			return {
				id: option.idStatement,
				label: formatMessage(option.label)(dispatch, getState),
				selected: false,
			}
		})
		addAction(
			actionsShow,
			[CHOICE_SUBTYPE.CHECKBOX, answers],
			DELAY_LENGTH.LONG,
		)(dispatch, getState)
		addAction(hideActionBar)(dispatch, getState)
	}
}

export const reportContentListener = () => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)
	const stepData = getState().eliminate.stepData
	if (stepData) {
		reportContentFormActions.open(studyObject, stepData)(dispatch, getState)
	}
}

export const optionsChangeListener = options => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let currentStep = definition.steps[responses.length]
	let numOfReplacements = currentStep.suggestions.length
	let selectedOptions = options.filter(option => option.selected === true)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (selectedOptions.length >= 1) {
		if (numOfReplacements > 1) {
			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			let buttonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
			showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
		} else {
			// one click submit on yes/no answer
			primaryActionListener()(dispatch, getState) // eslint-disable-line no-use-before-define
		}
	} else {
		if (numOfReplacements > 1) {
			let buttonText = formatMessage('eliminate_none_of_the_above')(dispatch, getState)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			showActionButton(ACTION_BUTTON_STATE.SKIP_READY, buttonText)(dispatch, getState)
		} else {
			let hintText = formatMessage('choice_single_hint')(dispatch, getState)
			showActionHint(hintText)(dispatch, getState)
		}
	}
}

export const primaryActionListener = () => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let options = getActionOptions()(dispatch, getState)

	let currentStep = definition.steps[responses.length]
	let selectedOptions = options.filter(option => option.selected === true)
	let predefinedSelections = SUGGESTION_ANSWERS.map(option => option.idStatement)

	// build response object
	let newResponse = {
		label: currentStep.label,
		idStatement: currentStep.idStatement,
		replacements: [],
	}

	// handle case with 1 suggestion and YES/NO answer
	if (predefinedSelections.includes(selectedOptions[0].id)) {
		// only add response if YES was selected
		if (selectedOptions[0].id === SUGGESTION_IDS.YES) {
			newResponse.replacements.push(currentStep.suggestions[0])
		}
	} else {
		newResponse.replacements = selectedOptions.map(option => {
			return {
				idStatement: option.id,
				label: option.label,
			}
		})
	}
	// add response to store
	dispatch({ type: ADD_RESPONSE, newResponse })

	showActionLoader()(dispatch, getState)
	actionsHide()(dispatch, getState)

	removeTempMessages()(dispatch, getState)

	let allResponses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	// show prompt for new answer
	if (allResponses.length < definition.steps.length) {
		let nextStep = definition.steps[allResponses.length + 1]

		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			showNextStep(nextStep, true)(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	} else if (allResponses.length === definition.steps.length) {
		endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const secondaryActionListener = () => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let currentStep = definition.steps[responses.length]

	// build response object
	let newResponse = {
		label: currentStep.label,
		idStatement: currentStep.idStatement,
		replacements: [],
	}

	// add response to store
	dispatch({ type: ADD_RESPONSE, newResponse })

	showActionLoader()(dispatch, getState)
	actionsHide()(dispatch, getState)

	removeTempMessages()(dispatch, getState)

	let allResponses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	if (allResponses.length < definition.steps.length) {
		let nextStep = definition.steps[allResponses.length + 1]

		/**
		 * removeTempMessages has 500ms animation timeout so we wait
		 * for it to finish before showing step that adds new temp messages
		 */
		setTimeout(() => {
			showNextStep(nextStep, true)(dispatch, getState)
		}, DELAY_LENGTH.LONG)
	} else if (allResponses.length === definition.steps.length) {
		endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const endStep = () => (dispatch, getState) => {
	let studyObject = getStudyObject()(dispatch, getState)
	let responses = getEliminateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	dispatch({ type: SET_STEP_DATA, stepData: null })
	setReportContentListener()(dispatch, getState)

	let response = {}
	response[studyObject.id] = responses
	getState().study.getNextStep(response, null, true)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
	stepData: null,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses]
		newState.responses.push(action.newResponse)

		return newState
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'eliminate') {
			return state
		}
		let newState = Object.assign({}, state)
		newState.responses = initialState.responses

		return newState
	},
	[SET_STEP_DATA]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.stepData = action.stepData

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters
// ------------------------------------
export const getEliminateResponses = () => (dispatch, getState) => {
	let state = getState()
	return state.eliminate.responses
}
