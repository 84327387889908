/* eslint-disable max-len */

const messages = {
	not_found: '您访问的页面不存在',
	service_outage: '由于我们供应商的云服务全球中断，我们的系统故障。我们深表歉意',
	error_unexpected_error: '遇到异常。请稍后再试',

	error_img_alt: '图片错误',
	error_try_again: '请再试一次',

	error_generic_header: '出错了',
	error_generic_message:
		'请再试一次。如果还是不行， 请连同上面的二维码，发送邮件到 support@groupsolver.com',

	error_offline_header: '请检查网络连接',
	error_offline_message: '在您的网络重新连接后，你的问卷调查将继续',

	error_landscape_small_screen_header: '请旋转您的设备',
	error_landscape_small_screen_text: '你的屏幕太短，无法看到这个问卷调查。请旋转您的设备',

	error_small_browser_window_header: '请放大您的浏览器窗口',
	error_small_browser_window_text: '您的浏览器窗口太小，无法看到这个问卷调查。请放大您的浏览器窗口',

	login_error_empty_email: '电子邮件不能为空',
	login_error_invalid_email: '请输入有效电子邮件',
	login_error_unable_to_login: '目前无法登录。请稍后再试',

	login_email_placeholder: '输入您的电子邮件',
	login_email_button: '继续',
	login_email_back: '或者回到登录选项',
	login_email_label: '使用电子邮件继续',
	login_anonymous_label: '匿名继续',

	login_privacy_statement: 'GroupSolver尊重您的隐私和您提供的信息',
	login_privacy_statement_email:
		'除了授权进入这个问卷调查，你的电子邮件将永远不会被分享、出售或以任何方式使用',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: '您正在寻找的问卷调查现在无法访问',
	study_unavailable_description:
		'感谢您对我们的调查问卷感兴趣! 遗憾的是，此调查问卷已经结束或暂停。对于由此造成的不便，我们深表歉意',
	study_preview_limit_reached_label: '您已经达到预览上限',
	study_preview_limit_reached_description:
		'感谢您对我们的调查问卷感兴趣! 遗憾的是，这个问卷调查已经达到了预览上限',

	waiting_message:
		'一些人正在完成此问卷调查。我们将自动重试输入{number, plural, =0 {现在} one {在#秒后} other {在#秒后s}}',
	waiting_error_recoverable: '遇到异常',
	waiting_error_recoverable_retry:
		'我们将再次尝试 {number，plural，=0 {现在} one {在#秒后} other {在#秒后s}}。',
	waiting_error_unknown_study: '您访问的问卷调查不存在。',
	waiting_error_dead: '遇到异常。请稍后再试。',

	action_input_submit_button_text: '提交',
	action_input_skip_button_text: '继续',

	choice_single_hint: '请选择一个选项',
	general_skip_action_message: '继续下一步',

	action_button_primary_default_text: '开始',
	action_button_hint_default_text: '请选择一个选项',

	message_media_examine_video: '点击视频查看',
	message_media_examine_image: '点击图片查看',
	message_full_screen_close_button_text: '关闭页面',
	message_image_view_label: '查看图片',
	message_video_view_label: '查看视频',
	message_image_examined_label: '图片已被查看',
	message_video_examined_label: '视频已被查看',
	message_zoom_mouse: '请将鼠标放在图片上放大',
	message_zoom_touch: '请点击图片放大',

	textarea_too_long_hint: '您输入的文本太长了',

	ideate_placeholder: '请用简短的句子回答',
	ideate_skip: '或者继续',

	eliminate_no: '否',
	eliminate_yes: '是',
	eliminate_intro: '考虑以下陈述',
	eliminate_multiple_suggestions: '下列哪项可以代替它?',
	eliminate_one_suggestion: '它是否可以被以下替换？',
	eliminate_step: '考虑另一个陈述',
	eliminate_none_of_the_above: '以上皆否',

	elaborate_indifferent_label: '不确定',
	elaborate_disagree_label: '我不同意',
	elaborate_agree_label: '我同意',
	elaborate_unclear_statement_label: '意思不清楚',
	elaborate_intro: '其他人给出了以下答案。你同意他们的观点吗?',

	ideamatching_unsure_label: '不确定',
	ideamatching_unequal_label: '不同',
	ideamatching_equal_label: '相同',
	ideamatching_intro: '请看以下两个答案。它们的意思一样吗?',

	select_input_placeholder: '在这里输入选择',
	select_input_placeholder_max: '达到最大字数限制',
	select_multiple_choice_hint:
		'选择{minNumber，plural，=0 {0} other {#}}到{maxNumber，plural，=0 {0} other {#}}选项',
	select_no_matches_found: '没有匹配的搜索结果',

	report_content_inappropriate: '不恰当的答案',
	report_content_profane: '淫秽或亵渎的内容',
	report_content_gibberish: '乱语或无意义的文字',
	report_content_irrelevant: '与被问到的问题无关',
	report_content_different_language: '不同的语言',
	report_content_other: '其他',
	report_content_no_content: '无举报内容',
	report_content_header: '举报答案',
	report_content_form_header: '请选择您想举标记此答案的原因',
	report_content_flag_answer: '标记答案',
	report_content_return_to_study: '返回问卷调查',
	report_content_thank_you: '谢谢你的反馈。我们会审核你的意见，并在有需要时采取行动。',
	report_content_statement_flagged: '此陈述已被标记。',

	platform_update_header: 'GroupSolver 目前正在进行维护。',
	platform_update_message: '给您带来不便，我们深感歉意。',

	none_of_these: '这些都不是',

	allocation_instruction_limit: '在选项之间分配{points}',
	allocation_instruction_limit_no_minimum: '在选项之间最多分配{points}',
	allocation_instruction_no_limit: '在选项之间分配点数',
	allocation_instruction_points_left: '剩余{points}',
	allocation_options_out_of_range: '一些选项超出范围',
	allocation_limit_overflow: '{points}超出限制',
	allocation_option_invalid_range: '值超出{range}范围',
	allocation_option_invalid_min: '值必须大于或等于{min}',

	dropdown_select: '选择…',

	ranking_drag_options: '拖动或点击箭头以对选项进行排名',
	ranking_assign_option_values: '为所有选项排名',
	ranking_require_unique_values: '选项排名必须唯一',

	heatmap_instruction_intro: '要开始选择，请查看下面的图片。',
	heatmap_instruction_unlimited: '点击图片可以选择尽可能多的点。',
	heatmap_instruction_count:
		'点击图片选择 {number, plural, =0 {0个点} one {1个点} other {{number}个点}}。',
	heatmap_instruction_up_to_count:
		'点击图片选择 {number, plural, =0 {最多0个点} one {1个点} other {最多{number}个点}}。',
	heatmap_instruction_between_count: '点击图片选择 {from} 到 {to} 个点。',
	heatmap_instruction_at_least_count:
		'点击图片至少选择 {number, plural, =0 {0个点} one {1个点} other {{number}个点}}。',
	heatmap_response: '{number, plural, =0 {0个点} one {1个点} other {{number}个点}} 已选',

	continue: '继续',
	reset: '重置',

	input_invalid: '输入无效。',
	input_empty: '输入不能为空。',
	input_chars_left: '还剩 {number} {number, plural, =0 {个字符} one {个字符} other {个字符}}。',
	input_too_long: '超出限制 {number} {number, plural, =0 {个字符} one {个字符} other {个字符}}。',

	// accessibility
	ranking_select_rank: '选择 {label} 的排名。',

	ranking_select_aria_label: '通过输入数值为每个选项选择排名。',
	ranking_dnd_aria_label:
		'聚焦到一个选项并按空格键开始拖动。拖动时可以使用箭头键移动项目，按Esc取消。再次按空格键将项目放置到新位置。确保您的屏幕阅读器处于焦点模式或表单模式。',

	allocation_option_input: '{label} 值输入。',
	allocation_aria_label: '使用输入框为每个选项输入值。',

	play: '播放',
	pause: '暂停',
	stop: '停止',
	fwd: '快进',
	rwd: '倒带',
	volume_down: '降低音量',
	volume_up: '提高音量',
	volume_current: '音量：{number}%',

	loading: '加载中',

	press_tab_to_continue: '按Tab键继续',

	option_focus_selected: '选项 {label} 已聚焦。按回车取消选择。',
	option_focus_not_selected: '选项 {label} 已聚焦。按回车选择。',

	image_alt: '图片：',
	video_alt: '视频：',

	submit_enabled: '提交已启用。',
	skip_enabled: '跳过问题已启用',
	n_of_min_selected: '已选择 {selected} 个，至少需要选择 {min} 个。',
	option_selected: '{label} 已选择。',
	option_deselected: '{label} 已取消选择。',

	choice_multiple_hint: '选择 {min} 到 {max} 个选项。',

	ranking_position: '位置：{position}',
	ranking_position_changed: '{label} 的值已设置为 {value}。',
	ranking_input_aria_label: '{label}。当前位置：{position}。输入1到{max}之间的一个位置。',
	not_selected: '未选择',

	study_logo_alt: '研究标志',
	groupsolver_logo_alt: 'Groupsolver 标志',
}

export default messages
