import React from 'react'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'
import './ActionTextArea.scss'
import { SCROLL_TYPES } from 'constants/scroll'
import classnames from 'classnames'

class ActionTextArea extends React.Component {
	constructor(props) {
		super()
		this.props = props
		this.createState = this.createState.bind(this)
		this.handleOnChange = this.handleOnChange.bind(this)
		this.state = this.createState(props)
		this._scrollToBottom = this._scrollToBottom.bind(this)
		this.handleOnKeyDown = this.handleOnKeyDown.bind(this)
		this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
	}

	componentDidMount() {
		this.textarea.addEventListener('focus', this._scrollToBottom)
	}

	componentWillUnmount() {
		this.textarea.removeEventListener('focus', this._scrollToBottom)
	}

	_scrollToBottom() {
		// scroll down when:
		// keyboard opened (fix for iOs devices)
		if (this.props.chatViewScroll) {
			this.props.chatViewScroll(SCROLL_TYPES.BOTTOM_SCROLL)
		}
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		let newState = Object.assign({}, this.state)
		if (this.state.lastVisibility !== nextProps.isVisible) {
			newState = this.createState(nextProps)
		}

		if (nextProps.isDelayed) {
			if (newState.timeout !== null) {
				window.clearTimeout(newState.timeout)
			}
			if (nextProps.value.length > 0) {
				if (nextProps.isValid) {
					newState.showValidator = true
				} else {
					newState.timeout = window.setTimeout(() => {
						let newStateInner = Object.assign({}, this.state)
						newStateInner.showValidator = true
						this.setState(newStateInner)
					}, 500)
				}
			}
		} else {
			newState.showValidator = nextProps.value.length > 0 || nextProps.hint !== ''
		}
		newState.showPlaceholder = nextProps.value.length === 0
		this.setState(newState)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isEnabled && this.textarea) {
			this.textarea.focus()
		}
	}

	createState(props) {
		let newState = Object.assign({}, this.state)
		newState.showPlaceholder = this.props.value.length === 0
		newState.showValidator = false
		newState.lastVisibility = props.isVisible
		newState.lastEnable = props.isEnabled
		return newState
	}

	handleOnChange(event) {
		let value = event.currentTarget.value
		this.props.onChange(value, [])
	}

	handleOnKeyDown(event) {
		if (this.props.hasSingleRow && event.keyCode === 13) {
			event.preventDefault()
		}
	}

	handleOnKeyUp(event) {
		if (this.props.hasSingleRow && event.keyCode === 13) {
			event.preventDefault()
			if (this.props.isValid && this.props.isEnabled && !this.props.isKeyNavigationDisabled) {
				this.props.onSubmit(event.currentTarget.value, [])
			}
		}
	}

	render() {
		const hasSingleRow = this.props.hasSingleRow
		const isValidatorVisible = this.props.isValidatorVisible && this.state.showValidator
		const hint = this.props.hint || ''

		return (
			<div
				className={classnames('ActionTextArea-wrapper', {
					'ActionTextArea-wrapper-single-line': hasSingleRow,
				})}
			>
				<textarea
					aria-label={this.props.placeholder}
					ref={textarea => {
						this.textarea = textarea
					}}
					className={classnames('ActionTextArea-textArea', {
						'ActionTextArea-textArea-single-line': hasSingleRow,
						'ActionTextArea-textArea--accessiblity': this.props.isAccessibilityEnabled === true,
					})}
					disabled={!this.props.isEnabled}
					value={this.props.value}
					onChange={this.handleOnChange}
					onClick={this.handleOnClick}
					onFocus={this.handleOnFocus}
					onKeyDown={this.handleOnKeyDown}
					onKeyUp={this.handleOnKeyUp}
					onBlur={this.handleOnBlur}
				/>
				<div
					className={classnames('ActionTextArea-placeholder', {
						'ActionTextArea-placeholder--rtl': this.props.isStudyRtl === true,
						'ActionTextArea-placeholder-single-line': hasSingleRow,
					})}
				>
					{this.state.showPlaceholder && (
						<div className="ActionTextArea-placeholder-wrapper">
							<span className="ActionTextArea-placeholder-placeholder">
								{this.props.placeholder}
							</span>
						</div>
					)}
				</div>
				{isValidatorVisible && (hint !== '' || !this.props.isValid) && (
					<div
						className={classnames('ActionTextArea-validity-sign', {
							'ActionTextArea-validity-sign--ltr': this.props.isStudyRtl === false,
							'ActionTextArea-validity-sign--rtl': this.props.isStudyRtl === true,
							'ActionTextArea-validity-sign-valid':
								this.props.isValid || this.props.value.length === 0,
							'ActionTextArea-validity-sign-invalid':
								(this.props.isValid || this.props.value.length === 0) === false,
							'icon-cross': hint === '' && !this.props.isValid,
						})}
					>
						{hint}
					</div>
				)}
			</div>
		)
	}
}

ActionTextArea.propTypes = {
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func,

	maxLength: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
	hasSingleRow: PropTypes.bool.isRequired,
	isAccessibilityEnabled: PropTypes.bool.isRequired,
	isValidatorVisible: PropTypes.bool.isRequired,
	isValid: PropTypes.bool.isRequired,
	isEnabled: PropTypes.bool.isRequired,
	isDelayed: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
	isVisible: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
	isKeyNavigationDisabled: PropTypes.bool.isRequired,
	isStudyRtl: PropTypes.bool.isRequired,
	hint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	chatViewScroll: PropTypes.func,

	intl: intlShape.isRequired, // eslint-disable-line react/no-unused-prop-types
}

export default ActionTextArea
