import { requestStudyEntry } from 'store/_service/waitingRoom'
import { monitorCalls } from 'store/apiMonitor'
import { API } from 'store/api'
import { getStoredStudy, getStoredPcid, setUserInfo, setPcid } from 'store/user'
import { browserHistory } from 'react-router'

import { redirectIfInvalidParams } from 'routes/helpers'

const handleLogin = (nextState, replace) => (dispatch, getState) => {
	redirectIfInvalidParams(nextState.params)

	monitorCalls([API.REQUEST_STUDY_ENTRY, API.GET_NEXT_STEP, API.LOGIN])(dispatch, getState)

	// restore pcid or generate new
	setPcid(getStoredPcid())(dispatch, getState)

	const pcid = getState().user.pcid
	if (pcid === null || pcid === undefined) {
		const { pathname, search } = window.location

		window.location = `/not-supported.html?url=${pathname}${search}`
		return
	}

	let userInfo = getStoredStudy(nextState.params.idStudy, nextState.location.search)
	let studyInfo = {
		idStudy: null,
		query: '',
		token: null,
	}

	if (
		nextState.params.idStudy &&
		userInfo.idStudy !== nextState.params.idStudy &&
		userInfo.search !== nextState.location.search
	) {
		/*
			use studyInfo from URL if
			1. idStudy param is present
			2. idStudy or query is different then what we have stored for user
		*/
		studyInfo.idStudy = nextState.params.idStudy
		studyInfo.search = nextState.location.search
		studyInfo.query = nextState.location.query
	} else {
		/*
			Try to restore studyInfo from user storage
		*/
		studyInfo.idStudy = userInfo.idStudy
		studyInfo.query = userInfo.query
		studyInfo.search = userInfo.search
		studyInfo.token = userInfo.token
	}

	let state = getState()
	let entryAllowed = state.waitingRoom.isEntryAllowed

	if (studyInfo.idStudy && entryAllowed === false) {
		/*
			request entry for current study
		*/
		setUserInfo(
			studyInfo.idStudy,
			studyInfo.search,
			studyInfo.query,
			studyInfo.token,
		)(dispatch, getState)
		requestStudyEntry(studyInfo.idStudy, studyInfo.query)(dispatch, getState)
	} else if (entryAllowed === true && studyInfo.token === null) {
		/*
			newUser wihout stored apiToken
			-> showLoginScreen
		*/
		setUserInfo(
			studyInfo.idStudy,
			studyInfo.search,
			studyInfo.query,
			studyInfo.token,
		)(dispatch, getState)
	} else if (entryAllowed === true && studyInfo.token !== null) {
		/*
		 returning user with stored token
		 -> continue solving
		*/
		browserHistory.replace('/solve/' + studyInfo.idStudy)
	} else {
		browserHistory.replace('/not-found')
	}
}

export default (store, authHandler, loadStudyOptionsSpy = null) => ({
	path: 'login(/:idStudy)',
	onChange(prevState, nextState, replace) {
		handleLogin(nextState, replace)(store.dispatch, store.getState)
	},
	onEnter(nextState, replace) {
		const redirects = {
			'/login/199c587d-d389-495d-9ff0-6131a3ccea21(?!.*gs_preview)':
				'/preview/199c587d-d389-495d-9ff0-6131a3ccea21',
		}

		for (const [key, value] of Object.entries(redirects)) {
			const regex = new RegExp(key, 'gm')

			const matches = window.location.href.match(regex)

			if (matches !== null && matches.length > 0) {
				const url = window.location.href.replace(regex, value)
				return replace(url)
			}
		}

		return handleLogin(nextState, replace)(store.dispatch, store.getState)
	},
	/*  Async getComponent is only invoked when route matches   */
	getComponent(nextState, cb) {
		/*  Webpack - use 'require.ensure' to create a split point
		 and embed an async module loader (jsonp) when bundling   */
		require.ensure(
			[],
			require => {
				/*  Webpack - use require callback to define
			 dependencies for bundling   */
				const Component = require('./LoginRouteContainer').default

				/*  Return getComponent   */
				cb(null, Component)

				/* Webpack named bundle   */
			},
			'login',
		)
	},
})
