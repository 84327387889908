import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import QRCode from 'qrcode.react'
import classnames from 'classnames'

import { ERROR_TYPES } from 'store/_service/error'

import ActionButton from 'components/ActionButton'
import NetworkChecker from 'components/_scaffolding/NetworkChecker'
import PlatformStatus from 'components/_scaffolding/PlatformStatus'

import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { ACTION_BAR_TYPE } from 'constants/actionBar'

import landscapeError from './error_landscape.svg'
import resizeError from './error_browser_resize.svg'

import './CoreLayout.scss'
import '../../styles/core.scss'

const createState = props => {
	let orientationClass = 'dektop'

	if (window.orientation === 0 || window.orientation === 180) {
		orientationClass = 'portrait'
	} else if (window.orientation === 90 || window.orientation === -90) {
		orientationClass = 'landscape'
	}

	return { orientationClass }
}

const CoreLayout = props => {
	const [state, setState] = useState(createState(props))
	const errorHeaderRef = useRef(null)

	const { errorType, intl, showFullScreen } = props

	const handleResize = () => {
		setState(createState(props))
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)
		window.addEventListener('orientationchange', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('orientationchange', handleResize)
		}
	}, [handleResize])

	useLayoutEffect(() => {
		if (errorHeaderRef.current === null) {
			return
		}

		errorHeaderRef.current.focus()
	}, [])

	let errorHeader = intl.formatMessage({ id: 'error_small_browser_window_header' })
	let errorDescription = intl.formatMessage({ id: 'error_small_browser_window_text' })
	let imageSrc = resizeError

	const userAgent = window.navigator.userAgent

	if (
		userAgent.match(/Android/i) ||
		userAgent.match(/webOS/i) ||
		userAgent.match(/iPhone/i) ||
		userAgent.match(/iPad/i) ||
		userAgent.match(/iPod/i) ||
		userAgent.match(/BlackBerry/i) ||
		userAgent.match(/Windows Phone/i)
	) {
		errorHeader = intl.formatMessage({ id: 'error_landscape_small_screen_header' })
		errorDescription = intl.formatMessage({ id: 'error_landscape_small_screen_text' })
		imageSrc = landscapeError
	}

	const renderImage = () => {
		if (errorType === ERROR_TYPES.OFFLINE) {
			return <div className="icon-browser-dead offline-icon" />
		}

		return (
			<QRCode
				value={JSON.stringify({
					idStudy: props.user.idStudy,
					token: props.token,
					user: {
						isLoggedIn: props.user.isLoggedIn,
					},
					errorId: props.errorId,
					message: props.originalErrorMessage,
				})}
			/>
		)
	}

	return (
		<div
			className={classnames('CoreLayout', state.orientationClass, {
				showFullScreen: showFullScreen === true,
			})}
		>
			{/*
				display icon to load icons
			*/}
			<div className="icon-browser-dead hidden-icon" />
			<PlatformStatus />
			{props.isInPreviewMode ? <div className="CoreLayout-preview">Preview mode</div> : null}
			{props.children}
			{errorType === null ? (
				<div className="CoreLayout-appError-landscape-wrapper">
					<div className="CoreLayout-appError-landscape">
						<img
							src={imageSrc}
							alt={intl.formatMessage({ id: 'error_img_alt' }) + ' - ' + errorHeader}
						/>
						<br />
						<h1 ref={errorHeaderRef} tabIndex={0}>
							{errorHeader}
						</h1>
						<p tabIndex={0}>{errorDescription}.</p>
					</div>
				</div>
			) : (
				<div className="CoreLayout-appError-error-wrapper">
					<div className="CoreLayout-appError-error">
						{renderImage()}
						<br />
						<h1 ref={errorHeaderRef} tabIndex={0}>
							{intl.formatMessage({ id: props.errorHeader })}
						</h1>
						<p tabIndex={0}>
							{intl.formatMessage({ id: props.errorMessage })}
							{errorType !== ERROR_TYPES.OFFLINE && ` (${props.errorCode})`}
						</p>
						{props.showTryAgain === true && (
							<div className="CoreLayout-appError-button">
								<ActionButton
									type={ACTION_BAR_TYPE.BUTTON}
									actionState={ACTION_BUTTON_STATE.SUBMIT_READY}
									isUppercase
									buttonText={intl.formatMessage({ id: 'error_try_again' })}
									onPrimarySubmit={() => {
										window.location.reload()
									}}
								/>
							</div>
						)}
					</div>
				</div>
			)}
			<NetworkChecker handleStatus={props.handleStatus} />
		</div>
	)
}

CoreLayout.propTypes = {
	children: PropTypes.element.isRequired,
	errorCode: PropTypes.number,
	errorHeader: PropTypes.string.isRequired,
	errorId: PropTypes.string,
	errorMessage: PropTypes.string.isRequired,
	errorType: PropTypes.string,
	handleStatus: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	isInPreviewMode: PropTypes.bool.isRequired,
	originalErrorMessage: PropTypes.string.isRequired,
	showFullScreen: PropTypes.bool.isRequired,
	showTryAgain: PropTypes.bool.isRequired,
	token: PropTypes.string,
	user: PropTypes.object.isRequired,
}

export default CoreLayout
