import { connect } from 'react-redux'
import PageLayout from './PageLayout'
import { injectIntl } from 'react-intl'

const mapActionCreators = {}

const mapStateToProps = state => ({
	isAutoLoginEnabled: state.study.autoLogin,
	customLogo: state.study.customLogo,
})

export default connect(
	mapStateToProps,
	mapActionCreators,
)(injectIntl(PageLayout))
