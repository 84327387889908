export const reportContentFormFields = {
	ID_STATEMENT: 'idStatement',
	INAPPROPRIATE: 'inappropriate',
	PROFANE: 'profane',
	GIBBERISH: 'gibberish',
	IRRELEVANT: 'irrelevant',
	DIFFERENT_LANGUAGE: 'differentLanguage',
	OTHER: 'other',
	NOTE: 'note',
}

export const reasonCodes = {
	inappropriate: 'INAPPROPRIATE',
	profane: 'PROFANE',
	gibberish: 'GIBBERISH',
	irrelevant: 'IRRELEVANT',
	differentLanguage: 'DIFFERENT_LANGUAGE',
	other: 'OTHER',
}
