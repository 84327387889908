/* eslint-disable max-len */

const messages = {
	not_found: 'Pagina pe care o căutați nu există',
	service_outage:
		'Ne pare rău că din cauza unei întreruperi globale a serviciilor cloud ale furnizorului nostru sistemul nostru nu funcționează.',
	error_unexpected_error:
		'A apărut o problemă neașteptată. Vă rugăm să încercați din nou mai târziu.',

	error_img_alt: 'Imagine eroare',
	error_try_again: 'Încercați din nou',

	error_generic_header: 'Ceva nu a mers bine',
	error_generic_message:
		'Încercați din nou și dacă tot nu funcționează, vă rugăm să trimiteți un e-mail la support@groupsolver.com cu codul QR pe care îl vedeți mai sus.',

	error_offline_header: 'Se pare că sunteți offline',
	error_offline_message: 'Studiul dvs. va fi reluat după ce conexiunea dvs. va fi restabilită.',

	error_landscape_small_screen_header: 'Vă rugăm să rotiți dispozitivul',
	error_landscape_small_screen_text:
		'Ecranul dvs. este prea mic pentru a vizualiza acest studiu. Vă rugăm să rotiți dispozitivul.',

	error_small_browser_window_header: 'Vă rugăm să măriți fereastra browserului',
	error_small_browser_window_text:
		'Fereastra dvs. este prea mică pentru a vizualiza acest studiu. Vă rugăm să măriți fereastra browserului',

	login_error_empty_email: 'E-mailul nu poate fi gol',
	login_error_invalid_email: 'Vă rugăm să introduceți un e-mail valid',
	login_error_unable_to_login:
		'Imposibil de autentificat în acest moment. Vă rugăm să încercați din nou mai târziu.',

	login_email_placeholder: 'Introduceți adresa de e-mail',
	login_email_button: 'Continuați',
	login_email_back: 'sau reveniți la opțiunile de autentificare',
	login_email_label: 'Continuați cu e-mailul',
	login_anonymous_label: 'Continuați în mod anonim',

	login_privacy_statement:
		'GroupSolver respectă confidențialitatea și informațiile pe care le furnizați.',
	login_privacy_statement_email:
		'Adresa dvs. de e-mail nu va fi împărtășită, vândută sau utilizată în alt mod decât pentru a vă oferi acces la acest proiect.',
	login_privacy_policy: 'Politica de confidențialitate',

	study_unavailable_label: 'Studiul pe care îl căutați nu este disponibil în prezent.',
	study_unavailable_description:
		'Vă mulțumim pentru interesul dvs. în studiul nostru! Din păcate, acest studiu a fost deja completat sau este întrerupt în acest moment. Ne cerem scuze pentru eventualele neplăceri cauzate.',
	study_preview_limit_reached_label: 'Limita dvs. de previzualizare a fost atinsă',
	study_preview_limit_reached_description:
		'Vă mulțumim pentru interesul dvs. în studiul nostru! Din păcate, acest studiu a atins numărul de previzualizări disponibile.',

	waiting_message:
		'Acest studiu este rezolvat de mai multe persoane chiar acum. \n Vom încerca din nou să intrăm {number, plural, =0 { acum} one {în # secundă} other {în # secunde}}.',
	waiting_error_recoverable: 'Am întâlnit o eroare neașteptată.',
	waiting_error_recoverable_retry:
		'Vom încerca din nou {number, plural, =0 { acum} one {în # secundă} other {în # secunde}}',
	waiting_error_unknown_study: 'Studiul pe care îl căutați nu există.',
	waiting_error_dead: 'A apărut o problemă neașteptată. Vă rugăm să încercați din nou mai târziu.',

	action_input_submit_button_text: 'TRIMITE',
	action_input_skip_button_text: 'Continua',

	choice_single_hint: 'Alege una dintre opțiuni',
	general_skip_action_message: 'Continuați la pasul următor',

	action_button_primary_default_text: 'Acțiune',
	action_button_hint_default_text: 'Alegeți una dintre opțiuni',

	message_media_examine_video: 'Faceți clic pe videoclip pentru a-l revedea',
	message_media_examine_image: 'Faceți clic pe imagine pentru a o revedea',
	message_full_screen_close_button_text: 'Închide',
	message_image_view_label: 'Vizualizați imaginea',
	message_video_view_label: 'Vizualizați videoclipul',
	message_image_examined_label: 'Imagine revăzută',
	message_video_examined_label: 'Videoclip revăzut',
	message_zoom_mouse: 'Treceți mouse-ul pentru a mări',
	message_zoom_touch: 'Atingeți pentru a mări',

	textarea_too_long_hint: 'Textul tău este prea lung',

	ideate_placeholder: 'Răspundeți în propoziții scurte',
	ideate_skip: ' sau continua',

	eliminate_no: 'Nu',
	eliminate_yes: 'Da',
	eliminate_intro: 'Luați în considerare următoarea afirmație:',
	eliminate_multiple_suggestions: 'Care dintre următoarele ar putea să-l înlocuiască?',
	eliminate_one_suggestion: 'Ar putea fi înlocuit cu următoarea?',
	eliminate_step: 'Luați în considerare o altă afirmație:',
	eliminate_none_of_the_above: 'Niciunul dintre ele',

	elaborate_indifferent_label: 'Neutru',
	elaborate_disagree_label: 'Dezacord',
	elaborate_agree_label: 'De acord',
	elaborate_unclear_statement_label: 'Răspuns neclar',
	elaborate_intro: 'Alții au oferit următoarele răspunsuri. Sunteți de acord cu ele?',

	ideamatching_unsure_label: 'Nu sunt sigur',
	ideamatching_unequal_label: 'Diferit',
	ideamatching_equal_label: 'La fel',
	ideamatching_intro: 'Priviți această pereche de răspunsuri. Au aceeași semnificație?',

	select_input_placeholder: 'Tastați aici pentru a selecta',
	select_input_placeholder_max: 'S-a atins maximul',
	select_multiple_choice_hint:
		'Alegeți {minNumber, plural, =0 { 0 } other { # }} până la {maxNumber, plural, =0 { 0 } other { # }} opțiuni',
	select_no_matches_found: 'Nu s-au găsit potriviri',

	report_content_inappropriate: 'Răspuns inadecvat',
	report_content_profane: 'Conținut obscen sau profan',
	report_content_gibberish: 'Text fără sens sau nonsens',
	report_content_irrelevant: 'Nerelevant pentru întrebarea pusă',
	report_content_different_language: 'Limbă diferită',
	report_content_other: 'Altul',
	report_content_no_content: 'Fără conținut de raportat',
	report_content_header: 'Raportează răspunsul',
	report_content_form_header:
		'Vă rugăm să selectați motivul pentru care doriți să marcați acest răspuns',
	report_content_flag_answer: 'Marcați răspunsul',
	report_content_return_to_study: 'Reveniți la studiu',
	report_content_thank_you:
		'Vă mulțumim pentru feedback-ul dvs.\nVom analiza observațiile dvs. și vom lua măsuri, dacă este necesar.',
	report_content_statement_flagged: 'Declarația a fost marcată.',

	platform_update_header: 'GroupSolver se află în prezent în întreținere programată',
	platform_update_message: 'Ne cerem scuze pentru eventualele neplăceri.',

	none_of_these: 'Niciunul dintre acestea',

	allocation_instruction_limit: 'Alocați {points} între opțiuni',
	allocation_instruction_limit_no_minimum: 'Alocați până la {points} între opțiuni',
	allocation_instruction_no_limit: 'Alocați puncte între opțiuni',
	allocation_instruction_points_left: '{points} rămase',
	allocation_options_out_of_range: 'Unele opțiuni sunt în afara intervalului',
	allocation_limit_overflow: '{points} peste limită',
	allocation_option_invalid_range: 'Valoarea este în afara intervalului {range}',
	allocation_option_invalid_min: 'Valoarea trebuie să fie mai mare sau egală cu {min}',

	dropdown_select: 'Selectați...',

	ranking_drag_options: 'Trageți sau faceți clic pe săgeți pentru a clasifica opțiunile',
	ranking_assign_option_values: 'Clasificați toate opțiunile',
	ranking_require_unique_values: 'Rangurile opțiunilor trebuie să fie unice',

	next: 'Următorul',
	technical_difficulties: 'Am dificultăți tehnice',

	heatmap_instruction_intro: 'Pentru a începe să selectați, vizualizați imaginea de mai jos.',
	heatmap_instruction_unlimited: 'Selectați câte puncte doriți făcând clic pe imagine.',
	heatmap_instruction_count:
		'Selectați {number, plural, =0 {0 puncte} one {un punct} other {{number} puncte}} făcând clic pe imagine.',
	heatmap_instruction_up_to_count:
		'Selectați {number, plural, =0 {0 puncte} one {un punct} other {până la {number} puncte}} făcând clic pe imagine.',
	heatmap_instruction_between_count: 'Selectați de la {from} la {to} puncte făcând clic pe imagine',
	heatmap_instruction_at_least_count:
		'Selectați cel puțin {number, plural, =0 {0 puncte} one {un punct} other {{number} puncte}} făcând clic pe imagine.',
	heatmap_response:
		'{number, plural, =0 {0 puncte} one {1 punct} other {{number} puncte}} selectate',

	continue: 'Continuă',
	reset: 'Resetează',
	input_invalid: 'Intrarea nu este validă.',
	input_empty: 'Intrarea nu poate fi goală.',
	input_chars_left:
		'{number} {number, plural, =0 {caractere} one {caracter} other {caractere}} rămase.',
	input_too_long:
		'{number} {number, plural, =0 {caractere} one {caracter} other {caractere}} peste limită.',
	ranking_select_rank: 'Selectați rangul pentru {label}.',
	ranking_select_aria_label: 'Selectați rangul fiecărei opțiuni introducând o valoare.',
	ranking_dnd_aria_label:
		'Focalizați pe o opțiune și apăsați bara de spațiu pentru a începe o tragere. În timpul tragerii, puteți folosi tastele săgeată pentru a muta elementul și tasta escape pentru a anula. Apăsați din nou bara de spațiu pentru a plasa elementul în noua poziție. Asigurați-vă că cititorul de ecran este în modul de focalizare sau modul de formulare.',
	allocation_option_input: 'Intrare valoare {label}.',
	allocation_aria_label: 'Introduceți o valoare pentru fiecare opțiune folosind intrările.',
	play: 'Redă',
	pause: 'Pauză',
	stop: 'Oprește',
	fwd: 'Sari înainte',
	rwd: 'Derulează înapoi',
	volume_down: 'Scade volumul',
	volume_up: 'Crește volumul',
	volume_current: 'Volum: {number}%',
	loading: 'Se încarcă',
	press_tab_to_continue: 'Apăsați tab pentru a continua',
	option_focus_selected: 'Opțiunea {label} focalizată. Apăsați enter pentru a deselecta.',
	option_focus_not_selected: 'Opțiunea {label} focalizată. Apăsați enter pentru a selecta.',
	image_alt: 'imagine:',
	video_alt: 'video:',
	submit_enabled: 'Trimitere activată.',
	skip_enabled: 'Omitere întrebare activată',
	n_of_min_selected: '{selected} din {min} selectate.',
	option_selected: '{label} selectată.',
	option_deselected: '{label} deselectată.',
	choice_multiple_hint: 'Selectați între {min} și {max} opțiuni.',
	ranking_position: 'poziție: {position}',
	ranking_position_changed: 'Valoarea {label} setată la {value}.',
	ranking_input_aria_label:
		'{label}. Poziția curentă: {position}. Introduceți o poziție de la 1 la {max}.',
	not_selected: 'nu este selectat',
	study_logo_alt: 'Logo-ul studiului',
	groupsolver_logo_alt: 'Logo-ul Groupsolver',
}

export default messages
