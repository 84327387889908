import * as Sentry from '@sentry/react'

let environment = 'production'
environment = `${__DEV__}` === 'true' ? 'dev' : environment

export const errorHandler = (error, other) => (dispatch, getState) => {
	let state = getState() || {}

	Sentry.setTag('environment', environment)
	Sentry.setTag('idStudy', state.user ? state.user.idStudy : 'Unknown')
	Sentry.setTag('publicLabel', state.study ? state.study.publicLabel : 'Unknown')
	Sentry.setTag('userToken', state.user ? state.user.token : 'Unknown')

	Sentry.captureException(error, {
		extra: {
			other,
			state: {
				api: state.api,
				appError: state.appError,
				googleAnalytics: state.googleAnalytics,
				user: state.user,
				actions: state.logger.actions,
			},
		},
	})
}

// ------------------------------------
// initialState
// ------------------------------------
const initialState = {
	actions: [],
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const logger = (state, action) => {
	let newState = Object.assign({}, state)
	// keep history of last 50 actions
	newState.actions = state.actions.slice(0, 39)
	// add new action to top of array
	newState.actions.unshift(action)

	return newState
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function loggerReducer(state = initialState, action) {
	let newState = logger(state, action)

	return newState
}
