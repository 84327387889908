import { INPUT_TYPE } from 'constants/input'

// ------------------------------------
// Constants
// ------------------------------------
export const INPUT_SET = 'input.set'
export const INPUT_CHANGE = 'input.change'
export const INPUT_RESET = 'input.reset'
export const INPUT_VISIBLE_TOGGLE = 'input.visible'
export const INPUT_ENABLED_TOGGLE = 'input.enabled'
export const INPUT_VALID_TOGGLE = 'input.valid'
export const INPUT_PUSH_OPTION = 'input.push.option'
export const INPUT_REMOVE_OPTION = 'input.pop.option'
export const INPUT_SET_CHANGE_LISTENER = 'input.set.change.listener'
export const INPUT_SET_PLACEHOLDER = 'input.set.placeholder'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	inputType: INPUT_TYPE.INPUT,
	isVisible: false,

	maxLength: null,
	isValidatorVisible: false,
	hint: '',

	placeholder: '',

	isValid: false,
	isEnabled: false,
	isDelayed: false,
	isKeyNavigationDisabled: false,
	changeListener: null,

	value: '',
	options: [],
}

// ------------------------------------
// Actions
// ------------------------------------
export const setInput = (
	inputType,
	placeholder = '',
	maxLength = null,
	isEnabled = false,
	isDelayed = false,
	isValid = true,
	hint = '',
	isValidatorVisible = false,
) => (dispatch, getState) => {
	let isKeyNavigationDisabled = false
	let language = getState().study.language
	if (language === 'ja') {
		isKeyNavigationDisabled = true
	}

	dispatch({
		type: INPUT_SET,
		inputType,
		placeholder,
		maxLength,
		isEnabled,
		isDelayed,
		isValid,
		hint,
		isValidatorVisible,
		isKeyNavigationDisabled,
	})
}

export const pushOption = option => (dispatch, getState) =>
	dispatch({ type: INPUT_PUSH_OPTION, option })
export const removeOption = optionId => (dispatch, getState) =>
	dispatch({ type: INPUT_REMOVE_OPTION, optionId })

export const reset = () => (dispatch, getState) => dispatch({ type: INPUT_RESET })

export const showInput = () => (dispatch, getState) =>
	dispatch({ type: INPUT_VISIBLE_TOGGLE, isVisible: true })
export const hideInput = () => (dispatch, getState) =>
	dispatch({ type: INPUT_VISIBLE_TOGGLE, isVisible: false })

export const enableInput = () => (dispatch, getState) =>
	dispatch({ type: INPUT_ENABLED_TOGGLE, isEnabled: true })
export const disableInput = () => (dispatch, getState) =>
	dispatch({ type: INPUT_ENABLED_TOGGLE, isEnabled: false })

export const validInput = (hint = '') => (dispatch, getState) =>
	dispatch({ type: INPUT_VALID_TOGGLE, isValid: true, hint })
export const invalidInput = (hint = '') => (dispatch, getState) =>
	dispatch({ type: INPUT_VALID_TOGGLE, isValid: false, hint })

export const setPlaceholder = (placeholder = '') => (dispatch, getState) =>
	dispatch({ type: INPUT_SET_PLACEHOLDER, placeholder })

export const onInputChange = (value = '', options = []) => (dispatch, getState) => {
	dispatch({ type: INPUT_CHANGE, value, options })
	let newState = getState()
	let changeListener = newState.chatInput.changeListener
	if (changeListener) {
		changeListener(value, options)(dispatch, getState)
	}
}

export const setInputChangeListener = changeListener => (dispatch, getState) => {
	dispatch({
		type: INPUT_SET_CHANGE_LISTENER,
		changeListener,
	})
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[INPUT_RESET]: (state, action) => {
		return initialState
	},
	[INPUT_VISIBLE_TOGGLE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isVisible = action.isVisible

		return newState
	},
	[INPUT_ENABLED_TOGGLE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isEnabled = action.isEnabled

		return newState
	},
	[INPUT_VALID_TOGGLE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isValid = action.isValid
		newState.hint = action.hint

		return newState
	},
	[INPUT_SET]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.inputType = action.inputType
		newState.placeholder = action.placeholder
		newState.maxLength = action.maxLength
		newState.isValid = action.isValid
		newState.isEnabled = action.isEnabled
		newState.isDelayed = action.isDelayed
		newState.hint = action.hint
		newState.isValidatorVisible = action.isValidatorVisible
		newState.isKeyNavigationDisabled = action.isKeyNavigationDisabled

		newState.value = initialState.value
		newState.options = initialState.options

		return newState
	},
	[INPUT_CHANGE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.value = action.value
		newState.options = action.options

		return newState
	},
	[INPUT_SET_CHANGE_LISTENER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.changeListener = action.changeListener

		return newState
	},
	[INPUT_PUSH_OPTION]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.options = state.options.slice()
		newState.options.push(action.option)
		newState.value = ''
		return newState
	},
	[INPUT_REMOVE_OPTION]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.options = state.options.slice()
		let index = newState.options.findIndex(option => option.id === action.optionId)
		newState.options.splice(index, 1)
		return newState
	},
	[INPUT_SET_PLACEHOLDER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.placeholder = action.placeholder
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Getters
// ------------------------------------
export const getInputValue = () => (dispatch, getState) => {
	let state = getState()
	return state.chatInput.value
}
