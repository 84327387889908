import { addAction } from 'store/queue'
import { setActionSubmitListener, showActionButton, hideActionBar } from 'store/_chat/actionBar'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'

import { getStudyObjectDefinition } from 'selectors/study'

// ------------------------------------
// Primary Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	// acion bar setup
	setActionSubmitListener(primarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	showStep(module)(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const showStep = module => (dispatch, getState) => {
	// hotfix to enable auto redirect from visual flow
	// redirectLabel === 'NULL'
	if (module.redirectLabel === null || module.redirectLabel === 'NULL') {
		// show button which needs to be clicked on to redirect
		addAction(hideActionBar)(dispatch, getState)
		endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		addAction(showActionButton, [ACTION_BUTTON_STATE.SUBMIT_READY, module.redirectLabel])(
			dispatch,
			getState,
		)
	}
}

export const primarySubmitListener = () => (dispatch, getState) => {
	endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const endStep = () => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)

	if (definition.redirectUrl) {
		window.location = definition.redirectUrl
	}
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
