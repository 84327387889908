/* eslint-disable max-len */

const messages = {
	not_found: "Page you are looking for doesn't exist",
	service_outage:
		"We apologize that due to a global outage of our provider's cloud services our system is down.",
	error_unexpected_error: 'There was an unexpected problem. Please try again later.',

	error_img_alt: 'Error image',
	error_try_again: 'Try again',

	error_generic_header: 'Something went wrong',
	error_generic_message:
		"Try that again, and if it still doesn't work, please send email to support@groupsolver.com with the QR code you see above.",

	error_offline_header: 'You seem to be offline',
	error_offline_message: 'Your study will resume after your connection is re-established.',

	error_landscape_small_screen_header: 'Please rotate your device',
	error_landscape_small_screen_text:
		'Your screen is too short to view this study. Please rotate your device.',

	error_small_browser_window_header: 'Please enlarge your browser window',
	error_small_browser_window_text:
		'Your window is too small to view this study. Please enlarge your browser window',

	login_error_empty_email: 'Email cannot be empty',
	login_error_invalid_email: 'Please enter valid email',
	login_error_unable_to_login: 'Unable to login at this time. Please try again later.',

	login_email_placeholder: 'Enter your email',
	login_email_button: 'Continue',
	login_email_back: 'or go back to login options',
	login_email_label: 'Continue with email',
	login_anonymous_label: 'Continue anonymously',

	login_privacy_statement: 'GroupSolver respects your privacy and the information you provide.',
	login_privacy_statement_email:
		'Your email will never be shared, sold, or used in any way other than granting you access to this project.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Study you are looking for is currently unavailable.',
	study_unavailable_description:
		'Thank you for your interest in our study! Unfortunately, this study has already been filled or is paused at the moment. We apologize for any inconvenience it may have caused.',
	study_preview_limit_reached_label: 'Your preview limit has been reached',
	study_preview_limit_reached_description:
		'Thank you for your interest in our study! Unfortunately, this study has reached number of available previews.',

	waiting_message:
		'This study is being solved by several people right now. \n We will automatically retry entering {number, plural, =0 { now} one {in # second} other {in # seconds}}.',
	waiting_error_recoverable: 'We have encountered an unexpected error.',
	waiting_error_recoverable_retry:
		'We will try again {number, plural, =0 { now} one {in # second} other {in # seconds}}',
	waiting_error_unknown_study: "Study you are looking for doesn't exist.",
	waiting_error_dead: 'There was an unexpected problem. Please try again later.',

	action_input_submit_button_text: 'SUBMIT',
	action_input_skip_button_text: 'Continue',

	choice_single_hint: 'Choose one of the options',
	general_skip_action_message: 'Continue to next step',

	action_button_primary_default_text: 'Action',
	action_button_hint_default_text: 'Pick one of the options',

	message_media_examine_video: 'Click the video to review it',
	message_media_examine_image: 'Click the image to review it',
	message_full_screen_close_button_text: 'Close',
	message_image_view_label: 'View Image',
	message_video_view_label: 'View Video',
	message_image_examined_label: 'Image reviewed',
	message_video_examined_label: 'Video reviewed',
	message_zoom_mouse: 'Hover to zoom',
	message_zoom_touch: 'Touch to zoom',

	textarea_too_long_hint: 'Your text is too long',

	ideate_placeholder: 'Answer in short sentence(s)',
	ideate_skip: ' or continue',

	eliminate_no: 'No',
	eliminate_yes: 'Yes',
	eliminate_intro: 'Consider the following statement:',
	eliminate_multiple_suggestions: 'Which of the following could replace it?',
	eliminate_one_suggestion: 'Could it be replaced with the following?',
	eliminate_step: 'Consider another statement:',
	eliminate_none_of_the_above: 'None of them',

	elaborate_indifferent_label: 'Neutral',
	elaborate_disagree_label: 'Disagree',
	elaborate_agree_label: 'Agree',
	elaborate_unclear_statement_label: 'Unclear answer',
	elaborate_intro: 'Others provided the following answers. Do you agree with them?',

	ideamatching_unsure_label: 'Not sure',
	ideamatching_unequal_label: 'Different',
	ideamatching_equal_label: 'Same',
	ideamatching_intro: 'Look at this pair of answers. Do they mean the same?',

	select_input_placeholder: 'Type here to select',
	select_input_placeholder_max: 'Maximum reached',
	select_multiple_choice_hint:
		'Choose {minNumber, plural, =0 { 0 } other { # }} to {maxNumber, plural, =0 { 0 } other { # }} options',
	select_no_matches_found: 'No matches found',

	report_content_inappropriate: 'Inappropriate answer',
	report_content_profane: 'Obscene or profane content',
	report_content_gibberish: 'Gibberish or non-sensical text',
	report_content_irrelevant: 'Irrelevant to the question asked',
	report_content_different_language: 'Different language',
	report_content_other: 'Other',
	report_content_no_content: 'No content to report',
	report_content_header: 'Report answer',
	report_content_form_header: 'Please select the reason why you would like to flag this answer',
	report_content_flag_answer: 'Flag answer',
	report_content_return_to_study: 'Return to the study',
	report_content_thank_you:
		'Thank you for your feedback.\nWe will review your input and take action if necessary.',
	report_content_statement_flagged: 'Statement has been flagged.',

	platform_update_header: 'GroupSolver is currently undergoing scheduled maintenance',
	platform_update_message: 'We apologize for any inconvenience.',

	none_of_these: 'None of these',

	allocation_instruction_limit: 'Allocate {points} across options',
	allocation_instruction_limit_no_minimum: 'Allocate up to {points} across options',
	allocation_instruction_no_limit: 'Allocate points across options',
	allocation_instruction_points_left: '{points} remaining',
	allocation_options_out_of_range: 'Some options are out of range',
	allocation_limit_overflow: '{points} over limit',
	allocation_option_invalid_range: 'Value is out of {range} range',
	allocation_option_invalid_min: 'Value must be greater than or equal to {min}',

	dropdown_select: 'Select...',

	ranking_drag_options: 'Drag or click arrows to rank options',
	ranking_assign_option_values: 'Rank all of the options',
	ranking_require_unique_values: 'Option ranks have to be unique',

	next: 'Next',
	technical_difficulties: 'I have technical difficulties',

	heatmap_instruction_intro: 'To start selecting, view the image below.',
	heatmap_instruction_unlimited: 'Select as many points as you wish by clicking on the image.',
	heatmap_instruction_count:
		'Select {number, plural, =0 {0 points} one {one point} other {{number} points}} by clicking on the image.',
	heatmap_instruction_up_to_count:
		'Select {number, plural, =0 {0 points} one {one point} other {up to {number} points}} by clicking on the image.',
	heatmap_instruction_between_count: 'Select {from} to {to} points by clicking on the image',
	heatmap_instruction_at_least_count:
		'Select at least {number, plural, =0 {0 points} one {one point} other {{number} points}} by clicking on the image.',
	heatmap_response:
		'{number, plural, =0 {0 points} one {1 point} other {{number} points}} selected',

	continue: 'Continue',
	reset: 'Reset',

	input_invalid: 'Input is not valid.',
	input_empty: 'Input cannot be empty.',
	input_chars_left:
		'{number} {number, plural, =0 {characters} one {character} other {characters}} left.',
	input_too_long:
		'{number} {number, plural, =0 {characters} one {character} other {characters}} over limit.',

	// accessibility
	ranking_select_rank: 'Select rank of {label}.',

	ranking_select_aria_label: 'Select rank of each option by typing in a value.',
	ranking_dnd_aria_label:
		'Focus on an option and press space bar to start a drag. When dragging you can use the arrow keys to move the item around and escape to cancel. Press spacebar again to place the item to the new position. Ensure your screen reader is in focus mode or forms mode.',

	allocation_option_input: '{label} value input.',
	allocation_aria_label: 'Enter a value for each option using inputs.',

	play: 'Play',
	pause: 'Pause',
	stop: 'Stop',
	fwd: 'Skip forward',
	rwd: 'Rewind',
	volume_down: 'Decrease volume',
	volume_up: 'Increase volume',
	volume_current: 'Volume: {number}%',

	loading: 'Loading',

	press_tab_to_continue: 'Press tab to continue',

	option_focus_selected: 'Option {label} focused. Press enter to deselect.',
	option_focus_not_selected: 'Option {label} focused. Press enter to select.',

	image_alt: 'image:',
	video_alt: 'video:',

	submit_enabled: 'Submit enabled.',
	skip_enabled: 'Skip question enabled',
	n_of_min_selected: '{selected} of {min} selected.',
	option_selected: '{label} selected.',
	option_deselected: '{label} deselected.',

	choice_multiple_hint: 'Select {min} to {max} options.',

	ranking_position: 'position: {position}',
	ranking_position_changed: '{label} value set to {value}.',
	ranking_input_aria_label:
		'{label}. Current position: {position}. Type in a position from 1 to {max}.',
	not_selected: 'not selected',

	study_logo_alt: 'Study logo',
	groupsolver_logo_alt: 'Gropsolver logo',
}

export default messages
