/* eslint-disable max-len */

const messages = {
	not_found: 'Aradiginiz sayfa bulunmamaktadir',
	error_unexpected_error: 'Beklenmeyen bir sorun olustu. ‘Lütfen daha sonra tekrar deneyin.',
	error_img_alt: 'Hata simgesi',
	error_try_again: 'Yeniden dene',
	error_generic_header: 'Bir hata olustu',
	error_generic_message:
		'Bir sorun ile karsilastik. Bu sorunu çözmek için elimizden geleni yapiyoruz.',
	error_offline_header: 'Çevrimdisi görünüyorsunuz',
	error_offline_message:
		'Çalismaniz, baglantiniz tekrar kurulduktan sonra kaldigi yerden devam edecektir.',
	error_landscape_small_screen_header: 'Lütfen cihazinizi döndürün',
	error_landscape_small_screen_text:
		'Ekraniniz bu görüntü için çok küçük. Lütfen cihazinizi döndürün.',

	error_small_browser_window_header: 'Lütfen tarayıcı pencerenizi büyütün',
	error_small_browser_window_text:
		'Pencereniz bu anketi görmek için çok küçük. Lütfen tarayıcı pencerenizi büyütün',

	login_error_empty_email: 'E-posta kismi bos birakilamaz',
	login_error_invalid_email: 'Lütfen geçerli bir e-posta adresi girin',
	login_error_unable_to_login: 'Su an oturum açilamiyor. ‘Lütfen daha sonra tekrar deneyin.',
	login_email_placeholder: 'E-posta adresinizi girin.',
	login_email_button: 'Devam',
	login_email_back: 'Veya oturum açma seçeneklerine geri dönün',
	login_email_label: 'E-posta girisi ile devam edin',
	login_anonymous_label: 'Gizli kalarak devam et',
	login_privacy_statement: 'GroupSolver gizliliginize ve verdiginiz bilgilere saygi gösterir.',
	login_privacy_statement_email:
		'E-posta adresiniz hiçbir zaman paylasilmayacak veya bu projeye erisim saglamaniz disinda, baska bir amaçla kullanilmayacaktir.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Aramış olduğunuz anket bulunamadı',
	study_unavailable_description:
		'Anketimize göstermiş olduğunuz ilginiz teşekkür ederiz. Ancak, şu an itibarıyla anketimiz tamamlanmış / geçici olarak durdurulmuştur.',
	study_preview_limit_reached_label: 'Maksimum gösterim kotanıza ulaşmış bulunmaktasınız',
	study_preview_limit_reached_description:
		'Çalışmamıza göstermiş olduğunuz ilgi için teşekkür ederiz. Şu an itibarı ile bu çalışma için belirlenmiş gösterim kotası tamamlanmıştır.',

	waiting_message:
		'Bu çalisma su anda birden fazla kisi tarafindan çözülmektedir (anket çalismasina pek çok kisi katilmaktadir). {number, plural, =0 { ºimdi} one { # ikinci} other { # saniye}} sonra otomatik olarak yeniden giris yapmayi deneyecegiz.',
	waiting_error_recoverable: 'Beklenmeyen bir hata ile karsilastik.',
	waiting_error_recoverable_retry:
		'Simdi yeniden deneyecegiz.’ veya ‘{number, plural, =0 { ºimdi} one { # ikinci} other { # saniye}} sonra yeniden deneyecegiz.',
	waiting_error_unknown_study: 'Aradiginiz çalisma bulunmamaktadir.',
	waiting_error_dead: 'Beklenmeyen bir sorun olustu. ‘Lütfen daha sonra tekrar deneyin.',
	action_input_submit_button_text: 'GÖNDER',
	action_input_skip_button_text: 'ATLA',
	choice_single_hint: 'Seçeneklerden birini seçin',
	general_skip_action_message: 'Bir sonraki adima atla',
	action_button_primary_default_text: 'Aksiyon',
	action_button_hint_default_text: 'Seçeneklerden birisini seçin',
	message_media_examine_video: 'Lütfen videoyu gözden geçirin',
	message_media_examine_image: 'Lütfen görüntüyü gözden geçirin',
	message_full_screen_close_button_text: 'Kapat',
	message_image_view_label: 'Görüntüyü Izle',
	message_video_view_label: 'Videoyu Izle',
	message_image_examined_label: 'Görüntü gözden geçirildi',
	message_video_examined_label: 'Video gözden geçirildi',
	message_zoom_mouse: 'Büyütmek için üzerinde gezin',
	message_zoom_touch: 'Büyütmek için dokun',

	textarea_too_long_hint: 'Metin çok uzun',
	ideate_placeholder: 'Lütfen kısa cümleler halinde cevap veriniz',
	ideate_skip: ' (veya atlayin)',
	eliminate_no: 'Hayir',
	eliminate_yes: 'Evet',
	eliminate_intro: 'Daha önce söyle dediniz:',
	eliminate_multiple_suggestions: 'Asagidakilerden hangisi yanitinizin yerine geçebilir?',
	eliminate_one_suggestion: 'Su  yanit, yanitinizin yerine geçebilir mi?',
	eliminate_step: 'Verdiginiz bir baska yanit suydu:',
	eliminate_none_of_the_above: 'Bunlardan hiçbirisi',
	elaborate_indifferent_label: 'Emin degilim',
	elaborate_disagree_label: 'Katilmiyorum',
	elaborate_agree_label: 'Katiliyorum',
	elaborate_unclear_statement_label: 'Anlaşılmayan yanıt',
	elaborate_intro: 'Digerleri su yanitlari verdiler. Bu yanitlara katiliyor musunuz?',
	ideamatching_unsure_label: 'Emin degilim',
	ideamatching_unequal_label: 'Farkli',
	ideamatching_equal_label: 'Ayni',
	ideamatching_intro: 'Bu yanit çiftine bakin. Bunlar ayni anlama mi geliyor?',
	select_input_placeholder: 'Seçmek için buraya yazin',
	select_input_placeholder_max: 'Maksimum sayiya erisildi',
	select_multiple_choice_hint:
		"Seçenekler'i {minNumber, plural, =0 { 0 } other { # }} to {maxNumber, plural, =0 { 0 } other { # }} seçin",
	select_no_matches_found: 'Eslesme bulunamadi',

	report_content_inappropriate: 'Uygunsuz yanıt',
	report_content_profane: 'Müstehcen ya da küfürlü içerik',
	report_content_gibberish: 'Abuk subuk ya da anlaşılmaz metin',
	report_content_irrelevant: 'Sorulan soruyla ilgisiz',
	report_content_different_language: 'Başka bir dil',
	report_content_other: 'Diğer',
	report_content_no_content: 'Bildirilecek içerik yok',
	report_content_header: 'Yanıtı bildir',
	report_content_form_header: 'Lütfen bu yanıtı işaretlemek isteme nedeninizi seçin',
	report_content_flag_answer: 'Yanıtı işaretle',
	report_content_return_to_study: 'Çalışmaya geri dön',
	report_content_thank_you: `Geri bildiriminiz için teşekkür ederiz.\n
	İlettiklerinizi gözden geçireceğiz ve gerekiyorsa işlem yapacağız.`,
	report_content_statement_flagged: 'İfade işaretlendi.',

	platform_update_header: 'GroupSolver şu anda planlanmış bakımdan geçmektedir',
	platform_update_message: 'Verdiğimiz rahatsızlıktan dolayı özür dileriz.',

	none_of_these: 'Bunlardan hiçbiri',

	allocation_instruction_limit: 'Seçenekler arasından {points} nokta tayin et',
	allocation_instruction_limit_no_minimum: 'Seçenekler arasından en fazla {points} nokta tayin et',
	allocation_instruction_no_limit: 'Seçenekler arasından noktaları tayin et',
	allocation_instruction_points_left: '{points} nokta kaldı',
	allocation_options_out_of_range: 'Bazı seçenekler aralık dışında',
	allocation_limit_overflow: '{points} nokta limiti aşıyor',
	allocation_option_invalid_range: 'Değer {range} aralığının dışında',
	allocation_option_invalid_min: 'Değer {min} değerinden büyük veya ona eşit olmalı',

	dropdown_select: 'Şunu seç...',

	ranking_drag_options: 'Seçenekleri sıralamak için okları sürükle veya oklara tıkla',
	ranking_assign_option_values: 'Tüm seçenekleri sırala',
	ranking_require_unique_values: 'Seçenek sıralamaları benzersiz olmalı',

	heatmap_instruction_intro: 'Seçmeye başlamak için aşağıdaki görüntüyü inceleyin.',
	heatmap_instruction_unlimited: 'Görüntüye tıklayarak istediğiniz kadar nokta seçin.',
	heatmap_instruction_count:
		'Görüntüye tıklayarak {number, plural, =0 {0 nokta} one {bir nokta} other {{number} nokta}} seçin.',
	heatmap_instruction_up_to_count:
		'Görüntüye tıklayarak {number, plural, =0 {0 nokta} one {bir nokta} other {en fazla {number} nokta}} seçin.',
	heatmap_instruction_between_count: 'Görüntüye tıklayarak {from} ile {to} arasında nokta seçin.',
	heatmap_instruction_at_least_count:
		'Görüntüye tıklayarak en az {number, plural, =0 {0 nokta} one {bir nokta} other {{number} nokta}} seçin.',
	heatmap_response: '{number, plural, =0 {0 nokta} one {1 nokta} other {{number} nokta}} seçildi',
	continue: 'Devam et',
	reset: 'Sıfırla',
	input_invalid: 'Girdi geçerli değil.',
	input_empty: 'Girdi boş olamaz.',
	input_chars_left:
		'{number} {number, plural, =0 {karakter} one {karakter} other {karakter}} kaldı.',
	input_too_long:
		'{number} {number, plural, =0 {karakter} one {karakter} other {karakter}} limitin üzerinde.',
	ranking_select_rank: '{label} sıralamasını seçin.',
	ranking_select_aria_label: 'Her seçeneğin sıralamasını bir değer yazarak seçin.',
	ranking_dnd_aria_label:
		'Bir seçeneğe odaklanın ve sürüklemeye başlamak için boşluk tuşuna basın. Sürüklerken, öğeyi hareket ettirmek için ok tuşlarını ve iptal etmek için escape tuşunu kullanabilirsiniz. Öğeyi yeni konuma yerleştirmek için tekrar boşluk tuşuna basın. Ekran okuyucunuzun odak modunda veya form modunda olduğundan emin olun.',
	allocation_option_input: '{label} değer girişi.',
	allocation_aria_label: 'Her seçenek için girişleri kullanarak bir değer girin.',
	play: 'Oynat',
	pause: 'Duraklat',
	stop: 'Durdur',
	fwd: 'İleri atla',
	rwd: 'Geri sar',
	volume_down: 'Sesi azalt',
	volume_up: 'Sesi artır',
	volume_current: 'Ses: {number}%',
	loading: 'Yükleniyor',
	press_tab_to_continue: 'Devam etmek için tab tuşuna basın',
	option_focus_selected: 'Seçenek {label} odaklandı. Seçimi kaldırmak için enter tuşuna basın.',
	option_focus_not_selected: 'Seçenek {label} odaklandı. Seçmek için enter tuşuna basın.',
	image_alt: 'görüntü:',
	video_alt: 'video:',
	submit_enabled: 'Gönderme etkin.',
	skip_enabled: 'Soruyu atlama etkin',
	n_of_min_selected: '{min} seçilenden {selected} seçildi.',
	option_selected: '{label} seçildi.',
	option_deselected: '{label} seçimi kaldırıldı.',
	choice_multiple_hint: '{min} ile {max} arasında seçenek seçin.',
	ranking_position: 'pozisyon: {position}',
	ranking_position_changed: '{label} değeri {value} olarak ayarlandı.',
	ranking_input_aria_label:
		'{label}. Mevcut pozisyon: {position}. 1 ile {max} arasında bir pozisyon yazın.',
	not_selected: 'seçilmedi',
	study_logo_alt: 'Çalışma logosu',
	groupsolver_logo_alt: 'Groupsolver logosu',
}

export default messages
