/* eslint-disable max-len */
const messages = {
	not_found: 'Die Seite, nach der Sie suchen, gibt es nicht',
	error_unexpected_error:
		'Es ist ein unerwartetes Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',

	error_img_alt: 'Fehlerhaftes Bild',
	error_try_again: 'Wiederholen',

	error_generic_header: 'Es ist ein Fehler aufgetreten',
	error_generic_message:
		'Versuchen Sie es noch einmal, und wenn es immer noch nicht funktioniert, senden Sie bitte eine e-mail an support@groupsolver.com mit dem QR-Code, den Sie oben sehen.',

	error_offline_header: 'Sie scheinen offline zu sein',
	error_offline_message:
		'Ihre Studie wird fortgesetzt, nachdem Ihre Verbindung wiederhergestellt wurde.',

	error_landscape_small_screen_header: 'Bitte drehen Sie Ihr Gerät',
	error_landscape_small_screen_text:
		'Ihr Bildschirm ist zu klein, um diese Studie anzuzeigen. Bitte drehen Sie Ihr Gerät.',

	error_small_browser_window_header: 'Bitte vergrößern Sie Ihr Browserfenster',
	error_small_browser_window_text:
		'Ihr Fenster ist zu klein, um diese Studie anzuzeigen. Bitte vergrößern Sie Ihr Browserfenster',

	login_error_empty_email: 'e-mail darf nicht leer sein',
	login_error_invalid_email: 'Bitte geben Sie eine gültige e-mail an',
	login_error_unable_to_login:
		'Sie können sich zu diesem Zeitpunkt nicht anmelden. Bitte versuchen Sie es später noch einmal.',

	login_email_placeholder: 'Geben Sie Ihre e-mail ein',
	login_email_button: 'Weiter',
	login_email_back: 'oder zurück zu Anmeldeoptionen',
	login_email_label: 'Weiter mit e-mail',
	login_anonymous_label: 'Anonym fortsetzen',

	login_privacy_statement:
		'GroupSolver respektiert Ihre Privatsphäre und die von Ihnen mitgeteilten Informationen. ',
	login_privacy_statement_email:
		'Ihre e-mail wird niemals weitergegeben, verkauft oder auf andere Weise verwendet, als Ihnen Zugang zu diesem Projekt zu gewähren.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'Die von Ihnen gesuchte Studie ist nicht verfügbar.',
	study_unavailable_description:
		'Vielen Dank für Ihr Interesse an unserer Studie! Leider ist diese Studie bereits abgeschlossen oder pausiert zur Zeit. Wir entschuldigen uns für die dadurch eventuell verursachten Unannehmlichkeiten.',
	study_preview_limit_reached_label: 'Ihr Vorschau-Limit ist erreicht',
	study_preview_limit_reached_description:
		'Vielen Dank für Ihr Interesse an unserer Studie! Leider hat diese Studie die Anzahl der verfügbaren Vorschauen erreicht.',

	waiting_message:
		'Diese Studie wird derzeit von mehreren Personen gelöst. \n Wir werden die Eingabe automatisch {number, plural, =0 { jetzt } one {in # Sekunde} other {in # Sekunden}} erneut versuchen.',
	waiting_error_recoverable: 'Wir haben einen unerwarteten Fehler festgestellt.',
	waiting_error_recoverable_retry:
		'Wir werden es {number, plural, =0 { jetzt } one {in # Sekunde} other {in # Sekunden}} erneut versuchen.',
	waiting_error_unknown_study: 'Die Studie, nach der Sie suchen, gibt es nicht.',
	waiting_error_dead:
		'Es ist ein unerwartetes Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',

	action_input_submit_button_text: 'ABSENDEN',
	action_input_skip_button_text: 'ÜBERSPRINGEN',

	choice_single_hint: 'Wählen Sie eine der Optionen aus',
	general_skip_action_message: 'Schritt überspringen und mit dem nächsten Schritt fortfahren.',

	action_button_primary_default_text: 'Aktion',
	action_button_hint_default_text: 'Wählen Sie eine der Optionen aus',

	message_media_examine_video: 'Klicken Sie das Video an, um es noch einmal anzuschauen',
	message_media_examine_image: 'Klicken Sie das Bild an, um es noch einmal anzuschauen',
	message_full_screen_close_button_text: 'Schließen',
	message_image_view_label: 'Bild anschauen',
	message_video_view_label: 'Video anschauen',
	message_image_examined_label: 'Bild angeschaut',
	message_video_examined_label: 'Video angeschaut',
	message_zoom_mouse: 'Maus darüber halten, um zu zoomen',
	message_zoom_touch: 'Berühren, um zu zoomen',

	textarea_too_long_hint: 'Ihr Text ist zu lang',

	ideate_placeholder: 'Antworte in kurzen Sätzen',
	ideate_skip: ' oder spring über',

	eliminate_no: 'Nein',
	eliminate_yes: 'Ja',
	eliminate_intro: 'Sie haben zuvor gesagt:',
	eliminate_multiple_suggestions: 'Welche der folgenden Antworten könnte Ihre Antwort ersetzen?',
	eliminate_one_suggestion: 'Könnte die folgende Antwort Ihre ersetzen?',
	eliminate_step: 'Ein weitere Antwort von Ihnen lautete:',
	eliminate_none_of_the_above: 'Keine von denen',

	elaborate_indifferent_label: 'Weiß nicht',
	elaborate_disagree_label: 'Ich stimme nicht zu',
	elaborate_agree_label: 'Ich stimme zu',
	elaborate_unclear_statement_label: 'Unklare Antwort',
	elaborate_intro: 'Andere haben die folgenden Antworten angegeben. Stimmen Sie zu?',

	ideamatching_unsure_label: 'Weiß nicht',
	ideamatching_unequal_label: 'Unterschiedlich',
	ideamatching_equal_label: 'Gleich',
	ideamatching_intro: 'Schauen Sie sich diese beiden Antworten an. Bedeuten sie das Gleiche?',

	select_input_placeholder: 'Zur Auswahl hier tippen',
	select_input_placeholder_max: 'Maximum erreicht',
	select_multiple_choice_hint:
		'Wählen SIe {minNumber, plural, =0 { 0 } other { # }} to {maxNumber, plural, =0 { 0 } other { # }} Optionen aus',
	select_no_matches_found: 'Keine Übereinstimmungen gefunden',

	report_content_inappropriate: 'Unangemessene Antwort',
	report_content_profane: 'Obszöner oder profaner Inhalt',
	report_content_gibberish: 'Kauderwelsch oder unsinniger Text',
	report_content_irrelevant: 'Trifft nicht auf die gestellte Frage zu',
	report_content_different_language: 'Andere Sprache',
	report_content_other: 'Anderes',
	report_content_no_content: 'Kein Inhalt zu melden',
	report_content_header: 'Antwort melden',
	report_content_form_header: 'Bitte wählen Sie einen Grund warum Sie diese Antwort melden möchten',
	report_content_flag_answer: 'Antwort melden',
	report_content_return_to_study: 'Zur Studie zurückkehren',
	report_content_thank_you: `Vielen Dank für Ihre Rückmeldung.\n
		Wir werden Ihre Meldung prüfen und bei Bedarf handeln.`,
	report_content_statement_flagged: 'Aussage wurde gemeldet.',

	platform_update_header: 'GroupSolver wird derzeit planmäßig gewartet',
	platform_update_message: 'Wir bitten um Entschuldigung für die Unannehmlichkeiten.',

	none_of_these: 'Keine von diesen',

	allocation_instruction_limit: 'Weisen Sie den Optionen {points} zu',
	allocation_instruction_limit_no_minimum: 'Weisen Sie den Optionen bis zu {points} zu',
	allocation_instruction_no_limit: 'Verteilen Sie Punkte auf die Optionen',
	allocation_instruction_points_left: 'Noch {points}',
	allocation_options_out_of_range: 'Einige Optionen liegen außerhalb des Bereichs',
	allocation_limit_overflow: '{points} über dem Limit',
	allocation_option_invalid_range: 'Der Wert liegt außerhalb des {range} Bereichs',
	allocation_option_invalid_min: 'Der Wert muss größer oder gleich {min} sein',

	dropdown_select: 'Auswählen...',

	ranking_drag_options: 'Ziehen oder klicken Sie auf die Pfeile, um die Optionen einzustufen',
	ranking_assign_option_values: 'Stufen Sie alle Optionen ein',
	ranking_require_unique_values: 'Optionsränge müssen eindeutig sein',

	heatmap_instruction_intro: 'Um mit der Auswahl zu beginnen, betrachten Sie das Bild unten.',
	heatmap_instruction_unlimited:
		'Wählen Sie so viele Punkte aus, wie Sie möchten, indem Sie auf das Bild klicken.',
	heatmap_instruction_count:
		'Wählen Sie {number, plural, =0 {0 Punkte} one {einen Punkt} other {{number} Punkte}} aus, indem Sie auf das Bild klicken.',
	heatmap_instruction_up_to_count:
		'Wählen Sie bis zu {number, plural, =0 {0 Punkte} one {einen Punkt} other {bis zu {number} Punkte}} aus, indem Sie auf das Bild klicken.',
	heatmap_instruction_between_count:
		'Wählen Sie {from} bis {to} Punkte aus, indem Sie auf das Bild klicken.',
	heatmap_instruction_at_least_count:
		'Wählen Sie mindestens {number, plural, =0 {0 Punkte} one {einen Punkt} other {{number} Punkte}} aus, indem Sie auf das Bild klicken.',
	heatmap_response:
		'{number, plural, =0 {0 Punkte} one {1 Punkt} other {{number} Punkte}} ausgewählt',

	continue: 'Weiter',
	reset: 'Zurücksetzen',

	input_invalid: 'Eingabe ist ungültig.',
	input_empty: 'Eingabe darf nicht leer sein.',
	input_chars_left: '{number} {number, plural, =0 {Zeichen} one {Zeichen} other {Zeichen}} übrig.',
	input_too_long:
		'{number} {number, plural, =0 {Zeichen} one {Zeichen} other {Zeichen}} über dem Limit.',

	// accessibility
	ranking_select_rank: 'Wählen Sie den Rang von {label}.',

	ranking_select_aria_label: 'Wählen Sie den Rang jeder Option, indem Sie einen Wert eingeben.',
	ranking_dnd_aria_label:
		'Fokussieren Sie eine Option und drücken Sie die Leertaste, um einen Drag zu starten. Beim Ziehen können Sie die Pfeiltasten verwenden, um das Element zu verschieben, und Escape, um abzubrechen. Drücken Sie die Leertaste erneut, um das Element an der neuen Position abzulegen. Stellen Sie sicher, dass Ihr Screenreader im Fokus- oder Formularmodus ist.',

	allocation_option_input: '{label} Wert Eingabe.',
	allocation_aria_label:
		'Geben Sie für jede Option einen Wert ein, indem Sie die Eingabefelder verwenden.',

	play: 'Abspielen',
	pause: 'Pause',
	stop: 'Stopp',
	fwd: 'Vorwärts springen',
	rwd: 'Zurückspulen',
	volume_down: 'Lautstärke verringern',
	volume_up: 'Lautstärke erhöhen',
	volume_current: 'Lautstärke: {number}%',

	loading: 'Laden',

	press_tab_to_continue: 'Drücken Sie Tab, um fortzufahren',

	option_focus_selected: 'Option {label} fokussiert. Drücken Sie Enter, um die Auswahl aufzuheben.',
	option_focus_not_selected: 'Option {label} fokussiert. Drücken Sie Enter, um auszuwählen.',

	image_alt: 'Bild:',
	video_alt: 'Video:',

	submit_enabled: 'Absenden aktiviert.',
	skip_enabled: 'Frage überspringen aktiviert',
	n_of_min_selected: '{selected} von {min} ausgewählt.',
	option_selected: '{label} ausgewählt.',
	option_deselected: '{label} Auswahl aufgehoben.',

	choice_multiple_hint: 'Wählen Sie {min} bis {max} Optionen.',

	ranking_position: 'Position: {position}',
	ranking_position_changed: '{label} Wert auf {value} gesetzt.',
	ranking_input_aria_label:
		'{label}. Aktuelle Position: {position}. Geben Sie eine Position von 1 bis {max} ein.',
	not_selected: 'nicht ausgewählt',

	study_logo_alt: 'Studienlogo',
	groupsolver_logo_alt: 'Groupsolver-Logo',
}

export default messages
