import { connect } from 'react-redux'
import ActionBar from './ActionBar'
import { injectIntl } from 'react-intl'
import { onPrimarySubmit, onSecondarySubmit, reportContent } from 'store/_chat/actionBar'
import {
	onPrimarySubmit as fullscreenOnPrimarySubmit,
	onSecondarySubmit as fullscreenOnSecondarySubmit,
} from 'store/_chat/fullscreenView'
import { onInputChange } from 'store/_chat/input'
import { toggleOption, suggestionsShow, suggestionsHide } from 'store/_chat/suggestions'
import { getIsStudyRtl } from 'store/intl'

import { getIsAccessibilityEnabled } from 'selectors/study'

const mapActionCreators = {
	onPrimarySubmit,
	onSecondarySubmit,
	fullscreenOnPrimarySubmit,
	fullscreenOnSecondarySubmit,
	onInputChange,
	reportContent,
	toggleOption,
	suggestionsShow,
	suggestionsHide,
}

const mapStateToProps = (state, ownProps) => {
	const storeSource = ownProps.isFullscreen
		? state.chatFullscreenView.actionBar
		: state.chatActionBar
	const input = {
		inputType: state.chatInput.inputType,
		isVisible: state.chatInput.isVisible,
		placeholder: state.chatInput.placeholder,
		maxLength: state.chatInput.maxLength,
		value: state.chatInput.value,
		options: state.chatInput.options,
		hint: state.chatInput.hint,
		isValid: state.chatInput.isValid,
		isEnabled: state.chatInput.isEnabled,
		isDelayed: state.chatInput.isDelayed,
		isKeyNavigationDisabled: state.chatInput.isKeyNavigationDisabled,
		isValidatorVisible: state.chatInput.isValidatorVisible,
	}

	return {
		input: ownProps.isFullscreen === true ? { isEnabled: false } : input,
		actionType: storeSource.actionType,
		actionState: storeSource.actionState,
		hintText: storeSource.hintText,
		isStudyRtl: getIsStudyRtl(state),

		buttonText: storeSource.buttonText,

		accessibilityHint: state.chatAccessibilityHint,
		isAccessibilityEnabled: getIsAccessibilityEnabled(state),
		isReportContentButtonShown:
			state.elaborate.stepData !== null || state.eliminate.stepData !== null,

		loaderType: storeSource.loaderType,
		loadTime: storeSource.loadTime,
		loadPercent: storeSource.loadPercent,

		rightOffset: ownProps.isFullscreen ? 0 : state.chatActionBar.rightOffset,
	}
}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ActionBar))
