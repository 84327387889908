import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ActionButton from './ActionButton'
import { getIsStudyRtl } from 'store/intl'
import { getIsAccessibilityEnabled } from 'selectors/study'

const mapActionCreators = {}

const mapStateToProps = state => ({
	isStudyRtl: getIsStudyRtl(state),
	theme: state.study.theme,
	isAccessibilityEnabled: getIsAccessibilityEnabled(state),
})

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ActionButton))
