import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const ACCESSIBILITY_HINT_RESET = 'accessibility.hint.reset'
export const ACCESSIBILITY_HINT_ADD_TEXT = 'accessibility.hint.add.text'
export const ACCESSIBILITY_HINT_PUBLISH = 'accessibility.hint.publish'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	isPublished: false,
	textArray: [],
	message: '',
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => dispatch({ type: ACCESSIBILITY_HINT_RESET })

export const addAccessibilityHintText = text => (dispatch, getState) => {
	dispatch({
		type: ACCESSIBILITY_HINT_ADD_TEXT,
		text,
	})
}

export const publishAccessibilityHint = () => (dispatch, getState) =>
	dispatch({
		type: ACCESSIBILITY_HINT_PUBLISH,
	})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ACCESSIBILITY_HINT_RESET]: (state, action) => _.cloneDeep(initialState),
	[ACCESSIBILITY_HINT_ADD_TEXT]: (state, action) => ({
		...state,
		textArray: [...state.textArray, action.text],
	}),
	[ACCESSIBILITY_HINT_PUBLISH]: (state, action) => ({
		...state,
		isPublished: true,
		message: state.textArray.join(' '),
		textArray: [],
	}),
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
