import moment from 'moment'
import _ from 'lodash'

import { CHOICE_SUBTYPE } from 'constants/choice'
import { CHOICE_QUESTION_LOG_ACTIONS } from 'constants/choiceQuestionLog'

import { getStudyObject, getIsAccessibilityEnabled } from 'selectors/study'
import { getCurrentMessage } from 'store/_chat/history'
import { pushOption, removeOption } from 'store/_chat/input'
import { addAccessibilityHintText } from 'store/_chat/accessibilityHint'
import { formatMessage } from 'store/intl'

// ------------------------------------
// Constants
// ------------------------------------
export const SUGGESTIONS_RESET = 'suggestions.reset'
export const SUGGESTIONS_SET = 'suggestions.set'
export const SUGGESTIONS_SHOW = 'suggestions.show'
export const SUGGESTIONS_HIDE = 'suggestions.hide'
export const SUGGESTIONS_SET_OPTIONS_CHANGE_LISTENER = 'suggestions.set.options.change.listener'
export const SUGGESTIONS_SET_OPTIONS = 'suggestions.set.options'
export const SUGGESTIONS_SET_RECORD_SELECT_LOG_ITEM = 'suggestions.set.select.log.item'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	options: [],
	optionsChangeListener: null,
	multiple: false,
	min: 1,
	max: 1,
	actionType: CHOICE_SUBTYPE.SELECT,
	isVisible: false,
	recordSelectLogItem: null,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => {
	dispatch({ type: SUGGESTIONS_RESET })
}

export const suggestionsSet = (options, multiple, min, max) => (dispatch, getState) => {
	dispatch({ type: SUGGESTIONS_SET, options, multiple, min, max })
}

export const suggestionsShow = () => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)

	const { isVisible, recordSelectLogItem } = getState().chatSuggestions

	if (isVisible === false) {
		recordSelectLogItem(
			moment().toISOString(),
			studyObject.id,
			studyObject.type,
			CHOICE_QUESTION_LOG_ACTIONS.OPTIONS_DISPLAYED,
			{
				options: getState().chatSuggestions.options,
				currentMessage: getCurrentMessage()(dispatch, getState),
			},
		)(dispatch, getState)
	}
	dispatch({ type: SUGGESTIONS_SHOW })
}

export const suggestionsHide = () => (dispatch, getState) => {
	dispatch({ type: SUGGESTIONS_HIDE })
}

export const setSuggestionsChangeListener = optionsChangeListener => (dispatch, getState) => {
	dispatch({
		type: SUGGESTIONS_SET_OPTIONS_CHANGE_LISTENER,
		optionsChangeListener,
	})
}

export const setRecordSelectLogItem = recordSelectLogItem => (dispatch, getState) => {
	dispatch({ type: SUGGESTIONS_SET_RECORD_SELECT_LOG_ITEM, recordSelectLogItem })
}

export const toggleOption = (idOption, state) => (dispatch, getState) => {
	const clickedOption = getState().chatSuggestions.options.find(option => option.id === idOption)
	const studyObject = getStudyObject()(dispatch, getState)
	const { maxSelection, multiple } = studyObject.definition

	const newOptions = _.cloneDeep(getState().chatSuggestions.options)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	const alreadySelectedOptions = newOptions.filter(option => option.selected === true)
	const alreadySelectedRegularOptions = newOptions.filter(
		option => option.selected === true && option.isNoneOfThese === false,
	)

	if (
		clickedOption.selected === false &&
		clickedOption.isNoneOfThese === false &&
		multiple === false &&
		alreadySelectedRegularOptions.length > 0
	) {
		// do nothing
		// user has to remove previously selected option to add a new one
		return
	}

	if (
		clickedOption.selected === false &&
		clickedOption.isNoneOfThese === false &&
		multiple === true &&
		alreadySelectedOptions.length >= maxSelection
	) {
		// do nothing
		return
	}

	if (clickedOption.selected === false && clickedOption.isNoneOfThese === true) {
		newOptions.forEach(option => {
			if (option.id !== idOption && option.selected === true) {
				if (isAccessibilityEnabled === true) {
					addAccessibilityHintText(
						formatMessage('option_deselected', { label: option.label })(dispatch, getState),
					)(dispatch, getState)
				}

				option.selected = false
				removeOption(option.id)(dispatch, getState)
			}
		})
	}

	if (clickedOption.selected === false && clickedOption.isNoneOfThese === false) {
		newOptions.forEach(option => {
			if (option.isNoneOfThese === true && option.selected === true) {
				if (isAccessibilityEnabled === true) {
					addAccessibilityHintText(
						formatMessage('option_deselected', { label: option.label })(dispatch, getState),
					)(dispatch, getState)
				}

				option.selected = false
				removeOption(option.id)(dispatch, getState)
			}
		})
	}

	if (clickedOption.selected === false) {
		newOptions.forEach(option => {
			if (option.id === idOption) {
				if (isAccessibilityEnabled === true) {
					addAccessibilityHintText(
						formatMessage('option_selected', { label: option.label })(dispatch, getState),
					)(dispatch, getState)
				}

				option.selected = true
				pushOption(clickedOption)(dispatch, getState)
			}
		})
	} else {
		newOptions.forEach(option => {
			if (option.id === idOption) {
				if (isAccessibilityEnabled === true) {
					addAccessibilityHintText(
						formatMessage('option_deselected', { label: option.label })(dispatch, getState),
					)(dispatch, getState)
				}

				option.selected = false
				removeOption(idOption)(dispatch, getState)
			}
		})
	}

	const choiceOptionAction =
		clickedOption.selected === true
			? CHOICE_QUESTION_LOG_ACTIONS.OPTION_DESELECTED
			: CHOICE_QUESTION_LOG_ACTIONS.OPTION_SELECTED

	const { recordSelectLogItem } = getState().chatSuggestions

	recordSelectLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		choiceOptionAction,
		idOption,
	)(dispatch, getState)

	dispatch({
		type: SUGGESTIONS_SET_OPTIONS,
		options: newOptions,
	})

	const changeListener = getState().chatSuggestions.optionsChangeListener

	if (changeListener) {
		changeListener(newOptions)(dispatch, getState)
	}
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[SUGGESTIONS_RESET]: (state, action) => {
		return { ...initialState }
	},
	[SUGGESTIONS_SET]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.options = action.options
		newState.multiple = action.multiple
		newState.min = action.min
		newState.max = action.max
		return newState
	},
	[SUGGESTIONS_SHOW]: (state, action) => {
		let newState = Object.assign({}, state)
		let selectedOptions = newState.options.filter(option => option.selected)
		if (selectedOptions.length < newState.max) {
			newState.isVisible = true
		}
		return newState
	},
	[SUGGESTIONS_HIDE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isVisible = false
		return newState
	},
	[SUGGESTIONS_SET_OPTIONS_CHANGE_LISTENER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.optionsChangeListener = action.optionsChangeListener
		return newState
	},
	[SUGGESTIONS_SET_OPTIONS]: (state, action) => ({
		...state,
		options: action.options,
	}),
	[SUGGESTIONS_SET_RECORD_SELECT_LOG_ITEM]: (state, action) => {
		return {
			...state,
			recordSelectLogItem: action.recordSelectLogItem,
		}
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
