import { APP_ERROR_SET } from 'store/_service/error'
import { SET_USER_INFO } from 'store/user'
import { API } from 'store/api'
import { END_STUDY } from 'constants/endStudy'
import { event, pageview, set } from 'helpers/googleAnalytics'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	idStudy: null,
	idModule: null,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[SET_USER_INFO]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.idStudy = action.userInfo.idStudy

		set({ idStudy: newState.idStudy })

		return newState
	},
	[API.LOGIN.SUCCESS]: (state, action) => {
		event({ category: state.idStudy, action: 'LOGGED_IN', nonInteraction: true })

		set({ idUser: action.response.token, pcid: action.response.pcid })

		event({
			category: 'APP',
			action: 'LOGGED_IN',
			label: state.idStudy + ' - ' + state.idModule,
			nonInteraction: true,
		})

		return state
	},
	[API.LOGIN.ERROR]: state => {
		event({ category: state.idStudy, action: 'LOGIN_ERROR', nonInteraction: true })

		return state
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.idModule = action.response.studyObject.id

		// log respondent termination only if history === null, otherwise it means study reload
		if (action.response.studyObject.type === END_STUDY && action.response.history === null) {
			event({
				category: newState.idStudy,
				action: action.response.studyObject.definition.subtype,
				label: action.response.studyObject.id + ' - ' + action.response.studyObject.simpleName,
				nonInteraction: true,
			})
		} else {
			// reload active page
			pageview('solver/' + state.idStudy)
		}

		return newState
	},
	[APP_ERROR_SET]: state => {
		event({
			category: state.idStudy,
			action: 'ERROR',
			label: state.idModule,
			nonInteraction: true,
		})

		// LOG APP LEVEL ERRORS
		event({
			category: 'APP',
			action: 'ERROR',
			label: state.idStudy + ' - ' + state.idModule,
			nonInteraction: true,
		})

		set(state)
		// change active page to error
		pageview('error/' + state.idStudy)

		return state
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
