import PropTypes from 'prop-types'
import { MEDIA_TYPE } from 'constants/media'

const mediaShape = PropTypes.shape({
	id: PropTypes.string.isRequired,
	type: PropTypes.oneOf(Object.values(MEDIA_TYPE)).isRequired,
	alt: PropTypes.string,
	url: PropTypes.string.isRequired,
	thumbnailUrl: PropTypes.string.isRequired,
	render: PropTypes.shape({
		forceFullscreen: PropTypes.bool.isRequired,
		blurBefore: PropTypes.bool.isRequired,
		hideAfter: PropTypes.bool.isRequired,
		videoShowControls: PropTypes.bool,
		videoAutoPlay: PropTypes.bool,
	}),
})

export default mediaShape
