import React from 'react'
import PropTypes from 'prop-types'

import SelectedOption from './SelectedOption'
import SelectInput from './SelectInput'
import ActionSelectAccessible from './ActionSelectAccessible'

import './ActionSelect.scss'

class ActionSelect extends React.Component {
	constructor(props) {
		super(props)
		this.handleWheel = this.handleWheel.bind(this)
		this.handleTouch = this.handleTouch.bind(this)
		this.focusInput = this.focusInput.bind(this)
		this.handleInputOnKey = this.handleInputOnKey.bind(this)
		this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
		this.removeOption = this.removeOption.bind(this)
		this.state = {}
		this.state.shouldFocusInput = false
		this.state.lastOptionsLength = props.options.length
		this.state.timeout = null
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.isAccessibilityEnabled === true) {
			return
		}

		let newState = Object.assign({}, this.state)
		if (newState.lastOptionsLength < nextProps.options.length && this.selectInput.input) {
			this.selectInput.input.value = ''
			this.props.onChange('', nextProps.options)
		}
		newState.lastOptionsLength = nextProps.options.length
		this.setState(newState)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isAccessibilityEnabled === true) {
			return
		}

		if (this.state.shouldFocusInput) {
			this.selectInput.input.focus()
		}
		if (this.selectInput.input.value.length === 0 && this.props.isStudyRtl === false) {
			this.wrapperOptions.scrollLeft =
				this.wrapperOptions.scrollWidth -
				this.selectInput.wrapper.scrollWidth -
				(this.selectInput.wrapper.scrollWidth - 160)
		}

		if (this.selectInput.input.value.length === 0 && this.props.isStudyRtl === true) {
			// scroll to the end of selected options list but keep 160px left for placeholder
			this.wrapperOptions.scrollLeft =
				this.wrapperOptions.scrollWidth -
				(this.wrapperOptions.scrollWidth - this.selectInput.wrapper.scrollWidth) -
				160
		}
	}

	componentDidMount() {
		if (this.props.isAccessibilityEnabled === true) {
			return
		}

		if (!this.state.isLoading) {
			this.wrapperOptions.scrollLeft = this.wrapperOptions.scrollWidth
			this.state.defaultInputWidth = window
				.getComputedStyle(this.selectInput.wrapper)
				.getPropertyValue('width')
			this.resizeInput(true)
			this.setState(this.state)
		}
	}

	handleWheel(event) {
		if (event.deltaY > 0) {
			this.wrapperOptions.scrollLeft += 20
		} else if (event.deltaY < 0) {
			this.wrapperOptions.scrollLeft -= 20
		}
		event.preventDefault()
	}

	handleTouch(event) {
		this.handleOnMouseDown(event)
		let touchX = event.touches[0].screenX
		if (event.type === 'touchstart') {
			this.setState({
				lastTouchX: touchX,
				lastPosition: this.wrapperOptions.scrollLeft,
			})
		} else if (event.type === 'touchmove') {
			let direction = this.state.lastTouchX - touchX
			this.wrapperOptions.scrollLeft = this.state.lastPosition + direction
		}
		// iOS caret fix
		let temp = this.selectInput.input.value
		this.selectInput.input.value = ''
		this.selectInput.input.value = temp
	}

	focusInput(event) {
		if (this.selectInput.input) {
			this.selectInput.input.focus()
		}
	}

	handleInputOnKey(event) {
		let value = event.currentTarget.value
		if (
			event.keyCode === 8 &&
			value.length === 0 &&
			this.props.value.length === 0 &&
			this.props.options.length > 0
		) {
			this.removeOption(this.props.options[this.props.options.length - 1].id)
			this.resizeInput(true)
		} else {
			this.props.onChange(value, this.props.options)
		}
	}

	handleOnMouseDown(event) {
		event.preventDefault()
	}

	removeOption(id) {
		this.props.toggleOption(id, false)
	}

	resizeInput(deleting = false) {
		if (this.props.isAccessibilityEnabled === true) {
			return
		}

		let children = Array.from(this.wrapperOptions.children)
		children.pop() // input is last
		let width = 0
		for (let i = 0; i < children.length - (deleting ? 1 : 0); i++) {
			width += children[i].clientWidth
		}
		if (this.wrapperOptions.clientWidth >= width + this.selectInput.wrapper.clientWidth) {
			this.selectInput.wrapper.style.width = this.wrapperOptions.clientWidth - width + 'px'
		} else if (this.selectInput.input.scrollWidth > this.selectInput.wrapper.clientWidth) {
			this.selectInput.wrapper.style.width = this.selectInput.input.scrollWidth + 'px'
		} else if (this.props.value.length > this.selectInput.input.value.length) {
			this.selectInput.wrapper.style.width = this.state.defaultInputWidth
			this.selectInput.wrapper.style.width = this.selectInput.input.scrollWidth + 'px'
		}
	}

	render() {
		return (
			<div className="ActionSelect-wrapper-outer">
				{this.props.isAccessibilityEnabled === true ? (
					<div className="ActionSelect-wrapper--accessibility">
						<ActionSelectAccessible placeholder={this.props.placeholder} />
					</div>
				) : (
					<div className={'ActionSelect-wrapper'}>
						<div
							className="ActionSelect-wrapper-inner"
							onWheel={this.handleWheel}
							onTouchStart={this.handleTouch}
							onTouchMove={this.handleTouch}
							onTouchEnd={this.focusInput}
							onMouseDown={this.handleOnMouseDown}
							onClick={this.focusInput}
						>
							<div
								ref={wrapperOptions => {
									this.wrapperOptions = wrapperOptions
								}}
								className="ActionSelect-wrapper-options"
							>
								{this.props.options.map((option, i) => (
									<SelectedOption
										ref={opt => {
											this.options = this.options || []
											this.options[i] = opt
										}}
										key={'SelectedOption-' + i}
										option={option}
										removeOption={this.removeOption}
										focusInput={this.focusInput}
										theme={this.props.theme}
										isStudyRtl={this.props.isStudyRtl}
									/>
								))}
								<SelectInput
									ref={selectInput => {
										this.selectInput = selectInput
									}}
									placeholder={this.props.placeholder}
									handleInputOnKey={this.handleInputOnKey}
									suggestionsShow={this.props.suggestionsShow}
									suggestionsHide={this.props.suggestionsHide}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}

ActionSelect.propTypes = {
	isAccessibilityEnabled: PropTypes.bool.isRequired,
	isStudyRtl: PropTypes.bool.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	toggleOption: PropTypes.func,
	suggestionsShow: PropTypes.func.isRequired,
	suggestionsHide: PropTypes.func.isRequired,

	theme: PropTypes.object,
}

export default ActionSelect
