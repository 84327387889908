import 'isomorphic-fetch'
import 'es6-promise/auto'
import { push } from 'react-router'
import moment from 'moment'
import { errorHandler } from 'store/logger'

export default (requestUrl, requestData, actions) => (dispatch, getState) => {
	const progressToken = actions.STARTED
	const successToken = actions.SUCCESS
	const errorToken = actions.ERROR

	let requestLog = {
		request: actions.ENDPOINT,
		miliseconds: null,
		status: null,
	}

	// dispatch STARTED action
	dispatch({ type: progressToken, data: requestData })

	let promise = new Promise((resolve, reject) => {
		let requestStarted = moment.utc()

		// start a network call
		fetch(requestUrl, requestData)
			.then(r => {
				return Promise.all([r.status, r.statusText, r.text()])
			})
			.then(([status, statusText, text]) => {
				let requestEnded = moment.utc()
				requestLog.miliseconds = moment.duration(requestEnded.diff(requestStarted)).asMilliseconds()
				requestLog.status = status

				const response = {
					status,
					statusText,
					text,
				}

				try {
					response.json = JSON.parse(text)
				} catch (error) {
					errorHandler(new Error('Fetch error'), { errorToken, response, requestUrl, requestData })(
						dispatch,
						getState,
					)
					throw error
				}

				return response
			})
			.then(response => {
				let requestEnded = moment.utc()
				requestLog.miliseconds = moment.duration(requestEnded.diff(requestStarted)).asMilliseconds()
				requestLog.status = response.status

				let data = {
					type: errorToken,
					response,
					errorId: response.json.errorId,
				}

				if (response.status === 404 || response.status >= 500) {
					// if there is network or server error log, hit catch block and break promise chain
					errorHandler(new Error(`Response status ${response.status}`), { data })(
						dispatch,
						getState,
					)

					const breakPromiseChainError = new Error(`Response status ${response.status}`)
					breakPromiseChainError.errorId = response.json.errorId
					breakPromiseChainError.isLogged = true

					throw breakPromiseChainError
				}

				/*
				QUICK FIX for MS EDGE - if response has status 401 unathorised then no body is present, throuwing away server response
				this duplicates error handling bellow
			*/
				if (response.status === 401) {
					return {
						name: 'Unauthorized',
						errorMessage: ['Invalid authorization token'],
						code: 0,
						status: 401,
						type: 'yii\\web\\HttpException',
					}
				}
				// parse json in body
				return response.json
			})
			.then(jsonResponse => {
				// convert errorMessage to string for futere logging
				let errorMessage =
					jsonResponse.errorMessage !== null ? jsonResponse.errorMessage.toString() : null
				let errorTrace = JSON.stringify(jsonResponse.errorTrace)
				// prepare success and error message
				let success = {
					type: successToken,
					response: jsonResponse.data,
					status: jsonResponse.status,
					errorMessage,
				}
				let error = {
					type: errorToken,
					response: jsonResponse.data,
					status: jsonResponse.status,
					errorId: jsonResponse.errorId,
					errorTrace: errorTrace,
					errorMessage,
				}

				let message = null

				if (jsonResponse.status >= 400) {
					// use error message as a response
					message = error
				} else {
					// user success message as a response
					message = success
				}

				// dispatch SUCCESS or ERROR action
				let handled = false // was success handler finished succesfully?
				try {
					dispatch(message)
					handled = true
				} catch (e) {
					// log unsucesfull success handler to console
					// TODO: error handling to sentry
					console.error(e) // eslint-disable-line no-console
					dispatch(error)
					handled = false
				}

				// calling resolve from previous Promise allows API listeners to add custom .then functions
				resolve(handled === true ? message : error)
			})
			.catch(error => {
				if (error.isLogged !== true) {
					errorHandler(new Error(error.message), {
						errorToken,
						error,
						defaultFetcherCatch: true,
						requestUrl,
						requestData,
					})(dispatch, getState)
				}

				let parsedError = {}

				try {
					parsedError = JSON.parse(error.message)
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error('Cannot parse error.message', error.message)
				}

				// TODO: dispatch unknown error as general event API.ERROR
				let message = {
					data: null,
					status: 500,
					errorMessage: 'There was an unexpected error :( Please try again later.',
					errorId: parsedError.errorId || error.errorId,
				}
				dispatch({ type: errorToken, response: message })
				resolve({ type: errorToken, response: message })
				// eslint-disable-next-line no-console
				console.error('Unknown error in api comunication', error)
			})
	})

	return promise
}
