export default (store, authHandler, loadStudyOptionsSpy = null) => ({
	path: 'video-survey',
	/*  Async getComponent is only invoked when route matches   */
	getComponent(nextState, cb) {
		/*  Webpack - use 'require.ensure' to create a split point
		 and embed an async module loader (jsonp) when bundling   */
		require.ensure(
			[],
			require => {
				/*  Webpack - use require callback to define
			 dependencies for bundling   */
				const Component = require('./VideoSurvey').default

				/*  Return getComponent   */
				cb(null, Component)

				/* Webpack named bundle   */
			},
			'video-survey',
		)
	},
})
