import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { StyleSheet, css } from 'aphrodite/no-important'

import './DotsLoader.scss'
import loader from '!svg-inline-loader!./loader.svg'

const DotsLoader = props => {
	const styles = StyleSheet.create({
		dotsLoader: {
			':first-child circle': {
				fill: props.theme.primaryColor,
			},
		},
	})

	let cssClassName = 'DotsLoader-loader DotsLoader-loader-grey'

	if (props.isPrimaryColor === true) {
		cssClassName = 'DotsLoader-loader DotsLoader-loader-primary'
	} else if (props.isThemed === true) {
		cssClassName = classnames(css(styles.dotsLoader), 'DotsLoader-loader')
	}

	return <div className={cssClassName} dangerouslySetInnerHTML={{ __html: loader }} />
}

DotsLoader.defaultProps = {
	isThemed: false,
	isPrimaryColor: false,
}

DotsLoader.propTypes = {
	isThemed: PropTypes.bool.isRequired,
	isPrimaryColor: PropTypes.bool.isRequired,
	theme: PropTypes.object,
}

export default DotsLoader
