import { connect } from 'react-redux'
import ActionSelect from './ActionSelect'
import { injectIntl } from 'react-intl'
import { getIsStudyRtl } from 'store/intl'
import { getIsAccessibilityEnabled } from 'selectors/study'

const mapStateToProps = (state, ownProps) => ({
	theme: state.study.theme,
	isStudyRtl: getIsStudyRtl(state),
	isAccessibilityEnabled: getIsAccessibilityEnabled(state),
})

export default connect(mapStateToProps)(injectIntl(ActionSelect))
