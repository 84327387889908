import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ReportContentForm from './ReportContentForm'
import { getIsStudyRtl } from 'store/intl'

import { actions } from 'store/_chat/reportContentForm'

const mapActionCreators = {
	hideReportContentForm: actions.hideReportContentForm,
	addReport: actions.addReport,
	changeFormData: actions.changeFormData,
}

const mapStateToProps = state => ({
	formData: state.chatReportContentForm.formData,
	isSubmitted: state.chatReportContentForm.isSubmitted,
	hasDropdown: state.chatReportContentForm.hasDropdown,
	stepData: state.chatReportContentForm.stepData,
	isStudyRtl: getIsStudyRtl(state),
})

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ReportContentForm))
