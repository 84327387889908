const messages = {
	not_found: 'ไม่มีหน้าที่คุณกำลังค้นหา',
	service_outage:
		'เราต้องขออภัยที่ระบบของเราไม่ทำงานเนื่องจากเกิดปัญหาสัญญาณขาดหายกับบริการคลาวด์ของผู้ให้บริการของเราทั่วโลก',
	error_unexpected_error: 'มีปัญหาที่ไม่คาดคิด โปรดลองอีกครั้งในภายหลัง',

	error_img_alt: 'ภาพผิดพลาด',
	error_try_again: 'ลองอีกครั้ง',

	error_generic_header: 'บางอย่างผิดพลาด',
	error_generic_message:
		'ลองอีกครั้ง และหากยังใช้งานไม่ได้ โปรดส่งอีเมลไปที่ support@groupsolver.com พร้อมรหัส QR ที่คุณเห็นด้านบน',

	error_offline_header: 'ดูเหมือนว่าคุณกำลังออฟไลน์',
	error_offline_message: 'การศึกษาของคุณจะกลับมาทำงานหลังจากได้รับการเชื่อมต่อของคุณอีกครั้ง',

	error_landscape_small_screen_header: 'โปรดหมุนอุปกรณ์ของคุณ',
	error_landscape_small_screen_text:
		'หน้าจอของคุณสั้นเกินไปที่จะดูการศึกษานี้ โปรดหมุนอุปกรณ์ของคุณ',

	error_small_browser_window_header: 'โปรดขยายหน้าต่างเบราว์เซอร์ของคุณ',
	error_small_browser_window_text:
		'หน้าต่างของคุณเล็กเกินไปที่จะดูการศึกษานี้ โปรดขยายหน้าต่างเบราว์เซอร์ของคุณ',

	login_error_empty_email: 'ห้ามเว้นว่างอีเมล',
	login_error_invalid_email: 'โปรดใส่อีเมลที่ถูกต้อง',
	login_error_unable_to_login: 'ไม่สามารถเข้าสู่ระบบได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง',

	login_email_placeholder: 'กรอกอีเมลของคุณ',
	login_email_button: 'ดำเนินการต่อ',
	login_email_back: 'หรือกลับไปที่ตัวเลือกการเข้าสู่ระบบ',
	login_email_label: 'ดำเนินการต่อด้วยอีเมล',
	login_anonymous_label: 'ดำเนินการต่อโดยไม่ระบุชื่อ',

	login_privacy_statement: 'GroupSolver เคารพความเป็นส่วนตัวและข้อมูลที่คุณมอบให้',
	login_privacy_statement_email:
		'อีเมลของคุณจะไม่ถูกแชร์ ขาย หรือนำไปใช้ในทางอื่นนอกจากการอนุญาตให้คุณเข้าถึงโครงการนี้',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'การศึกษาที่คุณค้นหาไม่สามารถใช้งานได้ในขณะนี้',
	study_unavailable_description:
		'ขอบคุณสำหรับความสนใจในการศึกษาของเรา! น่าเสียดายที่การศึกษานี้สำเร็จแล้วหรือหยุดชั่วคราวในขณะนี้ เราขออภัยในความไม่สะดวกที่อาจเกิดขึ้น',
	study_preview_limit_reached_label: 'ถึงขีดจำกัดการดูตัวอย่างของคุณแล้ว',
	study_preview_limit_reached_description:
		'ขอบคุณสำหรับความสนใจในการศึกษาของเรา! โชคไม่ดีที่การศึกษานี้ถึงขีดจำกัดการดูตัวอย่างแล้ว',

	waiting_message:
		'การศึกษานี้กำลังได้รับการแก้ไขโดยหลายคนในขณะนี้ \n เราจะลองเข้าอีกครั้งโดยอัตโนมัติ {number, plural, =0 {ในตอนนี้} one {ใน # วินาที} other {ใน # วินาที}}',
	waiting_error_recoverable: 'เราประสบกับข้อผิดพลาดที่ไม่คาดคิด',
	waiting_error_recoverable_retry:
		'เราจะลองอีกครั้ง {number, plural, =0 {ในตอนนี้} one {ใน # วินาที} other {ใน # วินาที}}',
	waiting_error_unknown_study: 'ไม่มีการศึกษาที่คุณกำลังค้นหา',
	waiting_error_dead: 'มีปัญหาที่ไม่คาดคิด โปรดลองอีกครั้งในภายหลัง',

	action_input_submit_button_text: 'ส่ง',
	action_input_skip_button_text: 'ดำเนินการต่อ',

	choice_single_hint: 'เลือกหนึ่งในตัวเลือก',
	general_skip_action_message: 'ดำเนินการไปยังขั้นตอนถัดไป',

	action_button_primary_default_text: 'การดำเนินการ',
	action_button_hint_default_text: 'เลือกหนึ่งในตัวเลือก',

	message_media_examine_video: 'คลิกวิดีโอเพื่อตรวจสอบ',
	message_media_examine_image: 'คลิกภาพเพื่อตรวจสอบ',
	message_full_screen_close_button_text: 'ปิด',
	message_image_view_label: 'ดูภาพ',
	message_video_view_label: 'ดูวิดีโอ',
	message_image_examined_label: 'ตรวจสอบรูปภาพแล้ว',
	message_video_examined_label: 'ตรวจสอบวิดีโอแล้ว',
	message_zoom_mouse: 'วางเมาส์เหนือเพื่อซูม',
	message_zoom_touch: 'แตะเพื่อซูม',

	textarea_too_long_hint: 'ข้อความของคุณยาวเกินไป',

	ideate_placeholder: 'ตอบในประโยคสั้น ๆ',
	ideate_skip: ' หรือดำเนินการต่อ',

	eliminate_no: 'ไม่',
	eliminate_yes: 'ใช่',
	eliminate_intro: 'พิจารณาข้อความต่อไปนี้:',
	eliminate_multiple_suggestions: 'ข้อใดต่อไปนี้สามารถแทนที่ได้?',
	eliminate_one_suggestion: 'สามารถแทนที่ด้วยสิ่งต่อไปนี้ได้หรือไม่?',
	eliminate_step: 'พิจารณาอีกข้อความ:',
	eliminate_none_of_the_above: 'ไม่ใช่ทั้งนั้น',

	elaborate_indifferent_label: 'เป็นกลาง',
	elaborate_disagree_label: 'ไม่เห็นด้วย',
	elaborate_agree_label: 'เห็นด้วย',
	elaborate_unclear_statement_label: 'คำตอบไม่ชัดเจน',
	elaborate_intro: 'คนอื่น ๆ ให้คำตอบต่อไปนี้ คุณเห็นด้วยหรือไม่?',

	ideamatching_unsure_label: 'ไม่แน่ใจ',
	ideamatching_unequal_label: 'แตกต่าง',
	ideamatching_equal_label: 'เหมือนกัน',
	ideamatching_intro: 'ดูคำตอบคู่นี้ มีความหมายเดียวกันหรือไม่?',

	select_input_placeholder: 'พิมพ์ที่นี่เพื่อเลือก',
	select_input_placeholder_max: 'ถึงจุดสูงสุดแล้ว',
	select_multiple_choice_hint:
		'เลือก {minNumber, plural, =0 { 0 } other { # }} ถึง {maxNumber, plural, =0 { 0 } other { # }} ตัวเลือก',
	select_no_matches_found: 'ไม่พบที่ตรงกัน',

	report_content_inappropriate: 'คำตอบไม่เหมาะสม',
	report_content_profane: 'เนื้อหาที่ลามกหรือหยาบคาย',
	report_content_gibberish: 'ข้อความซึ่งเข้าใจยากหรือไร้ความหมาย',
	report_content_irrelevant: 'ไม่เกี่ยวข้องกับคำถามที่ถาม',
	report_content_different_language: 'ภาษาอื่น',
	report_content_other: 'อื่น ๆ',
	report_content_no_content: 'ไม่มีเนื้อหาที่จะรายงาน',
	report_content_header: 'รายงานคำตอบ',
	report_content_form_header: 'โปรดเลือกเหตุผลที่คุณต้องการทำเครื่องหมายปักธงคำตอบนี้',
	report_content_flag_answer: 'ปักธงคำตอบ',
	report_content_return_to_study: 'กลับไปที่การศึกษา',
	report_content_thank_you: `ขอบคุณสำหรับความคิดเห็นของคุณ\n
		เราจะตรวจสอบข้อมูลที่คุณป้อนและดำเนินการหากจำเป็น`,
	report_content_statement_flagged: 'ปักธงให้กับข้อความแล้ว',

	platform_update_header: 'GroupSolver กำลังอยู่ระหว่างการบำรุงรักษาตามกำหนดเวลา',
	platform_update_message: 'เราขออภัยในความไม่สะดวก',

	none_of_these: 'ไม่ใช่รายการเหล่านี้',

	allocation_instruction_limit: 'จัดสรร {points} ทุกตัวเลือก',
	allocation_instruction_limit_no_minimum: 'จัดสรรสูงสุด {points} ทุกตัวเลือก',
	allocation_instruction_no_limit: 'จัดสรรคะแนนทุกตัวเลือก',
	allocation_instruction_points_left: 'เหลือ {points}',
	allocation_options_out_of_range: 'ตัวเลือกบางรายการอยู่นอกเหนือขอบเขต',
	allocation_limit_overflow: 'เกินขีดจำกัด {points}',
	allocation_option_invalid_range: 'ค่าอยู่นอกเหนือขอบเขต {range}',
	allocation_option_invalid_min: 'ค่าต้องมีค่ามากกว่าหรือเท่ากับ {min}',

	dropdown_select: 'เลือก...',

	ranking_drag_options: 'ลากหรือคลิกลูกศรเพื่อจัดอันดับตัวเลือก',
	ranking_assign_option_values: 'จัดลำดับตัวเลือกทั้งหมด',
	ranking_require_unique_values: 'การจัดลำดับตัวเลือกต้องไม่ซ้ำกัน',

	heatmap_instruction_intro: 'ในการเริ่มต้นการเลือก ดูภาพด้านล่าง',
	heatmap_instruction_unlimited: 'เลือกจุดได้มากเท่าที่คุณต้องการโดยการคลิกที่ภาพ',
	heatmap_instruction_count:
		'เลือก {number, plural, =0 {0 จุด} one {1 จุด} other {{number} จุด}} โดยการคลิกที่ภาพ',
	heatmap_instruction_up_to_count:
		'เลือก {number, plural, =0 {0 จุด} one {1 จุด} other {สูงสุด {number} จุด}} โดยการคลิกที่ภาพ',
	heatmap_instruction_between_count: 'เลือก {from} ถึง {to} จุดโดยการคลิกที่ภาพ',
	heatmap_instruction_at_least_count:
		'เลือกอย่างน้อย {number, plural, =0 {0 จุด} one {1 จุด} other {{number} จุด}} โดยการคลิกที่ภาพ',
	heatmap_response: '{number, plural, =0 {0 จุด} one {1 จุด} other {{number} จุด}} ที่เลือก',
	continue: 'ดำเนินการต่อ',
	reset: 'รีเซ็ต',
	input_invalid: 'ข้อมูลที่ป้อนไม่ถูกต้อง',
	input_empty: 'ข้อมูลที่ป้อนไม่สามารถว่างเปล่าได้',
	input_chars_left:
		'{number} {number, plural, =0 {ตัวอักษร} one {ตัวอักษร} other {ตัวอักษร}} ที่เหลือ',
	input_too_long:
		'{number} {number, plural, =0 {ตัวอักษร} one {ตัวอักษร} other {ตัวอักษร}} เกินขีดจำกัด',
	ranking_select_rank: 'เลือกอันดับของ {label}',
	ranking_select_aria_label: 'เลือกอันดับของแต่ละตัวเลือกโดยการพิมพ์ค่า',
	ranking_dnd_aria_label:
		'โฟกัสที่ตัวเลือกและกดแป้นเว้นวรรคเพื่อเริ่มการลาก เมื่อกำลังลากคุณสามารถใช้ปุ่มลูกศรเพื่อย้ายรายการไปรอบ ๆ และกด escape เพื่อยกเลิก กดแป้นเว้นวรรคอีกครั้งเพื่อวางรายการในตำแหน่งใหม่ ตรวจสอบให้แน่ใจว่าผู้อ่านหน้าจอของคุณอยู่ในโหมดโฟกัสหรือโหมดฟอร์ม',
	allocation_option_input: 'ป้อนค่าของ {label}',
	allocation_aria_label: 'ป้อนค่าของแต่ละตัวเลือกโดยใช้การป้อนข้อมูล',
	play: 'เล่น',
	pause: 'หยุดชั่วคราว',
	stop: 'หยุด',
	fwd: 'ข้ามไปข้างหน้า',
	rwd: 'กรอถอยหลัง',
	volume_down: 'ลดระดับเสียง',
	volume_up: 'เพิ่มระดับเสียง',
	volume_current: 'ระดับเสียง: {number}%',
	loading: 'กำลังโหลด',
	press_tab_to_continue: 'กดแท็บเพื่อดำเนินการต่อ',
	option_focus_selected: 'ตัวเลือก {label} โฟกัส กด enter เพื่อยกเลิกการเลือก',
	option_focus_not_selected: 'ตัวเลือก {label} โฟกัส กด enter เพื่อเลือก',
	image_alt: 'ภาพ:',
	video_alt: 'วิดีโอ:',
	submit_enabled: 'เปิดใช้งานการส่ง',
	skip_enabled: 'เปิดใช้งานการข้ามคำถาม',
	n_of_min_selected: '{selected} จาก {min} ที่เลือก',
	option_selected: '{label} ที่เลือก',
	option_deselected: '{label} ที่ยกเลิกการเลือก',
	choice_multiple_hint: 'เลือก {min} ถึง {max} ตัวเลือก',
	ranking_position: 'ตำแหน่ง: {position}',
	ranking_position_changed: 'ค่าของ {label} ตั้งเป็น {value}',
	ranking_input_aria_label: '{label}. ตำแหน่งปัจจุบัน: {position}. พิมพ์ตำแหน่งจาก 1 ถึง {max}',
	not_selected: 'ไม่ได้เลือก',
	study_logo_alt: 'โลโก้การศึกษา',
	groupsolver_logo_alt: 'โลโก้ Gropsolver',
}

export default messages
