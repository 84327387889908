import AnimateHeight from 'react-animate-height'
import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { intlShape } from 'react-intl'

import Option from './Option'
import BlockOption from './BlockOption'
import optionShape from 'shapes/option'

import './ActionChoices.scss'

import { CHOICE_SUBTYPE, CHOICE_DISPLAY_TYPE } from 'constants/choice'
import { DELAY_LENGTH } from 'constants/delay'

class ActionChoices extends React.Component {
	constructor(props) {
		super(props)
		this.state = this.createState(this.props)

		this.calculateType = this.calculateType.bind(this)
		this.selectOption = this.selectOption.bind(this)
		this.handleUpdate = this.handleUpdate.bind(this)
		this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
		this.createState = this.createState.bind(this)
		this.disableHover = this.disableHover.bind(this)
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.isVisible !== this.state.lastVisibility) {
			this.setState(this.createState(nextProps))
		}
	}

	componentDidUpdate() {
		this.handleUpdate()
	}

	componentDidMount() {
		this.handleUpdate()
		window.addEventListener('touchstart', this.disableHover)
	}

	componentWillUnmount() {
		window.removeEventListener('touchstart', this.disableHover)
	}

	createState(nextProps) {
		let newState = Object.assign({}, this.state)
		newState.display = CHOICE_DISPLAY_TYPE.ROW
		newState.visible = false
		newState.typeCalculated = false
		newState.height = 0
		newState.lastScroll = null
		newState.lastVisibility = nextProps.isVisible
		newState.isHoverEnabled = true

		return newState
	}

	handleOnMouseDown(event) {
		event.preventDefault()
	}

	handleUpdate() {
		if (!this.state.visible) {
			let newState = Object.assign({}, this.state)
			if (!this.state.typeCalculated) {
				newState.display = this.calculateType()
				newState.typeCalculated = true
			} else {
				newState.visible = true
				if (this.props.isVisible) {
					newState.height = 'auto'
				}
			}
			this.setState(newState)
		}
	}

	calculateType() {
		if (this.choicesWrapper && this.choicesWrapper.contentElement.clientHeight > 50) {
			return CHOICE_DISPLAY_TYPE.COLUMN
		}
		return this.state.display
	}

	selectOption(event) {
		const idOption = event.currentTarget.getAttribute('data-id')

		this.props.toggleOption(this.props.actionType, idOption)

		this.setState(this.state)
	}

	disableHover() {
		if (this.state.isHoverEnabled) {
			let newState = Object.assign({}, this.state)
			newState.isHoverEnabled = false
			this.setState(newState)
		}
	}

	getActionTypeClass = () => {
		const { actionType } = this.props
		if (actionType === CHOICE_SUBTYPE.CHECKBOX) return 'choice'

		if (actionType === CHOICE_SUBTYPE.SELECT) return 'select'

		if (actionType === CHOICE_SUBTYPE.BLOCK) return 'block'

		if (actionType === CHOICE_SUBTYPE.IMAGE) {
			const faultyImageOption = this.props.options.find(choice => choice.media === null)
			if (faultyImageOption !== undefined) {
				console.warn('Image Option with id', faultyImageOption.id, 'has no media set.') // eslint-disable-line no-console
			}

			return 'image'
		}

		return ''
	}

	render() {
		const { actionType, isStudyRtl, studyObject } = this.props
		const { formatMessage } = this.props.intl
		const optionsShown = []

		const actionTypeClass = this.getActionTypeClass()
		const isSingleRow =
			actionType === CHOICE_SUBTYPE.CHECKBOX
				? this.state.display === CHOICE_DISPLAY_TYPE.ROW
				: false

		const hasCenteredColumnOptions =
			this.state.display === CHOICE_DISPLAY_TYPE.COLUMN &&
			[CHOICE_SUBTYPE.CHECKBOX, CHOICE_SUBTYPE.SELECT].includes(actionType) &&
			_.get(studyObject, 'definition.hasCenteredOptions', false)

		return (
			<AnimateHeight
				ref={choicesWrapper => {
					this.choicesWrapper = choicesWrapper
				}}
				duration={DELAY_LENGTH.LONG}
				// calculateType bugfix
				/**
				 * if height is set to 0, which is components initial state,
				 * AnimateHeight add display: none to content and that causes
				 * faulty calculateType behavior.
				 */
				height={this.state.height || 1}
				contentClassName={classnames(
					`ActionChoices-wrapper-${this.state.display}`,
					`ActionChoices-type-${actionTypeClass}`,
					{
						[`ActionChoices-type-${actionTypeClass}--rtl`]: isStudyRtl === true,
						'ActionChoices-wrapper-hide-border': this.state.height === 0,
						'ActionChoices-wrapper-column--custom-align': hasCenteredColumnOptions === true,
					},
				)}
			>
				<div
					onMouseDown={this.handleOnMouseDown}
					className={classnames({
						'ActionChoices-wrapper-column__custom-align-options-wrapper':
							hasCenteredColumnOptions === true,
						'ActionChoices-wrapper-column__custom-align-options-wrapper--rtl':
							hasCenteredColumnOptions === true && isStudyRtl === true,
					})}
				>
					{this.props.options.map((choice, i) => {
						if (actionType === CHOICE_SUBTYPE.SELECT && (choice.selected || !choice.visible)) {
							return null
						}

						optionsShown.push(choice)

						return (
							<div
								key={i}
								className={classnames(
									`ActionChoices-wrapper-${this.state.display}-${actionTypeClass}`,
								)}
							>
								{((actionType === CHOICE_SUBTYPE.IMAGE && choice.media !== null) ||
									actionType === CHOICE_SUBTYPE.BLOCK) && (
									<BlockOption
										actionType={actionType}
										option={choice}
										selectOption={this.selectOption}
										intl={this.props.intl}
										isHoverEnabled={this.state.isHoverEnabled}
										showImage={actionType === CHOICE_SUBTYPE.IMAGE}
										showLabel={actionType === CHOICE_SUBTYPE.BLOCK ? true : choice.showLabel}
										theme={this.props.theme}
										openFullscreenImageOption={this.props.openFullscreenImageOption}
										studyTags={this.props.studyTags}
									/>
								)}
								{(actionType === CHOICE_SUBTYPE.CHECKBOX ||
									actionType === CHOICE_SUBTYPE.SELECT) && (
									<Option
										actionType={actionType}
										option={choice}
										intl={this.props.intl}
										isSingleRow={isSingleRow}
										isHoverEnabled={this.state.isHoverEnabled}
										selectOption={this.selectOption}
										handleOnMouseDown={this.handleOnMouseDown}
										theme={this.props.theme}
									/>
								)}
							</div>
						)
					})}
					{optionsShown.length === 0 && actionType === CHOICE_SUBTYPE.SELECT && (
						<div className="ActionChoices-nosuggestions">
							{formatMessage({ id: 'select_no_matches_found' })}
						</div>
					)}
				</div>
			</AnimateHeight>
		)
	}
}

ActionChoices.propTypes = {
	actionType: PropTypes.oneOf(Object.values(CHOICE_SUBTYPE)).isRequired,
	intl: intlShape.isRequired,
	isStudyRtl: PropTypes.bool.isRequired,
	isVisible: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(optionShape).isRequired,
	theme: PropTypes.object,
	toggleOption: PropTypes.func.isRequired,
	studyObject: PropTypes.object,
	// experimental
	studyTags: PropTypes.object.isRequired,
	openFullscreenImageOption: PropTypes.func.isRequired,
}

export default ActionChoices
