import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import FullscreenView from './FullscreenView'

import { getIsAccessibilityEnabled } from 'selectors/study'

const mapStateToProps = state => ({
	isAccessibilityEnabled: getIsAccessibilityEnabled(state),
	type: state.chatFullscreenView.mediaType,
	media: state.chatFullscreenView.media,
})

export default connect(mapStateToProps)(injectIntl(FullscreenView))
