import { combineReducers } from 'redux'
import locationReducer from './location'

import logger from 'store/logger'
import intl from 'store/intl'

import api from 'store/api'
import apiMonitor from 'store/apiMonitor'
import networkChecker from 'store/networkChecker'
import googleAnalytics from 'store/googleAnalytics'
import platformStatus from 'store/platformStatus'

import appError from 'store/_service/error'
import login from 'store/_service/login'
import waitingRoom from 'store/_service/waitingRoom'
import user from 'store/user'
import queue from 'store/queue'

import chatAccessibilityHint from 'store/_chat/accessibilityHint'
import chatActionBar from 'store/_chat/actionBar'
import chatActions from 'store/_chat/actions'
import chatInteractiveOptions from 'store/_chat/interactiveOptions'
import chatFullscreenView from 'store/_chat/fullscreenView'
import chatHeatmap from 'store/_chat/heatmap'
import chatHistory from 'store/_chat/history'
import chatInput from 'store/_chat/input'
import chatReportContentForm from 'store/_chat/reportContentForm'
import chatSuggestions from 'store/_chat/suggestions'
import chatTimer from 'store/_chat/timer'
import chatView from 'store/_chat/view'

// study modules
import study from 'store/study'
import allocation from 'store/_modules/allocation'
import choice from 'store/_modules/choice'
import elaborate from 'store/_modules/elaborate'
import eliminate from 'store/_modules/eliminate'
import endStudy from 'store/_modules/endStudy'
import freeText from 'store/_modules/freeText'
import heatmap from 'store/_modules/heatmap'
import ideamatching from 'store/_modules/ideamatching'
import ideate from 'store/_modules/ideate'
import maxDiff from 'store/_modules/maxDiff'
import message from 'store/_modules/message'
import ranking from 'store/_modules/ranking'
import researchLabeling from 'store/_modules/researchLabeling'
import select from 'store/_modules/select'
import uiCommand from 'store/_modules/uiCommand'

export const makeRootReducer = asyncReducers => {
	return combineReducers({
		location: locationReducer,
		api,
		apiMonitor,
		intl,
		appError,
		login,
		waitingRoom,
		study,
		queue,
		user,
		platformStatus,
		// chat components
		chatAccessibilityHint,
		chatActionBar,
		chatActions,
		chatInteractiveOptions,
		chatFullscreenView,
		chatHeatmap,
		chatHistory,
		chatInput,
		chatReportContentForm,
		chatSuggestions,
		chatTimer,
		chatView,
		// study modules
		allocation,
		choice,
		elaborate,
		eliminate,
		endStudy,
		freeText,
		heatmap,
		ideamatching,
		ideate,
		maxDiff,
		message,
		ranking,
		researchLabeling,
		select,
		uiCommand,
		...asyncReducers,
		networkChecker,
		googleAnalytics,
		logger, // logger has to be last
	})
}

export const injectReducer = (store, { key, reducer }) => {
	if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

	store.asyncReducers[key] = reducer
	store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
