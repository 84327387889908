/* eslint-disable max-len */

const messages = {
	not_found: 'A página que procura não existe',
	service_outage:
		'Lamentamos que, devido a uma interrupção global dos serviços na nuvem dos nossos fornecedores, o nosso sistema esteja inativo.',
	error_unexpected_error: 'Houve um problema inesperado. Por favor, tente novamente mais tarde.',

	error_img_alt: 'Imagem de erro',
	error_try_again: 'Tente novamente',

	error_generic_header: 'Algo correu mal',
	error_generic_message:
		'Tente isso novamente e, se mesmo assim não funcionar, por favor, envie um e-mail para support@groupsolver.com com o código QR que vê acima.',

	error_offline_header: 'Parece estar offline',
	error_offline_message: 'O seu estudo será retomado após a sua ligação ser restabelecida.',

	error_landscape_small_screen_header: 'Por favor, rode o seu dispositivo',
	error_landscape_small_screen_text:
		'O seu ecrã é muito pequeno para ver este estudo. Por favor, rode o seu dispositivo.',

	error_small_browser_window_header: 'Por favor, aumente a janela do seu navegador',
	error_small_browser_window_text:
		'A sua janela é muito pequena para ver este estudo. Por favor, aumente a janela do seu navegador',

	login_error_empty_email: 'O e-mail não pode estar em branco',
	login_error_invalid_email: 'Por favor, digite um e-mail válido',
	login_error_unable_to_login:
		'Impossível iniciar a sessão desta vez. Por favor, tente novamente mais tarde.',

	login_email_placeholder: 'Digite o seu e-mail',
	login_email_button: 'Continuar',
	login_email_back: 'ou voltar às opções de início de sessão',
	login_email_label: 'Continuar com o e-mail',
	login_anonymous_label: 'Continuar no anonimato',

	login_privacy_statement:
		'A GroupSolver respeita a sua privacidade e as informações que fornecer.',
	login_privacy_statement_email:
		'O seu e-mail nunca será partilhado, vendido ou utilizado de forma alguma exceto para lhe facultar o acesso a este projeto.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'O estudo que procura não está disponível de momento.',
	study_unavailable_description:
		'Obrigado pelo interesse demonstrado no nosso estudo! Infelizmente, este estudo já foi concluído ou está parado neste momento. Pedimos desculpa por qualquer incómodo que isso possa ter causado.',
	study_preview_limit_reached_label: 'O seu limite de visualizações foi atingido',
	study_preview_limit_reached_description:
		'Obrigado pelo interesse demonstrado no nosso estudo! Infelizmente, este estudo atingiu o número de visualizações disponíveis.',

	waiting_message:
		'Este estudo está a ser resolvido por várias pessoas neste preciso momento. \n Voltaremos a tentar entrar automaticamente {number, plural, =0 { agora } one {daqui a # segundo} other {daqui a # segundos}}.',
	waiting_error_recoverable: 'Encontrámos um erro inesperado.',
	waiting_error_recoverable_retry:
		'Tentaremos novamente {number, plural, =0 { agora } one {daqui a # segundo} other {daqui a # segundos}}',
	waiting_error_unknown_study: 'O estudo que procura não existe.',
	waiting_error_dead: 'Houve um problema inesperado. Por favor, tente novamente mais tarde.',

	action_input_submit_button_text: 'ENVIAR',
	action_input_skip_button_text: 'Continuar',

	choice_single_hint: 'Escolha uma das opções',
	general_skip_action_message: 'Passar à próxima etapa',

	action_button_primary_default_text: 'Ação',
	action_button_hint_default_text: 'Escolha uma das opções',

	message_media_examine_video: 'Clique no vídeo para o rever',
	message_media_examine_image: 'Clique na imagem para a rever',
	message_full_screen_close_button_text: 'Fechar',
	message_image_view_label: 'Ver a Imagem',
	message_video_view_label: 'Ver o Vídeo',
	message_image_examined_label: 'Imagem revista',
	message_video_examined_label: 'Vídeo revisto',
	message_zoom_mouse: 'Passe o rato para ampliar',
	message_zoom_touch: 'Toque para ampliar',

	textarea_too_long_hint: 'O seu texto é muito longo',

	ideate_placeholder: 'Responda com frase(s) curta(s)',
	ideate_skip: 'ou continue',

	eliminate_no: 'Não',
	eliminate_yes: 'Sim',
	eliminate_intro: 'Considere a seguinte afirmação:',
	eliminate_multiple_suggestions: 'Qual das seguintes opções poderia substituí-la?',
	eliminate_one_suggestion: 'Poderia ser substituída pela seguinte opção?',
	eliminate_step: 'Considere outra afirmação:',
	eliminate_none_of_the_above: 'Nenhuma delas',

	elaborate_indifferent_label: 'Neutro',
	elaborate_disagree_label: 'Discordo',
	elaborate_agree_label: 'Concordo',
	elaborate_unclear_statement_label: 'Resposta pouco clara',
	elaborate_intro: 'Outras pessoas forneceram as seguintes respostas. Concorda com elas?',

	ideamatching_unsure_label: 'Não sei ao certo',
	ideamatching_unequal_label: 'Diferente',
	ideamatching_equal_label: 'O mesmo',
	ideamatching_intro: 'Veja este par de respostas. Elas significam o mesmo?',

	select_input_placeholder: 'Digite aqui para selecionar',
	select_input_placeholder_max: 'Máximo atingido',
	select_multiple_choice_hint:
		'Escolha {minNumber, plural, =0 { 0 } other { # }} a {maxNumber, plural, =0 { 0 } other { # }} opções',
	select_no_matches_found: 'Nenhumas correspondências encontradas',

	report_content_inappropriate: 'Resposta inadequada',
	report_content_profane: 'Conteúdo obsceno ou profano',
	report_content_gibberish: 'Texto incompreensível ou sem sentido',
	report_content_irrelevant: 'Irrelevante para a pergunta feita',
	report_content_different_language: 'Idioma diferente',
	report_content_other: 'Outro',
	report_content_no_content: 'Nenhum conteúdo a denunciar',
	report_content_header: 'Denunciar a resposta',
	report_content_form_header:
		'Por favor, selecione o motivo pelo qual gostaria de sinalizar esta resposta',
	report_content_flag_answer: 'Sinalizar a resposta',
	report_content_return_to_study: 'Voltar ao estudo',
	report_content_thank_you: `Obrigado pelo seu feedback.\n
		Analisaremos a sua opinião e, se necessário, tomaremos medidas.`,
	report_content_statement_flagged: 'A afirmação foi sinalizada.',

	platform_update_header: 'A GroupSolver encontra-se atualmente na manutenção programada',
	platform_update_message: 'Pedimos desculpa por qualquer incómodo causado.',

	none_of_these: 'Nenhuma dessas opções',

	allocation_instruction_limit: 'Alocar {points} entre as opções',
	allocation_instruction_limit_no_minimum: 'Alocar até {points} entre as opções',
	allocation_instruction_no_limit: 'Alocar entre as opções',
	allocation_instruction_points_left: '{points} restantes',
	allocation_options_out_of_range: 'Algumas opções estão fora do intervalo',
	allocation_limit_overflow: '{points} acima do limite',
	allocation_option_invalid_range: 'Valor fora do {range} intervalo',
	allocation_option_invalid_min: 'Valor deve ser maior que ou igual a {min}',

	dropdown_select: 'Selecione...',

	ranking_drag_options: 'Arraste ou clique nas setas para classificar as opções',
	ranking_assign_option_values: 'Classifique todas as opções',
	ranking_require_unique_values: 'Classificações de opções devem ser únicas',

	heatmap_instruction_intro: 'Para começar a selecionar, veja a imagem abaixo.',
	heatmap_instruction_unlimited: 'Selecione quantos pontos desejar clicando na imagem.',
	heatmap_instruction_count:
		'Selecione {number, plural, =0 {0 pontos} one {um ponto} other {{number} pontos}} clicando na imagem.',
	heatmap_instruction_up_to_count:
		'Selecione {number, plural, =0 {0 pontos} one {um ponto} other {até {number} pontos}} clicando na imagem.',
	heatmap_instruction_between_count: 'Selecione de {from} a {to} pontos clicando na imagem',
	heatmap_instruction_at_least_count:
		'Selecione pelo menos {number, plural, =0 {0 pontos} one {um ponto} other {{number} pontos}} clicando na imagem.',
	heatmap_response:
		'{number, plural, =0 {0 pontos} one {1 ponto} other {{number} pontos}} selecionados',
	continue: 'Continuar',
	reset: 'Redefinir',
	input_invalid: 'Entrada não é válida.',
	input_empty: 'Entrada não pode estar vazia.',
	input_chars_left:
		'{number} {number, plural, =0 {caracteres} one {caractere} other {caracteres}} restantes.',
	input_too_long:
		'{number} {number, plural, =0 {caracteres} one {caractere} other {caracteres}} acima do limite.',
	ranking_select_rank: 'Selecione a classificação de {label}.',
	ranking_select_aria_label: 'Selecione a classificação de cada opção digitando um valor.',
	ranking_dnd_aria_label:
		'Foque em uma opção e pressione a barra de espaço para iniciar um arrasto. Ao arrastar, você pode usar as teclas de seta para mover o item e a tecla escape para cancelar. Pressione a barra de espaço novamente para colocar o item na nova posição. Certifique-se de que seu leitor de tela esteja no modo de foco ou modo de formulários.',
	allocation_option_input: 'Entrada de valor de {label}.',
	allocation_aria_label: 'Digite um valor para cada opção usando as entradas.',
	play: 'Reproduzir',
	pause: 'Pausar',
	stop: 'Parar',
	fwd: 'Avançar',
	rwd: 'Retroceder',
	volume_down: 'Diminuir volume',
	volume_up: 'Aumentar volume',
	volume_current: 'Volume: {number}%',
	loading: 'Carregando',
	press_tab_to_continue: 'Pressione tab para continuar',
	option_focus_selected: 'Opção {label} focada. Pressione enter para desmarcar.',
	option_focus_not_selected: 'Opção {label} focada. Pressione enter para selecionar.',
	image_alt: 'imagem:',
	video_alt: 'vídeo:',
	submit_enabled: 'Envio habilitado.',
	skip_enabled: 'Pular pergunta habilitado',
	n_of_min_selected: '{selected} de {min} selecionados.',
	option_selected: '{label} selecionado.',
	option_deselected: '{label} desmarcado.',
	choice_multiple_hint: 'Selecione de {min} a {max} opções.',
	ranking_position: 'posição: {position}',
	ranking_position_changed: 'Valor de {label} definido para {value}.',
	ranking_input_aria_label: '{label}. Posição atual: {position}. Digite uma posição de 1 a {max}.',
	not_selected: 'não selecionado',
	study_logo_alt: 'Logotipo do estudo',
	groupsolver_logo_alt: 'Logotipo do Groupsolver',
}

export default messages
