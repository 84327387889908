import { API } from 'store/api'
import { formatMessage } from 'store/intl'
import { addAction, addTempMessage } from 'store/queue'
import { DELAY_LENGTH } from 'constants/delay'
import { createComponent, removeTempMessages } from 'store/_chat/history'
import { COMPONENTS, POSITION } from 'constants/component'
import { setActionSubmitListener, showActionButton, showActionLoader } from 'store/_chat/actionBar'
import { ACTION_BUTTON_STATE, ACTION_BUTTON_LOADER_TYPE } from 'constants/actionButton'
import {
	setOptionsChangeListener,
	actionsShow,
	actionsHide,
	getActionOptions,
} from 'store/_chat/actions'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { MATCHING_OPTIONS, MATCHING_OPTIONS_ORDER } from 'constants/ideaMatching'

import { getStudyObjectDefinition, getStudyObject } from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'ideamatching.add.response'

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	setActionSubmitListener(null, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setOptionsChangeListener(primarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	if (module.priorMessage !== null) {
		let priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		addTempMessage(priorMessage, POSITION.LEFT, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep(module.steps[0])(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const showNextStep = nextStep => (dispatch, getState) => {
	let responses = getIdeaMatchingResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let definition = getStudyObjectDefinition()(dispatch, getState)

	let intro = createComponent(COMPONENTS.MESSAGE)
	intro.props.text = formatMessage('ideamatching_intro')(dispatch, getState)
	addTempMessage(intro, POSITION.LEFT, DELAY_LENGTH.LONG)(dispatch, getState)

	let firstAnswer = createComponent(COMPONENTS.QUOTE)
	firstAnswer.props.text = nextStep.first.label
	firstAnswer.props.id = nextStep.first.idStatement
	addTempMessage(firstAnswer, POSITION.LEFT, DELAY_LENGTH.LONG)(dispatch, getState)

	let secondAnswer = createComponent(COMPONENTS.QUOTE)
	secondAnswer.props.text = nextStep.second.label
	secondAnswer.props.id = nextStep.second.idStatement
	addTempMessage(secondAnswer, POSITION.LEFT, DELAY_LENGTH.LONG)(dispatch, getState)

	let options = Object.values(MATCHING_OPTIONS_ORDER).map(option => {
		return {
			id: option.idStatement,
			label: formatMessage(option.label)(dispatch, getState),
			selected: false,
		}
	})

	if (responses.length === 0) {
		if (definition.minResponses === 0) {
			let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)
			addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
				dispatch,
				getState,
			)
		} else {
			let hintText = formatMessage('choice_single_hint')(dispatch, getState)
			addAction(showActionButton, [
				ACTION_BUTTON_STATE.SKIP_PREP,
				hintText,
				ACTION_BUTTON_LOADER_TYPE.PERCENT,
				0,
			])(dispatch, getState)
		}
	} else if (
		responses.length >= definition.minResponses &&
		responses.length < definition.maxResponses
	) {
		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)
		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	}
	addAction(actionsShow, [CHOICE_SUBTYPE.CHECKBOX, options])(dispatch, getState)
}

export const primarySubmitListener = () => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getIdeaMatchingResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let options = getActionOptions()(dispatch, getState)

	let currentStep = definition.steps[responses.length]
	let selectedOption = options.filter(option => option.selected === true)[0]
	let selectedAnswer = Object.values(MATCHING_OPTIONS).find(
		apiAnswer => apiAnswer.idStatement === selectedOption.id,
	)

	let newResponse = {
		first: currentStep.first,
		second: currentStep.second,
		answer: selectedAnswer.answer,
	}

	dispatch({ type: ADD_RESPONSE, newResponse })

	// update action bar
	if (responses.length + 1 <= definition.minResponses) {
		let phaseProgress = ((responses.length + 1) / definition.minResponses) * 100
		let hintText = formatMessage('choice_single_hint')(dispatch, getState)
		addAction(showActionButton, [
			ACTION_BUTTON_STATE.SKIP_PREP,
			hintText,
			ACTION_BUTTON_LOADER_TYPE.PERCENT,
			phaseProgress,
		])(dispatch, getState)
	}

	if (responses.length + 1 === definition.maxResponses) {
		// limit reached, sent to server
		endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		actionsHide()(dispatch, getState)
		removeTempMessages()(dispatch, getState)
		if (responses.length >= definition.minResponses) {
			showActionLoader()(dispatch, getState)
		}

		let nextStep = definition.steps[responses.length + 1]
		showNextStep(nextStep)(dispatch, getState)
	}
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const endStep = () => (dispatch, getState) => {
	actionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)
	removeTempMessages()(dispatch, getState)

	let studyObject = getStudyObject()(dispatch, getState)
	let responses = getIdeaMatchingResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	let apiResponse = {}
	apiResponse[studyObject.id] = responses
	getState().study.getNextStep(apiResponse, null, true)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses]
		newState.responses.push(action.newResponse)

		return newState
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'ideamatching') {
			return state
		}
		let newState = Object.assign({}, state)
		newState.responses = initialState.responses

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Helpers
// ------------------------------------
export const getIdeaMatchingResponses = () => (dispatch, getState) => {
	let state = getState()
	return state.ideamatching.responses
}
