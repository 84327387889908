import { connect } from 'react-redux'
import ActionChoices from './ActionChoices'
import { injectIntl } from 'react-intl'
import { toggleOption as toggleAction, openFullscreenImageOption } from 'store/_chat/actions'
import { toggleOption as toggleSuggestion } from 'store/_chat/suggestions'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { getIsStudyRtl } from 'store/intl'

const mapActionCreators = {
	toggleOption: (type, idOption, state) =>
		type === CHOICE_SUBTYPE.SELECT
			? toggleSuggestion(idOption, state)
			: toggleAction(idOption, state),
	openFullscreenImageOption,
}

const mapStateToProps = (state, ownProps) => {
	let source = ownProps.type === CHOICE_SUBTYPE.SELECT ? 'chatSuggestions' : 'chatActions'

	return {
		actionType: state[source].actionType,
		focus: ownProps.type === CHOICE_SUBTYPE.SELECT ? state.chatSuggestions.focus : null,
		isStudyRtl: getIsStudyRtl(state),
		isVisible: state[source].isVisible,
		options: state[source].options,
		studyTags: state.study.tags,
		theme: state.study.theme,
		studyObject: state.study.studyObject,
	}
}

export default connect(mapStateToProps, mapActionCreators)(injectIntl(ActionChoices))
