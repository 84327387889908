import { blur, unblur, clear } from 'store/_chat/history'
import { addCommand, addAction } from 'store/queue'
import { DELAY_LENGTH } from 'constants/delay'
import { buttonStateUpdateListener } from './_tools/button'
import { setActionSubmitListener, showActionButton } from 'store/_chat/actionBar'
import { setTimerListeners, setTimerInterval, stopInterval } from 'store/_chat/timer'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { HISTORY_COMMANDS } from 'constants/history'

import { getStudyObjectDefinition, getStudyObject } from 'selectors/study'
// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// Primary Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	let definition = module

	if (definition.actionButton !== null) {
		blockCommandInit(definition)(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		showStep(definition)(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const showStep = definition => (dispatch, getState) => {
	let command = null
	let commands = []

	if (definition.history !== null) {
		command = historyCommandInit(definition)(dispatch, getState) // eslint-disable-line no-use-before-define
		commands.push(command)
	}
	if (definition.layout !== null) {
		command = layoutCommandInit(definition)(dispatch, getState) // eslint-disable-line no-use-before-define
		commands.push(command)
	}

	commands.forEach((comm, i) => {
		addCommand(comm, DELAY_LENGTH.LONG)(dispatch, getState) // eslint-disable-line no-use-before-define
		if (commands.length - 1 === i && definition.actionButton === null) {
			addCommand(comm, DELAY_LENGTH.LONG, endStep, [module])(dispatch, getState) // eslint-disable-line no-use-before-define
		}
	})

	// bugfix for invalid UI Command modules
	if (
		definition.layout === null &&
		definition.history === null &&
		definition.actionButton === null
	) {
		endStep(module)(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const endStep = module => (dispatch, getState) => {
	let studyObject = getStudyObject()(dispatch, getState)
	let response = {}
	response[studyObject.id] = null
	getState().study.getNextStep(response, null, true)(dispatch, getState)
}

// ------------------------------------
// Listeners
// ------------------------------------
export const updateListener = (currentTime, initialTime) => (dispatch, getState) => {
	let parentDefinition = getStudyObjectDefinition()(dispatch, getState)
	let definition = parentDefinition.actionButton

	if (definition === undefined || definition === null) {
		// study object changed
		return
	}

	buttonStateUpdateListener(
		definition.minTimer,
		definition.maxTimer,
		currentTime - initialTime,
		definition.label,
	)(dispatch, getState)
}

// ------------------------------------
// Helper Actions
// ------------------------------------
export const historyCommandInit = definition => (dispatch, getState) => {
	let command
	switch (definition.history) {
		case HISTORY_COMMANDS.BLUR:
			command = {
				name: 'blur',
				blur,
			}
			break
		case HISTORY_COMMANDS.UNBLUR:
			command = {
				name: 'unblur',
				unblur,
			}
			break
		case HISTORY_COMMANDS.CLEAR:
			command = {
				name: 'clear',
				clear,
			}
			break
		default:
			break
	}

	return command
}

export const layoutCommandInit = definition => (dispatch, getState) => {
	// TODO: cinema view
	let command
	switch (definition.layout) {
		case 'CINEMA_START':
			command = () => {}
			break
		case 'CINEMA_END':
			command = () => {}
			break
		default:
			break
	}

	return command
}

export const blockCommandInit = definition => (dispatch, getState) => {
	setActionSubmitListener(handleActionButton)(dispatch, getState) // eslint-disable-line no-use-before-define
	handleButtonState(definition.actionButton)(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const handleButtonState = buttonDefinition => (dispatch, getState) => {
	if (buttonDefinition.minTimer !== null || buttonDefinition.maxTimer !== null) {
		let duration = buttonDefinition.minTimer + buttonDefinition.maxTimer
		setTimerListeners(updateListener)(dispatch, getState)
		addAction(setTimerInterval, [duration])(dispatch, getState)
	} else {
		addAction(showActionButton, [ACTION_BUTTON_STATE.SUBMIT_READY, buttonDefinition.label])(
			dispatch,
			getState,
		)
	}
}

export const handleActionButton = () => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)

	stopInterval()(dispatch, getState)
	showStep(definition)(dispatch, getState)
	endStep()(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
