import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const APP_ERROR_SET = 'app.error.set'
export const APP_ERROR_CLEAR = 'app.error.clear'

export const ERROR_TYPES = {
	OFFLINE: 'OFFLINE',
	GENERIC: 'GENERIC',
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	showTryAgain: false,
	header: '',
	message: '',
	errorMessage: '',
	type: null,
	code: null,
	trace: null,
	id: null,
}

// ------------------------------------
// Actions
// ------------------------------------
export const setError = (
	errorType,
	header,
	message,
	showTryAgain = false,
	errorCode = null,
	errorTrace = null,
	errorId = null,
	errorMessage = null,
) => (dispatch, getState) => {
	dispatch({
		type: APP_ERROR_SET,
		errorType,
		header,
		message,
		showTryAgain,
		errorCode,
		errorTrace,
		errorId,
		errorMessage,
	})
}

export const setGenericError = (
	showTryAgain = true,
	errorCode = null,
	errorTrace = null,
	errorId = null,
	errorMessage = null,
) => (dispatch, getState) => {
	setError(
		ERROR_TYPES.GENERIC,
		'error_generic_header',
		'error_generic_message',
		showTryAgain,
		errorCode,
		errorTrace,
		errorId,
		errorMessage,
	)(dispatch, getState)
}

export const clearError = () => (dispatch, getState) => {
	dispatch({ type: APP_ERROR_CLEAR })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[APP_ERROR_SET]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.header = action.header
		newState.message = action.message
		newState.errorMessage = action.errorMessage
		newState.type = action.errorType
		newState.showTryAgain = action.showTryAgain
		newState.code = action.errorCode
		newState.trace =
			_.isNil(action.errorTrace) === false ? action.errorTrace.substring(0, 300) : null
		newState.id = action.errorId

		return newState
	},
	[APP_ERROR_CLEAR]: (state, action) => {
		return initialState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
