import isuuid from 'isuuid'

export const getStudyLinkWithParams = (route, nextState) => {
	return route + nextState.params.studyId + nextState.location.search + nextState.location.hash
}

export const getLinkWithParams = (route, nextState) => {
	return route + nextState.location.search + nextState.location.hash
}

export const redirectIfInvalidParams = params => {
	const { idStudy, studyId } = params

	const isRouteInvalid =
		(idStudy !== undefined && isuuid(idStudy) === false) ||
		(studyId !== undefined && isuuid(studyId) === false)

	if (isRouteInvalid) {
		window.location.replace('/not-found')
	}
}
