import { setError, clearError, ERROR_TYPES } from 'store/_service/error'

export const SET_INIT = 'network.init'
export const SET_ONLINE = 'network.online'
export const SET_OFFLINE = 'network.offline'
// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	online: true,
}

export const initChecker = () => (dispatch, getState) => {
	dispatch({ type: SET_INIT })
}

export const handleStatus = status => (dispatch, getState) => {
	if (status === 'offline') {
		setError(
			ERROR_TYPES.OFFLINE,
			'error_offline_header',
			'error_offline_message',
		)(dispatch, getState)
		dispatch({ type: SET_OFFLINE })
	} else if (status === 'online') {
		clearError()(dispatch, getState)
		dispatch({ type: SET_ONLINE })

		// refresh window to recover solver into last good state
		window.location = window.location
	}
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[SET_INIT]: (state, action) => {
		return initialState
	},
	[SET_ONLINE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.online = true
		return newState
	},
	[SET_OFFLINE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.online = false
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
