import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

import './Checkbox.scss'

const Checkbox = props => {
	const className = props.value ? 'icon-check' : ''
	return (
		<div
			className="checkbox-wrapper"
			onClick={() => {
				props.onChange(props.id, !props.value)
			}}
		>
			<div
				id={props.id}
				className={classnames('checkbox', className, {
					'checkbox--ltr': props.isStudyRtl === false,
					'checkbox--rtl': props.isStudyRtl === true,
				})}
			/>
			<div className="checkbox-label">{props.label}</div>
		</div>
	)
}

Checkbox.propTypes = {
	id: PropTypes.string.isRequired,
	isStudyRtl: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool.isRequired,
}

export default Checkbox
