/* eslint-disable max-len */

const messages = {
	not_found: 'الصفحة التي تبحث عنها غير موجودة',
	service_outage: 'نعتذر عن تعطل نظامنا بسبب انقطاع خدمات السحابة الخاصة بمزود الخدمة لدينا.',
	error_unexpected_error: 'كانت هناك مشكلة غير متوقعة. يُرجى إعادة المحاولة لاحقًا.',

	error_img_alt: 'خطأ في الصورة',
	error_try_again: 'حاول مرة أخرى',

	error_generic_header: 'هناك خطأ ما',
	error_generic_message:
		'أعد المحاولة مرة أخرى، وإذا استمرت المشكلة، فالرجاء إرسال رسالة بريد إلكتروني إلى support@groupsolver.com باستخدام رمز الاستجابة السريعة الموجود  أعلاه.',

	error_offline_header: 'يبدو أنك غير متصل بالإنترنت',
	error_offline_message: 'ستُستأنف دراستك بعد إعادة الاتصال.',

	error_landscape_small_screen_header: 'الرجاء تدوير جهازك',
	error_landscape_small_screen_text:
		'شاشتك أقصر وبالتالي لن تتمكن من مشاهدة هذه الدراسة. يرجى تدوير جهازك.',

	error_small_browser_window_header: 'الرجاء تكبير نافذة المتصفح',
	error_small_browser_window_text:
		'نافذتك صغيرة جدًا بحيث لا يمكنك مشاهدة هذه الدراسة. الرجاء تكبير نافذة المتصفح الخاص بك',

	login_error_empty_email: 'لا يمكن أن تكون خانة البريد الإلكتروني فارغةً',
	login_error_invalid_email: 'الرجاء إدخال بريد إلكتروني صحيح',
	login_error_unable_to_login: 'يتعذر تسجيل الدخول في الوقت الحالي. يُرجى إعادة المحاولة لاحقًا.',

	login_email_placeholder: 'أدخل عنوان بريدك الإلكتروني',
	login_email_button: 'متابعة',
	login_email_back: 'أو ارجع إلى خيارات تسجيل الدخول',
	login_email_label: 'متابعة بالبريد الإلكتروني',
	login_anonymous_label: 'استمر بهوية مجهولة',

	login_privacy_statement: 'تحترم GroupSolver خصوصيتك والمعلومات التي تقدمها.',
	login_privacy_statement_email:
		'لن تتم مشاركة بريدك الإلكتروني أو بيعه أو استخدامه بأي شكل من الأشكال بخلاف منحك حق الوصول إلى هذا المشروع.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'الدراسة التي تبحث عنها غير متاحة حاليًا.',
	study_unavailable_description:
		'شكرًا لك على اهتمامك بدراستنا! لسوء الحظ، تم بالفعل ملء هذه الدراسة أو تم إيقافها مؤقتًا في الوقت الحالي. نعتذر عن أي إزعاج قد يسبّبه لك هذا الأمر',
	study_preview_limit_reached_label: 'تم بلوغ حد المعاينة',
	study_preview_limit_reached_description:
		'شكرًا لك على اهتمامك بدراستنا! لسوء الحظ، وصلت هذه الدراسة إلى عدد المعاينات المتاحة.',

	waiting_message:
		'يتم حل هذه الدراسة من قبل العديد من الأشخاص في الوقت الحالي. \n سنعيد المحاولة تلقائيًا للدخول {number, plural, =0 { الآن } one {خلال # ثانية} other { خلال # ثوان}}.',
	waiting_error_recoverable: 'لقد واجهنا خطأً غير متوقع.',
	waiting_error_recoverable_retry:
		'سنحاول من جديد {number, plural, =0 { الآن } one {خلال # ثانية} other {خلال # ثوانٍ}}',
	waiting_error_unknown_study: 'الدراسة التي تبحث عنها غير موجودة.',
	waiting_error_dead: 'لقد حدثت مشكلة غير متوقعة. يُرجى إعادة المحاولة لاحقًا.',

	action_input_submit_button_text: 'إرسال',
	action_input_skip_button_text: 'متابعة',

	choice_single_hint: 'اختر أحد الخيارات',
	general_skip_action_message: 'متابعة إلى الخطوة التالية',

	action_button_primary_default_text: 'إجراء',
	action_button_hint_default_text: 'اختر أحد الخيارات',

	message_media_examine_video: 'انقر على الفيديو لمراجعته',
	message_media_examine_image: 'انقر على الصورة لمراجعتها',
	message_full_screen_close_button_text: 'إغلاق',
	message_image_view_label: 'عرض الصورة',
	message_video_view_label: 'عرض الفيديو',
	message_image_examined_label: 'تمت مراجعة الصورة',
	message_video_examined_label: 'تمت مراجعة الفيديو',
	message_zoom_mouse: 'أدِر للتكبير',
	message_zoom_touch: 'المس للتكبير',

	textarea_too_long_hint: 'النص الخاص بك طويل جداً',

	ideate_placeholder: "الإجابة في جملة (جمل) قصيرة'",
	ideate_skip: ' أو متابعة',

	eliminate_no: 'لا',
	eliminate_yes: 'نعم',
	eliminate_intro: 'شاهد البيان التالي:',
	eliminate_multiple_suggestions: 'أي مما يلي يمكن أن يحل محله؟',
	eliminate_one_suggestion: 'هل يمكن استبداله بما يلي؟',
	eliminate_step: 'شاهد بيانًا آخر:',
	eliminate_none_of_the_above: 'لا احد منهم',

	elaborate_indifferent_label: 'محايد',
	elaborate_disagree_label: 'لا أوافق',
	elaborate_agree_label: 'موافق',
	elaborate_unclear_statement_label: 'إجابة غير واضحة',
	elaborate_intro: 'لقد قدم آخرون الإجابات التالية. هل تتفق معهم؟',

	ideamatching_unsure_label: 'غير متأكد',
	ideamatching_unequal_label: 'أختلف معهم',
	ideamatching_equal_label: 'نفس الشيء',
	ideamatching_intro: 'شاهِد هذا الزوج من الإجابات. هل تعني نفس الشيء؟',

	select_input_placeholder: 'اكتب هنا للاختيار',
	select_input_placeholder_max: 'تم الوصول إلى الحد الأقصى',
	select_multiple_choice_hint:
		'اختر {minNumber, plural, =0 { 0 } other { # }} إلى {maxNumber, plural, =0 { 0 } other { # }} من الخيارات',
	select_no_matches_found: 'لم يتم العثور على تطابق',

	report_content_inappropriate: 'إجابة غير لائقة',
	report_content_profane: 'محتوى فاحش أو بذيء',
	report_content_gibberish: 'نص غامض أو غير حسي',
	report_content_irrelevant: 'لا علاقة له بالسؤال المطروح',
	report_content_different_language: 'لغه مختلفه',
	report_content_other: 'شيء آخر',
	report_content_no_content: 'لا يوجد محتوى للإبلاغ عنه',
	report_content_header: 'الإبلاغ عن إجابة',
	report_content_form_header: 'الرجاء تحديد سبب رغبتك في الإبلاغ عن هذه الإجابة',
	report_content_flag_answer: 'الإبلاغ عن إجابة',
	report_content_return_to_study: 'عودة إلى الدراسة',
	report_content_thank_you: `شكرًا لك على ملاحظتك.\n
سنراجع مدخلاتك ونتخذ إجراءً إذا لزم الأمر.`,
	report_content_statement_flagged: 'تم الإبلاغ عن البيان.',

	platform_update_header: 'تخضع GroupSolver حاليًا للصيانة المجدولة',
	platform_update_message: 'نحن نعتذر عن أي إزعاج.',

	none_of_these: 'لا شيء من هذا',

	allocation_instruction_limit: 'تخصيص {points} عبر الخيارات',
	allocation_instruction_limit_no_minimum: 'تخصيص ما يصل إلى {points} عبر الخيارات',
	allocation_instruction_no_limit: 'تخصيص النقاط عبر الخيارات',
	allocation_instruction_points_left: '{points} نقطة/نقاط متبقية',
	allocation_options_out_of_range: 'بعض الخيارات خارج النطاق',
	allocation_limit_overflow: '{points} نقطة/نقاط فوق الحد',
	allocation_option_invalid_range: 'القيمة خارج النطاق {range}',
	allocation_option_invalid_min: 'القيمة يجب أن تكون أكبر من أو تساوي {min}',

	dropdown_select: 'تحديد...',

	ranking_drag_options: 'اسحب أو انقر على الأسهم لترتيب الخيارات ',
	ranking_assign_option_values: 'تصنيف جميع الخيارات',
	ranking_require_unique_values: 'يجب أن يكون تصنيف كل خيار فريد من نوعه',

	heatmap_instruction_intro: 'لبدء التحديد، انظر إلى الصورة أدناه.',
	heatmap_instruction_unlimited: 'اختر عدد النقاط التي تريدها بالنقر على الصورة.',
	heatmap_instruction_count:
		'اختر {number, plural, =0 {0 نقاط} one {نقطة واحدة} other {{number} نقاط}} بالنقر على الصورة.',
	heatmap_instruction_up_to_count:
		'اختر حتى {number, plural, =0 {0 نقاط} one {نقطة واحدة} other {حتى {number} نقاط}} بالنقر على الصورة.',
	heatmap_instruction_between_count: 'اختر من {from} إلى {to} نقاط بالنقر على الصورة',
	heatmap_instruction_at_least_count:
		'اختر على الأقل {number, plural, =0 {0 نقاط} one {نقطة واحدة} other {{number} نقاط}} بالنقر على الصورة.',
	heatmap_response: '{number, plural, =0 {0 نقاط} one {1 نقطة} other {{number} نقاط}} مختارة',

	continue: 'متابعة',
	reset: 'إعادة تعيين',

	input_invalid: 'الإدخال غير صالح.',
	input_empty: 'لا يمكن أن يكون الإدخال فارغاً.',
	input_chars_left: '{number} {number, plural, =0 {أحرف} one {حرف} other {أحرف}} متبقية.',
	input_too_long: '{number} {number, plural, =0 {أحرف} one {حرف} other {أحرف}} فوق الحد المسموح.',

	// accessibility
	ranking_select_rank: 'اختر ترتيب {label}.',

	ranking_select_aria_label: 'اختر ترتيب كل خيار عن طريق إدخال قيمة.',
	ranking_dnd_aria_label:
		'ركز على خيار واضغط على شريط المسافة لبدء السحب. عند السحب يمكنك استخدام مفاتيح الأسهم لتحريك العنصر والضغط على escape لإلغاء. اضغط على شريط المسافة مرة أخرى لوضع العنصر في الموضع الجديد. تأكد من أن قارئ الشاشة في وضع التركيز أو وضع النماذج.',

	allocation_option_input: 'إدخال قيمة {label}.',
	allocation_aria_label: 'أدخل قيمة لكل خيار باستخدام المدخلات.',

	play: 'تشغيل',
	pause: 'إيقاف مؤقت',
	stop: 'إيقاف',
	fwd: 'تخطي للأمام',
	rwd: 'ترجيع',
	volume_down: 'خفض مستوى الصوت',
	volume_up: 'زيادة مستوى الصوت',
	volume_current: 'مستوى الصوت: {number}٪',

	loading: 'جارٍ التحميل',

	press_tab_to_continue: 'اضغط على tab للمتابعة',

	option_focus_selected: 'الخيار {label} مفعّل. اضغط enter لإلغاء التحديد.',
	option_focus_not_selected: 'الخيار {label} مفعّل. اضغط enter للتحديد.',

	image_alt: 'صورة:',
	video_alt: 'فيديو:',

	submit_enabled: 'الإرسال مفعّل.',
	skip_enabled: 'تخطي السؤال مفعّل',
	n_of_min_selected: '{selected} من {min} مختارة.',
	option_selected: '{label} مختار.',
	option_deselected: '{label} غير مختار.',

	choice_multiple_hint: 'اختر {min} إلى {max} خيارات.',

	ranking_position: 'الموضع: {position}',
	ranking_position_changed: 'تم تعيين قيمة {label} إلى {value}.',
	ranking_input_aria_label: '{label}. الموضع الحالي: {position}. أدخل موضعًا من 1 إلى {max}.',
	not_selected: 'غير مختار',

	study_logo_alt: 'شعار الدراسة',
	groupsolver_logo_alt: 'شعار Gropsolver',
}

export default messages
