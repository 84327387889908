const messages = {
	not_found: 'Sidan du söker finns inte ',
	service_outage:
		'Vi ber om ursäkt men på grund av ett globalt fel I molntjänsten så ligger vårt system nere.',
	error_unexpected_error: 'Ett oväntat fel uppstod. Vänligen försök igen senare.',

	error_img_alt: 'Felbild',
	error_try_again: 'Försök igen',

	error_generic_header: 'Något gick fel',
	error_generic_message:
		'Testa det där igen, och om det inte fungerar, skicka gärna ett e-postmeddelande till support@groupsolver.com med QR-koden som är ovan.',

	error_offline_header: 'Du verkar vara offline',
	error_offline_message:
		'Din tudie kommer att fortsätta efter att din anslutning har återkopplats.',

	error_landscape_small_screen_header: 'Vänligen rotera din enhet',
	error_landscape_small_screen_text:
		'Din skärm är för kort för den här studien. Vänligen rotera din enhet',

	error_small_browser_window_header: 'Vänligen förstora ditt läsfönster',
	error_small_browser_window_text:
		'Ditt fönster är för litet för att visa den här studien. Vänligen förstora ditt läsfönster',

	login_error_empty_email: 'Fältet e-post kan inte vara tomt',
	login_error_invalid_email: 'Vänligen ange en giltig e-post',
	login_error_unable_to_login: 'Kan inte logga in just nu. Vänligen försök igen senare.',

	login_email_placeholder: 'Ange din e-post',
	login_email_button: 'Fortsätt',
	login_email_back: 'eller gå tillbaka till inloggningsalternativ',
	login_email_label: 'Fortsätt med e-post',
	login_anonymous_label: 'Fortsätt anonymt',

	login_privacy_statement:
		'GroupSolver respekterar din integritet och informationen du tillhandahåller.',
	login_privacy_statement_email:
		'Din e-post kommer aldrig att delas, såld eller användas på något annat sätt än att den ger dig åtkomst till det här projektet.',
	login_privacy_policy: 'Integritetspolicy',

	study_unavailable_label: 'Studien du söker efter är för närvarande inte tillgänglig.',
	study_unavailable_description:
		'Tack för ditt intresse för vår studie! Tyvärr är den här studien redan fulltalig, eller tillfälligt pausad. Vi ber om ursäkt för eventuella bekymmer det medför.',
	study_preview_limit_reached_label: 'Din förhandsgranskningsgräns har uppnåtts',
	study_preview_limit_reached_description:
		'Tack för ditt intresse i vår studie! Tyvärr har den här studiens tillgängliga förhandsgranskningar redan uppnåtts.',

	waiting_message:
		'Den här studien utförs av flera person just nu.\nVi kommer automatiskt att återuppta antagningen {number, plural, =0 { nu} one {om # sekundd} other {om # sekunder}}.',
	waiting_error_recoverable: 'Vi har stött på ett oväntat fel.',
	waiting_error_recoverable_retry:
		'Vi kommer att försöka igen {number, plural, =0 { nu} one {om # sekund} other {om # sekunder}}',
	waiting_error_unknown_study: 'Studien du söker finns inte.',
	waiting_error_dead: 'Ett oväntat problem uppstod. Vänligen försök igen senare.',

	action_input_submit_button_text: 'SKICKA',
	action_input_skip_button_text: 'Fortsätt',

	choice_single_hint: 'Välj ett av alternativen',
	general_skip_action_message: 'Fortsätt till nästa steg',

	action_button_primary_default_text: 'Åtgärd',
	action_button_hint_default_text: 'Välj ett av alternativen',

	message_media_examine_video: 'Klicka på videon för att granskad en',
	message_media_examine_image: 'Klicka på bilden för att granska den',
	message_full_screen_close_button_text: 'Stäng',
	message_image_view_label: 'Visa bild',
	message_video_view_label: 'Visa video',
	message_image_examined_label: 'Bilden granskad',
	message_video_examined_label: 'Videon granskad',
	message_zoom_mouse: 'Sväva för att zooma',
	message_zoom_touch: 'Tryck för att zooma',

	textarea_too_long_hint: 'Din text är för lång',

	ideate_placeholder: 'Svara med kort(a) mening(ar)',
	ideate_skip: ' eller fortsätt',

	eliminate_no: 'Nej',
	eliminate_yes: 'Ja',
	eliminate_intro: 'Överväg följande uttalande:',
	eliminate_multiple_suggestions: 'Vilket av följande kan ersätta det?',
	eliminate_one_suggestion: 'Kan det ersättas med följande?',
	eliminate_step: 'Överväg ett annat uttalande:',
	eliminate_none_of_the_above: 'Inget av dem',

	elaborate_indifferent_label: 'Neutral',
	elaborate_disagree_label: 'Håller inte med',
	elaborate_agree_label: 'Håller med',
	elaborate_unclear_statement_label: 'Otydliga svar',
	elaborate_intro: 'Andra har givit följande svar. Håller du med dem?',

	ideamatching_unsure_label: 'Inte säker',
	ideamatching_unequal_label: 'Annorlunda',
	ideamatching_equal_label: 'Samma',
	ideamatching_intro: 'Titta på det här svarsparet. Betyder de samma sak?',

	select_input_placeholder: 'Skriv här för att välja',
	select_input_placeholder_max: 'Maximalt uppnått',
	select_multiple_choice_hint:
		'Välj {minNumber, plural, =0 { 0 } other { # }} till {maxNumber, plural, =0 { 0 } other { # }} alternativ',
	select_no_matches_found: 'Inga matchningar hittades',

	report_content_inappropriate: 'Olämpligt svar',
	report_content_profane: 'Obscent och profant innehåll',
	report_content_gibberish: 'Svammel och oläslig text',
	report_content_irrelevant: 'Irrelevant i förhållande till frågan',
	report_content_different_language: 'Annat språk',
	report_content_other: 'Annat',
	report_content_no_content: 'Inget innehåll att rapportera',
	report_content_header: 'Rapportera svar',
	report_content_form_header: 'Vänligen välj anledningen till varför du vill flagga det här svaret',
	report_content_flag_answer: 'Flagga svar',
	report_content_return_to_study: 'Återgå till studien',
	report_content_thank_you:
		'Tack för din respons.\nVi kommer att granska ditt meddelande och vidta åtgärder om det behövs.',
	report_content_statement_flagged: 'Uttalandet har flaggats.',

	platform_update_header: 'GroupSolver utför just nu ett schemalagt underhåll',
	platform_update_message: 'Vi ber om ursäkt för om det ställer till det.',

	none_of_these: 'Inget av dessa',

	allocation_instruction_limit: 'Fördela {points} över alternativen',
	allocation_instruction_limit_no_minimum: 'Fördela upp till {points} över alternativen',
	allocation_instruction_no_limit: 'Fördela poäng över alternativen',
	allocation_instruction_points_left: 'Återstående {points}',
	allocation_options_out_of_range: 'Vissa poäng är utanför intervallet',
	allocation_limit_overflow: '{points} över gräns',
	allocation_option_invalid_range: 'Värdet är utanför {range}-intervallet',
	allocation_option_invalid_min: 'Värdet måste vara större än, eller lika med {min}',

	dropdown_select: 'Välj...',

	ranking_drag_options: 'Drag eller klicka på pilarna för att rangordna alternativ',
	ranking_assign_option_values: 'Rangordna alla alternativen',
	ranking_require_unique_values: 'Alterntivrankningen måste vara unik',

	heatmap_instruction_intro: 'För att börja välja, se bilden nedan.',
	heatmap_instruction_unlimited: 'Välj så många punkter du vill genom att klicka på bilden.',
	heatmap_instruction_count:
		'Välj {number, plural, =0 {0 punkter} one {en punkt} other {{number} punkter}} genom att klicka på bilden.',
	heatmap_instruction_up_to_count:
		'Välj {number, plural, =0 {0 punkter} one {en punkt} other {upp till {number} punkter}} genom att klicka på bilden.',
	heatmap_instruction_between_count: 'Välj {from} till {to} punkter genom att klicka på bilden',
	heatmap_instruction_at_least_count:
		'Välj minst {number, plural, =0 {0 punkter} one {en punkt} other {{number} punkter}} genom att klicka på bilden.',
	heatmap_response: '{number, plural, =0 {0 punkter} one {1 punkt} other {{number} punkter}} valda',
	continue: 'Fortsätt',
	reset: 'Återställ',
	input_invalid: 'Inmatningen är inte giltig.',
	input_empty: 'Inmatningen kan inte vara tom.',
	input_chars_left: '{number} {number, plural, =0 {tecken} one {tecken} other {tecken}} kvar.',
	input_too_long:
		'{number} {number, plural, =0 {tecken} one {tecken} other {tecken}} över gränsen.',
	ranking_select_rank: 'Välj rang för {label}.',
	ranking_select_aria_label: 'Välj rang för varje alternativ genom att skriva in ett värde.',
	ranking_dnd_aria_label:
		'Fokusera på ett alternativ och tryck på mellanslagstangenten för att börja dra. När du drar kan du använda piltangenterna för att flytta objektet och escape för att avbryta. Tryck på mellanslagstangenten igen för att placera objektet på den nya positionen. Se till att din skärmläsare är i fokusläge eller formulärläge.',
	allocation_option_input: '{label} värdeinmatning.',
	allocation_aria_label: 'Ange ett värde för varje alternativ med hjälp av inmatningar.',
	play: 'Spela',
	pause: 'Pausa',
	stop: 'Stoppa',
	fwd: 'Hoppa framåt',
	rwd: 'Spola tillbaka',
	volume_down: 'Sänk volymen',
	volume_up: 'Höj volymen',
	volume_current: 'Volym: {number}%',
	loading: 'Laddar',
	press_tab_to_continue: 'Tryck på tab för att fortsätta',
	option_focus_selected: 'Alternativ {label} fokuserat. Tryck på enter för att avmarkera.',
	option_focus_not_selected: 'Alternativ {label} fokuserat. Tryck på enter för att välja.',
	image_alt: 'bild:',
	video_alt: 'video:',
	submit_enabled: 'Inlämning aktiverad.',
	skip_enabled: 'Hoppa över fråga aktiverad',
	n_of_min_selected: '{selected} av {min} valda.',
	option_selected: '{label} vald.',
	option_deselected: '{label} avmarkerad.',
	choice_multiple_hint: 'Välj {min} till {max} alternativ.',
	ranking_position: 'position: {position}',
	ranking_position_changed: '{label} värde satt till {value}.',
	ranking_input_aria_label:
		'{label}. Nuvarande position: {position}. Skriv in en position från 1 till {max}.',
	not_selected: 'inte vald',
	study_logo_alt: 'Studielogo',
	groupsolver_logo_alt: 'Groupsolver logo',
}

export default messages
