import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'

import './ChatLayout.scss'

import { ACTION_BAR_HEIGHT } from 'constants/actionBar'
import { CHOICE_SUBTYPE } from 'constants/choice'
import { DELAY_LENGTH } from 'constants/delay'
import { ID_ANIMATED_HISTORY_DIV } from 'constants/history'
import { INPUT_TYPE } from 'constants/input'
import { SCROLL_TYPES } from 'constants/scroll'
import { USER_STATE } from 'constants/userState'

import AnimateHeight from 'react-animate-height'

import ActionChoices from 'components/ActionChoices'
import ActionBar from 'components/ActionBar'
import ReportContentForm from 'components/ReportContentForm'
import FullscreenView from 'components/FullscreenView'
import Heatmap from 'components/Heatmap'

class ChatLayout extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			chatLayoutHeight:
				document.documentElement.getBoundingClientRect().height - ACTION_BAR_HEIGHT.BASE,
		}

		this.calculateHeight = this.calculateHeight.bind(this)
		this.resizeChatLayout = this.resizeChatLayout.bind(this)
		this.scroll = this.scroll.bind(this)
		this.setActionBarOffset = this.setActionBarOffset.bind(this)
	}

	debouncedSetUserStateInactive = _.debounce(this.props.setUserStateInactive, 60 * 1000)

	handleVisibilityChange = () => {
		if (document.visibilityState === 'hidden') {
			this.props.setUserStateInactive()
			return
		}

		this.props.setUserStateActive()
	}

	handleUserInteraction = () => {
		if (this.props.userState === USER_STATE.INACTIVE) {
			this.props.setUserStateActive()
		}

		this.debouncedSetUserStateInactive()
	}

	componentDidMount() {
		window.addEventListener('resize', this.resizeChatLayout)
		window.addEventListener('resize', this.handleVisibilityChange)
		window.addEventListener('mousemove', this.handleUserInteraction)
		window.addEventListener('keydown', this.handleUserInteraction)
		window.addEventListener('touchstart', this.handleUserInteraction)
		window.addEventListener('visibilitychange', this.handleVisibilityChange)
		this.setActionBarOffset()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeChatLayout)
		window.removeEventListener('resize', this.handleVisibilityChange)
		window.removeEventListener('mousemove', this.handleUserInteraction)
		window.removeEventListener('keydown', this.handleUserInteraction)
		window.removeEventListener('touchstart', this.handleUserInteraction)
		window.removeEventListener('visibilitychange', this.handleVisibilityChange)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.showExtendedActionBar !== prevProps.showExtendedActionBar ||
			this.props.actionBarInputType !== prevProps.actionBarInputType
		) {
			this.calculateHeight()
		}
		this.setActionBarOffset()
	}

	setActionBarOffset() {
		if (this.history) {
			const historyWidth = this.history.getBoundingClientRect().width
			const screenWidth = window.innerWidth
			const scrollbarWidth = screenWidth - historyWidth
			this.props.setRigthOffset(scrollbarWidth)
		}
	}

	calculateHeight() {
		let newState = Object.assign({}, this.state)
		let actionBarHeight = ACTION_BAR_HEIGHT.BASE
		if (this.props.showExtendedActionBar === true) {
			if (this.props.actionBarInputType === INPUT_TYPE.TEXTAREA) {
				actionBarHeight = ACTION_BAR_HEIGHT.EXTENDED_BIGGER
			} else if (this.props.actionBarInputType === INPUT_TYPE.INPUT) {
				actionBarHeight = ACTION_BAR_HEIGHT.EXTENDED
			} else if (this.props.actionBarInputType === INPUT_TYPE.CHOICE && screen.width > 350) {
				actionBarHeight = ACTION_BAR_HEIGHT.EXTENDED
			}
		}
		newState.chatLayoutHeight =
			document.documentElement.getBoundingClientRect().height - actionBarHeight
		this.setState(newState)
	}

	resizeChatLayout() {
		this.calculateHeight()
		this.scroll(SCROLL_TYPES.BOTTOM_SCROLL)
	}

	scroll(type) {
		switch (type) {
			case SCROLL_TYPES.BOTTOM_SCROLL:
				// scroll down to bottom
				// used when window.resize event is fired - Android keyboard displayed
				// or when ActionTextArea textArea is focused - user started typing in iPhone
				window.setTimeout(() => {
					this.chatView.contentElement.scrollTo({
						top: this.chatView.contentElement.scrollHeight,
						left: 0,
						behavior: 'smooth',
					})
				}, DELAY_LENGTH.LONG)
				break
			case SCROLL_TYPES.LIMITED_SCROLL:
				// scroll down but keep at least 20% of ChatView shown before update
				// before displaying long message or many choice options
				// used on StudyRoute and History update
				window.setTimeout(() => {
					const historyElement = document.getElementById(ID_ANIMATED_HISTORY_DIV)

					const historyHeight =
						_.isNil(historyElement) === false ? historyElement.getBoundingClientRect().height : null

					const contentHeight =
						historyHeight === null ||
						this.chatView.contentElement.getBoundingClientRect().height < historyHeight
							? this.chatView.contentElement.getBoundingClientRect().height
							: historyHeight

					const maxCoefficient = contentHeight > 300 ? 0.8 : 0.5

					const max = maxCoefficient * contentHeight
					const top =
						this.chatView.contentElement.scrollHeight -
						contentHeight -
						this.chatView.contentElement.scrollTop

					const scrollBy = top > max ? max : top

					if (scrollBy > 0) {
						this.chatView.contentElement.scrollBy({ top: scrollBy, left: 0, behavior: 'smooth' })
					}
				}, DELAY_LENGTH.LONG)
				break
			default:
				break
		}
	}

	render() {
		let suggestionsClass = this.props.showSuggestions === true ? 'visible' : 'hidden '
		let viewClass = this.props.showCinemaView === true ? 'showCinemaView' : 'showChatView'
		let isSelectOnSmallScreen = this.props.inputType === INPUT_TYPE.CHOICE && screen.width < 350
		let showExtendedActionBar =
			this.props.isInputVisisble && !isSelectOnSmallScreen ? 'showExtendedActionBar' : ''

		const childrenWithProp = React.Children.map(this.props.children, child => {
			return React.cloneElement(child, { chatViewScroll: this.scroll })
		})

		return (
			<div className={classnames('ChatLayout', viewClass, showExtendedActionBar)}>
				<div className="ChatLayout-cinemaView" />
				<AnimateHeight
					duration={DELAY_LENGTH.SHORT}
					height={this.state.chatLayoutHeight}
					className="ChatLayout-chatView-wrapper-animate"
					contentClassName={'ChatLayout-chatView'}
					ref={chatView => {
						this.chatView = chatView
					}}
				>
					<div
						className="get-width"
						ref={history => {
							this.history = history
						}}
					/>
					{childrenWithProp}
				</AnimateHeight>
				<div className="ChatLayout-actionBar">
					{this.props.isAccessibilityEnabled === false && (
						<div
							className={classnames('ChatLayout-suggestions', suggestionsClass, {
								lower: isSelectOnSmallScreen,
							})}
						>
							<div className="ChatLayout-suggestions-holder">
								<ActionChoices type={CHOICE_SUBTYPE.SELECT} chatViewScroll={this.scroll} multiple />
							</div>
						</div>
					)}

					<div className={'ChatLayout-actionBar'}>
						<ActionBar
							isFullscreen={false}
							chatViewScroll={this.scroll}
							showContent={this.props.actionBarIsVisible}
						/>
					</div>
				</div>
				{this.props.showFullScreen && (
					<div className="ChatLayout-fullScreen">
						<FullscreenView />
					</div>
				)}
				{this.props.showHeatmap === true && <Heatmap />}
				{this.props.showReportContentForm && <ReportContentForm />}
			</div>
		)
	}
}

ChatLayout.propTypes = {
	showCinemaView: PropTypes.bool.isRequired,
	showFullScreen: PropTypes.bool.isRequired,
	showSuggestions: PropTypes.bool.isRequired,
	actionBarIsVisible: PropTypes.bool.isRequired,
	isAccessibilityEnabled: PropTypes.bool.isRequired,
	isInputVisisble: PropTypes.bool.isRequired,
	inputType: PropTypes.oneOf(Object.values(INPUT_TYPE)).isRequired,
	children: PropTypes.element.isRequired,
	actionBarInputType: PropTypes.oneOf(Object.values(INPUT_TYPE)).isRequired,
	showExtendedActionBar: PropTypes.bool.isRequired,
	showReportContentForm: PropTypes.bool.isRequired,
	showHeatmap: PropTypes.bool.isRequired,
	setRigthOffset: PropTypes.func.isRequired,
	setUserStateActive: PropTypes.func.isRequired,
	setUserStateInactive: PropTypes.func.isRequired,
	userState: PropTypes.oneOf([USER_STATE.ACTIVE, USER_STATE.INACTIVE]).isRequired,
}

export default ChatLayout
