import { API } from 'store/api'

import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addMessage, addAction } from 'store/queue'
import { createComponent } from 'store/_chat/history'
import {
	setActionSubmitListener,
	showActionButton,
	showActionLoader,
	showActionHint,
} from 'store/_chat/actionBar'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'

import { DELAY_LENGTH } from 'constants/delay'
import { COMPONENTS, POSITION } from 'constants/component'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import {
	disableInput,
	enableInput,
	getInputValue,
	hideInput,
	invalidInput,
	setInput,
	setInputChangeListener,
	showInput,
	validInput,
} from 'store/_chat/input'
import { INPUT_TYPE } from 'constants/input'
import { IDEATE_MIN_LIMIT, IDEATE_MAX_LIMIT } from 'constants/ideate'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'ideate.add.response'

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	// listeners setup
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setInputChangeListener(inputChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	if (module.priorMessage !== null) {
		const priorMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

		let priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		addMessage(priorMessage, priorMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	showNextStep()(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const showNextStep = () => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getIdeateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	// request new answer
	// set placeholder for correct answer count (+1 to switch to normal language for translators)
	let placeholder = formatMessage('ideate_placeholder')(dispatch, getState)
	if (responses.length >= definition.minResponses) {
		let skip = formatMessage('ideate_skip')(dispatch, getState)
		placeholder += skip
	}

	// show propmt for new answer
	if (responses.length < definition.maxResponses) {
		addAction(setInput, [
			INPUT_TYPE.TEXTAREA,
			placeholder,
			IDEATE_MAX_LIMIT,
			true,
			true,
			true,
			IDEATE_MAX_LIMIT,
			true,
		])(dispatch, getState)
		addAction(enableInput)(dispatch, getState)
		addAction(showInput)(dispatch, getState)
	}
	// show secondary action if min limit is reached
	if (responses.length >= definition.minResponses) {
		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else {
		let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
		addAction(showActionButton, [ACTION_BUTTON_STATE.DEFAULT, submitButtonText])(dispatch, getState)
	}
}

export const inputChangeListener = (input, options) => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getIdeateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	const inputIsTooLongHint = formatMessage('textarea_too_long_hint')(dispatch, getState)
	const numberOfCharsLeft = IDEATE_MAX_LIMIT - input.length

	if (input.trim().length === 0 && definition.mandatory === true) {
		invalidInput(formatMessage('input_empty')(dispatch, getState))(dispatch, getState)
		showActionHint(formatMessage('input_empty')(dispatch, getState))(dispatch, getState)
	} else if (input.length > IDEATE_MAX_LIMIT) {
		const tooLongTranslation = formatMessage('input_too_long', {
			number: Math.abs(numberOfCharsLeft),
		})(dispatch, getState)

		invalidInput(tooLongTranslation)(dispatch, getState)
		showActionHint(inputIsTooLongHint)(dispatch, getState)
	} else if (input.trim().length >= IDEATE_MIN_LIMIT) {
		const remainingCharactersText = formatMessage('input_chars_left', {
			number: numberOfCharsLeft,
		})(dispatch, getState)

		validInput(remainingCharactersText)(dispatch, getState)

		let buttonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(remainingCharactersText)(dispatch, getState)
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, buttonText)(dispatch, getState)
	} else if (responses.length >= definition.minResponses) {
		let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
	} else {
		const remainingCharactersText = formatMessage('input_chars_left', {
			number: numberOfCharsLeft,
		})(dispatch, getState)

		validInput(remainingCharactersText)(dispatch, getState)
		let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
		showActionButton(ACTION_BUTTON_STATE.SUBMIT, submitButtonText)(dispatch, getState)
	}
}

export const primarySubmitListener = () => (dispatch, getState) => {
	showActionLoader()(dispatch, getState)
	disableInput()(dispatch, getState)

	let definition = getStudyObjectDefinition()(dispatch, getState)
	let responses = getIdeateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define
	let input = getInputValue()(dispatch, getState)

	dispatch({ type: ADD_RESPONSE, idea: { label: input.trim() } })

	// response messages
	const responseIdeas = input.split('\n')
	responseIdeas.forEach(idea => {
		const responseMessage = createComponent(COMPONENTS.MESSAGE)
		responseMessage.props.text = idea
		responseMessage.props.mayBeHidden = true

		const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

		addMessage(responseMessage, messagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	})

	if (responses.length + 1 === definition.maxResponses) {
		addAction(hideInput)(dispatch, getState)
		endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		showNextStep()(dispatch, getState)
	}
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	showActionLoader()(dispatch, getState)
	disableInput()(dispatch, getState)

	endStep()(dispatch, getState) // eslint-disable-line no-use-before-define
}

export const endStep = () => (dispatch, getState) => {
	let studyObject = getStudyObject()(dispatch, getState)
	let responses = getIdeateResponses()(dispatch, getState) // eslint-disable-line no-use-before-define

	let response = {}
	response[studyObject.id] = responses
	getState().study.getNextStep(response, null, false)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses]
		newState.responses.push(action.idea)

		return newState
	},
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'ideate') {
			return state
		}
		let newState = Object.assign({}, state)
		newState.responses = initialState.responses

		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}

// ------------------------------------
// Helpers
// ------------------------------------
export const getIdeateResponses = () => (dispatch, getState) => {
	let state = getState()
	return state.ideate.responses
}
