/* eslint-disable max-len */

const messages = {
	not_found: 'あなたが探しているページは存在しません',
	service_outage:
		'大変ご迷惑をお掛けしております。現在、当社プロバイダによるグローバル規模でのクラウドサービス停止のため、当システムは停止しています。',
	error_unexpected_error: '予期しない問題が発生しました。後でもう一度お試しください。',

	error_img_alt: 'エラー画像',
	error_try_again: '再試行',

	error_generic_header: '何かがうまく行きませんでした',
	error_generic_message:
		'再試行してください。それでもうまくいかない場合は、上記のQRコードと共に電子メールを support@groupsolver.com 宛てに送信してください。',

	error_offline_header: 'オフラインになっているようです',
	error_offline_message: '接続が再度確立された後で、調査が再開されます。',

	error_landscape_small_screen_header: 'デバイスを回転させてください',
	error_landscape_small_screen_text:
		'画面が短すぎてこの調査が表示できません。デバイスを回転させてください。',

	error_small_browser_window_header: 'ブラウザのウィンドウを拡大してください',
	error_small_browser_window_text:
		'ウィンドウが小さすぎてこの調査が表示できません。ブラウザのウィンドウを拡大してください',

	login_error_empty_email: '電子メールは空にできません',
	login_error_invalid_email: '有効な電子メールを入力してください',
	login_error_unable_to_login: '今はログインできません。後でもう一度お試しください。',

	login_email_placeholder: '電子メールを入力',
	login_email_button: '続行',
	login_email_back: 'あるいは、ログイン オプションに戻ります',
	login_email_label: '電子メールで続行',
	login_anonymous_label: '匿名で続行',

	login_privacy_statement:
		'GroupSolverはあなたのプライバシー、およびあなたが提供する情報を尊重します。',
	login_privacy_statement_email:
		'あなたの電子メールは、あなたがこのプロジェクトにアクセスすることを認めるためにのみ使用され、それ以外の目的ではいかなる形であれ共有、売却、または使用されることはありません。',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'あなたが探している調査は利用できません。',
	study_unavailable_description:
		'調査に関心をお寄せいただきありがとうございます！申し訳ありませんが、この調査はすでに終わっている、または現在中止になっています。ご不便をおかけして申し訳ありません。',
	study_preview_limit_reached_label: 'プレビューの制限に達しました。',
	study_preview_limit_reached_description:
		'当社調査に興味をお持ちいただき、ありがとうございます！残念ながら、当調査は利用可能なプレビューの数に達しました。',

	waiting_message:
		'この調査は今現在数人の人々によって解決されています。\n {number, plural, =0 { now } one {in # second} other {in # seconds}} を入力することで再試行します。',
	waiting_error_recoverable: '予期しないエラーに遭遇しました。',
	waiting_error_recoverable_retry:
		'再試行します {number, plural, =0 { now } one {in # second} other {in # seconds}}',
	waiting_error_unknown_study: 'あなたが探している調査は存在しません。',
	waiting_error_dead: '予期しない問題が発生しました。後でもう一度お試しください。',

	action_input_submit_button_text: '送信',
	action_input_skip_button_text: '続行',

	choice_single_hint: '1つを選択',
	general_skip_action_message: '次のステップに進む',

	action_button_primary_default_text: 'アクション',
	action_button_hint_default_text: 'オプションのうちの1つを選択',

	message_media_examine_video: 'ビデオをクリックしてレビュー',
	message_media_examine_image: '画像をクリックしてレビュー',
	message_full_screen_close_button_text: '閉じる',
	message_image_view_label: '画像を表示',
	message_video_view_label: 'ビデオを表示',
	message_image_examined_label: 'レビュー済み画像',
	message_video_examined_label: 'レビュー済みビデオ',
	message_zoom_mouse: 'ポイントして拡大',
	message_zoom_touch: 'タッチして拡大',

	textarea_too_long_hint: 'あなたのテキストは長すぎます',

	ideate_placeholder: '自由に記述',
	ideate_skip: ' または続行',

	eliminate_no: 'いいえ',
	eliminate_yes: 'はい',
	eliminate_intro: 'あなたの回答',
	eliminate_multiple_suggestions: 'あなたの回答と等しい内容といえるのはどちらですか？',
	eliminate_one_suggestion: 'あなたの回答は以下と等しい内容だといえますか？',
	eliminate_step: 'あなたからのもう1つの回答は次のとおりでした：',
	eliminate_none_of_the_above: 'いずれでもない',

	elaborate_indifferent_label: 'わからない',
	elaborate_disagree_label: '同意しない',
	elaborate_agree_label: '同意する',
	elaborate_unclear_statement_label: '意味不明',
	elaborate_intro: '他の方は次のように回答しました。あなたも同意しますか？',

	ideamatching_unsure_label: 'わかりません',
	ideamatching_unequal_label: '違う',
	ideamatching_equal_label: '同じ',
	ideamatching_intro: 'この回答のペアを見てください。それらは同じことを意味していますか？',

	select_input_placeholder: 'ここに入力して選択',
	select_input_placeholder_max: '最大に到達しました',
	select_multiple_choice_hint:
		'{minNumber, plural, =0 { 0 } other { # }} から {maxNumber, plural, =0 { 0 } other { # }} のオプションのうちから選んでください',
	select_no_matches_found: '一致するものはありません',

	report_content_inappropriate: '不適切な回答',
	report_content_profane: '卑猥または下品な内容',
	report_content_gibberish: 'でたらめ、意味不明',
	report_content_irrelevant: '問われた質問と無関係',
	report_content_different_language: '異なる言語',
	report_content_other: 'その他',
	report_content_no_content: '報告すべき内容がない',
	report_content_header: '回答を報告',
	report_content_form_header: 'この回答にフラグを付ける理由を選択してください',
	report_content_flag_answer: '回答にフラグを付ける',
	report_content_return_to_study: '調査に戻る',
	report_content_thank_you:
		'フィードバックをありがとうございました。\nあなたからのご意見を検討して必要に応じた行動を取ります。',
	report_content_statement_flagged: '声明にフラグが付けられました。',

	platform_update_header: '現在GroupSolverでは定期メンテナンスを実施いたしております。',
	platform_update_message: 'ご不便をおかけしておりますことをお詫び申し上げます。',

	none_of_these: 'どれでもない',

	allocation_instruction_limit: 'オプション間で{points}を割り当てる',
	allocation_instruction_limit_no_minimum: 'オプション間で最大{points}を割り当てる',
	allocation_instruction_no_limit: 'オプション間でポイントを割り当てる',
	allocation_instruction_points_left: '残り{points}',
	allocation_options_out_of_range: 'いくつかのオプションは範囲外です',
	allocation_limit_overflow: '{points}が制限を超えています',
	allocation_option_invalid_range: '値が{range}の範囲外です',
	allocation_option_invalid_min: '値は{min}以上である必要があります',

	dropdown_select: '選択...',

	ranking_drag_options: 'ドラッグまたは矢印をクリックしてオプションをランク付け',
	ranking_assign_option_values: 'すべてのオプションをランク付け',
	ranking_require_unique_values: 'オプションのランクは一意である必要があります',

	heatmap_instruction_intro: '選択を開始するには、以下の画像を表示してください。',
	heatmap_instruction_unlimited: '画像をクリックして、好きなだけポイントを選択してください。',
	heatmap_instruction_count:
		'画像をクリックして{number, plural, =0 {0ポイント} one {1ポイント} other {{number}ポイント}}を選択してください。',
	heatmap_instruction_up_to_count:
		'画像をクリックして、最大{number, plural, =0 {0ポイント} one {1ポイント} other {最大{number}ポイント}}を選択してください。',
	heatmap_instruction_between_count: '{from}から{to}ポイントを画像をクリックして選択してください。',
	heatmap_instruction_at_least_count:
		'画像をクリックして、少なくとも{number, plural, =0 {0ポイント} one {1ポイント} other {{number}ポイント}}を選択してください。',
	heatmap_response:
		'{number, plural, =0 {0ポイント} one {1ポイント} other {{number}ポイント}}選択されました。',
	continue: '続ける',
	reset: 'リセット',
	input_invalid: '入力が無効です。',
	input_empty: '入力は空にできません。',
	input_chars_left: '残り{number} {number, plural, =0 {文字} one {文字} other {文字}}。',
	input_too_long:
		'{number} {number, plural, =0 {文字} one {文字} other {文字}}制限を超えています。',
	ranking_select_rank: '{label}のランクを選択してください。',
	ranking_select_aria_label: '各オプションのランクを値を入力して選択してください。',
	ranking_dnd_aria_label:
		'オプションにフォーカスしてスペースバーを押すとドラッグを開始します。ドラッグ中は矢印キーを使ってアイテムを移動させ、エスケープキーでキャンセルします。再度スペースバーを押すと新しい位置にアイテムを配置します。スクリーンリーダーがフォーカスモードまたはフォームモードになっていることを確認してください。',
	allocation_option_input: '{label}の値を入力。',
	allocation_aria_label: '入力を使用して各オプションの値を入力してください。',
	play: '再生',
	pause: '一時停止',
	stop: '停止',
	fwd: '早送り',
	rwd: '巻き戻し',
	volume_down: '音量を下げる',
	volume_up: '音量を上げる',
	volume_current: '音量: {number}%',
	loading: '読み込み中',
	press_tab_to_continue: 'タブを押して続行',
	option_focus_selected: 'オプション{label}にフォーカス。Enterキーを押して選択解除。',
	option_focus_not_selected: 'オプション{label}にフォーカス。Enterキーを押して選択。',
	image_alt: '画像:',
	video_alt: 'ビデオ:',
	submit_enabled: '送信が有効です。',
	skip_enabled: '質問をスキップすることができます。',
	n_of_min_selected: '{min}のうち{selected}が選択されました。',
	option_selected: '{label}が選択されました。',
	option_deselected: '{label}が選択解除されました。',
	choice_multiple_hint: '{min}から{max}のオプションを選択してください。',
	ranking_position: '位置: {position}',
	ranking_position_changed: '{label}の値が{value}に設定されました。',
	ranking_input_aria_label: '{label}。現在の位置: {position}。1から{max}の位置を入力してください。',
	not_selected: '未選択',
	study_logo_alt: '研究ロゴ',
	groupsolver_logo_alt: 'グループソルバーロゴ',
}

export default messages
