// layouts
import CoreLayout from 'layouts/CoreLayout'
import ChatLayout from 'layouts/ChatLayout'
import PageLayout from 'layouts/PageLayout'
import VideoChatLayout from 'layouts/VideoChatLayout'

// page routes
import StudyRoute from 'routes/Study'
import VideoSurvey from 'routes/VideoSurvey'

// service routes
import LoginRoute from 'routes/_service/Login'
import RetakeRoute from 'routes/_service/Retake'
import PreviewRoute from 'routes/_service/Preview'

// scaffolding routes
import ErrorRoute from 'routes/_scaffolding/Error'
import NotFoundRoute from 'routes/_scaffolding/NotFound'

const createRoutes = store => ({
	component: CoreLayout,
	childRoutes: [
		{
			component: PageLayout,
			childRoutes: [
				// ServiceOutage - uncomment to show service page instead of solver
				// ServiceOutage(store),

				// service routes
				LoginRoute(store),
				RetakeRoute(store),
				PreviewRoute(store),

				// scaffolding routes
				ErrorRoute(store),
			],
		},
		{
			component: ChatLayout,
			indexRoute: StudyRoute,
			childRoutes: [StudyRoute(store)],
		},
		{
			component: VideoChatLayout,
			childRoutes: [VideoSurvey(store)],
		},
		{
			component: PageLayout,
			indexRoute: NotFoundRoute,
			childRoutes: [NotFoundRoute(store)],
		},
	],
})

export default createRoutes
