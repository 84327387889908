import React from 'react'
import PropTypes from 'prop-types'

class NetworkChecker extends React.Component {
	constructor(props) {
		super(props)

		this.setOnline = this.setOnline.bind(this)
		this.setOffline = this.setOffline.bind(this)
		this.scrollRight = this.componentWillUnmount.bind(this)
	}

	setOnline() {
		this.props.handleStatus('online')
	}

	setOffline() {
		this.props.handleStatus('offline')
	}

	componentDidMount() {
		window.addEventListener('online', this.setOnline)
		window.addEventListener('offline', this.setOffline)
	}

	componentWillUnmount() {
		window.removeEventListener('online', this.setOnline)
		window.removeEventListener('offline', this.setOffline)
	}

	render() {
		return null
	}
}

NetworkChecker.propTypes = {
	handleStatus: PropTypes.func,
}

export default NetworkChecker
