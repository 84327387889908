import React from 'react'
import PropTypes from 'prop-types'
import DotsLoader from 'components/_scaffolding/DotsLoader'
import optionShape from 'shapes/option'
import './BlockOption.scss'
import { renderLabel, renderBlockOptionLabel } from 'store/tools/MarkdownText'
import { StyleSheet, css } from 'aphrodite/no-important'
import { createClassName } from 'components/helpers'
import classnames from 'classnames'

import { CHOICE_SUBTYPE } from 'constants/choice'

class BlockOption extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.state.isLoaded = false
		this.handleImageLoad = this.handleImageLoad.bind(this)
		this.getImageAlt = this.getImageAlt.bind(this)
	}

	handleImageLoad(event) {
		let newState = Object.assign({}, this.state)
		newState.isLoaded = true
		setTimeout(() => {
			this.setState(newState)
		}, 500)
	}

	renderMarkdown = text =>
		this.props.showImage === true ? renderLabel(text) : renderBlockOptionLabel(text)

	handleImageZoomClick = event => {
		event.stopPropagation()
		this.props.openFullscreenImageOption(this.props.option.media)
	}

	getImageAlt = () => {
		if (this.props.showImage !== true) {
			return ''
		}

		return this.props.option.media.alt || this.props.option.label
	}

	render() {
		const styles = StyleSheet.create({
			hoverEnabled: {
				':hover': {
					border: '1px solid',
					borderColor: this.props.theme.primaryColor,
					cursor: 'pointer',
				},
			},
		})

		let blockOptionCss = 'BlockOption-wrapper'
		if (this.props.isHoverEnabled) {
			blockOptionCss = createClassName(css(styles.hoverEnabled), 'BlockOption-wrapper')
		}

		const idAriaLabelTranslation =
			this.props.option.selected === true ? 'option_focus_selected' : 'option_focus_not_selected'

		return (
			<div
				className={blockOptionCss}
				onClick={this.props.selectOption}
				data-id={this.props.option.id}
				tabIndex={0}
				onKeyUp={event => {
					if (event.keyCode === 13) {
						event.persist()
						this.props.selectOption(event)
					}
				}}
				aria-label={this.props.intl.formatMessage(
					{ id: idAriaLabelTranslation },
					{ label: this.props.option.label },
				)}
			>
				{this.props.showImage === true && (
					<div className="BlockOption-wrapper-option">
						<img
							className={
								'BlockOption-image ' +
								(this.state.isLoaded === false ? 'BlockOption-image-hidden' : '')
							}
							src={this.props.option.media.url}
							alt={this.getImageAlt()}
							data-id={this.props.option.media.id}
							onLoad={this.handleImageLoad}
						/>
						{!this.state.isLoaded ? (
							<div className="BlockOption-image-loader">
								<div className="BlockOption-image-loader-wrapper">
									<DotsLoader />
								</div>
							</div>
						) : null}
					</div>
				)}
				{this.props.showImage === true && this.props.studyTags.experimental === true && (
					<div>
						<div
							className="BlockOption-image-show-fullscreen icon-magnify"
							onClick={this.handleImageZoomClick}
						>
							Zoom
						</div>
					</div>
				)}
				{this.props.showLabel ? (
					<div
						className={classnames('BlockOption-image-label', {
							'BlockOption-block-label': this.props.actionType === CHOICE_SUBTYPE.BLOCK,
						})}
						dangerouslySetInnerHTML={{ __html: this.renderMarkdown(this.props.option.label) }}
					/>
				) : null}
				<div className={this.props.option.selected ? 'BlockOption-image-overlay icon-check' : ''} />
			</div>
		)
	}
}

BlockOption.propTypes = {
	actionType: PropTypes.string.isRequired,
	option: optionShape,
	selectOption: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	isHoverEnabled: PropTypes.bool.isRequired,
	showImage: PropTypes.bool.isRequired,
	showLabel: PropTypes.bool.isRequired,
	theme: PropTypes.object,
	// experimental
	studyTags: PropTypes.object.isRequired,
	openFullscreenImageOption: PropTypes.func.isRequired,
}

export default BlockOption
