import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { API } from 'store/api'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import {
	RANKING_SUBTYPE,
	RANKING_QUESTION_LOG_ACTIONS,
	RANKING_DRAG_AND_DROP_SUBMIT_TIMEOUT,
} from 'constants/ranking'
import { COMPONENTS, POSITION } from 'constants/component'
import { DELAY_LENGTH } from 'constants/delay'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import {
	interactiveOptionsShow,
	interactiveOptionsHide,
	setOptionsChangeListener,
	selectors as interactiveOptionsSelectors,
} from 'store/_chat/interactiveOptions'
import { createComponent, getCurrentMessage, removeTempMessages } from 'store/_chat/history'
import { formatMessage, getIsStudyRtl } from 'store/intl'
import {
	setActionSubmitListener,
	showActionButton,
	showActionLoader,
	showActionHint,
} from 'store/_chat/actionBar'
import { setTimerListeners, setTimerInterval, timerReset } from 'store/_chat/timer'
import { buttonStateUpdateListener } from './_tools/button'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_RESPONSE = 'ranking.add.response'
export const RECORD_RANKING_LOG_ACTION = 'ranking.record.action.log'
export const RESET_RANKING_LOG = 'ranking.log.reset'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	responses: [],
	rankingLog: [],
	idIteration: uuidv4(),
}

// ------------------------------------
// Helpers
// ------------------------------------
export const getRankingLog = getState => {
	return getState().ranking.rankingLog
}

export const recordRankingLogItem = (
	timestamp,
	idStudyObject,
	studyObjectType,
	action,
	data = null,
) => (dispatch, getState) => {
	dispatch({
		type: RECORD_RANKING_LOG_ACTION,
		timestamp,
		idStudyObject,
		studyObjectType,
		action,
		data,
	})
}

const getAreValuesValid = options =>
	options.every(
		option => option.value !== null && option.value !== '' && isNaN(Number(option.value)) !== true,
	)

const getAreValuesUnique = options => _.uniqBy(options, o => o.value).length === options.length

// TODO:
// check accessibility study tag and don't include buttons in instruction if it's true
const getInstructionTranslation = definition =>
	definition.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP
		? 'ranking_drag_options'
		: 'ranking_assign_option_values'

const getAriaLabelTranslation = definition =>
	definition.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP
		? 'ranking_dnd_aria_label'
		: 'ranking_select_aria_label'

// ------------------------------------
// Respondent interactions listeners
// ------------------------------------
const handleOptionsChange = (options, changedOption) => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const { requireUniqueValues, mandatory } = definition

	const studyObject = getStudyObject()(dispatch, getState)

	recordRankingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		RANKING_QUESTION_LOG_ACTIONS.OPTION_CHANGED,
		{ changedOption },
	)(dispatch, getState)

	const areValuesValid = getAreValuesValid(options)
	const areValuesUnique = getAreValuesUnique(options)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (isAccessibilityEnabled === true && definition.subtype === RANKING_SUBTYPE.RANKING_SELECT) {
		const actionStatusText = formatMessage('ranking_position_changed', {
			label: changedOption.label,
			value: changedOption.newValue,
		})(dispatch, getState)

		addAccessibilityHintText(actionStatusText)(dispatch, getState)
	}

	if (areValuesValid === false && mandatory === true) {
		// values are invalid
		// show hint
		const hintTranslation = getInstructionTranslation(definition)

		const hint = formatMessage(hintTranslation)(dispatch, getState)

		addAction(showActionHint, [hint])(dispatch, getState)

		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(hint)(dispatch, getState)
			publishAccessibilityHint()(dispatch, getState)
		}

		return
	}

	if (areValuesValid === false && mandatory === false) {
		// values are invalid
		// allow skip
		const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)

		return
	}

	if (requireUniqueValues === false || areValuesUnique === true) {
		// values are valid and pass the validation
		// allow submit
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
		addAction(showActionButton, [ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText])(
			dispatch,
			getState,
		)

		return
	}

	// values are valid but do not pass the validation
	if (mandatory === false) {
		// allow skip
		const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)

		return
	}

	// show hint
	const hint = formatMessage('ranking_require_unique_values')(dispatch, getState)

	addAction(showActionHint, [hint])(dispatch, getState)

	if (isAccessibilityEnabled === true) {
		addAccessibilityHintText(hint)(dispatch, getState)
		publishAccessibilityHint()(dispatch, getState)
	}
}

const primarySubmitListener = () => (dispatch, getState) => {
	const state = getState()
	const options = interactiveOptionsSelectors.getChatInteractiveOptionsOptions(state)

	const answerMessageText = _.sortBy(options, o => o.value)
		.map(option => option.label)
		.join('\n')

	const answwerMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT
	const answerMessage = createComponent(COMPONENTS.MESSAGE)
	answerMessage.props.text = answerMessageText

	addMessage(answerMessage, answwerMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)

	recordRankingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		RANKING_QUESTION_LOG_ACTIONS.SUBMIT,
		{ options },
	)(dispatch, getState)

	endStep(options)(dispatch, getState) // eslint-disable-line no-use-before-define
}

const secondarySubmitListener = () => (dispatch, getState) => {
	const skipMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT
	const skippedMessageText = formatMessage('general_skip_action_message')(dispatch, getState)
	const skippedMessage = createComponent(COMPONENTS.MESSAGE)
	skippedMessage.props.text = skippedMessageText

	addMessage(skippedMessage, skipMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	const studyObject = getStudyObject()(dispatch, getState)

	recordRankingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		RANKING_QUESTION_LOG_ACTIONS.SKIP,
	)(dispatch, getState)

	endStep(null)(dispatch, getState) // eslint-disable-line no-use-before-define
}

// ------------------------------------
// Listeners
// ------------------------------------
export const updateListener = (currentTime, initialTime) => (dispatch, getState) => {
	const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	buttonStateUpdateListener(
		RANKING_DRAG_AND_DROP_SUBMIT_TIMEOUT,
		null,
		currentTime - initialTime,
		submitButtonText,
	)(dispatch, getState)
}

// ------------------------------------
// Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState)
	setOptionsChangeListener(handleOptionsChange)(dispatch, getState)

	if (_.isNil(module.priorMessage) === false) {
		const priorMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

		let priorMessage = createComponent(COMPONENTS.MESSAGE)
		priorMessage.props.text = module.priorMessage.definition.text
		addMessage(priorMessage, priorMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)
	}

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showNextStep()(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

const showNextStep = () => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)

	const solverMessagePosition = getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

	const instructionTranslation = getInstructionTranslation(definition)
	const ariaLabelTranslation = getAriaLabelTranslation(definition)

	const instruction = createComponent(COMPONENTS.INSTRUCTION)
	instruction.props.text = formatMessage(instructionTranslation)(dispatch, getState)
	instruction.props.ariaLabel = formatMessage(ariaLabelTranslation)(dispatch, getState)

	addTempMessage(instruction, solverMessagePosition, DELAY_LENGTH.LONG)(dispatch, getState)

	if (
		definition.mandatory === false &&
		definition.subtype !== RANKING_SUBTYPE.RANKING_DRAG_AND_DROP
	) {
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	}

	if (
		definition.mandatory === true &&
		definition.subtype !== RANKING_SUBTYPE.RANKING_DRAG_AND_DROP
	) {
		const hint = formatMessage(instructionTranslation)(dispatch, getState)

		addAction(showActionHint, [hint])(dispatch, getState)
	}

	const studyObject = getStudyObject()(dispatch, getState)
	const currentMessage = getCurrentMessage()(dispatch, getState)

	recordRankingLogItem(
		moment().toISOString(),
		studyObject.id,
		studyObject.type,
		RANKING_QUESTION_LOG_ACTIONS.OPTIONS_DISPLAYED,
		{
			options: definition.options,
			requireUniqueValues: definition.requireUniqueValues,
			currentMessage,
		},
	)(dispatch, getState)

	const options =
		definition.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP
			? definition.options.map((option, index) => ({ ...option, value: index + 1 }))
			: definition.options

	addAction(
		interactiveOptionsShow,
		[definition.subtype, options, null, { requireUniqueValues: definition.requireUniqueValues }],
		DELAY_LENGTH.SHORT,
	)(dispatch, getState)

	if (
		definition.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP &&
		definition.options.length === 1
	) {
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

		addAction(showActionButton, [ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText])(
			dispatch,
			getState,
		)
	}

	if (
		definition.subtype === RANKING_SUBTYPE.RANKING_DRAG_AND_DROP &&
		definition.options.length > 1
	) {
		timerReset()(dispatch, getState)
		setTimerListeners(updateListener)(dispatch, getState)
		setTimerInterval(RANKING_DRAG_AND_DROP_SUBMIT_TIMEOUT)(dispatch, getState)
	}
}

const resetRankingLog = () => (dispatch, getState) => {
	dispatch({ type: RESET_RANKING_LOG })
}

const endStep = options => (dispatch, getState) => {
	const studyObject = getStudyObject()(dispatch, getState)

	const studyObjectResponse =
		options === null
			? null
			: {
					[studyObject.id]: {
						options: options.map(option => ({
							idOption: option.id,
							value: option.value,
						})),
					},
			  }

	const studyObjectActionLog = getRankingLog(getState)

	resetRankingLog()(dispatch, getState)
	removeTempMessages()(dispatch, getState)
	interactiveOptionsHide()(dispatch, getState)
	showActionLoader()(dispatch, getState)

	getState().study.getNextStep(studyObjectResponse, studyObjectActionLog, true)(dispatch, getState)
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ADD_RESPONSE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.responses = [...state.responses]
		newState.responses.push(action.idea)

		return newState
	},
	[RECORD_RANKING_LOG_ACTION]: (state, action) => ({
		...state,
		rankingLog: [
			...state.rankingLog,
			{
				idIteration: state.idIteration,
				...action,
			},
		],
	}),
	[RESET_RANKING_LOG]: (state, action) => ({
		...state,
		rankingLog: [],
		idIteration: uuidv4(),
	}),
	[API.GET_NEXT_STEP.SUCCESS]: (state, action) => {
		if (action.response.type === 'ranking') {
			return state
		}

		return _.cloneDeep(initialState)
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
