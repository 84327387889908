import { monitorCalls } from 'store/apiMonitor'
import { API } from 'store/api'
import { getNextStep, setGetNextStep } from 'store/study'

export default (store, authHandler, loadStudyOptionsSpy = null) => ({
	path: 'solve/:idStudy',
	onEnter(nextState, replace) {
		let state = store.getState()
		if (state.user.isLoggedIn === true) {
			monitorCalls([API.GET_NEXT_STEP])(store.dispatch, store.getState)

			setGetNextStep(getNextStep)(store.dispatch, store.getState)

			getNextStep(null, null, true)(store.dispatch, store.getState)
		} else {
			replace('/login')
		}
	},
	/*  Async getComponent is only invoked when route matches   */
	getComponent(nextState, cb) {
		/*  Webpack - use 'require.ensure' to create a split point
		 and embed an async module loader (jsonp) when bundling   */
		require.ensure(
			[],
			require => {
				/*  Webpack - use require callback to define
			 dependencies for bundling   */
				const Component = require('./StudyRouteContainer').default

				/*  Return getComponent   */
				cb(null, Component)

				/* Webpack named bundle   */
			},
			'study',
		)
	},
})
