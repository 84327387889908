import _ from 'lodash'
import { ALLOCATION_SUBTYPE } from 'constants/allocation'

// ------------------------------------
// Constants
// ------------------------------------
export const INTERACTIVE_OPTIONS_RESET = 'interactive.options.reset'
export const INTERACTIVE_OPTIONS_HIDE = 'interactive.options.hide'
export const INTERACTIVE_OPTIONS_SHOW = 'interactive.options.show'
export const INTERACTIVE_OPTIONS_SET_OPTIONS_CHANGE_LISTENER =
	'interactive.options.set.options.change.listener'
export const INTERACTIVE_OPTIONS_OPTION_CHANGE = 'interactive.options.option.change'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	options: [],
	optionsChangeListener: null,
	interactiveOptionsType: ALLOCATION_SUBTYPE.INPUT, // RANKING_SUBTYPE.RANKING_SELECT
	isVisible: false,
	allocationSettings: {
		limit: {
			isEnabled: true,
			value: 0,
		},
		range: {
			min: 0,
			max: null,
		},
	},
	rankingSettings: {
		requireUniqueValues: true,
	},
}

// ------------------------------------
// getters
// ------------------------------------
const getChatInteractiveOptionsState = state => {
	return state.chatInteractiveOptions
}

const getChatInteractiveOptionsOptions = state => {
	return getChatInteractiveOptionsState(state).options
}

const getChatInteractiveOptionsChangeListener = state => {
	return getChatInteractiveOptionsState(state).optionsChangeListener
}

const getChatInteractiveOptionsVisible = state => {
	return getChatInteractiveOptionsState(state).isVisible
}

const getChatInteractiveOptionsType = state => {
	return getChatInteractiveOptionsState(state).interactiveOptionsType
}

const getChatInteractiveOptionsAllocationSettings = state => {
	return getChatInteractiveOptionsState(state).allocationSettings
}

const getChatInteractiveOptionsRankingSettings = state => {
	return getChatInteractiveOptionsState(state).rankingSettings
}

export const selectors = {
	getChatInteractiveOptionsAllocationSettings,
	getChatInteractiveOptionsChangeListener,
	getChatInteractiveOptionsOptions,
	getChatInteractiveOptionsRankingSettings,
	getChatInteractiveOptionsType,
	getChatInteractiveOptionsVisible,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => {
	dispatch({ type: INTERACTIVE_OPTIONS_RESET })
}

export const interactiveOptionsShow = (
	interactiveOptionsType,
	options,
	allocationSettings,
	rankingSettings,
) => (dispatch, getState) => {
	dispatch({
		type: INTERACTIVE_OPTIONS_SHOW,
		interactiveOptionsType,
		options,
		allocationSettings,
		rankingSettings,
	})
}

export const interactiveOptionsHide = () => (dispatch, getState) => {
	dispatch({ type: INTERACTIVE_OPTIONS_HIDE })
}

export const setOptionsChangeListener = optionsChangeListener => (dispatch, getState) => {
	dispatch({ type: INTERACTIVE_OPTIONS_SET_OPTIONS_CHANGE_LISTENER, optionsChangeListener })
}

export const handleOptionChange = (idOption, value) => (dispatch, getState) => {
	const state = getState()
	const currentOptions = getChatInteractiveOptionsOptions(state)

	const changedOption = {
		id: idOption,
		oldValue: '',
		newValue: '',
	}

	const newOptions = currentOptions.map(option => {
		if (option.id !== idOption) {
			return option
		}

		changedOption.label = option.label
		changedOption.oldValue = option.value
		changedOption.newValue = value

		return {
			...option,
			value: value,
		}
	})

	dispatch({ type: INTERACTIVE_OPTIONS_OPTION_CHANGE, options: newOptions })

	const optionsChangeListener = getChatInteractiveOptionsChangeListener(state)

	if (optionsChangeListener !== null) {
		optionsChangeListener(newOptions, changedOption)(dispatch, getState)
	}
}

export const handleOptionDrag = (newOptions, draggedOption, draggedFrom, draggedTo) => (
	dispatch,
	getState,
) => {
	const state = getState()

	const changedOption = {
		id: draggedOption.id,
		label: draggedOption.label,
		oldValue: draggedFrom,
		newValue: draggedTo,
	}

	dispatch({ type: INTERACTIVE_OPTIONS_OPTION_CHANGE, options: newOptions })

	const optionsChangeListener = getChatInteractiveOptionsChangeListener(state)

	if (optionsChangeListener !== null) {
		optionsChangeListener(newOptions, changedOption)(dispatch, getState)
	}
}

export const handleNoneOfTheseChange = () => (dispatch, getState) => {
	const state = getState()
	const currentOptions = getChatInteractiveOptionsOptions(state)

	const changedOption = {
		id: '',
		oldValue: '',
		newValue: '',
		isNoneOfThese: true,
	}

	const newOptions = currentOptions.map(option => {
		if (option.isNoneOfThese !== true) {
			return { ...option, value: 0 }
		}

		changedOption.id = option.id
		changedOption.label = option.label
		changedOption.oldValue = option.isChecked
		changedOption.newValue = option.isChecked === false

		return {
			...option,
			isChecked: option.isChecked === false,
		}
	})

	dispatch({ type: INTERACTIVE_OPTIONS_OPTION_CHANGE, options: newOptions })

	const optionsChangeListener = getChatInteractiveOptionsChangeListener(state)

	if (optionsChangeListener !== null) {
		optionsChangeListener(newOptions, changedOption)(dispatch, getState)
	}
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[INTERACTIVE_OPTIONS_RESET]: (state, action) => {
		return _.cloneDeep(initialState)
	},
	[INTERACTIVE_OPTIONS_SHOW]: (state, action) => ({
		...state,
		interactiveOptionsType: action.interactiveOptionsType,
		options: action.options.slice(),
		allocationSettings:
			action.allocationSettings === null ? state.allocationSettings : action.allocationSettings,
		rankingSettings:
			action.rankingSettings === null ? state.rankingSettings : action.rankingSettings,
		isVisible: true,
	}),
	[INTERACTIVE_OPTIONS_HIDE]: (state, action) => ({
		...state,
		isVisible: false,
	}),
	[INTERACTIVE_OPTIONS_SET_OPTIONS_CHANGE_LISTENER]: (state, action) => ({
		...state,
		optionsChangeListener: action.optionsChangeListener,
	}),
	[INTERACTIVE_OPTIONS_OPTION_CHANGE]: (state, action) => ({
		...state,
		options: action.options,
	}),
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
