import { ACTION_BAR_TYPE } from 'constants/actionBar'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'

// ------------------------------------
// Constants
// ------------------------------------
export const ACTION_BAR_SET_LOADER = 'action.bar.set.loader'
export const ACTION_BAR_SET_BUTTON = 'action.bar.set.button'
export const ACTION_BAR_RESET = 'action.bar.reset'
export const ACTION_SET_ACTION_SUBMIT_LISTENER = 'actions.set.action.submit.listener'
export const ACTION_BAR_HIDE = 'action.bar.hide'
export const ACTION_SET_REPORT_ANSWER_LISTENER = 'actions.set.report.answer.listener'
export const ACTION_BAR_SET_RIGHT_OFFSET = 'action.bar.set.right.offset'
export const ACTION_BAR_SET_HINT = 'action.bar.set.hint'

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {
	actionType: ACTION_BAR_TYPE.LOADER,
	actionState: ACTION_BUTTON_STATE.DEFAULT,
	isVisible: false,

	hintText: '',

	buttonText: '',
	isPrimaryEnabled: false,

	loaderType: null,
	loadTime: null,
	loadPercent: null,

	reportListener: null,

	secondaryText: '',
	isSecondaryEnabled: false,

	primaryListener: null,
	secondaryListener: null,

	backupState: null,

	rightOffset: 0,
}

// ------------------------------------
// Actions
// ------------------------------------
export const reset = () => (dispatch, getState) => dispatch({ type: ACTION_BAR_RESET })

export const showActionLoader = () => (dispatch, getState) => {
	dispatch({ type: ACTION_BAR_SET_LOADER })
}

export const showActionButton = (
	actionState = ACTION_BUTTON_STATE.DEFAULT,
	text = '',
	actionButtonLoaderType = null,
	actionButtonLoaderValue = null,
) => (dispatch, getState) => {
	dispatch({
		type: ACTION_BAR_SET_BUTTON,
		actionState,
		text,
		actionButtonLoaderType,
		actionButtonLoaderValue,
	})
}

export const showActionHint = hintText => (dispatch, getState) => {
	dispatch({
		type: ACTION_BAR_SET_HINT,
		hintText,
	})
}

export const hideActionBar = () => (dispatch, getState) => dispatch({ type: ACTION_BAR_HIDE })

export const setActionSubmitListener = (primaryListener = null, secondaryListener = null) => (
	dispatch,
	getState,
) => {
	dispatch({
		type: ACTION_SET_ACTION_SUBMIT_LISTENER,
		primaryListener,
		secondaryListener,
	})
}

export const onPrimarySubmit = (input, options) => (dispatch, getState) => {
	let newState = getState()
	let changeListener = newState.chatActionBar.primaryListener
	if (changeListener) {
		changeListener(input, options)(dispatch, getState)
	}
}

export const onSecondarySubmit = () => (dispatch, getState) => {
	let newState = getState()
	let changeListener = newState.chatActionBar.secondaryListener
	if (changeListener) {
		changeListener()(dispatch, getState)
	}
}

export const setReportContentListener = (reportListener = null) => (dispatch, getState) => {
	dispatch({ type: ACTION_SET_REPORT_ANSWER_LISTENER, reportListener })
}

export const reportContent = () => (dispatch, getState) => {
	const reportListener = getState().chatActionBar.reportListener
	if (reportListener) {
		reportListener()(dispatch, getState)
	}
}

export const setRigthOffset = offset => (dispatch, getState) => {
	dispatch({ type: ACTION_BAR_SET_RIGHT_OFFSET, offset })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	[ACTION_BAR_RESET]: (state, action) => {
		return initialState
	},
	[ACTION_BAR_SET_LOADER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.actionType = ACTION_BAR_TYPE.LOADER
		newState.isVisible = true

		return newState
	},
	[ACTION_BAR_SET_BUTTON]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isVisible = true
		newState.actionState = action.actionState
		// sets loadPercent or loadTime
		if (action.actionButtonLoaderType) {
			newState[action.actionButtonLoaderType] = action.actionButtonLoaderValue
		}
		newState.loaderType = action.actionButtonLoaderType

		newState.actionType = ACTION_BAR_TYPE.BUTTON
		newState.buttonText = action.text

		return newState
	},
	[ACTION_BAR_SET_HINT]: (state, action) => ({
		...state,
		isVisible: true,
		actionType: ACTION_BAR_TYPE.HINT,
		actionState: ACTION_BUTTON_STATE.DEFAULT,
		hintText: action.hintText,
	}),
	[ACTION_BAR_HIDE]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.isVisible = false
		return newState
	},
	[ACTION_SET_ACTION_SUBMIT_LISTENER]: (state, action) => {
		let newState = Object.assign({}, state)
		newState.primaryListener = action.primaryListener
		newState.secondaryListener = action.secondaryListener

		return newState
	},
	[ACTION_SET_REPORT_ANSWER_LISTENER]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.reportListener = action.reportListener
		return newState
	},
	[ACTION_BAR_SET_RIGHT_OFFSET]: (state, action) => {
		const newState = Object.assign({}, state)
		newState.rightOffset = action.offset
		return newState
	},
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
