/* eslint-disable max-len */

const messages = {
	not_found: 'Ojú-ewé tí o ń wá kò sí nílẹ̀',
	service_outage:
		'A tọrọ gáfárà pé nítorí àìṣiṣẹ́ káríayé ti olùpèsè àwọn iṣẹ́ inú àwọsánmọ̀ wa ẹ̀rọ wa kò ṣiṣẹ́.',
	error_unexpected_error: 'Ìṣòro àìlérò kan jẹyọ. Jọ̀wọ́ gbìyànjú lẹ́ẹ̀kan síi nígbà míràn.',

	error_img_alt: 'Ìbọ̀rọ àwòrán',
	error_try_again: 'Fẹ́ àwọn iṣẹ́ yii',

	error_generic_header: 'Ohun kan ko lọ déédé',
	error_generic_message:
		'Gbìyànjú ìyẹn lẹ́ẹ̀kan síi, tí kò bá sì ṣiṣẹ́ síbẹ̀, jọ̀wọ́ fí í-meèlì ṣọwọ́ sí support@groupsolver.com pẹ̀lú kóòdù QR tí o rí lókè.',

	error_offline_header: 'Ó dàbí wípé o kò sí lórí ayélujára',
	error_offline_message: 'Ìwádìí rẹ yóò tún bẹ̀rẹ̀ lẹ́yìn tí ìsopọ̀ rẹ bá ti fìdímúlẹ padà̀.',

	error_landscape_small_screen_header: 'Jọ̀wọ́ ṣe ìyíkiri ẹ̀rọ rẹ',
	error_landscape_small_screen_text: 'Àwòrán rẹ ti kéré jù láti rí ẹ̀kọ́ yii. Jọ̀wọ́ yí ẹ̀rọ rẹ kiri. ',

	error_small_browser_window_header: 'Jọ̀wọ́ fẹ wíndò aṣàwákiri rẹ',
	error_small_browser_window_text:
		'Wíndò rẹ kéré jù láti wo ẹ̀kọ́ yìí. Jọ̀wọ́ fẹ wíndò aṣàwákiri rẹ lójú',

	login_error_empty_email: 'Í-meèlì kò le ṣófo',
	login_error_invalid_email: 'Jọ̀wọ́ tẹ í-meèlì tó tọ̀nà wọlé',
	login_error_unable_to_login: 'Kò le wọlé ní àkókò yìí.  Jọ̀wọ́ gbìyànjú lẹ́ẹ̀kan sii tó bá ṣe díẹ.',

	login_email_placeholder: 'Tẹ í-meèlì rẹ wọlé',
	login_email_button: 'Tẹ̀síwájú',
	login_email_back: 'tàbí padà sí àwọn àṣàyàn ìwọlé',
	login_email_label: 'Tẹsiwájú pẹ̀lú í-meèlì',
	login_anonymous_label: 'Tẹ̀síwájú ní àìlórúkọ',

	login_privacy_statement: 'GroupSolver bọ̀wọ̀ fún ohun ìpamọ́ rẹ àti ìwífún-ni tí o pèsè.',
	login_privacy_statement_email:
		'A kò ní pín, tà, tàbí lo í-meèlì rẹ ní ọ̀nà míràn yàtọ̀ sí láti fún ọ ní ìráyèsí iṣẹ́-àkànṣe yìí.',
	login_privacy_policy: 'Òfin ìpamọ́',

	study_unavailable_label: 'Ìwádìí́ tí ò ń wá kò sí nílẹ̀ lọ́wọ́lọ́wọ́.̀̀',
	study_unavailable_description:
		'O ṣeun fún ìfẹ́ tí o ní sí ìwádìí́ wa! Ó ṣeni láànù wípé, ìwádìí́ yìí ti dé òpin tàbí ó ní ìdádúró díẹ̀ ní àkókò yí.  A tọrọ gáfárà fún ìdàmú tí ó ti lè ṣokùnfà.',
	study_preview_limit_reached_label: 'O ti dé òpin ìrìn àjò rẹ',
	study_preview_limit_reached_description:
		'O ṣeun fún ìfẹ́ tí o ní sí ìwádìí́ wa! Ó ṣeni láànú pé, ìwádìí yii ti dé òpin àwọn àkówò tó wà nílẹ̀.  ',

	waiting_message:
		'Ọ̀pọ̀lọpọ̀ ènìyàn ló n wá ìyanjú sí ìwádìí ní báyìí. \n A ó tún gbìyànjú ní aládàáṣe ìtẹ̀wọlé {number, plural, =0 { báyìí} one {ní # ìṣẹ́jú àáyá} other {ní # àwọn ìṣẹ́jú àáyá}}.',
	waiting_error_recoverable: 'A ti kojú àṣìṣe kan tí a kò rò tẹ́lẹ̀.',
	waiting_error_recoverable_retry:
		'A ó tùn gbìyànjú lẹ́ẹ̀kansii {number, plural, =0 {báyìí} one {ní # ìṣẹ̀jú àáyá} other {ní # àwọn ìṣẹ́jú àáyá}}',
	waiting_error_unknown_study: 'Ìwádìí tí ò n wá kò sí.',
	waiting_error_dead: 'Ìṣòro àìlérò kan jẹyọ. Jọ̀wọ́ gbìyànjú lẹ́ẹ̀kan sii nígbà míràn. ',

	action_input_submit_button_text: 'FI SÍLẸ̀',
	action_input_skip_button_text: 'Tẹ̀síwájú',

	choice_single_hint: 'Yan ọ̀kan nínú àwọn àṣàyàn',
	general_skip_action_message: 'Tẹ̀síwájú sí ìgbésẹ̀ tókàn',

	action_button_primary_default_text: 'Ìṣe',
	action_button_hint_default_text: 'Mú ọ̀kan nínú àwọn àṣàyàn',

	message_media_examine_video: 'Tẹ fídíò náà láti tún un wò',
	message_media_examine_image: 'Tẹ àwòrán náà láti tún un wò',
	message_full_screen_close_button_text: 'Pádé',
	message_image_view_label: 'Wo Àwòrán',
	message_video_view_label: 'Wo Fídíò',
	message_image_examined_label: 'Ti ṣàtúnwò àwòrán',
	message_video_examined_label: 'Ti ṣàtúnwò fídíò',
	message_zoom_mouse: 'Rábàbà láti sún un',
	message_zoom_touch: 'Fọwọ́kàn láti sún un',

	textarea_too_long_hint: 'Nnkan kíkọ rẹ ti gùn jù',

	ideate_placeholder: 'Dáhùn ní (àwọn) gbólóhùn kúkurú',
	ideate_skip: ' tàbí tẹ̀síwájú',

	eliminate_no: 'Bẹ́ẹ̀kọ́',
	eliminate_yes: 'Bẹ́ẹ̀ni',
	eliminate_intro: 'Ṣàgbéyẹ̀wò gbólóhùn wọ̀nyí:',
	eliminate_multiple_suggestions: 'Èwo nínú ìwọ̀nyí ni ó le rọ́pò rẹ̀?',
	eliminate_one_suggestion: 'Ṣé ìwọ̀nyí le rọ́pò rẹ̀ bí?',
	eliminate_step: 'Ṣàgbéyẹ̀wò gbólóhùn míràn:',
	eliminate_none_of_the_above: 'Kò kí n ṣẹ èyíkéyìí nínú wọn',

	elaborate_indifferent_label: 'Àárín',
	elaborate_disagree_label: 'Kò Faramọ́́',
	elaborate_agree_label: 'Faramọ́',
	elaborate_unclear_statement_label: 'The answer is no',
	elaborate_intro: "Àwọn 'èsì tí àwọn míràn pèsè nì wọ̀nyí.  Njẹ́ o fara mọ́ wọn bí?",

	ideamatching_unsure_label: 'Kò dájú',
	ideamatching_unequal_label: 'Ó yàtọ̀',
	ideamatching_equal_label: 'Bákannà',
	ideamatching_intro: 'Wo ẹyọ àwọn èsì yí.  Njẹ́ wọ́n túmọ̀ sí nnkan kannà?',

	select_input_placeholder: 'Tẹ̀ síbí láti yàn',
	select_input_placeholder_max: "It's overkill",
	select_multiple_choice_hint:
		'Yan {minNumber, plural, =0 { 0 } other { # }} si {maxNumber, plural, =0 { 0 } other{ # }} àṣàyàn',
	select_no_matches_found: 'Kò rí ohunkóhun tó bá dọ́gba',

	report_content_inappropriate: 'Ìdáhùn tí kò tọ̀nà',
	report_content_profane: 'Àkóónú òdì tàbí ìsọkúsọ',
	report_content_gibberish: 'Ọ̀rọ̀ àìmọ́gbọ́wá tàbí àsé',
	report_content_irrelevant: 'Àìwúlò sí ìbéèrè tí a bèrè',
	report_content_different_language: 'Èdè tó yàtọ̀',
	report_content_other: 'Òmíràn',
	report_content_no_content: 'Kò si àkóónú láti jábọ̀',
	report_content_header: 'Iroyin idahun',
	report_content_form_header: 'Jọ̀wọ́ yan ìdí tí o fi fẹ́ ṣàmìsí ìdáhùn yìí',
	report_content_flag_answer: 'Ṣàmìsí ìdáhùn',
	report_content_return_to_study: 'Padà sí ìwádìí',
	report_content_thank_you:
		'O ṣeun fún ìjábọ̀ rẹ.\nA ó ṣàtúnwò ìtẹ̀wọlé rẹ a ó sì gbé ìgbésẹ̀ tó bá yẹ.',
	report_content_statement_flagged: 'A ti ṣàmìsí gbólóhùn náà.',

	platform_update_header: 'GroupSolver wà lábẹ́ àbójútó tí a ṣètò lọ́wọ́lọ́wọ́',
	platform_update_message: 'A tọrọ gáfárà fún èyíkéyìí wàhála.',

	none_of_these: 'Kòsí ìkankan nínú ìwọ̀nyí',

	allocation_instruction_limit: 'Pín {points} káàkiri àwọn àṣàyàn',
	allocation_instruction_limit_no_minimum: 'Pín èyí tí ó tó {points} yíká àwọn àṣàyàn',
	allocation_instruction_no_limit: 'Pín àwọ̀n àmì káàkiri àwọn àṣàyàn',
	allocation_instruction_points_left: '{points} tó kù',
	allocation_options_out_of_range: 'Àwọn àṣàyàn kan kò sí nínú ààlà',
	allocation_limit_overflow: '{points} tó ju òdíwọ̀n lọ',
	allocation_option_invalid_range: 'Iye ti kọjá {range} ààlà',
	allocation_option_invalid_min: 'Iye gbọ́dọ̀ pọ̀jù tàbí dọ́gba sí {min}',

	dropdown_select: 'Yan...',

	ranking_drag_options: 'Wọ́ tàbí tẹ àwọn ọfà láti fún àwọn àṣàyàn ní ipò',
	ranking_assign_option_values: 'Ṣe ipò gbogbo àṣàyàn',
	ranking_require_unique_values: 'Àwọn ipò àṣàyàn ní láti yàtọ̀',

	next: 'Èyí tó kàn',
	technical_difficulties: 'Mo ní àwọn ìṣòro ìmọ̀-ẹ̀rọ',

	heatmap_instruction_intro: 'Láti bẹ̀rẹ̀ yíyàn, wo àwòrán nísàlẹ̀.',
	heatmap_instruction_unlimited: 'Yan ọ̀pọ̀ àwọn àmì bí o ti fẹ́ nípa títẹ àwòrán náà.',
	heatmap_instruction_count:
		'Yan {number, plural, =0 {àwọn àmì 0} one {àmì kan} other {{number} àwọn àmì}} nípa títẹ àwòràn náà.',
	heatmap_instruction_up_to_count:
		'Yan {number, plural, =0 {àwọn àmì 0} one {àmì kan} other {tí ó tó bii {number} àwọn àmì}} nípa títẹ àwòràn náà.',
	heatmap_instruction_between_count: 'Yan {from} sí {to} àwọ̀n àmì nípa títẹ àwòrán náà',
	heatmap_instruction_at_least_count:
		'Yan ó kéré jù {number, plural, =0 {àwọn àmì 0} one {àmì kan} other {{number} àwọn àmì}} nípa títẹ àwòrán náà.',
	heatmap_response:
		'{number, plural, =0 {àwọn àmì 0} one {àmì 1} other {{number} àwọn àmì}} tí a yàn',

	continue: 'Tesiwaju',
	reset: 'Tun pada',
	input_invalid: 'Gbigbasilẹ ko wulo.',
	input_empty: 'Gbigbasilẹ ko le ṣofo.',
	input_chars_left:
		'{number} {number, plural, =0 {awọn ohun kikọ} one {ohun kikọ} other {awọn ohun kikọ}} ti o ku.',
	input_too_long:
		'{number} {number, plural, =0 {awọn ohun kikọ} one {ohun kikọ} other {awọn ohun kikọ}} kọja opin.',
	ranking_select_rank: 'Yan ipo ti {label}.',
	ranking_select_aria_label: 'Yan ipo ti aṣayan kọọkan nipa titẹ iye kan.',
	ranking_dnd_aria_label:
		'Fojusi lori aṣayan kan ki o tẹ aaye lati bẹrẹ gbigbe. Nigbati o ba n gbe, o le lo awọn bọtini itọka lati gbe nkan naa kaakiri ati sa fun lati fagilee. Tẹ aaye lẹẹkansi lati gbe nkan naa si ipo tuntun. Rii daju pe oluka iboju rẹ wa ni ipo idojukọ tabi ipo awọn fọọmu.',
	allocation_option_input: 'Gbigbasilẹ iye {label}.',
	allocation_aria_label: 'Tẹ iye kan fun aṣayan kọọkan nipa lilo awọn gbigbasilẹ.',
	play: 'Mu ṣiṣẹ',
	pause: 'Duro',
	stop: 'Duro',
	fwd: 'Fo siwaju',
	rwd: 'Tun pada',
	volume_down: 'Din iwọn didun',
	volume_up: 'Mu iwọn didun pọ si',
	volume_current: 'Iwọn didun: {number}%',
	loading: 'Nṣiṣẹ',
	press_tab_to_continue: 'Tẹ tab lati tẹsiwaju',
	option_focus_selected: 'Aṣayan {label} ti dojukọ. Tẹ tẹ lati yọ.',
	option_focus_not_selected: 'Aṣayan {label} ti dojukọ. Tẹ tẹ lati yan.',
	image_alt: 'aworan:',
	video_alt: 'fidio:',
	submit_enabled: 'Firanṣẹ ti muu ṣiṣẹ.',
	skip_enabled: 'Fo ibeere ti muu ṣiṣẹ',
	n_of_min_selected: '{selected} ti {min} ti a ti yan.',
	option_selected: '{label} ti a ti yan.',
	option_deselected: '{label} ti a ti yọ.',
	choice_multiple_hint: 'Yan {min} si {max} awọn aṣayan.',
	ranking_position: 'ipo: {position}',
	ranking_position_changed: 'Iye {label} ti ṣeto si {value}.',
	ranking_input_aria_label: '{label}. Ipo lọwọlọwọ: {position}. Tẹ ipo kan lati 1 si {max}.',
	not_selected: 'ko yan',
	study_logo_alt: 'Aami ikẹkọ',
	groupsolver_logo_alt: 'Aami Gropsolver',
}

export default messages
