import PropTypes from 'prop-types'
import React from 'react'

import './VideoChatLayout.scss'

const VideoChatLayout = props => {
	return <div className="VideoChatLayout">{props.children}</div>
}

VideoChatLayout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default VideoChatLayout
