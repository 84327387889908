import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import CoreLayout from './CoreLayout'
import { handleStatus } from 'store/networkChecker'

const mapActionCreators = {
	handleStatus,
}

const mapStateToProps = state => ({
	showFullScreen: state.chatView.showFullScreen,
	originalErrorMessage: state.appError.errorMessage,
	errorType: state.appError.type,
	errorCode: state.appError.code,
	errorTrace: state.appError.trace,
	errorId: state.appError.id,
	user: state.user,
	token: state.api.token,
	showTryAgain: state.appError.showTryAgain,
	errorHeader: state.appError.header,
	errorMessage: state.appError.message,
	online: state.networkChecker.online,
	isInPreviewMode: state.study.isInPreviewMode,
})

export default connect(
	mapStateToProps,
	mapActionCreators,
)(injectIntl(CoreLayout))
