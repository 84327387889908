import { v4 as uuidv4 } from 'uuid'

export default (store, authHandler, loadStudyOptionsSpy = null) => ({
	path: 'retake(/:studyId)',
	onEnter(nextState, replace) {
		let idStudy = nextState.params.studyId
		let query = nextState.location.search
		let gsUuid = uuidv4()

		// if query exists
		if (query.indexOf('?') === 0) {
			query += '&gs_retake=true&gs_stamp=' + gsUuid
		} else {
			query = '?gs_retake=true&gs_stamp=' + gsUuid
		}
		replace('/login/' + idStudy + query + nextState.location.hash)
	},
})
