export const getStudyObjectDefinition = () => (dispatch, getState) => {
	let state = getState()
	return state.study.studyObject.definition
}

export const getStudyObject = () => (dispatch, getState) => {
	let state = getState()
	return state.study.studyObject
}

export const getIsAccessibilityEnabled = state => {
	return state.study.isAccessibilityEnabled
}
