import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import classnames from 'classnames'

import { ACTION_BAR_TYPE } from 'constants/actionBar'
import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import ActionButton from 'components/ActionButton'

import './Heatmap.scss'

const getHintCountText = (module, intl) => {
	const { minAnswers, maxAnswers } = module

	if (minAnswers === maxAnswers) {
		return intl.formatMessage(
			{ id: 'heatmap_instruction_count' },
			{
				number: maxAnswers,
			},
		)
	}

	if (minAnswers === 1 && maxAnswers !== null) {
		return intl.formatMessage(
			{ id: 'heatmap_instruction_up_to_count' },
			{
				number: module.maxAnswers,
			},
		)
	}

	if (minAnswers > 1 && maxAnswers !== null) {
		return intl.formatMessage(
			{ id: 'heatmap_instruction_between_count' },
			{
				from: minAnswers,
				to: maxAnswers,
			},
		)
	}

	if (minAnswers === 1 && maxAnswers === null) {
		return intl.formatMessage({ id: 'heatmap_instruction_unlimited' })
	}

	if (minAnswers > 1 && maxAnswers === null) {
		return intl.formatMessage(
			{ id: 'heatmap_instruction_at_least_count' },
			{
				number: module.minAnswers,
			},
		)
	}

	throw new Error(`unhandled case. minAnswers: ${minAnswers}, maxAnswers: ${maxAnswers}`)
}

const Heatmap = props => {
	const imgRef = useRef(null)
	const [zoom, setZoom] = useState(1)
	const [imgDimensions, setImgDimensions] = useState(null)

	const onImageLoaded = () => {
		const { height, width } = imgRef.current.getBoundingClientRect()

		setImgDimensions({ height, width })
	}

	const zoomIn = () =>
		setZoom(current => {
			return current < 4 ? current + 1 : current
		})

	const zoomOut = () =>
		setZoom(current => {
			return current > 1 ? current - 1 : current
		})

	if (props.isVisible === false) {
		return null
	}

	const { answers, definition } = props

	const message = getHintCountText(definition, props.intl)

	const canSubmit =
		answers.length >= definition.minAnswers && answers.length <= definition.maxAnswers

	const handleImageClick = event => {
		const eventX = event.clientX
		const eventY = event.clientY

		const rect = event.target.getBoundingClientRect()
		const absoluteX = eventX - rect.x
		const absoluteY = eventY - rect.y

		const { width, height } = rect

		const x = Math.round((absoluteX / width) * 1000000) / 10000
		const y = Math.round((absoluteY / height) * 1000000) / 10000

		if (answers.length >= definition.maxAnswers) {
			return
		}

		props.heatmapAddAnswer({ x, y })
	}

	const imgSize =
		imgDimensions === null
			? {}
			: { height: imgDimensions.height * zoom, width: imgDimensions.width * zoom }

	const imgStyle =
		zoom === 1
			? { ...imgSize, maxWidth: '100%', maxHeight: '100%' }
			: { ...imgSize, top: 0, left: 0 }

	return (
		<div className="Heatmap__holder">
			<div className="Heatmap__instruction">
				<div className="Heatmap__message">{message}</div>
				<div className="Heatmap__zoom-wrapper">
					<div
						className={classnames('Heatmap__zoom-button', {
							'Heatmap__zoom-button--disabled': zoom === 1,
						})}
						onClick={zoomOut}
					>
						-
					</div>
					<div>{zoom}x</div>
					<div
						className={classnames('Heatmap__zoom-button', {
							'Heatmap__zoom-button--disabled': zoom === 4,
						})}
						onClick={zoomIn}
					>
						+
					</div>
				</div>
			</div>
			<div className="Heatmap__image">
				<img
					ref={imgRef}
					src={definition.imageUrl}
					alt=""
					onClick={handleImageClick}
					onLoad={onImageLoaded}
					style={imgStyle}
				/>
				<div style={{ position: 'absolute', ...imgStyle, pointerEvents: 'none' }}>
					<div className="Heatmap__answers">
						{answers.map((answer, index) => (
							<div
								className="Heatmap__click"
								style={{ top: `${answer.y}%`, left: `${answer.x}%` }}
								key={index}
							/>
						))}
					</div>
				</div>
			</div>
			<div className="Heatmap__controls">
				<div>
					<ActionButton
						type={ACTION_BAR_TYPE.BUTTON}
						actionState={
							answers.length > 0 ? ACTION_BUTTON_STATE.SKIP_READY : ACTION_BUTTON_STATE.SKIP_PREP
						}
						isUppercase={false}
						buttonText={props.intl.formatMessage({ id: 'reset' })}
						onSecondarySubmit={() => props.heatmapResetAnswers()}
					/>
				</div>
				<div>
					<ActionButton
						type={ACTION_BAR_TYPE.BUTTON}
						actionState={
							canSubmit === true ? ACTION_BUTTON_STATE.SUBMIT_READY : ACTION_BUTTON_STATE.SKIP_PREP
						}
						isUppercase={false}
						buttonText={props.intl.formatMessage({ id: 'continue' })}
						onPrimarySubmit={() => props.heatmapSubmit()}
					/>
				</div>
			</div>
		</div>
	)
}

Heatmap.propTypes = {
	answers: PropTypes.arrayOf(
		PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
	).isRequired,
	definition: PropTypes.shape({
		imageUrl: PropTypes.string.isRequired,
		mandatory: PropTypes.bool.isRequired,
		maxAnswers: PropTypes.number,
		maxTimer: PropTypes.number,
		minAnswers: PropTypes.number.isRequired,
	}).isRequired,
	isVisible: PropTypes.bool.isRequired,
	intl: PropTypes.object.isRequired,
	// action creators
	heatmapAddAnswer: PropTypes.func.isRequired,
	heatmapResetAnswers: PropTypes.func.isRequired,
	heatmapSubmit: PropTypes.func.isRequired,
	// helpers
}

export default Heatmap
