/* eslint-disable max-len */

const messages = {
	not_found: 'La página que busca no existe',
	error_unexpected_error: 'Hubo un problema inesperado. Vuelva a intentarlo más tarde.',

	error_img_alt: 'Imagen de error',
	error_try_again: 'Vuelva a intentarlo',

	error_generic_header: 'Algo salió mal.',
	error_generic_message:
		'Vuelva a intentarlo y, si aún no funciona, envíe un correo electrónico a support@groupsolver.com con el código QR que ve en la parte superior.',

	error_offline_header: 'Parece que no tiene conexión a Internet',
	error_offline_message: 'Su estudió continuará cuando se restablezca la conexión.',

	error_landscape_small_screen_header: 'Gire su dispositivo',
	error_landscape_small_screen_text:
		'Su pantalla es muy pequeña para ver este estudio. Gire su dispositivo.',

	error_small_browser_window_header: 'Maximice la ventana del navegador',
	error_small_browser_window_text:
		'Su ventana es muy pequeña para ver este estudio. Maximice la ventana del navegador',

	login_error_empty_email: 'El correo electrónico no puede estar vacío',
	login_error_invalid_email: 'Ingrese un correo electrónico válido',
	login_error_unable_to_login:
		'No se puede hacer inicio de sesión en este momento. Vuelva a intentarlo más tarde',

	login_email_placeholder: 'Ingrese su correo electrónico',
	login_email_button: 'Siga',
	login_email_back: 'O vuelva a las opciones de inicio de sesión',
	login_email_label: 'Seguir con el correo electrónico',

	login_anonymous_label: 'Siga anónimamente',
	login_anonymous_description: 'Siga anónimamente',

	login_privacy_statement: 'GroupSolver respeta su privacidad y la información que proporciona.',
	login_privacy_statement_email:
		'Nunca se compartirá, venderá o utilizará su correo electrónico de ninguna manera que no sea para proporcionarle acceso a este proyecto.',
	login_privacy_policy: 'Privacy policy',

	study_unavailable_label: 'El estudio que está buscando no está disponible.',
	study_unavailable_description:
		'¡Gracias por su interés en nuestro estudio! Lamentablemente, este estudio ya se ha completado o está en pausa en este momento. Lamentamos los inconvenientes causados.',
	study_preview_limit_reached_label: 'El límite maximo de vistas previas ha sido alcanzado',
	study_preview_limit_reached_description:
		'¡Gracias por tu interés en nuestro estudio! Desafortunadamente, este estudio ha alcanzado el límite maximo de vistas previas disponibles.',

	waiting_message:
		'Varias personas están solucionando este estudio en este momento. \n Intentaremos ingresar automáticamente {number, plural, =0 { ahora } one {en # segundo} other {en # segundos}}.',
	waiting_error_recoverable: 'Encontramos un error inesperado.',
	waiting_error_recoverable_retry:
		'Intentaremos de nuevo {number, plural, =0 { ahora } one {en # segundo} other {en # segundos}}.',
	waiting_error_unknown_study: 'El estudio que busca no existe.',
	waiting_error_dead: 'Hubo un problema inesperado. Vuelva a intentarlo más tarde.',

	action_input_submit_button_text: 'ENVIAR',
	action_input_skip_button_text: 'OMITIR',

	choice_single_hint: 'Elija una de las opciones',
	general_skip_action_message: 'Ir al siguiente paso',

	action_button_primary_default_text: 'Acción',
	action_button_hint_default_text: 'Elija una de las opciones',

	message_media_examine_video: 'Haga clic en el video para revisarlo',
	message_media_examine_image: 'Haga clic en la imagen para revisarla',
	message_full_screen_close_button_text: 'Cerrar',
	message_image_view_label: 'Ver imagen',
	message_video_view_label: 'Ver video',
	message_image_examined_label: 'Imagen revisada',
	message_video_examined_label: 'Video revisado',
	message_zoom_mouse: 'Desplace mouse sobre foto para ampliar',
	message_zoom_touch: 'Presione foto para ampliar',

	textarea_too_long_hint: 'Su texto es demasiado largo',

	ideate_placeholder: 'Responda con oraciones cortas',
	ideate_skip: ' u omita',

	eliminate_no: 'No',
	eliminate_yes: 'Sí',
	eliminate_intro: 'Anteriormente, dijo lo siguiente:',
	eliminate_multiple_suggestions:
		'¿Cuál de las siguientes opciones podría reemplazar su respuesta?',
	eliminate_one_suggestion: '¿Podría la siguiente respuesta reemplazar la suya?',
	eliminate_step: 'Otra de sus respuestas era:',
	eliminate_none_of_the_above: 'Ninguna de ellas',

	elaborate_indifferent_label: 'No estoy seguro/segura',
	elaborate_disagree_label: 'No estoy de acuerdo',
	elaborate_agree_label: 'Estoy de acuerdo',
	elaborate_unclear_statement_label: 'Respuesta poco clara',
	elaborate_intro: 'Otras personas dieron las siguientes respuestas. ¿Está de acuerdo con ellas?',

	ideamatching_unsure_label: 'No estoy seguro/segura',
	ideamatching_unequal_label: 'Diferente',
	ideamatching_equal_label: 'Mismo',
	ideamatching_intro: 'Observe este par de respuestas. ¿Dicen lo mismo?',

	select_input_placeholder: 'Escriba aquí para seleccionar',
	select_input_placeholder_max: 'Se alcanzó el máximo',
	select_multiple_choice_hint:
		'Elija {minNumber, plural, =0 { 0 } other { # }} a {maxNumber, plural, =0 { 0 } other { # }} opciones',
	select_no_matches_found: 'No se encontraron coincidencias',

	report_content_inappropriate: 'Respuesta inapropriada',
	report_content_profane: 'Contenido obsceno or profano',
	report_content_gibberish: 'Galimatías o sin sentido',
	report_content_irrelevant: 'Irrelevante a la pregunta',
	report_content_different_language: 'Lenguaje diferente',
	report_content_other: 'Otro',
	report_content_no_content: 'No contenido para reportar',
	report_content_header: 'Reporta la respuesta',
	report_content_form_header: 'Seleccione la razón por la que desea marcar esta respuesta',
	report_content_flag_answer: 'Marca la respuesta',
	report_content_return_to_study: 'Regresar al estudio',
	report_content_thank_you: `Gracias por tus observaciones.\n
		Las revisaremos y tomaremos medidas si es necesario.`,
	report_content_statement_flagged: 'Declaración ha sido marcada',

	platform_update_header: 'GroupSolver se encuentra en proceso mantenimiento programado',
	platform_update_message: 'Nos disculpamos por cualquier inconveniencia.',

	none_of_these: 'Ninguna de estas',

	allocation_instruction_limit: 'Asignar {points} entre opciones',
	allocation_instruction_limit_no_minimum: 'Asignar hasta {points} entre opciones',
	allocation_instruction_no_limit: 'Asignar puntos entre opciones',
	allocation_instruction_points_left: '{points} restante',
	allocation_options_out_of_range: 'Algunas opciones están fuera de rango',
	allocation_limit_overflow: '{points} sobre el limite',
	allocation_option_invalid_range: 'El valor está fuera de {range} rango',
	allocation_option_invalid_min: 'El valor debe ser mayor que {min}',

	dropdown_select: 'Seleccionar...',

	ranking_drag_options: 'Arrastre o haga clic en las flechas para clasificar las opciones',
	ranking_assign_option_values: 'Clasifica todas las opciones',
	ranking_require_unique_values: 'Las clasificaciones deben ser únicas',

	heatmap_instruction_intro: 'Para comenzar sus selecciones, vea la siguiente imagen.',
	heatmap_instruction_unlimited: 'Seleccione tantos puntos como desee haciendo clic en la imagen.',
	heatmap_instruction_count:
		'Seleccione {number, plural, =0 {0 puntos} one {un punto} other {{number} puntos}} haciendo clic en la imagen.',
	heatmap_instruction_up_to_count:
		'Seleccione {number, plural, =0 {0 puntos} one {un punto} other {hasta {number} puntos}} haciendo clic en la imagen.',
	heatmap_instruction_between_count:
		'Seleccione entre {from} y {to} puntos haciendo clic en la imagen',
	heatmap_instruction_at_least_count:
		'Seleccione al menos {number, plural, =0 {0 puntos} one {un punto} other {{number} puntos}} haciendo clic en la imagen.',
	heatmap_response:
		'{number, plural, =0 {0 puntos} one {1 punto} other {{number} puntos}} seleccionados',

	continue: 'Continuar',
	reset: 'Reiniciar',

	input_invalid: 'La entrada no es válida.',
	input_empty: 'La entrada no puede estar vacía.',
	input_chars_left:
		'Quedan {number} {number, plural, =0 {caracteres} one {carácter} other {caracteres}}.',
	input_too_long:
		'Se superó el límite por {number} {number, plural, =0 {caracteres} one {carácter} other {caracteres}}.',

	// accessibility
	ranking_select_rank: 'Seleccione el rango de {label}.',

	ranking_select_aria_label: 'Seleccione el rango de cada opción escribiendo un valor.',
	ranking_dnd_aria_label:
		'Enfoque en una opción y presione la barra espaciadora para iniciar un arrastre. Al arrastrar, puede usar las teclas de flecha para mover el elemento y escape para cancelar. Presione la barra espaciadora nuevamente para colocar el elemento en la nueva posición. Asegúrese de que su lector de pantalla esté en modo de enfoque o modo de formulario.',

	allocation_option_input: 'Entrada de valor de {label}.',
	allocation_aria_label: 'Ingrese un valor para cada opción usando las entradas.',

	play: 'Reproducir',
	pause: 'Pausa',
	stop: 'Detener',
	fwd: 'Avanzar',
	rwd: 'Regresar',
	volume_down: 'Disminuir volumen',
	volume_up: 'Aumentar volumen',
	volume_current: 'Volumen: {number}%',

	loading: 'Cargando',

	press_tab_to_continue: 'Presione tabulación para continuar',

	option_focus_selected: 'Opción {label} enfocada. Presione enter para deseleccionar.',
	option_focus_not_selected: 'Opción {label} enfocada. Presione enter para seleccionar.',

	image_alt: 'imagen:',
	video_alt: 'video:',

	submit_enabled: 'Enviar habilitado.',
	skip_enabled: 'Omitir pregunta habilitado',
	n_of_min_selected: '{selected} de {min} seleccionados.',
	option_selected: '{label} seleccionado.',
	option_deselected: '{label} deseleccionado.',

	choice_multiple_hint: 'Seleccione de {min} a {max} opciones.',

	ranking_position: 'posición: {position}',
	ranking_position_changed: 'Valor de {label} establecido en {value}.',
	ranking_input_aria_label:
		'{label}. Posición actual: {position}. Escriba una posición de 1 a {max}.',
	not_selected: 'no seleccionado',

	study_logo_alt: 'Logo del estudio',
	groupsolver_logo_alt: 'Logo de Groupsolver',
}

export default messages
